import { Grid, Skeleton } from "@mui/material";

const SkeletonBody = ({ isPopupView }: { isPopupView?: boolean }) => {
  if (isPopupView) {
    return (
      <Grid container gap={2} sx={{ p: 3 }}>
        <Grid container gap={2}>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={50}
            width="20%"
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            height={50}
            width="75%"
          />
        </Grid>

        <Skeleton animation="wave" variant="rounded" height={50} width="100%" />
        <Skeleton
          animation="wave"
          variant="rounded"
          height={200}
          width="100%"
        />

        <Skeleton animation="wave" variant="rounded" height={50} width="100%" />
        <Skeleton
          animation="wave"
          variant="rounded"
          height={200}
          width="100%"
        />
      </Grid>
    );
  }

  return (
    <Grid container rowSpacing={1} sx={{ pb: 3 }}>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12} sm={6}>
          <Skeleton animation="wave" variant="rounded" height={30} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton animation="wave" variant="rounded" height={30} />
        </Grid>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12} sm={6}>
          <Skeleton animation="wave" variant="rounded" height={30} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton animation="wave" variant="rounded" height={30} />
        </Grid>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12} sm={6}>
          <Skeleton animation="wave" variant="rounded" height={30} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton animation="wave" variant="rounded" height={30} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SkeletonBody;
