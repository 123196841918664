import { Box, Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import styles from "./styles";

interface Props {
  contents?: JSX.Element;
}

export default function Carousel({ contents }: Props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const checkScrollable = () => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = containerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth + 1 < scrollWidth);
    }
  };

  useEffect(() => {
    checkScrollable();
    window.addEventListener("resize", checkScrollable);
    const tableContainerCurrentElement = containerRef.current;
    if (tableContainerCurrentElement) {
      tableContainerCurrentElement.addEventListener("scroll", checkScrollable);
    }
    return () => {
      window.removeEventListener("resize", checkScrollable);
      if (tableContainerCurrentElement) {
        tableContainerCurrentElement.removeEventListener(
          "scroll",
          checkScrollable,
        );
      }
    };
  }, []);

  const handleScroll = (direction: string) => {
    if (containerRef.current) {
      const { scrollLeft, clientWidth } = containerRef.current;
      const newScrollPosition =
        direction === "left"
          ? scrollLeft - clientWidth
          : scrollLeft + clientWidth;
      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
    setTimeout(() => {
      checkScrollable();
    }, 750);
  };

  return (
    <Box sx={styles.root}>
      <Box
        onClick={() => handleScroll("left")}
        sx={[styles.arrowGridLeft, canScrollLeft && styles.arrowVisibleLeft]}
      >
        <IconButton sx={[styles.arrow, !canScrollLeft && styles.hideIcon]}>
          <Box component="i" className="fa-solid fa-arrow-left" />
        </IconButton>
      </Box>
      <Grid container gap={isMobileScreen ? 0 : 2} alignItems="center">
        <Grid sx={styles.container} ref={containerRef} item xs>
          {contents}
        </Grid>
      </Grid>
      <Box
        onClick={() => handleScroll("right")}
        sx={[styles.arrowGridRight, canScrollRight && styles.arrowVisibleRight]}
      >
        <IconButton sx={[styles.arrow, !canScrollRight && styles.hideIcon]}>
          <Box component="i" className="fa-solid fa-arrow-right" />
        </IconButton>
      </Box>
    </Box>
  );
}
