import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { BentoBrand, SendOption } from "schemas/dashboard";
import { EmailSettings } from "schemas/email";
import { ALL } from "schemas/forms";

import { Template } from "features/Influencer/ContactList/schema";
import { makeDeepCopy } from "utils/updateLocalState";

import SendTemplateOption from "../PickSendOption";
import TemplatePerCategory from "../TemplatePerCategory";
import EditBrandList from "../TemplatePerCategory/EditBrandList";
import { BulkSendBrandsGroup } from "../schema";
import UsePreviousTemplateToggle from "./UsePreviousTemplateToggle";
import styles from "./styles";

interface Props {
  editList: string;
  setEditList: Dispatch<SetStateAction<string>>;
  sendOption?: SendOption;
  renderTimePickedOption: () => JSX.Element;
  categories: BulkSendBrandsGroup;
  setCategories: Dispatch<SetStateAction<BulkSendBrandsGroup>>;
  pickOption: (option: SendOption) => void;
  fromTrackingTable?: boolean;
  lastUsedTemplateSelected: boolean;
  setLastUsedTemplateSelected: Dispatch<SetStateAction<boolean>>;
  previewTemplate: (templates: Template[], bentoBrand: BentoBrand) => void;
  removeFromBulkBrands: (bentoBrand: BentoBrand) => void;
  allEmailSettings: EmailSettings[];
  groupingLoading: boolean;
}
const MainDialogBody = ({
  editList,
  setEditList,
  sendOption,
  renderTimePickedOption,
  categories,
  setCategories,
  pickOption,
  lastUsedTemplateSelected,
  setLastUsedTemplateSelected,
  previewTemplate,
  fromTrackingTable = false,
  removeFromBulkBrands,
  allEmailSettings,
  groupingLoading,
}: Props) => {
  const updateEmailSequencesByCategories = (
    category: string,
    templates: Template[],
  ) => {
    setCategories((prev) => {
      const copy = makeDeepCopy(prev);
      copy[category].fallbackTemplates = templates;
      return copy;
    });
  };

  if (editList) {
    const bentoBrands = categories[editList]?.emailSequences?.map(
      (es) => es.bentoBrand,
    );
    return (
      <EditBrandList
        bentoBrands={bentoBrands}
        category={editList}
        setCategories={setCategories}
        removeFromBulkBrands={removeFromBulkBrands}
        sendOption={sendOption}
        categories={categories}
      />
    );
  } else if (sendOption === SendOption.same) {
    return (
      <>
        <Grid container justifyContent="space-between">
          <Box>
            <strong> Select Templates</strong>
            <UsePreviousTemplateToggle
              fromTrackingTable={fromTrackingTable}
              lastUsedTemplateSelected={lastUsedTemplateSelected}
              setLastUsedTemplateSelected={setLastUsedTemplateSelected}
            />
          </Box>

          <Tooltip title="Preview templates">
            <IconButton
              sx={{ fontSize: 14, color: "black" }}
              onClick={() =>
                previewTemplate(
                  categories[ALL]?.fallbackTemplates,
                  categories[ALL]?.emailSequences?.[0]?.bentoBrand,
                )
              }
            >
              <Box component="i" className="fa-regular fa-eye" />
            </IconButton>
          </Tooltip>
        </Grid>

        <Typography variant="body2" sx={[styles.subtitle, { mb: 2 }]}>
          {lastUsedTemplateSelected
            ? "If brand has no previous templates, we will default to the templates you select below."
            : "Bento will automatically populate your outreach emails using your templates."}
        </Typography>

        {categories[ALL]?.emailSequences && (
          <TemplatePerCategory
            category={ALL}
            emailSequences={categories[ALL]?.emailSequences}
            fallbackTemplates={categories[ALL]?.fallbackTemplates}
            updateEmailSequencesByCategories={updateEmailSequencesByCategories}
            setEditList={setEditList}
            previewTemplate={previewTemplate}
            allEmailSettings={allEmailSettings}
          />
        )}
        {renderTimePickedOption()}
      </>
    );
  } else if (
    sendOption === SendOption.by_category ||
    sendOption === SendOption.by_automation
  ) {
    return (
      <>
        <Divider sx={{ my: 2, mx: -3 }} />
        <Typography sx={{ fontSize: 14 }}>
          Select Templates by{" "}
          {sendOption === SendOption.by_category ? "Category" : "Automation"}
        </Typography>
        <UsePreviousTemplateToggle
          fromTrackingTable={fromTrackingTable}
          lastUsedTemplateSelected={lastUsedTemplateSelected}
          setLastUsedTemplateSelected={setLastUsedTemplateSelected}
        />
        {Object.keys(categories).map(
          (category) =>
            categories[category]?.emailSequences &&
            categories[category]?.emailSequences?.length > 0 && (
              <TemplatePerCategory
                category={category}
                emailSequences={categories[category]?.emailSequences}
                fallbackTemplates={categories[category]?.fallbackTemplates}
                updateEmailSequencesByCategories={
                  updateEmailSequencesByCategories
                }
                key={category}
                setEditList={setEditList}
                previewTemplate={previewTemplate}
                groupingName={category}
              />
            ),
        )}
        <Divider sx={{ my: 2, mx: -3 }} />
        {renderTimePickedOption()}
      </>
    );
  } else if (allEmailSettings?.length > 0) {
    return (
      <SendTemplateOption
        sendOption={sendOption}
        pickOption={pickOption}
        validOptions={
          fromTrackingTable
            ? [SendOption.same]
            : [
                SendOption.same,
                SendOption.by_category,
                SendOption.by_automation,
              ]
        }
        groupingLoading={groupingLoading}
      />
    );
  } else {
    return <></>;
  }
};

export default MainDialogBody;
