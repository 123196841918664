export const COMMON_DOMAINS = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "outlook.com",
  "protonmail.com",
  "proton.me",
  "live.ca",
  "icloud.com",
  "rocketmail.com",
  "duck.com",
  "yandex.ru",
  "msn.com",
];

export const getDomain = (email: string) => {
  return email.split("@")[1];
};

export const isCommonDomain = (email: string) => {
  return COMMON_DOMAINS.includes(getDomain(email));
};
