import { Extension } from "@tiptap/react";

export const ShiftEnterCreateExtension = Extension.create({
  name: "newline",
  priority: 1000, // Override default behavior of TipTap

  // @ts-ignore
  addCommands() {
    return {
      addNewline:
        () =>
        ({ state, dispatch }: { state: any; dispatch: any }) => {
          const { schema, tr } = state;
          const paragraph = schema.nodes.paragraph;

          const transaction = tr
            .deleteSelection()
            .replaceSelectionWith(paragraph.create(), true)
            .scrollIntoView();
          if (dispatch) dispatch(transaction);
          return true;
        },
    };
  },
  addKeyboardShortcuts() {
    return {
      // @ts-ignore
      "Shift-Enter": () => this.editor.commands.addNewline(),
    };
  },
});
