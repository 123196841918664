import {
  Box,
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { BrandsContext } from "contexts/Brands";
import { useContext, useState } from "react";
import { BentoCategory, SearchParams } from "schemas/dashboard";

import { CATEGORY_ALL_FILTER } from "utils/localStorage";
import { trackEvent } from "utils/tracking";
import { useSearchBrand } from "utils/useSearchBrand";

interface Props {
  category: BentoCategory;
}

const ALL = "All";

const TopLevelCategory = ({ category: topLevelCategory }: Props) => {
  const allQueryString = sessionStorage.getItem(CATEGORY_ALL_FILTER);
  const allQueries = allQueryString ? JSON.parse(allQueryString) : {};

  const {
    addCategoryQueryToSearch,
    addAllCategoryIdQueryToSearch,
    removeCategoryIdWithAllSelected,
  } = useSearchBrand();
  const { discoverFilterParams } = useContext(BrandsContext);

  const selectedCategoryIds = discoverFilterParams.selectedCategoryIds;

  const [open, setOpen] = useState(false);
  const handleClick = (value: string) => {
    trackEvent("Category Expanded", { Category: parseInt(value) });
    setOpen((prev) => !prev);
  };

  const handleSelectCategory = (category: BentoCategory, value: string) => {
    if (value === ALL) {
      addAllCategoryIdQueryToSearch(
        SearchParams.CATEGORY_ID_DISCOVER,
        category.id,
      );
    } else {
      if (isAllSelected) {
        removeCategoryIdWithAllSelected(
          SearchParams.CATEGORY_ID_DISCOVER,
          parseInt(value),
          category.id,
        );
      } else {
        addCategoryQueryToSearch(SearchParams.CATEGORY_ID_DISCOVER, value);
      }
    }
  };

  const isAllSelected =
    `${topLevelCategory.id}` in allQueries ||
    Boolean(
      selectedCategoryIds?.find((x) => x.key === `${topLevelCategory.id}`),
    );

  return (
    <Box key={`${topLevelCategory.id}`}>
      <ListItemButton
        sx={{ py: 1, pl: 1, pr: 0.5 }}
        disableRipple
        onClick={() => handleClick(`${topLevelCategory.id}`)}
      >
        <Typography sx={{ fontSize: { xs: 14, md: 12 } }}>
          {topLevelCategory.name}
          {open ? (
            <Box component="i" className="fa-thin fa-minus" sx={{ ml: 1 }} />
          ) : (
            <Box component="i" className="fa-thin fa-plus" sx={{ ml: 1 }} />
          )}
        </Typography>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          {topLevelCategory?.subcategories?.map((subcategory) => (
            <ListItemButton
              key={`${subcategory.id}`}
              sx={{ pl: 2, py: 0 }}
              onClick={() =>
                handleSelectCategory(topLevelCategory, `${subcategory.id}`)
              }
            >
              <Checkbox
                edge="start"
                checked={
                  Boolean(
                    selectedCategoryIds?.find(
                      (x) => x.key === `${subcategory.id}`,
                    ),
                  ) || isAllSelected
                }
                tabIndex={-1}
                disableRipple
                icon={<Box component="i" className="fa-regular fa-square" />}
                checkedIcon={
                  <Box component="i" className="fa-solid fa-square-check" />
                }
                disabled={isAllSelected}
              />

              <ListItemText
                primary={subcategory.displayName || subcategory.name}
              />
            </ListItemButton>
          ))}

          <ListItemButton
            disableRipple
            sx={{ pl: 2, py: 0 }}
            onClick={() => handleSelectCategory(topLevelCategory, ALL)}
          >
            <Checkbox
              edge="start"
              checked={isAllSelected}
              tabIndex={-1}
              disableRipple
              icon={<Box component="i" className="fa-regular fa-square" />}
              checkedIcon={
                <Box component="i" className="fa-solid fa-square-check" />
              }
            />
            <ListItemText primary={"All"} />
          </ListItemButton>
        </List>
      </Collapse>
    </Box>
  );
};

export default TopLevelCategory;
