import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import { trackEvent } from "utils/tracking";

type QA = { question: string; answer: string | JSX.Element };

const QuestionsAndAnswers = [
  {
    question: "When does my subscription start?",
    answer: `Your 12-month subscription begins the first day your pitches are sent out by your VA. The time between now and when you approve your pitches is considered onboarding.`,
  },
  {
    question: "Do I approve the pitches before my VA begins pitching?",
    answer:
      "Yes! You will receive an email to review your pitches in your dashboard. No pitches will be sent out until you approve them.",
  },
  {
    question: "Can I add or change my pitches later on?",
    answer:
      "Yes! You can edit your pitches in your dashboard, and any new pitches that go out will use your most up-to-date pitch.",
  },
  {
    question: `Can I add a new niche later on?`,
    answer: (
      <>
        Yes! You will need to create a new pitch and title it{" "}
        <strong>VA - [New Niche Name]</strong>, then email{" "}
        <a href="mailto:va@onbento.com">va@onbento.com</a> to let us know you'd
        like to start pitching to a new niche. For example, if you create a new
        pitch titled <strong>VA - Pets</strong>, email us to mention you'd like
        to start pitching to pet brands and use the <strong>VA - Pets</strong>{" "}
        template for that niche
      </>
    ),
  },
  {
    question: "What if my VA is pitching to brands I don't like?",
    answer: (
      <>
        We use AI to decide which brands to pitch to on your behalf. You will
        receive a link to download our app, where you will be notified a few
        days before pitches go out. You can review the list of brands and delete
        any you don't want to be pitched to. Alternatively, if you'd like to be
        more hands-on, you can favorite the brands you'd like to be pitched to,
        and we will prioritize sending pitches to those brands. Once we've gone
        through your entire list, we will begin preselecting brands for you.
        <br />
        <strong>IMPORTANT:</strong> If you like to give feedback on why a brand
        is not a fit for you, please email us at{" "}
        <a href="mailto:feedback@onbento.com">feedback@onbento.com</a>.
      </>
    ),
  },
  {
    question: "Do pitches go out through my email?",
    answer:
      "Yes, emails will be sent from the email linked to your Bento account.",
  },
  {
    question:
      "How do I share successful deals I've received through the Virtual Assistant?",
    answer: (
      <>
        We'd love to hear about your successful deals! DM us on Instagram{" "}
        <a href="https://www.instagram.com/onbento/">(@onbento)</a> or email us
        at <a href="mailto:va@onbento.com">va@onbento.com</a>
      </>
    ),
  },
  {
    question: "Can I cancel my subscription?",
    answer: (
      <>
        If you cancel your subscription after pitches have been written for you,
        you will receive a partial refund (75% of your initial payment). If
        pitches have already been sent out, you have up to 60 days to receive a
        50% refund. After 60 days, no refunds will be issued. Email{" "}
        <a href="mailto:va@onbento.com">va@onbento.com</a> if you require a
        refund
      </>
    ),
  },
];

export default function AccordionUsage() {
  const trackFaqClick = (qa: QA) => {
    trackEvent("VA Onboarding Flow - FAQ Clicked", {
      question: qa.question,
    });
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        FAQ
      </Typography>
      {QuestionsAndAnswers.map((qa, index) => (
        <Accordion
          key={index}
          elevation={0}
          style={{ boxShadow: "none" }}
          sx={{ backgroundColor: "secondary.light" }}
          onClick={() => trackFaqClick(qa)}
        >
          <AccordionSummary
            expandIcon={
              <Box component="i" className="fa-regular fa-chevron-down" />
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {qa.question}
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">{qa.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
