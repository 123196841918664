import { pageStyles } from "components/Styled/Page/styles";
import { tableStyles } from "components/Styled/Table/sharedStyles";

const styles = {
  ...pageStyles,
  ...tableStyles,
  button: {
    fontSize: 14,
    color: "primary.main",
  },
  root: {
    pb: "60px",
  },
  banner: {
    backgroundColor: "secondary.light",
    px: 2,
    py: 1,
    borderRadius: 4,
  },
};

export default styles;
