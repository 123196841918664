import {
  Box,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { APIProvider } from "@vis.gl/react-google-maps";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import {
  QuickSendDrawerContext,
  QuickSendTabView,
} from "contexts/QuickSendDrawer";
import { useContext, useEffect, useRef } from "react";
import { CustomEvent } from "schemas/functions";

import { useAutoCompletePrediction } from "components/LocationAutocomplete/useAutoCompletePrediction";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";
import { usePageSize } from "utils/usePageSize";

import BrandCardActions from "../Results/BrandCard/BrandCardActions";
import DetailedBrandViewWrapper from "./Wrapper";
import BrandBody from "./components/BrandBody";
import { useBrandDetails } from "./components/BrandBody/useBrandDetails";
import styles from "./styles";

function DetailedBrandView() {
  const headRef = useRef<HTMLDivElement>(null);
  const { API_KEY } = useAutoCompletePrediction();
  const { isTwoColumnView, isLargeRecommendedPage } = usePageSize();
  const { hideBrandInfoViewDiscoverTab } = usePageSize();

  // Contexts
  const { currentOrg } = useContext(OrganizationUserContext);
  const {
    closeDrawer,
    brandDetailsId,
    brandView,
    setBrandView,
    setView,
    handleOpenBrandDetails,
    handleOpenQuickSendIndividual,
  } = useContext(QuickSendDrawerContext);
  const { discoverTab, setBrandNavigationHistory, brandNavigationHistory } =
    useContext(DiscoverViewContext);

  const {
    fetchSelectedBentobrand,
    selectedBrand,
    fetchLoading,
    similarBrands,
    fetchSimilarLoading,
  } = useBrandDetails({ brandDetailsId });

  useEffect(() => {
    if (brandDetailsId) {
      fetchSelectedBentobrand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandDetailsId]);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const onClose = (e?: CustomEvent) => {
    e?.stopPropagation();
    closeDrawer();
  };
  const handleHideDrawer = (e: CustomEvent) => {
    trackEvent("Hide Brand Info Button Clicked", {
      "Bento Brand ID": selectedBrand?.id,
      button: "Hide View",
    });
    if (isMobileScreen) {
      onClose();
    } else {
      setBrandView(QuickSendTabView.COLLAPSE);
      setView(QuickSendTabView.COLLAPSE);
    }
  };

  useEffect(() => {
    if (brandDetailsId > -1) {
      if (headRef && headRef.current) {
        headRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
      setBrandView(QuickSendTabView.EXPAND);
    }
    trackEvent("Detailed Brand View Drawer Shown", {
      "Bento Brand ID": selectedBrand?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandDetailsId, currentOrg?.id]);

  // Hide and show the brand view if user open Discover View on Recommendations page
  useEffect(() => {
    hideBrandInfoViewDiscoverTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discoverTab]);

  if (brandDetailsId < 0 || brandView === QuickSendTabView.COLLAPSE) {
    return <></>;
  }

  const returnToPreviousBrand = (e: CustomEvent) => {
    if (brandNavigationHistory?.length < 2) return;

    e.stopPropagation();

    const copy = makeDeepCopy(brandNavigationHistory);
    let lastBrand = copy[copy.length - 2];
    copy.pop();
    if (!lastBrand) {
      lastBrand = copy[0];
    }
    setBrandNavigationHistory(copy);
    handleOpenBrandDetails(lastBrand, "Similar Brands");
    handleOpenQuickSendIndividual(
      lastBrand?.id,
      "Return to Previous Similar Brands",
      undefined,
      false,
    );
  };

  return (
    <APIProvider apiKey={API_KEY || ""}>
      <DetailedBrandViewWrapper handleClose={onClose}>
        <>
          <div ref={headRef} />
          {isLargeRecommendedPage && !isTwoColumnView ? (
            <></>
          ) : (
            <DialogTitle sx={[styles.dialogTitleText, styles.dialogWrapper]}>
              <Grid
                onClick={handleHideDrawer}
                sx={styles.dialogGrid}
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item container alignItems="center" xs>
                  {brandNavigationHistory?.length > 1 && (
                    <IconButton
                      onClick={returnToPreviousBrand}
                      sx={[{ mr: 1 }, styles.icon]}
                    >
                      <Box component="i" className="fa-regular fa-arrow-left" />
                    </IconButton>
                  )}
                  {/* If hasContacts is undefined, we don't have information on if the brand has contacts or not, so don't render anything yet */}
                  {!isLargeRecommendedPage &&
                    selectedBrand &&
                    selectedBrand.hasContacts !== undefined && (
                      <BrandCardActions
                        brand={selectedBrand}
                        inBrandDetailsHeader
                      />
                    )}
                </Grid>

                <Box>
                  <IconButton sx={styles.icon} onClick={onClose}>
                    <Box component="i" className="fa-solid fa-xmark" />
                  </IconButton>
                </Box>
              </Grid>
            </DialogTitle>
          )}

          <BrandBody
            selectedBrand={selectedBrand}
            fetchLoading={fetchLoading}
            similarBrands={similarBrands}
            fetchSimilarLoading={fetchSimilarLoading}
            isPopupView
          />
        </>
      </DetailedBrandViewWrapper>
    </APIProvider>
  );
}

export default DetailedBrandView;
