import { LoadingButton } from "@mui/lab";
import { Box, Button, FormHelperText, Grid } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext } from "react";
import { SendOption } from "schemas/dashboard";

import { accessToVaFeature } from "utils/influencer";

import styles from "./styles";

interface Props {
  sendOption?: SendOption;
  pickOption: (option: SendOption) => void;
  groupingLoading: boolean;
  validOptions?: SendOption[];
}
const SendTemplateOption = ({
  sendOption,
  pickOption,
  groupingLoading,
  validOptions = [
    SendOption.same,
    SendOption.by_category,
    SendOption.by_automation,
  ],
}: Props) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { subscription, isVaPlan } = useContext(SubscriptionContext);
  const hasAccessToVa = accessToVaFeature(currentOrg, subscription, isVaPlan);

  return (
    <Box>
      <strong>How do you want to send your templates?</strong>
      <FormHelperText>
        {validOptions?.includes(SendOption.same) && (
          <>
            Same: same templates for all brands. <br />
          </>
        )}{" "}
        {validOptions?.includes(SendOption.by_category) && (
          <>
            By Category: different templates for each category.
            <br />
          </>
        )}{" "}
        {hasAccessToVa &&
          validOptions?.includes(SendOption.by_automation) &&
          "By Automation: different templates for each automation."}
      </FormHelperText>

      <Grid container gap={1} sx={{ py: 2 }}>
        {validOptions?.includes(SendOption.same) && (
          <Button
            sx={[
              styles.sendOption,
              sendOption === SendOption.same ? styles.selected : {},
            ]}
            onClick={() => pickOption(SendOption.same)}
          >
            Same
          </Button>
        )}
        {validOptions?.includes(SendOption.by_category) && (
          <Button
            sx={[
              styles.sendOption,
              sendOption === SendOption.by_category ? styles.selected : {},
            ]}
            onClick={() => pickOption(SendOption.by_category)}
          >
            By Category
          </Button>
        )}
        {hasAccessToVa && validOptions?.includes(SendOption.by_automation) && (
          <LoadingButton
            loading={groupingLoading}
            sx={[
              styles.sendOption,
              sendOption === SendOption.by_automation ? styles.selected : {},
            ]}
            onClick={() => pickOption(SendOption.by_automation)}
          >
            By Automation
          </LoadingButton>
        )}
      </Grid>
    </Box>
  );
};

export default SendTemplateOption;
