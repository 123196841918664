import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { SubscriptionType } from "schemas/dashboard";
import { Plans } from "schemas/payments";
import { routes } from "schemas/routes";

import { trackEvent } from "utils/tracking";
import { useSubscription } from "utils/useSubscription";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const UpgradeDialog = ({ open, handleClose }: Props) => {
  const [searchParams] = useSearchParams();
  const { subscription, isFreePlan } = useContext(SubscriptionContext);
  const checkoutSuccess = searchParams.get("checkout") === "success";

  const isSubscribed =
    subscription?.subscription?.status === SubscriptionType.ACTIVE &&
    !isFreePlan;

  const { handleCheckout, checkoutLoading } = useSubscription();

  const checkoutFromNeedsHelpDialog = () => {
    trackEvent("Subscribe Button Clicked from Onboarding Video");
    handleCheckout(
      Plans.PRO,
      undefined,
      "_self",
      `/${routes.dashboard}`,
      `Onboarding Video`,
    );
  };

  useEffect(() => {
    if (open && (isSubscribed || checkoutSuccess)) {
      trackEvent("Take your Pitching To Next Level Dialog Shown");
    } else if (open) {
      trackEvent("Upgrade Offer Dialog Shown");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, checkoutSuccess]);

  if (isSubscribed || checkoutSuccess) {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography
            variant="h6"
            textAlign="center"
            sx={{ fontSize: { xs: 13, md: 16 } }}
          >
            Take Your Pitching to Next Level ✨
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography>
            {checkoutSuccess &&
              "We're so glad to have you part of the Bento community 🙌."}{" "}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h6" textAlign="center">
            Upgrade Offer
          </Typography>
        </DialogTitle>

        <DialogContent>
          Pay $20 and receive 1 month of Bento's Pro account (send 1000 emails
          per month)
          <Grid container justifyContent="center" sx={{ my: 4 }}>
            <LoadingButton
              loading={checkoutLoading}
              variant="contained"
              disableElevation
              onClick={checkoutFromNeedsHelpDialog}
            >
              Subscribe
            </LoadingButton>
          </Grid>
          <Typography>
            Note: For <strong>newer creators</strong> this Offer is even more
            relevant to you. As a new creator without a track record, writing a
            catchy and unique email is the only way you will stand out!
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }
};

export default UpgradeDialog;
