import { useAuth } from "@clerk/clerk-react";
import { Box } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useState } from "react";
import { BentoBrand } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { sanitizeInput } from "features/Auth/Onboarding/AboutForm/helpers";
import { fetcherAuth } from "utils/api";

import BrandLink from "./BrandLink";
import EditDialog from "./EditDialog";
import { BentoBrandLinks } from "./helpers";
import { EditField } from "./schema";

interface Props {
  bentoBrand: BentoBrand;
}

export default function BrandLinks({ bentoBrand }: Props) {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setAlert, setErrorAlert } = useContext(AlertContext);
  const [editField, setEditField] = useState({
    name: "",
    value: "",
    label: "",
  });

  const onSubmit = async (
    e: CustomEvent,
    field: EditField,
    newValue: string,
  ) => {
    e.preventDefault();
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/user-suggestions/${bentoBrand.id}`,
        "POST",
        {},
        {
          fieldName: field.name,
          currentValue: sanitizeInput(field.value),
          suggestedValue: newValue,
          label: editField.label,
        },
      );
      setAlert("Your suggestion has been submitted.", "success");
      setEditField({ name: "", value: "", label: "" });
    } catch (error) {
      setErrorAlert(error);
    }
  };

  return (
    <Box>
      {Object.keys(BentoBrandLinks).map((key) => (
        <BrandLink
          name={key}
          bentoBrand={bentoBrand}
          setEditField={setEditField}
        />
      ))}

      <EditDialog
        open={Boolean(editField?.value)}
        handleClose={() => setEditField({ name: "", value: "", label: "" })}
        editField={editField}
        onSubmit={onSubmit}
      />
    </Box>
  );
}
