import { useAuth } from "@clerk/clerk-react";
import { Grid, Slide, useTheme } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import {
  ENTER_DELAY,
  EXIT_DELAY,
  SlideAnimationContext,
  SlideAnimationProvider,
} from "contexts/SlideAnimation";
import { SubscriptionContext } from "contexts/Subscription";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  OrganizationVaProfile,
  SearchParams,
  VAStatus,
  VaOnboardingType,
} from "schemas/dashboard";
import { Map } from "schemas/functions";
import { routes } from "schemas/routes";

import Alert from "components/Alert";
import Loading from "components/Loading";
import { PAGE_VISITED } from "constants/trackingProps";
import { fetcherAuth } from "utils/api";
import { getSource, identifyUser, trackEvent } from "utils/tracking";
import { useAlert } from "utils/useAlert";

import AboutForm from "../Onboarding/AboutForm";
import { getUserType } from "../Onboarding/AboutForm/helpers";
import CategoryForm from "../Onboarding/CategoryForm";
import { vaOnboardingNavigate } from "../Onboarding/helper";
import VAIntroduction from "../VAOnboarding/VAIntroduction";
import VAPaidPortal from "../VAOnboarding/VAPaidPortal";
import VAQuestions from "../VAOnboarding/VAQuestions";
import VASteps from "../VAOnboarding/VASteps";
import IncomeForm from "./IncomeForm";
import { getIncomeLevel } from "./IncomeForm/helpers";
import VAVideo from "./VAVideo";
import VaBackgroundForm from "./VaProfileForm/Background";
import VaFinalTouchForm from "./VaProfileForm/FinalTouch";
import VaNicheDetailsForm from "./VaProfileForm/NicheDetails";
import VaNichePickForm from "./VaProfileForm/NichePick";
import VaOnboardingTypeForm from "./VaProfileForm/OnboardingType";
import { redirectToMainDashboard, redirectToStep } from "./helpers";
import styles from "./styles";

const OnboardingVAInfluencer = () => {
  const {
    currentOrg,
    currentUser,
    setCurrentUser,
    setCurrentOrg,
    setUserNotifications,
    setUserIntegrations,
    setEmailSettings,
    setHideCityDialog,
    setProfile,
    setIncludedBentoSearchFilterIds,
    setExcludedBentoSearchFilterIds,
  } = useContext(OrganizationUserContext);
  const theme = useTheme();
  const { setSubscription } = useContext(SubscriptionContext);
  const [searchParams] = useSearchParams();

  const { slideOutUp, slideInUp, slideOutDown, slideInDown, slideProps } =
    useContext(SlideAnimationContext);
  const { fetchOutreachTemplates, templates } = useContext(
    OutreachTemplatesContext,
  );

  const referralCode = searchParams.get(SearchParams.REFERRAL_CODE);

  const [message, severity, setAlert, closeAlert] = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const { getToken, signOut } = useAuth();

  const [fetchUserLoading, setFetchUserLoading] = useState(true);
  const source = getSource();

  const { isVaPlan, subscription } = useContext(SubscriptionContext);

  const [onboardingForm, setOnboardingForm] = useState<Map>({
    name: currentUser?.name || "",
    location: "",
    brands: [],
    wishlistBrands: [],
    isUgcCreator: null,
    isInfluencer: null,
    incomeLevel: "",
    pastBrandValidation: null,
    primarySocialMediaPlatform: "instagram",

    // These fields are auto-filled when user sign up
    email: "",
    organizations: [],
    organizationId: "",
    organizationName: "",
    type: "influencer",
  });
  const [organizationVaProfile, setOrganizationVaProfile] =
    useState<OrganizationVaProfile | null>(null);
  const { emailHealth, setEmailHealth, setScopes, setUserIntegration } =
    useContext(UserIntegrationsContext);

  const fetchUserInfo = async () => {
    try {
      const res = await fetcherAuth(getToken, `/api/organization/auth/user`);
      setFetchUserLoading(false);
      identifyUser(res.organizationUser.id);
      setCurrentUser(res.organizationUser);
      setCurrentOrg(res.organization);
      setSubscription(res.subscription);
      setUserIntegrations(res.userIntegrations);
      setUserNotifications(res.userNotifications);
      setEmailSettings(res.emailSetting);
      setIncludedBentoSearchFilterIds(res.includedBentoSearchFilterIds);
      setExcludedBentoSearchFilterIds(res.excludedBentoSearchFilterIds);
      // Set OrganizationProfile and OrganizationVaProfile.
      if (res.organizationProfile) {
        setOnboardingForm((prev) => ({
          ...prev,
          ...res.organizationProfile,
          name: res.organizationUser.name,
          userType: getUserType(res.organizationProfile),
          incomeLevel: getIncomeLevel(res.organizationProfile),
        }));
        setProfile(res.organizationProfile);
      } else {
        setOnboardingForm((prev) => ({
          ...prev,
          name: res.organizationUser.name,
        }));
      }
      setOrganizationVaProfile(res.organizationVaProfile);
      setHideCityDialog(true);
      slideInUp();

      // If user comes through some specific emails that redirect to a certain page
      // skip the FAQ screen and go to that page
      const routeToDashboard = redirectToMainDashboard(
        res,
        currentOrg,
        location,
      );
      if (routeToDashboard) {
        navigate(routeToDashboard);
        return;
      }
      if (
        !new RegExp(`${routes.onboardingVaInfluencer}/?$`).test(
          location.pathname,
        )
      ) {
        return;
      }
      if (
        ![VAStatus.not_interested, VAStatus.disqualified]?.includes(
          res.organization.vaStatus,
        )
      ) {
        let grantedEmailAccess = emailHealth;
        if (!grantedEmailAccess) {
          const healthCheckRes = await fetcherAuth(
            getToken,
            `/api/organization/${res.organization.id}/user-integrations/health-check`,
          );
          grantedEmailAccess = healthCheckRes.emailHealth;
          setEmailHealth(healthCheckRes.emailHealth);
          setScopes(healthCheckRes.scopes);
          setUserIntegration(healthCheckRes.userIntegration);
        }
        const routeToStep = redirectToStep(res, grantedEmailAccess, location);
        if (routeToStep) {
          navigate(routeToStep);
        }
      } else {
        navigate(`/${routes.pitchOnboarding}${location.search}`);
      }
    } catch (error) {
      handleClerkUserSignUp();
    }
  };

  const handleClerkUserSignUp = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/auth/signup`,
        "POST",
        {},
        {
          referralCode,
          source: referralCode ? "referral" : source,
          signedUpForVa: true,
        },
      );
      identifyUser(res.organizationUser.id);
      setCurrentUser(res.organizationUser);
      setCurrentOrg(res.organization);
      setOnboardingForm((prev) => ({
        ...prev,
        name: res.organizationUser.name,
      }));

      setHideCityDialog(true);
      navigate(`/${routes.aboutVaOnboarding}${location.search}`);
      slideInUp();
    } catch (error) {
      // the user needs to be logged out now
      signOut();
      navigate(`/${routes.signIn}`);
      setAlert(
        error?.message || "Unable to fetch information from user.",
        "error",
      );
    } finally {
      setFetchUserLoading(false);
    }
  };

  const moveNext = async (onboardingType?: VaOnboardingType) => {
    await slideOutUp();

    navigate(
      `/${vaOnboardingNavigate(location.pathname, "next", false, onboardingType)}${location.search}`,
    );

    slideInUp();
  };

  const saveOnboardingForm = async (onboardingForm: Map, route: string) => {
    const onboardingFormReq = {
      ...onboardingForm,
      source,
      page: route,
      isVaFlow: true,
    };
    if (route) {
      trackEvent("Onboarding Form - Next Button Pressed", { route });
    }
    await moveNext(organizationVaProfile?.onboardingType);

    try {
      const { organizationProfile } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/onboarding`,
        "PUT",
        {},
        onboardingFormReq,
      );

      setOnboardingForm((prev) => ({
        ...prev,
        ...organizationProfile,
      }));
      setProfile({ ...organizationProfile });
    } catch (error) {
      await slideOutDown();
      slideInDown();
      navigate(`${route}${location.search}`);

      setAlert(
        error?.message ||
          "Unable to save your information. Please reload and try again",
        "error",
      );
    }
  };

  const saveVaProfileForm = async (
    organizationVaProfile: OrganizationVaProfile | null,
    page?: string,
    source?: string | null,
    skipMovingNext?: boolean,
  ) => {
    if (!organizationVaProfile) return;
    if (page) {
      trackEvent("Onboarding Form - Next Button Pressed", { route: page });
    }
    if (!skipMovingNext) {
      await moveNext(organizationVaProfile.onboardingType);
    }
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/onboarding/va-profile`,
        "PUT",
        {},
        { ...organizationVaProfile, page, source },
      );
      setOrganizationVaProfile(res.organizationVaProfile);
    } catch (error) {
      await slideOutDown();
      slideInDown();
      navigate(`${page}${location.search}`);
      setAlert(
        error?.message ||
          "Unable to save your information. Please reload and try again",
        "error",
      );
    }
  };

  useEffect(() => {
    fetchUserInfo();
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Fetch outreach templates to store for default pitch email.
    if (currentOrg?.id && templates?.length === 0) {
      fetchOutreachTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  const hasCompletedNormalFlow =
    onboardingForm?.pitchEmail || onboardingForm?.defaultGoal;
  const paidForVA = isVaPlan && subscription?.isSubscribed;
  const paidThroughSettings = paidForVA && hasCompletedNormalFlow;

  return fetchUserLoading ? (
    <Loading />
  ) : (
    <>
      <Grid container sx={styles.root}>
        <Slide
          in={slideProps.in}
          direction={slideProps.direction}
          easing={{
            enter: theme.transitions.easing.easeInOut,
            exit: theme.transitions.easing.easeIn,
          }}
          timeout={{
            enter: ENTER_DELAY,
            exit: EXIT_DELAY,
          }}
        >
          <Grid sx={styles.formContainer} item xs={12}>
            <Routes>
              <Route
                path="/about"
                element={
                  <AboutForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    isVaFlow={true}
                  />
                }
              />
              <Route
                path="/category"
                element={
                  <CategoryForm
                    onboardingForm={onboardingForm}
                    isVaOnboarding={true}
                    moveNext={moveNext}
                  />
                }
              />
              <Route
                path="/income"
                element={
                  <IncomeForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                  />
                }
              />
              <Route
                path="/va-introduction"
                element={
                  <VAIntroduction paidThroughSettings={paidThroughSettings} />
                }
              />
              <Route path="/va-paid-portal" element={<VAPaidPortal />} />
              <Route
                path="/va-steps"
                element={<VASteps paidThroughSettings={paidThroughSettings} />}
              />
              <Route
                path="/va-type"
                element={
                  <VaOnboardingTypeForm
                    organizationVaProfile={organizationVaProfile}
                    setOrganizationVaProfile={setOrganizationVaProfile}
                    saveVaProfileForm={saveVaProfileForm}
                  />
                }
              />
              <Route path="/va-video-overview" element={<VAVideo />} />
              <Route
                path="/va-niche-pick"
                element={
                  <VaNichePickForm
                    organizationVaProfile={organizationVaProfile}
                    setOrganizationVaProfile={setOrganizationVaProfile}
                    saveVaProfileForm={saveVaProfileForm}
                  />
                }
              />
              <Route
                path="/va-niche-details"
                element={
                  <VaNicheDetailsForm
                    organizationVaProfile={organizationVaProfile}
                    setOrganizationVaProfile={setOrganizationVaProfile}
                    saveVaProfileForm={saveVaProfileForm}
                  />
                }
              />
              <Route
                path="/va-background"
                element={
                  <VaBackgroundForm
                    organizationVaProfile={organizationVaProfile}
                    setOrganizationVaProfile={setOrganizationVaProfile}
                    saveVaProfileForm={saveVaProfileForm}
                  />
                }
              />
              <Route
                path="/va-final-touch"
                element={
                  <VaFinalTouchForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    saveVaProfileForm={saveVaProfileForm}
                    organizationVaProfile={organizationVaProfile}
                  />
                }
              />
              <Route
                path="/va-faq"
                element={
                  <VAQuestions organizationVaProfile={organizationVaProfile} />
                }
              />
            </Routes>
          </Grid>
        </Slide>
      </Grid>

      <Alert message={message} severity={severity} closeAlert={closeAlert} />
    </>
  );
};

export default function VAOnboarding() {
  return (
    <SlideAnimationProvider>
      <OnboardingVAInfluencer />
    </SlideAnimationProvider>
  );
}
