import { useAuth } from "@clerk/clerk-react";
import { SetStateAction, createContext, useContext, useState } from "react";
import { BentoCategory } from "schemas/dashboard";

import { fetcherAuth } from "utils/api";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";

type BentoCategoryMap = { [key: number]: BentoCategory };

interface BentoCategoriesContextInterface {
  topLevelCategories: BentoCategory[];
  setTopLevelCategories: React.Dispatch<SetStateAction<BentoCategory[]>>;
  categoriesById: BentoCategoryMap;
  setCategoriesById: React.Dispatch<SetStateAction<BentoCategoryMap>>;
  categoriesLoading: boolean;
  fetchBentoCategories: () => Promise<void>;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  topLevelCategories: [],
  setTopLevelCategories: defaultContextMissingFunction,
  categoriesById: {},
  setCategoriesById: defaultContextMissingFunction,
  categoriesLoading: false,
  fetchBentoCategories: defaultContextMissingFunction,
};

const BentoCategoriesContext =
  createContext<BentoCategoriesContextInterface>(defaultInterface);

interface BentoCategoriesProviderProps {
  children: React.ReactNode;
}

const BentoCategoriesProvider = ({
  children,
}: BentoCategoriesProviderProps) => {
  const { setErrorAlert } = useContext(AlertContext);
  const { getToken } = useAuth();
  const [topLevelCategories, setTopLevelCategories] = useState<BentoCategory[]>(
    [],
  );
  const [categoriesById, setCategoriesById] = useState<BentoCategoryMap>({});
  const { currentOrg } = useContext(OrganizationUserContext);
  const [categoriesLoading, setCategoriesLoading] = useState(true);

  const fetchBentoCategories = async () => {
    if (!currentOrg) {
      return;
    }

    setCategoriesLoading(true);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-categories`,
        "GET",
        {},
        {},
        false,
        false,
        true,
      );
      setTopLevelCategories(res.bentoCategories);
      const categoriesById: BentoCategoryMap = {};
      for (const category of res.bentoCategories) {
        categoriesById[category.id] = category;
        for (const subcategory of category.subcategories) {
          categoriesById[subcategory.id] = {
            ...subcategory,
            displayName: `${category.name} - ${subcategory.name}`,
          };
        }
      }

      setCategoriesById(categoriesById);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setCategoriesLoading(false);
    }
  };

  return (
    <BentoCategoriesContext.Provider
      value={{
        topLevelCategories,
        setTopLevelCategories,
        categoriesById,
        setCategoriesById,
        categoriesLoading,
        fetchBentoCategories,
      }}
    >
      {children}
    </BentoCategoriesContext.Provider>
  );
};

export { BentoCategoriesContext, BentoCategoriesProvider };
export type { BentoCategoryMap };
