import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { VA_MONEY_BACK_DEAL } from "constants/influencer";
import { CURRENT_VA_STEP } from "utils/localStorage";
import { getSource, trackEvent } from "utils/tracking";
import { useTracking } from "utils/useTracking";

import { vaOnboardingNavigate } from "../../Onboarding/helper";
import styles from "./styles";

interface Props {
  paidThroughSettings: boolean;
}

const VAIntroduction = ({ paidThroughSettings }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const slideContext = useContext(SlideAnimationContext);
  const { currentOrg } = useContext(OrganizationUserContext);

  const currentStep = localStorage.getItem(
    `${CURRENT_VA_STEP}-${currentOrg?.id}`,
  );

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const { trackVaStep } = useTracking();
  const source = getSource();

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "back")}${location.search}`,
    );
  };

  const steps = [
    {
      label: "🪄 We Pitch",
      description: `Bento uses AI + Humans to pitch to brands on your behalf using your email address.`,
    },
    {
      label: "📥 You Respond",
      description:
        "You are responsible for responding to and negotiating with brands when you get a response.",
    },
    {
      label: "💰 Money Back Guarantee",
      description: (
        <>
          If you do not land more than ${VA_MONEY_BACK_DEAL} in{" "}
          <Box component="span" sx={{ color: "black" }}>
            <strong>paid deals*</strong>
          </Box>{" "}
          in the next 12 months,{" "}
          <Box component="span" sx={{ color: "black" }}>
            <strong>you'll get your money back</strong>
          </Box>
          .
          <br />
          <br />
          <FormHelperText>
            * Paid deals mean actual income made from pitches by Bento for you.
            This excludes gifted partnerships, any partnerships you receive
            outside of the platform, and any affiliate partnerships. Must
            subscribe by April 7, 2025, 11:59pm EST.
          </FormHelperText>
        </>
      ),
    },
  ];

  const onReady = () => {
    trackVaStep(location.pathname, source);
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "next", paidThroughSettings)}${location.search}`,
    );
  };

  useEffect(() => {
    trackEvent(
      "VA Onboarding Flow - Bento's Income Guaranteed Program Steps Viewed",
      {
        Step: steps[activeStep]?.label,
      },
    );
    if (currentOrg?.id && activeStep > 0) {
      localStorage.setItem(
        `${CURRENT_VA_STEP}-${currentOrg?.id}`,
        activeStep?.toString(),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (currentStep && parseInt(currentStep) !== activeStep) {
      setActiveStep(parseInt(currentStep));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center", mt: 0 }}>
        📝 Bento's Virtual Assistant Program
      </Typography>

      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ textAlign: "center", mb: 3 }}
      >
        Learn how Bento's Virtual Assistant Program works.
      </Typography>

      <Box sx={{ maxWidth: 1000 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label} expanded={activeStep >= index}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography sx={styles.description}>
                  {step.description}
                </Typography>
                <Box sx={{ mb: 2 }}>
                  {activeStep === index && index !== steps?.length - 1 && (
                    <Button
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1, textTransform: "none" }}
                      size="small"
                      disableElevation
                      variant="contained"
                    >
                      Continue
                    </Button>
                  )}
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Grid item xs="auto" container columnGap={1} sx={{ mt: 4 }}>
        <Grid item xs="auto">
          <Button onClick={handleBack} color="secondary" disableElevation>
            Back
          </Button>
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          <Button
            disableElevation
            variant="contained"
            onClick={onReady}
            disabled={activeStep < steps?.length - 1}
          >
            I'm Ready{" "}
            <Box
              sx={{ ml: 1 }}
              component="i"
              className="fa-solid fa-arrow-right"
            />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default VAIntroduction;
