import { grey } from "@mui/material/colors";

const styles = {
  root: {
    width: { xs: "auto", md: "fit-content" },
    background: "white",
    border: `1px solid ${grey[300]}`,
    borderRadius: 2,
    position: "relative",
    p: 2,
  },
  videoContainer: {
    justifyContent: { xs: "center", md: "left" },
    width: { xs: "auto", md: "fit-content" },
    overflowX: "auto",
    "overscroll-behavior-x": "none",
  },
  closeContainer: {
    position: "absolute",
    right: { xs: 10, md: 10 },
    top: { xs: 10, md: 10 },
  },
  close: {
    color: grey[500],
    fontSize: 15,
    width: 20,
    height: 20,
  },
};

export default styles;
