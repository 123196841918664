import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Dispatch, SetStateAction } from "react";
import { BentoBrand, EmailSequence } from "schemas/dashboard";
import { EmailSettings } from "schemas/email";
import { ALL } from "schemas/forms";

import TemplateScreen from "components/TemplateScreen";
import { Template } from "features/Influencer/ContactList/schema";
import { generateInitials } from "utils/string";

interface Props {
  category: string;
  emailSequences: EmailSequence[];
  fallbackTemplates: Template[];
  updateEmailSequencesByCategories: (
    category: string,
    templates: Template[],
  ) => void;
  setEditList: Dispatch<SetStateAction<string>>;
  previewTemplate: (templates: Template[], bentoBrand: BentoBrand) => void;
  allEmailSettings?: EmailSettings[];
  groupingName?: string;
}
const TemplatePerCategory = ({
  category,
  emailSequences,
  fallbackTemplates,
  updateEmailSequencesByCategories,
  setEditList,
  previewTemplate,
  allEmailSettings,
  groupingName,
}: Props) => {
  const brands = emailSequences?.map((es) => es.bentoBrand);

  const setTemplates = (templates: Template[]) => {
    return updateEmailSequencesByCategories(category, templates);
  };

  const handleEditList = () => {
    setEditList(category);
  };

  const handlePreviewTemplate = () => {
    previewTemplate(fallbackTemplates, emailSequences[0]?.bentoBrand);
  };

  return (
    <Grid container sx={{ mb: 2 }} key={category} alignItems="center" gap={1}>
      {category !== ALL && (
        <>
          <Grid container alignItems="center" gap={1}>
            <Typography>
              <strong>
                {groupingName} ({brands?.length})
              </strong>
            </Typography>

            <Box sx={{ flexGrow: 1, display: "flex" }} gap={1}>
              <AvatarGroup
                max={10}
                sx={{
                  "& .MuiAvatar-root": {
                    width: 30,
                    height: 30,
                    fontSize: 12,
                    border: `1px solid ${grey[300]}`,
                  },
                }}
              >
                {brands?.map((bentoBrand) => (
                  <Tooltip
                    title={bentoBrand.brandName}
                    key={bentoBrand.id}
                    placement="top"
                  >
                    <Box>
                      {bentoBrand?.logoUrl ? (
                        <Avatar src={bentoBrand?.logoUrl} />
                      ) : (
                        <Avatar
                          sx={{ backgroundColor: "black", color: "white" }}
                        >
                          {generateInitials(bentoBrand?.brandName)}
                        </Avatar>
                      )}
                    </Box>
                  </Tooltip>
                ))}
              </AvatarGroup>
            </Box>

            <Grid item xs={2}>
              <Tooltip title="Preview Template">
                <IconButton
                  sx={{ fontSize: 12, color: "black" }}
                  onClick={handlePreviewTemplate}
                >
                  <Box component="i" className="fa-regular fa-eye" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit brands list">
                <IconButton
                  sx={{ fontSize: 12, color: "black" }}
                  onClick={handleEditList}
                >
                  <Box component="i" className="fa-regular fa-edit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      )}

      <TemplateScreen
        templates={fallbackTemplates}
        setTemplates={setTemplates}
        category={category}
        allEmailSettings={allEmailSettings}
      />
    </Grid>
  );
};

export default TemplatePerCategory;
