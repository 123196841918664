export enum AddOnName {
  support_call = "support_call",
  pitching_templates = "pitching_templates",
}

export type AddOnDetails = {
  display_name: string;
  max_quantity: number;
  min_quantity: number;
  price: number;
};

export enum AddOnDialogSource {
  top_navigation = "top_navigation",
  onboarding = "onboarding",
  settings = "settings",
  templates_page = "templates_page",
  templates_sidebar = "templates_sidebar",
  feature_announcement = "feature_announcement",
}

export enum TemplateScreen {
  niche = "niche",
  background = "background",
  completed = "completed",
}

export const TemplateScreenOrders = [
  TemplateScreen.niche,
  TemplateScreen.background,
];
