import { useAuth } from "@clerk/clerk-react";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BentoCategory } from "schemas/dashboard";
import { Map } from "schemas/functions";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { getSource, trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import { onboardingNavigate, vaOnboardingNavigate } from "../helper";
import styles from "./styles";

interface Props {
  onboardingForm: Map;
  moveNext?: () => void;
  isVaOnboarding?: boolean;
  setNeedsUpdateCategory?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CategoryForm = ({
  onboardingForm,
  moveNext,
  isVaOnboarding = false,
  setNeedsUpdateCategory,
}: Props) => {
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { currentOrg, setCurrentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const source = getSource();
  const slideContext = useContext(SlideAnimationContext);

  const [categoriesToPickFrom, setCategoriesToPickFrom] = useState<
    BentoCategory[]
  >([]);

  const categories = currentOrg?.bentoCategories;

  const handleSubmit = async (event: CustomEvent) => {
    event.preventDefault();
    trackEvent("Onboarding Form - Next Button Pressed", {
      route: location.pathname,
    });
    if (currentOrg?.bentoCategories?.length === 0) {
      setAlert("Please fill out at least one category", "error");
      return;
    }
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/onboarding/categories`,
        "PUT",
        {},
        {
          categoryIds: currentOrg?.bentoCategories?.map((x) => x.id),
          page: location.pathname,
          source,
          isVaFlow: isVaOnboarding,
        },
      );
      if (moveNext) await moveNext();
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    if (isVaOnboarding) {
      navigate(
        `/${vaOnboardingNavigate(location.pathname, "back")}${location.search}`,
      );
    } else {
      navigate(
        `/${onboardingNavigate(location.pathname, onboardingForm, "back")}${
          location.search
        }`,
      );
    }
  };

  const getCategoriesToPickFrom = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-categories/onboarding`,
      );
      setCategoriesToPickFrom(res.onboardingBentoCategories);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const isSelected = (chip: BentoCategory) => {
    return categories?.some((x: BentoCategory) => x.id === chip.id);
  };

  const addCategory = (copy: Map, category: BentoCategory) => {
    const index = copy.bentoCategories?.findIndex(
      (x: BentoCategory) => x.id === category.id,
    );
    if (index > -1) {
      copy.bentoCategories.splice(index, 1);
    } else {
      copy.bentoCategories.push(category);
    }
    return copy;
  };

  const selectChip = (chip: BentoCategory) => {
    if (setNeedsUpdateCategory) setNeedsUpdateCategory(true);
    setCurrentOrg((prev) => {
      const copy = makeDeepCopy(prev);
      if (!copy.bentoCategories) copy.bentoCategories = [];
      addCategory(copy, chip);
      const subcategories = categoriesToPickFrom?.find(
        (x) => x.id === chip.id,
      )?.subcategories;
      if (subcategories && subcategories?.length > 0) {
        subcategories.forEach((subcategory) => {
          addCategory(copy, subcategory);
        });
      }
      return copy;
    });
  };

  useEffect(() => {
    if (currentOrg?.id) {
      getCategoriesToPickFrom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return categoriesToPickFrom?.length > 0 ? (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="h3" gutterBottom sx={{ textAlign: "center" }}>
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-layer-group"
          />
          Your Brand Types
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ textAlign: "center", mb: 3 }}
        >
          What are your preferred types of brands to work with? *
        </Typography>
        <Grid container justifyContent="center" sx={{ my: 2 }} gap={2}>
          {categoriesToPickFrom?.map((category, index) => (
            <Chip
              key={index}
              sx={[styles.hover, isSelected(category) ? styles.selected : {}]}
              label={category.name}
              onClick={() => selectChip(category)}
            />
          ))}
        </Grid>
        <Grid item xs="auto" container columnGap={1}>
          <Grid item xs="auto">
            <Button onClick={handleBack} color="secondary" disableElevation>
              Back
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <Button
              disableElevation
              variant="contained"
              type="submit"
              disabled={categories?.length === 0}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  ) : (
    <></>
  );
};

export default CategoryForm;
