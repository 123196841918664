import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Button,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { QuickSendContext } from "contexts/QuickSend";
import { useContext, useEffect, useState } from "react";
import { CustomEvent, StringMap } from "schemas/functions";

import {
  CreateTemplateStatus,
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { containsMergeTagsTemplates, snakeToCamel } from "utils/string";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy, updateList } from "utils/updateLocalState";
import useTemplate from "utils/useTemplate";

import NextButton from "../../../SharedComponents/NextButton";
import NoMergeTagsWarningDialog from "../../NoMergeTagsWarningDialog";
import SignatureStep from "./FillInBlankStep/Signature";
import FinalizeFollowupStep from "./FinalizeStep/followup";
import FinalizeOutreachStep from "./FinalizeStep/outreach";
import GoalStep from "./GoalStep";
import PickedTemplateStep from "./PickedTemplate";
import { convertBentoTemplate, getTags, prefillSurvey } from "./helpers";
import {
  BentoTemplate,
  BentoTemplates,
  SelectedIndex,
  SelectedTemplates,
  StepNames,
  SurveyFollowUpStep,
  SurveyStep,
  TemplateCreateOption,
  TemplateSurvey,
} from "./schema";
import styles from "./styles";

interface PresetFlowProps {
  setIsImport: (isImport: boolean) => void;
  onSuccess: () => void;
  handleCloseDialog: () => void;
  isGeneratingFollowUp?: boolean;
}

export default function PresetFlow({
  setIsImport,
  onSuccess,
  handleCloseDialog,
  isGeneratingFollowUp,
}: PresetFlowProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { getToken } = useAuth();
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const [option, setOption] = useState<TemplateCreateOption | null>(null);

  // Contexts
  const {
    templates,
    setTemplates,
    userHasNoTemplates,
    setUserHasNoTemplates,
    setDefaultTemplates,
  } = useContext(OutreachTemplatesContext);
  const { filledInOrgProfile } = useTemplate();
  const { currentOrg, profile, setProfile, currentUser } = useContext(
    OrganizationUserContext,
  );
  const [enabledFollowUp, setEnabledFollowUp] = useState(false);
  const {
    setTemplateForQuickSend,
    newlyAddedTemplateIndex,
    handleRefetchPreviews,
  } = useContext(QuickSendContext);

  const [bentoTemplates, setBentoTemplates] = useState<BentoTemplates>({
    outreaches: [],
    followups: [],
  });
  const [selectedTemplates, setSelectedTemplates] = useState<SelectedTemplates>(
    {
      outreach: null,
      followup: null,
    },
  );
  const [selectedIndex, setSelectedIndex] = useState<SelectedIndex>({
    outreach: 0,
    followup: 0,
  });
  const [template, setTemplate] = useState<Template>({
    name: "",
    subject: "",
    contentType: TemplateContentType.OUTREACH,
    body: "",
  });

  const [validation, setValidation] = useState<StringMap>({
    goal: "",
    tone: "",
    highlight: "",
    content: "",
  });

  const [survey, setSurvey] = useState<TemplateSurvey>({ signature: "" });
  const [activeStep, setActiveStep] = useState<number>(0);
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [openMergeTagWarning, setOpenMergeTagWarning] =
    useState<boolean>(false);
  const [displayError, setDisplayError] = useState(false);

  const currentStepName =
    activeStep >= 0 && activeStep < StepNames?.length
      ? StepNames[activeStep]
      : "";

  const handleSurveyChange = (e: CustomEvent, orgProfileField?: string) => {
    let { name, value } = e.target;
    setSurvey((prevSurvey: TemplateSurvey) => {
      return {
        ...prevSurvey,
        [name]: value,
      };
    });
    if (orgProfileField) {
      const field = snakeToCamel(orgProfileField);
      setProfile((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
    setIsDirty(true);
  };

  const getBentoTemplates = async () => {
    if (!currentOrg?.id) {
      return;
    }

    if (isGeneratingFollowUp) {
      setValidation({
        tone: survey?.tone ? "" : "Please pick an option",
      });
      if (!survey.tone) {
        return;
      }
    } else {
      setValidation({
        tone: survey?.tone ? "" : "Please pick an option",
        goal: survey?.goal ? "" : "Please pick an option",
        highlight: survey?.highlight ? "" : "Please pick an option",
        content: survey?.content ? "" : "Please pick an option",
      });

      if (
        !survey.tone ||
        !survey.highlight ||
        !survey.goal ||
        !survey.content
      ) {
        return;
      }
    }
    let url = `/api/organization/${currentOrg?.id}/bento-templates?`;
    const { outreachTags, followupTags } = getTags(survey);
    try {
      setGenerateLoading(true);
      let outreachUrl = url;
      if (survey?.highlight) outreachUrl += `&highlight=${survey.highlight}`;

      let outreachBentoTemplates = [];
      if (
        survey?.goal &&
        survey?.tone &&
        survey?.content &&
        survey?.highlight
      ) {
        const outreachRes = await fetcherAuth(
          getToken,
          `${outreachUrl}${outreachTags}&content_type=${TemplateContentType.OUTREACH}`,
        );
        outreachBentoTemplates = outreachRes.bentoTemplates;
      }
      const followupRes = await fetcherAuth(
        getToken,
        `${url}${followupTags}&content_type=${TemplateContentType.FOLLOWUP}`,
      );
      setBentoTemplates({
        outreaches: outreachBentoTemplates,
        followups: followupRes.bentoTemplates,
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setGenerateLoading(false);
    }
  };

  const handleCreateTemplate = async (ignoreMergeTagWarning?: boolean) => {
    setIsDirty(false);

    if (!template?.name || template?.name?.trim()?.length === 0) {
      setDisplayError(true);
      return;
    }
    if (
      !containsMergeTagsTemplates(template.body || "") &&
      !ignoreMergeTagWarning
    ) {
      setOpenMergeTagWarning(true);
      return;
    }
    const existingName = templates?.find((x) => x.name === template?.name);
    if (existingName) {
      setAlert("You already have a template with the same name", "error");
      return;
    }
    try {
      setGenerateLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/templates`,
        "POST",
        {},
        template,
      );
      setTemplate(res.template);
      updateList(templates, setTemplates, res.template, "id");
      if (userHasNoTemplates) {
        setDefaultTemplates([res.template]);
        setUserHasNoTemplates(false);
      }
      handleSetNewTemplateOnQuickSend(res.template);
      if (
        selectedTemplates?.followup &&
        enabledFollowUp &&
        activeStep === SurveyStep.SAVE_OUTREACH
      ) {
        convertTemplate(selectedTemplates?.followup);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        filledInOrgProfile();
        onSuccess();
      }
    } catch (error) {
      setAlert(
        "Unable to create template. Please reload and retry again",
        "error",
      );
    } finally {
      setGenerateLoading(false);
      setDisplayError(false);
    }
  };

  const handleSetNewTemplateOnQuickSend = (newTemplate: Template) => {
    if (newlyAddedTemplateIndex === -1) {
      return;
    }
    const index =
      newTemplate?.contentType === TemplateContentType.OUTREACH ? 0 : 1;

    setTemplateForQuickSend((prev) => {
      const copy = makeDeepCopy(prev);
      copy[index] = newTemplate;
      handleRefetchPreviews(copy);
      return copy;
    });
  };

  const handleBack = () => {
    trackEvent(
      "Template Generation - Back Button on Preset Flow Dialog Pressed",
      {
        "Step Index": activeStep,
        "Step Name": currentStepName,
      },
    );
    if (activeStep <= 0) {
      handleCloseDialog();
    } else if (activeStep === SurveyStep.PICKED_TEMPLATE) {
      setSelectedTemplates({ outreach: null, followup: null });
      setSelectedIndex({ outreach: 0, followup: 0 });
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const convertTemplate = (bentoTemplate: BentoTemplate) => {
    const templateToCreate = convertBentoTemplate(
      bentoTemplate,
      survey,
      currentUser?.name,
    );
    setTemplate(templateToCreate);
  };

  const handleNext = () => {
    trackEvent(
      "Template Generation - Next Button on Preset Flow Dialog Pressed",
      {
        "Step Index": activeStep,
        "Step Name": currentStepName,
      },
    );
    if (activeStep === SurveyStep.GOAL) {
      getBentoTemplates();
    } else if (
      activeStep === SurveyStep.PICKED_TEMPLATE &&
      selectedTemplates?.outreach &&
      !isGeneratingFollowUp
    ) {
      convertTemplate(selectedTemplates?.outreach);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (
      activeStep === SurveyFollowUpStep.PICKED_TEMPLATE &&
      selectedTemplates?.followup
    ) {
      convertTemplate(selectedTemplates?.followup);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (
      [
        SurveyStep.SAVE_FOLLOWUP,
        SurveyStep.SAVE_OUTREACH,
        SurveyFollowUpStep.SAVE_FOLLOWUP,
      ]?.includes(activeStep)
    ) {
      handleCreateTemplate();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const goalStep = (
    <GoalStep
      survey={survey}
      handleSurveyChange={handleSurveyChange}
      isGeneratingFollowUp={isGeneratingFollowUp}
      validation={validation}
      setValidation={setValidation}
      option={option}
      setOption={setOption}
    />
  );

  const pickedTemplateStep = (
    <PickedTemplateStep
      bentoTemplates={bentoTemplates}
      enabledFollowUp={enabledFollowUp}
      setEnabledFollowUp={setEnabledFollowUp}
      setSelectedIndex={setSelectedIndex}
      selectedIndex={selectedIndex}
      selectedTemplates={selectedTemplates}
      setSelectedTemplates={setSelectedTemplates}
      isGeneratingFollowUp={isGeneratingFollowUp}
    />
  );

  const followUpStep = (
    <FinalizeFollowupStep
      isDirty={isDirty}
      template={template}
      setTemplate={setTemplate}
      displayError={displayError}
      setIsDirty={setIsDirty}
    />
  );

  const renderSteps = () => {
    if (activeStep === SurveyStep.GOAL) {
      return goalStep;
    } else if (activeStep === SurveyStep.SIGNATURE) {
      return (
        <SignatureStep
          survey={survey}
          handleSurveyChange={handleSurveyChange}
          orgProfileField={"signature"}
        />
      );
    } else if (activeStep === SurveyStep.PICKED_TEMPLATE) {
      return pickedTemplateStep;
    } else if (activeStep === SurveyStep.SAVE_OUTREACH) {
      return (
        <FinalizeOutreachStep
          isDirty={isDirty}
          template={template}
          setTemplate={setTemplate}
          displayError={displayError}
          setIsDirty={setIsDirty}
        />
      );
    } else {
      return followUpStep;
    }
  };

  const renderFollowUpSteps = () => {
    if (activeStep === SurveyFollowUpStep.GOAL) {
      return goalStep;
    } else if (activeStep === SurveyFollowUpStep.PICKED_TEMPLATE) {
      return pickedTemplateStep;
    } else {
      return followUpStep;
    }
  };

  const getDialogTitle = () => {
    if (templates?.length === 0) {
      return "Let's Create Your First Email Template";
    }
    if (activeStep === SurveyStep.PICKED_TEMPLATE) {
      return "Customize Template";
    }
    return "Create Template";
  };

  const goToImport = () => {
    setIsImport(true);
    trackEvent("Template Option Selected", {
      option: CreateTemplateStatus.SCRATCH,
      routes: window.location.href,
    });
  };

  useEffect(() => {
    if (profile && profile?.id > 0) {
      const defaultSurvey = prefillSurvey(profile);
      if (defaultSurvey) {
        setSurvey(defaultSurvey);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.id]);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: { xs: 2, md: 6 } }}
      >
        <Button onClick={handleBack} sx={{ textTransform: "none" }}>
          <Box
            component="i"
            className="fa-regular fa-arrow-left"
            sx={{ mr: 1 }}
          />
          Back
        </Button>
        {!isMobileScreen && (
          <Typography variant="h6">{getDialogTitle()}</Typography>
        )}
        <Button size="small" sx={styles.startFromScratch} onClick={goToImport}>
          Start From Scratch
        </Button>
      </Grid>
      <DialogContent sx={styles.dialogContent}>
        {isGeneratingFollowUp ? renderFollowUpSteps() : renderSteps()}
      </DialogContent>
      {option === TemplateCreateOption.user && (
        <NextButton
          handleNext={handleNext}
          activeStep={activeStep}
          loading={generateLoading}
          selectedTemplates={selectedTemplates}
          isGeneratingFollowUp={isGeneratingFollowUp}
        />
      )}
      <NoMergeTagsWarningDialog
        open={openMergeTagWarning}
        handleClose={() => setOpenMergeTagWarning(false)}
        onProceed={() => handleCreateTemplate(true)}
      />
    </>
  );
}
