import { useAuth } from "@clerk/clerk-react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { grey } from "@mui/material/colors";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useEffect, useState } from "react";

import { fetcherAuth } from "utils/api";

const steps = [
  { label: "Onboarding", sublabel: "" },
  { label: "VA Curating Brands For You", sublabel: "usually 3-7 days" },
  { label: "VA Scheduling Pitches", sublabel: "usually 2-4 days" },
];

export default function AnnualVAProgressBar() {
  const { getToken } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setErrorAlert } = useContext(AlertContext);

  const fetchStep = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/onboarding/current-va-step`,
        "GET",
      );
      setActiveStep(res.currentStep);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  useEffect(() => {
    if (currentOrg?.id) {
      fetchStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return activeStep > 2 ? (
    <></>
  ) : (
    <Box sx={{ width: "100%", mt: 2, mb: 1 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>
              {index === activeStep && (
                <Box
                  component="i"
                  className="fa-solid fa-spinner"
                  sx={{ mr: 0.5 }}
                />
              )}{" "}
              {step.label} <br />{" "}
              <Typography
                variant="body2"
                sx={{ color: grey[500], fontSize: 12 }}
              >
                {step.sublabel}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
