import { useAuth } from "@clerk/clerk-react";
import { Box, Button, FormHelperText, Grid, Typography } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  OrganizationVaProfile,
  VaOrganizationProfileStatus,
} from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";
import { Map } from "schemas/functions";

import BentoBrandsAutocomplete from "components/BentoBrandsAutocomplete";
import ImproveCurrentPitch from "features/Auth/Onboarding/ImproveCurrentPitch";
import ExcludeIncludeFilters from "features/Auth/Onboarding/RecommendationForm/ExcludeIncludeFilters";
import { vaOnboardingNavigate } from "features/Auth/Onboarding/helper";
import { fetcherAuth } from "utils/api";
import { getSource } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

interface Props {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  saveVaProfileForm: (
    organizationVaProfile: OrganizationVaProfile | null,
    page?: string,
    source?: string | null,
    skipMovingNext?: boolean,
  ) => void;
  organizationVaProfile: OrganizationVaProfile | null;
}

const VaFinalTouchForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  saveVaProfileForm,
  organizationVaProfile,
}: Props) => {
  const { getToken } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { currentOrg, excludedBentoSearchFilterIds } = useContext(
    OrganizationUserContext,
  );
  const { setErrorAlert } = useContext(AlertContext);
  const slideContext = useContext(SlideAnimationContext);
  const { templates } = useContext(OutreachTemplatesContext);

  const [excludeFiltersHasChanged, setExcludeFiltersHasChanged] =
    useState(false);
  const [dreamBrandValidation, setDreamBrandValidation] = useState<
    string | undefined
  >(undefined);
  const [pitchEmailValidationVa, setPitchEmailValidationVa] = useState<
    string | undefined
  >(undefined);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "back", false, organizationVaProfile?.onboardingType)}${location.search}`,
    );
  };

  const handleNext = async (e: CustomEvent) => {
    e.preventDefault();

    if (!(onboardingForm?.wishlistBrands?.length > 0)) {
      setDreamBrandValidation("Please fill out this field");
      return;
    }

    if (!(onboardingForm?.wishlistBrands?.length <= 10)) {
      setDreamBrandValidation("You can only set up a maximum of 10 brands");
      return;
    }

    if (
      (!(templates?.length > 0) && !onboardingForm?.pitchEmail) ||
      onboardingForm?.pitchEmail === "<p></p>"
    ) {
      setPitchEmailValidationVa("Please fill out this field");
      return;
    }

    await saveOnboardingForm(onboardingForm, location.pathname);
    if (excludeFiltersHasChanged) {
      await handleSaveFilters();
    }
    const copy = makeDeepCopy(organizationVaProfile);
    copy.status = VaOrganizationProfileStatus.completed;
    saveVaProfileForm(copy, location.pathname, getSource(), true);
  };

  const handleSaveFilters = async () => {
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/search-filters`,
        "PUT",
        {},
        {
          exclude: excludedBentoSearchFilterIds,
        },
      );
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const updateWishlistBrands = (newBrands: string[]) => {
    setOnboardingForm((prev) => {
      return {
        ...prev,
        wishlistBrands: newBrands,
      };
    });
    if (newBrands.length > 0) {
      setDreamBrandValidation(undefined);
    }
  };

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center", mt: 0 }}>
        Final touches!
      </Typography>

      {!(templates?.length > 0) && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">What's your current pitch email?</Typography>
          <ImproveCurrentPitch
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            fromVaFlow={true}
            pitchEmailValidationVa={pitchEmailValidationVa}
            setPitchEmailValidationVa={setPitchEmailValidationVa}
          />
        </Box>
      )}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Niches to Avoid</Typography>

        <ExcludeIncludeFilters
          setExcludeIncludeFilterHasChanged={setExcludeFiltersHasChanged}
          displayExcludeOnly={true}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Dream Brands</Typography>
        <FormHelperText error={!!dreamBrandValidation} sx={styles.helperText}>
          Add a maximum of 10 dream brands below. Your VA will put extra
          attention to pitching (and repitching) to these brands. *
        </FormHelperText>
        <BentoBrandsAutocomplete
          selectedBentoBrands={onboardingForm?.wishlistBrands || []}
          updateSelectedBentoBrands={updateWishlistBrands}
        />
        {dreamBrandValidation && (
          <FormHelperText sx={{ mt: -2 }} error={!!dreamBrandValidation}>
            {dreamBrandValidation}
          </FormHelperText>
        )}
      </Box>

      <form onSubmit={handleNext}>
        <Grid item xs="auto" container columnGap={1} sx={{ mt: 4 }}>
          <Grid item xs="auto">
            <Button onClick={handleBack} color="secondary" disableElevation>
              Back
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <Button disableElevation variant="contained" type="submit">
              Next{" "}
              <Box
                sx={{ ml: 1 }}
                component="i"
                className="fa-solid fa-arrow-right"
              />
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default VaFinalTouchForm;
