import { Box, Grid, Typography } from "@mui/material";
import { BrandsContext, TagLabel } from "contexts/Brands";
import { useContext } from "react";

import DiscoverChips from "./Chips";
import styles from "./styles";

interface Props {
  buttons: JSX.Element;
  filtersList: JSX.Element;
  sortFilter: JSX.Element;
  displayedSelectedTags: TagLabel[];
}

const DiscoverFilterDesktop = ({
  filtersList,
  sortFilter,
  buttons,
  displayedSelectedTags,
}: Props) => {
  const { selectedMetadataTags } = useContext(BrandsContext);

  return (
    <Grid container sx={styles.root}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item md={1.5}>
          <Typography sx={{ ml: 1 }} component="span">
            <strong>Categories</strong>
          </Typography>
        </Grid>

        <Grid container item xs={12} md alignItems="center">
          {filtersList}
          {sortFilter}
        </Grid>
        <Box>{buttons}</Box>
      </Grid>
      {(selectedMetadataTags.length > 0 ||
        displayedSelectedTags.length > 0) && (
        <Grid container>
          <Grid item md={1.5} />
          <DiscoverChips
            selectedMetadataTags={selectedMetadataTags}
            selectedTags={displayedSelectedTags}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DiscoverFilterDesktop;
