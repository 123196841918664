import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BentoBrand } from "schemas/dashboard";

import { useCollection } from "utils/useCollection";

import BrandMetadata from "../DetailedBrandView/components/BrandMetadata";
import BrandCardActions from "../Results/BrandCard/BrandCardActions";
import { BrandCardSource } from "../Results/schema";
import BrandIcons from "./BrandIcons";
import BrandLogo from "./BrandLogo";
import BrandSocialLinks from "./BrandSocialLinks";

const styles = {
  brandName: (inCardView?: boolean) => ({
    fontSize: 14,
    fontWeight: 700,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: {
      xs: inCardView ? 225 : 200,
      sm: inCardView ? 270 : 425,
      lg: inCardView ? 270 : 600,
      xl: inCardView ? 270 : 800,
    },
  }),
  draft: {
    color: "error.main",
  },
  about: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 2,
    textOverflow: "ellipsis",
    fontSize: 12,
  },
  aboutExpanded: {
    fontSize: 12,
  },
};

interface Props {
  bentoBrand: BentoBrand;
  hideMetadata?: boolean;
  source?: BrandCardSource;
  expanded?: boolean;
  isCardView?: boolean;
}

export default function BrandTitle({
  bentoBrand,
  hideMetadata,
  source,
  expanded,
  isCardView,
}: Props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { isDraftBrand } = useCollection();
  const isDrafted = isDraftBrand(bentoBrand);

  return (
    <Grid item container xs>
      <Grid
        item
        xs={12}
        container
        gap={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          item
          xs={source ? 9 : 12}
          container
          sx={{ mb: 1 }}
          rowGap={0.5}
          columnGap={1}
          alignItems="center"
        >
          <BrandLogo bentoBrand={bentoBrand} />
          <Box sx={styles.brandName(isCardView)}>
            {bentoBrand.brandName}
            {isDrafted && (
              <>
                ,{" "}
                <Box component="span" sx={styles.draft}>
                  Draft
                </Box>
              </>
            )}
          </Box>
          {!hideMetadata && <BrandIcons bentoBrand={bentoBrand} />}
          {!isMobileScreen && <BrandSocialLinks bentoBrand={bentoBrand} />}
        </Grid>

        {source && (
          <Grid item xs container justifyContent="flex-end">
            <BrandCardActions source={source} brand={bentoBrand} />
          </Grid>
        )}
      </Grid>

      {isMobileScreen && (
        <Box sx={{ mb: 1 }}>
          <BrandSocialLinks bentoBrand={bentoBrand} />
        </Box>
      )}

      {bentoBrand.aboutSummary && (
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography
            color="textSecondary"
            sx={expanded ? styles.aboutExpanded : styles.about}
          >
            {bentoBrand.aboutSummary}
          </Typography>
        </Grid>
      )}

      {!hideMetadata && !isMobileScreen && (
        <Grid container item xs={12} sx={{ my: 0.5 }}>
          <BrandMetadata selectedBrand={bentoBrand} />
        </Grid>
      )}
    </Grid>
  );
}
