import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";

import { makeDeepCopy } from "utils/updateLocalState";

import styles from "../styles";

interface CustomizeCategoryProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
}

type CategorySpecificDetails = {
  about_me?: string;
  past_brands?: string;
  sample_video?: string;
};

const CustomizeCategoryEmailForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
}: CustomizeCategoryProps) => {
  const [expandAll, setExpandAll] = useState(false);
  const location = useLocation();
  const { currentOrg } = useContext(OrganizationUserContext);
  const categories = currentOrg?.bentoCategories || [];

  const handleChange = (category: string) => (e: CustomEvent) => {
    const { name, value } = e.target;
    const copy = makeDeepCopy(onboardingForm);
    if (!copy.categorySpecificDetails) {
      copy.categorySpecificDetails = {};
    }
    if (!copy.categorySpecificDetails[category]) {
      copy.categorySpecificDetails[category] = {};
    }
    copy.categorySpecificDetails[category][name] = value;
    setOnboardingForm(copy);
  };

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    saveOnboardingForm(onboardingForm, location.pathname);
  };

  const getSpecificForm = () => {
    const specificCategoryForm: { [key: string]: CategorySpecificDetails } =
      makeDeepCopy(onboardingForm?.categorySpecificDetails || {});
    for (const category of categories) {
      const name = category?.name;
      if (name && !(name in specificCategoryForm)) {
        specificCategoryForm[name] = {
          about_me: "",
          past_brands: "",
          sample_video: "",
        };
      }
    }
    return specificCategoryForm;
  };

  const allKeys = Object.keys(getSpecificForm());
  const displayExpandAllButton = allKeys?.length > 3;
  const shortenedKeys =
    displayExpandAllButton && !expandAll ? allKeys?.splice(0, 3) : allKeys;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container sx={styles.fieldContainer} rowGap={2}>
          {shortenedKeys.map((category: string) => (
            <Grid item xs={12} key={category}>
              <Typography paragraph>
                For pitching to brands in <strong>{category}</strong>, share:
              </Typography>
              <Box sx={{ mx: 1 }}>
                <FormHelperText sx={styles.helperText}>
                  What's something unique about yourself that will help you
                  stand out to brands in {category}?
                </FormHelperText>
                <TextField
                  sx={styles.textField}
                  fullWidth
                  name="about_me"
                  placeholder="I absolutely love ..."
                  onChange={handleChange(category)}
                  multiline
                  minRows={2}
                  value={
                    onboardingForm?.categorySpecificDetails?.[category]
                      ?.about_me || ""
                  }
                />
                <FormHelperText sx={styles.helperText}>
                  Any past brands you've worked with in {category}:
                </FormHelperText>
                <TextField
                  sx={styles.textField}
                  fullWidth
                  name="past_brands"
                  placeholder="Share brand names ..."
                  onChange={handleChange(category)}
                  value={
                    onboardingForm?.categorySpecificDetails?.[category]
                      ?.past_brands || ""
                  }
                />
                <FormHelperText sx={styles.helperText}>
                  Can you share a link to a sample video in {category}?
                </FormHelperText>
                <TextField
                  sx={styles.textField}
                  fullWidth
                  name="sample_video"
                  placeholder="Enter social media link"
                  onChange={handleChange(category)}
                  value={
                    onboardingForm?.categorySpecificDetails?.[category]
                      ?.sample_video || ""
                  }
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </form>

      {displayExpandAllButton && !expandAll && (
        <Button
          size="small"
          variant="outlined"
          onClick={() => setExpandAll(true)}
        >
          View All Categories{" "}
          <Box
            component="i"
            className="fa-regular fa-chevron-down"
            sx={{ ml: 1 }}
          />
        </Button>
      )}
    </>
  );
};

export default CustomizeCategoryEmailForm;
