import { Box, Button, Grid, Typography } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { OrganizationVaProfile, VaOnboardingType } from "schemas/dashboard";
import { routes } from "schemas/routes";

import { BENTO_VA_ONBOARDING_CALENDLY_LINK } from "constants/influencer";
import { getSource, trackEvent } from "utils/tracking";
import { useTracking } from "utils/useTracking";

import FAQ from "./FAQ";

interface Props {
  organizationVaProfile: OrganizationVaProfile | null;
}

const VAQuestions = ({ organizationVaProfile }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentOrg } = useContext(OrganizationUserContext);
  const source = getSource();
  const { trackVaStep } = useTracking();

  const goToDashboard = () => {
    trackEvent("VA Onboarding Flow - Go to Dashboard Clicked");
    navigate(`/${routes.dashboard}${location.search}`);
  };

  const handleBookCall = () => {
    trackEvent("VA Onboarding Flow - Book Call Button Clicked");
  };

  useEffect(() => {
    if (currentOrg?.id) {
      trackVaStep(location.pathname, source);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return (
    <>
      <Typography variant="body2" sx={{ textAlign: "center", mb: 2 }}>
        All pending items are completed 💯
      </Typography>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center", mt: 0 }}>
        We Did It! Here's What Next
      </Typography>

      {organizationVaProfile?.onboardingType === VaOnboardingType?.book_call ? (
        <Box sx={{ display: "block", margin: "auto", mb: 3 }}>
          <ol>
            ☎️ During our call, we'll review your pitching strategy and gather
            the necessary details to tailor your pitches to your need!
          </ol>
          <ol>
            ✍️ Our team of copywriters will begin writing your pitch templates
            (typically 5-7 business days)
          </ol>
          <ol>
            ✅ Once you've approved your pitches, we hand them off to your VA to
            start pitching.
          </ol>
        </Box>
      ) : (
        <Box sx={{ display: "block", margin: "auto", mb: 3 }}>
          <ol>
            ✍️ Our team of copywriters will begin writing your pitch templates
            (typically 5-7 business days)
          </ol>
          <ol>
            📧 You'll receive an email once your templates are ready for review
          </ol>
          <ol>
            ✅ Once you've approved your pitches, we hand them off to your VA to
            start pitching.
          </ol>
        </Box>
      )}

      {organizationVaProfile?.onboardingType ===
        VaOnboardingType?.book_call && (
        <Grid container justifyContent="center">
          <Button
            onClick={handleBookCall}
            target="_blank"
            href={BENTO_VA_ONBOARDING_CALENDLY_LINK}
            variant="contained"
          >
            Book Call
          </Button>
        </Grid>
      )}

      <FAQ />

      <Grid item xs="auto" container columnGap={1} sx={{ mt: 4 }}>
        <Grid item xs="auto">
          <Button disableElevation onClick={goToDashboard}>
            Go to Main App
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default VAQuestions;
