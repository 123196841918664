import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { useContext, useEffect, useState } from "react";
import { PreviewMessage } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

interface SelectTemplateProps {
  preview: PreviewMessage;
  index: number;
}

export default function SelectTemplate({
  preview,
  index,
}: SelectTemplateProps) {
  const { getToken } = useAuth();
  const { setErrorAlert } = useContext(AlertContext);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isFollowUpEmail = index > 0;
  const { emailSettings, currentOrg } = useContext(OrganizationUserContext);
  const {
    templateForQuickSend,
    setTemplateForQuickSend,
    setNewlyAddedTemplateIndex,
    handleRefetchPreviews,
    setTemplatesToUpdate,
    bentoBrand,
    templatesToUpdate,
  } = useContext(QuickSendContext);
  const [scheduledTemplate, setScheduledTemplate] = useState<Template | null>(
    null,
  );
  const { templates } = useContext(OutreachTemplatesContext);
  const { quickSendBrandId, previewLoading } = useContext(
    QuickSendDrawerContext,
  );
  const { outreachTemplates, followupTemplates, setCreateTemplateDialogOpen } =
    useContext(OutreachTemplatesContext);

  const options =
    preview.contentType === TemplateContentType.OUTREACH
      ? outreachTemplates
      : followupTemplates;

  const fetchLastScheduledTemplate = async () => {
    if (!currentOrg || !bentoBrand) return;
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach/${bentoBrand.id}/last-scheduled-template`,
        "GET",
      );
      if (res.templateId) {
        const scheduledTemplate = templates?.find(
          (x) => x.id === res.templateId,
        );
        if (scheduledTemplate) {
          setScheduledTemplate(scheduledTemplate);
          setTemplateForQuickSend((prev) => {
            const copy = makeDeepCopy(prev);
            if (scheduledTemplate) copy[index] = scheduledTemplate;
            handleRefetchPreviews(copy);
            return copy;
          });
        }
      } else {
        setScheduledTemplate(null);
      }
    } catch (error) {
      setErrorAlert(error);
    }
  };
  const handleCheckbox = (e: CustomEvent) => {
    const checked = e.target.checked;
    trackEvent("Save Template Edit Checkbox Clicked", {
      checked,
      preview,
    });
    setTemplatesToUpdate((prev) => {
      if (!preview?.templateId) return prev;
      const copy = makeDeepCopy(prev);
      const templateIndex = copy?.findIndex(
        (x: number) => x === preview?.templateId,
      );
      if (checked && templateIndex === -1) {
        copy.push(preview?.templateId);
      } else if (templateIndex > -1 && !checked) {
        copy.splice(templateIndex, 1);
      }
      return copy;
    });
  };

  const getTemplateValue = (t: Template) => {
    if (t.emailTemplateId) {
      return `${t.name}-${t.emailTemplateId}`;
    } else if (t.id) {
      return `${t.name}-${t.id}`;
    } else {
      return `${t.name}`;
    }
  };

  const handleChangeTemplate = (e: CustomEvent) => {
    const value = e.target.value;
    if (value === "Add Template +") {
      return;
    }

    trackEvent("Template Changed", {
      "Template Name": value,
    });

    let template: Template | null = null;
    for (const t of options) {
      const templateValue = getTemplateValue(t);
      if (value === templateValue) {
        template = t;
        break;
      }
    }

    if (!template) {
      return;
    }
    setTemplateForQuickSend((prev) => {
      const copy = makeDeepCopy(prev);
      if (template) copy[index] = template;
      handleRefetchPreviews(copy);
      return copy;
    });
  };

  const handleTrackAddTemplate = () => {
    trackEvent("Add Template Button Clicked", {
      "Clicked From": "Select Template Component",
    });
    setNewlyAddedTemplateIndex(index);
    setCreateTemplateDialogOpen(true);
  };

  const handleGenerateTemplate = () => {
    trackEvent("Generate Template Button Clicked");
    setNewlyAddedTemplateIndex(index);
    setCreateTemplateDialogOpen(true);
  };

  const hasTemplateSettings =
    emailSettings?.emailSettingTemplates &&
    emailSettings?.emailSettingTemplates?.length > 0;
  const presetTemplate = hasTemplateSettings
    ? emailSettings?.emailSettingTemplates?.find((x) => x.emailOrder === index)
    : null;

  const getSelectValue = () => {
    if (preview?.templateId) {
      return `${preview?.templateName}-${preview.templateId}`;
    } else {
      return preview?.templateName;
    }
  };

  const templateInList = () => {
    const exists = options?.find(
      (x) => getTemplateValue(x) === getSelectValue(),
    );
    return exists;
  };

  const getTemplateName = (template: Template) => {
    if (template?.isAutogenerated) {
      return "Bento Generated";
    } else {
      return template?.name || template.subject;
    }
  };

  const renderTemplateOption = () => {
    if (previewLoading === quickSendBrandId) {
      return <Skeleton animation="wave" width={120} height={40} />;
    } else if (!templateInList() || options?.length <= 0) {
      return (
        <Button
          variant="contained"
          size="small"
          onClick={handleGenerateTemplate}
          sx={{ height: 25, textTransform: "none" }}
          disableElevation
        >
          Generate {isFollowUpEmail ? "Follow-Up" : "Template"}
        </Button>
      );
    } else {
      return (
        <Select
          onChange={(e: CustomEvent) => handleChangeTemplate(e)}
          value={getSelectValue()}
          size="small"
          sx={[
            styles.templateSelect,
            styles.noSelectBorder,
            styles.templateWidth,
          ]}
        >
          <MenuItem
            onClick={handleTrackAddTemplate}
            sx={{ color: "secondary.main" }}
          >
            <em>Add Template +</em>
          </MenuItem>
          {options?.map((template: Template, index: number) => (
            <MenuItem
              key={index}
              value={getTemplateValue(template)}
              sx={styles.templateMenuItem}
            >
              {template.isAutogenerated && (
                <Box
                  sx={{ color: "info.main", mr: 1 }}
                  component="i"
                  className="fa-solid fa-wand-magic-sparkles"
                />
              )}
              <Box sx={styles.templateMenuName(template.isAutogenerated)}>
                {getTemplateName(template)}
              </Box>

              {scheduledTemplate && template?.id === scheduledTemplate?.id && (
                <Chip
                  size="small"
                  sx={styles.chip}
                  label="Scheduled Template"
                />
              )}
              {!scheduledTemplate &&
                presetTemplate &&
                template?.id === presetTemplate?.templateId && (
                  <Chip size="small" sx={styles.chip} label="Last Used" />
                )}
            </MenuItem>
          ))}
        </Select>
      );
    }
  };

  useEffect(() => {
    fetchLastScheduledTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, bentoBrand?.id]);

  return (
    <>
      {scheduledTemplate && (
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "secondary.light",
            fontSize: 12,
            px: 2,
            py: 1,
          }}
        >
          You currently have a <strong>scheduled email</strong> with this brand.
        </Grid>
      )}
      <Grid container justifyContent="space-between" sx={{ pr: 1, mt: 1 }}>
        <Box sx={styles.subjectWrapper}>
          <Typography component="span" sx={styles.annotation}>
            Template:
          </Typography>
          {renderTemplateOption()}
        </Box>

        <Grid
          container
          alignItems="center"
          justifyContent={isMobileScreen ? "flex-start" : "flex-end"}
          item
          xs={12}
          md
          sx={isMobileScreen ? { pl: 2 } : {}}
        >
          {preview?.templateId &&
            !templateForQuickSend[index]?.isAutogenerated && (
              <FormControl
                onChange={handleCheckbox}
                onMouseDown={(e) => e.preventDefault()}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      icon={
                        <Box component="i" className="fa-regular fa-square" />
                      }
                      checkedIcon={
                        <Box
                          component="i"
                          className="fa-solid fa-square-check"
                        />
                      }
                    />
                  }
                  checked={templatesToUpdate?.includes(preview?.templateId)}
                  label={
                    <FormHelperText sx={{ ml: 0, mr: -2 }}>
                      Update template
                    </FormHelperText>
                  }
                />
              </FormControl>
            )}
        </Grid>
      </Grid>
    </>
  );
}
