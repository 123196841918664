import { Grid, Skeleton } from "@mui/material";

const SimilarBrandSkeletonBody = () => {
  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <Skeleton animation="wave" variant="rounded" height={30} width={150} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent="flex-start"
        gap={2}
        sx={{
          display: "grid",
          gridAutoFlow: "column",
          overflowX: "clip",
          width: "100%",
          "&::-webkit-scrollbar": {
            width: 0,
          },
        }}
      >
        {Array.from({ length: 10 }, (x, i) => (
          <Skeleton
            key={i}
            animation="wave"
            variant="rounded"
            height={140}
            width={300}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default SimilarBrandSkeletonBody;
