import { grey } from "@mui/material/colors";

const styles = {
  sendOption: {
    textTransform: "none",
    color: grey[400],
    borderRadius: 1,
    borderColor: grey[400],
    "&:hover": {
      color: "black",
    },
    fontSize: 13,
    border: `1px solid ${grey[300]}`,
  },
  selected: {
    color: "black",
    border: `1px solid black`,
  },
  subtitle: {
    mt: 1,
    color: grey[600],
  },
};

export default styles;
