import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";

export const CleanPaste = Extension.create({
  name: "cleanPaste",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("cleanPaste"),
        props: {
          transformPastedHTML(html) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");

            // Select all <br> elements and remove them
            const brElements = doc.querySelectorAll("br");
            brElements.forEach((br) => br.remove());
            return doc.body.innerHTML;
          },
        },
      }),
    ];
  },
});
