import { useAuth } from "@clerk/clerk-react";
import { AlertContext } from "contexts/Alert";
import { BentoCategoriesContext } from "contexts/BentoCategories";
import { BrandsContext, TagLabel } from "contexts/Brands";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { UserRequestsContext } from "contexts/UserRequests";
import lodash from "lodash";
import { Dispatch, SetStateAction, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import {
  BentoBrandMetadataTags,
  BentoBrandSizeToSearchKey,
  CombinedRequest,
  DISCOVER_FILTER,
  LOCATIONS,
  MetadataLocationType,
  MetadataType,
  RequestStatus,
  SavedBrandStatus,
  SearchParams,
  UserRequestSource,
} from "schemas/dashboard";
import { ALL } from "schemas/forms";
import { CustomEvent, Map } from "schemas/functions";

import { DiscoverSearchGeopoint } from "components/LocationAutocomplete/schema";

import { fetcherAuth } from "./api";
import {
  CATEGORY_ALL_FILTER,
  DISCOVER_METADATA_TAGS,
  DISCOVER_SEARCH_PARAMS,
} from "./localStorage";
import { trackEvent } from "./tracking";

export const MetadataTypeToParams: Map = {
  [MetadataType.tags]: SearchParams.TAGS_DISCOVER,
  [MetadataType.size]: SearchParams.SIZE_DISCOVER,
};

export const useSearchBrand = () => {
  const { decreaseBrandRequestsCount } = useContext(SubscriptionContext);
  const { setUserRequests, setTotal } = useContext(UserRequestsContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { setAlert, setErrorAlert } = useContext(AlertContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    setSelectedMetadataTags,
    discoverFilterParams,
    selectedMetadataTags,
  } = useContext(BrandsContext);
  const { categoriesById } = useContext(BentoCategoriesContext);

  const selectedTags = Object.keys(discoverFilterParams)
    .reduce((acc: TagLabel[], key: string) => {
      // @ts-ignore
      return acc.concat(discoverFilterParams[key as keyof DiscoverParams]);
    }, [])
    ?.filter((tag) => tag);

  const hasTags =
    selectedTags?.filter(
      (x) => x?.key?.length > 0 && x?.params !== SearchParams.SORT_DISCOVER,
    )?.length > 0 || selectedMetadataTags?.length > 0;

  const handleSelectOption = (value: string, currentOptions: string[]) => {
    if (value === ALL) {
      return ALL;
    }
    const indexOfSelectedOption = currentOptions?.findIndex((x) => x === value);
    const isOptionSelected = indexOfSelectedOption > -1;

    // If option is already selected, remove option. Else add it to the list.
    if (isOptionSelected) {
      currentOptions.splice(indexOfSelectedOption, 1);
    } else {
      currentOptions.push(value);
    }
    return currentOptions
      ?.filter((x: string) => x !== ALL && x?.length > 0)
      ?.join(",");
  };

  const deleteChip = (value: string, paramsValue: string) => {
    if (paramsValue?.includes(",")) {
      const arr = paramsValue.split(",");
      const index = arr?.findIndex((x) => x === value?.toLowerCase());
      if (index > -1) {
        arr.splice(index, 1);
      }
      const strArr = arr?.join(",");
      return strArr;
    } else if (value.toLowerCase() === paramsValue.toLowerCase()) {
      return "";
    } else {
      return paramsValue;
    }
  };

  const addCategoryQueryToSearch = (name: string, value: string) => {
    // When user select option from CategoryFilters or when they click on a Tag.
    if (name === SearchParams.SORT_DISCOVER) {
      searchParams.set(name, value);
    } else {
      const paramsValue = searchParams.get(name) || "";
      const newParamsValue = handleSelectOption(value, paramsValue?.split(","));
      if (newParamsValue === "") {
        searchParams.delete(name);
      } else {
        searchParams.set(name, newParamsValue);
      }
    }
    setSearchParams(searchParams);
    storeUserQuery();
  };

  /**
   * When "All" is selected for a top-level category but one of the subcategories is deselected, deselect "All" and remove that one subcategory
   */
  const removeCategoryIdWithAllSelected = (
    name: string,
    deselectSubcategoryId: number,
    topLevelCategoryId: number,
  ) => {
    const currentValue = searchParams.get(name) || "";
    let currentOptions = currentValue.split(",");
    const subcategoryIds =
      categoriesById[topLevelCategoryId].subcategories?.map(
        (sub) => `${sub.id}`,
      ) || [];

    const indexOfSelectedOption = currentOptions?.findIndex(
      (x) => x === `${topLevelCategoryId}:all`,
    );
    const isOptionSelected = indexOfSelectedOption > -1;
    if (isOptionSelected) {
      currentOptions.splice(indexOfSelectedOption, 1);
    }
    const newOptions = subcategoryIds?.filter(
      (x) => x !== `${deselectSubcategoryId}`,
    );
    currentOptions = [...currentOptions, ...(newOptions || [])];

    _storeAllAndUser(currentOptions, name);
    storeAllQuery(`${topLevelCategoryId}`, "remove");
  };

  /**
   * Add or remove "All" of a top-level category when the user toggles the "All" checkbox
   */
  const addAllCategoryIdQueryToSearch = (
    name: string,
    topLevelCategoryId: number,
  ) => {
    const currentValue = searchParams.get(name) || "";
    let currentOptions = currentValue.split(",");
    const subcategoryIds =
      categoriesById[topLevelCategoryId].subcategories?.map(
        (sub) => `${sub.id}`,
      ) || [];

    const indexOfSelectedOption = currentOptions?.findIndex(
      (x) => x === `${topLevelCategoryId}:all` || x === `${topLevelCategoryId}`,
    );
    const isOptionSelected = indexOfSelectedOption > -1;

    if (isOptionSelected) {
      currentOptions.splice(indexOfSelectedOption, 1);
      storeAllQuery(`${topLevelCategoryId}`, "remove");
    } else {
      currentOptions.push(`${topLevelCategoryId}:all`);
      currentOptions = currentOptions?.filter(
        (x) => !subcategoryIds.includes(x),
      );
      storeAllQuery(`${topLevelCategoryId}`, "add");
    }
    _storeAllAndUser(currentOptions, name);
  };

  const _storeAllAndUser = (currentOptions: string[], name: string) => {
    const joined = currentOptions
      ?.filter((x: string) => x?.length > 0)
      ?.join(",");
    searchParams.set(name, joined);
    setSearchParams(searchParams);
    storeUserQuery();
  };

  const deleteCategoryFromSearch = (chip: TagLabel) => {
    // Delete a tag from Category dropdown.
    if (chip.params === SearchParams.GEOPOINTS_DISCOVER && chip.key) {
      const parsed = JSON.parse(chip.key);
      if (parsed?.longitude && parsed?.latitude) {
        handleDeleteGeopoint(parsed.latitude, parsed.longitude);
        return;
      }
    }

    const paramsValue = searchParams.get(chip.params) || "";
    const strArr = deleteChip(chip.key, paramsValue);
    if (strArr === "") {
      searchParams.delete(chip.params);
    } else {
      searchParams.set(chip.params, strArr);
    }
    setSearchParams(searchParams);
    storeUserQuery();

    if (chip.key.includes(":all")) {
      const mainCategory = chip.key.split(":")[0];
      storeAllQuery(mainCategory, "remove");
    }
  };

  const deleteMetadataFromSearch = (tag: BentoBrandMetadataTags) => {
    // Delete a tag from Metadata.
    setSelectedMetadataTags((prev) => {
      const newArray = prev?.filter((x) => x.value !== tag.value);
      sessionStorage.setItem(DISCOVER_METADATA_TAGS, JSON.stringify(newArray));
      return newArray;
    });
    if (tag.type === MetadataType.location && tag.latitude && tag.longitude) {
      handleDeleteGeopoint(tag.latitude, tag.longitude);
    } else {
      const params = MetadataTypeToParams[tag.type];
      const strArr = deleteChip(tag.value, searchParams.get(params) || "");
      if (strArr) {
        searchParams.set(params, strArr);
      } else {
        searchParams.delete(params);
      }
      setSearchParams(searchParams);
      storeUserQuery();
    }
  };

  const handleAddGeopoint = (geopoint: DiscoverSearchGeopoint) => {
    const { country_code, latitude, longitude, label } = geopoint;

    // Search location using latitude / longitude.
    const value: DiscoverSearchGeopoint = {
      country_code,
      latitude,
      longitude,
      label,
    };
    let arrayOfGeopoints = [];
    const paramsValue = searchParams.get(SearchParams.GEOPOINTS_DISCOVER) || "";
    if (paramsValue) {
      arrayOfGeopoints = JSON.parse(paramsValue);
    }
    const exists = arrayOfGeopoints?.find(
      (x: DiscoverSearchGeopoint) =>
        (x.latitude === latitude && x.longitude === longitude) ||
        x.label === label,
    );

    if (!exists) {
      arrayOfGeopoints.push(value);
    }

    if (arrayOfGeopoints?.length > 0) {
      searchParams.set(
        SearchParams.GEOPOINTS_DISCOVER,
        JSON.stringify(arrayOfGeopoints),
      );
    } else {
      searchParams.delete(SearchParams.GEOPOINTS_DISCOVER);
    }
    setSearchParams(searchParams);
    storeUserQuery();
  };

  const handleDeleteGeopoint = (latitude: number, longitude: number) => {
    // Remove latitude / longitude from search.
    const paramsValue = searchParams.get(SearchParams.GEOPOINTS_DISCOVER);
    if (!paramsValue) return;
    const arrayOfGeopoints = JSON.parse(paramsValue);
    const index = arrayOfGeopoints?.findIndex(
      (x: DiscoverSearchGeopoint) =>
        x.latitude === latitude && x.longitude === longitude,
    );
    if (index > -1) {
      arrayOfGeopoints.splice(index, 1);
    }
    if (arrayOfGeopoints?.length === 0) {
      searchParams.delete(SearchParams.GEOPOINTS_DISCOVER);
    } else {
      const strArr = JSON.stringify(arrayOfGeopoints);
      searchParams.set(SearchParams.GEOPOINTS_DISCOVER, strArr);
    }
    setSearchParams(searchParams);
    storeUserQuery();
  };

  const handleClickOnTag = (
    e: CustomEvent,
    tag: BentoBrandMetadataTags,
    setInputValue?: Dispatch<SetStateAction<string>>,
  ) => {
    trackEvent("Brand Card - Tag Clicked", { Tag: tag.value });
    e.stopPropagation();
    searchParams.delete(SearchParams.QUERY_DISCOVER);
    setSearchParams(searchParams);

    if (tag?.type === MetadataType.location && tag?.value) {
      if (
        tag?.locationType === MetadataLocationType.continent ||
        tag?.locationType === MetadataLocationType?.country
      ) {
        const categoryLocation = LOCATIONS?.find(
          (x) => x.name === lodash.startCase(tag?.value),
        );
        if (categoryLocation) {
          addCategoryQueryToSearch(
            SearchParams.LOCATION_DISCOVER,
            categoryLocation.key,
          );
          return;
        }
      }
      if (setInputValue) setInputValue(tag?.value);
    } else {
      const params = MetadataTypeToParams[tag.type];
      const searchQuery = searchParams.get(params);
      let arr = searchQuery?.split(",");
      if (!arr) {
        arr = [];
      }
      let value: any = tag?.value;
      if (tag?.type === MetadataType.size) {
        if (tag.value?.toLowerCase() in BentoBrandSizeToSearchKey) {
          // @ts-ignore
          value = BentoBrandSizeToSearchKey[tag.value?.toLowerCase()];
        }
      } else {
        const category = Object.values(categoriesById).find(
          (c) => c.name.toLowerCase() === value.toLowerCase(),
        );
        if (category) {
          addCategoryQueryToSearch(
            SearchParams.CATEGORY_ID_DISCOVER,
            `${category.id}`,
          );
          return;
        }
      }

      const exists = arr?.find((x) => x === value.toLowerCase());
      if (!exists) {
        arr.push(value?.toLowerCase());
        if (tag?.type !== MetadataType.size) {
          setSelectedMetadataTags((prev) => {
            const exists = prev?.find((x) => x.value?.toLowerCase() === value);
            if (!exists) {
              prev.push(tag);
            }
            sessionStorage.setItem(
              DISCOVER_METADATA_TAGS,
              JSON.stringify(prev),
            );
            return prev;
          });
        }
      }
      const newStr = arr?.join(",");
      searchParams.set(params, newStr);
      setSearchParams(searchParams);
      storeUserQuery();
    }
  };

  const storeUserQuery = () => {
    // Store user's search query.
    const urlWithQuery = window.location.href?.split("?");

    let relevantKey = false;
    if (urlWithQuery?.length >= 2) {
      searchParams.forEach((value, key) => {
        if (
          key in DISCOVER_FILTER ||
          key === SearchParams.CATEGORY_ID_DISCOVER ||
          key === SearchParams.TAGS_DISCOVER ||
          key === SearchParams.GEOPOINTS_DISCOVER
        ) {
          relevantKey = true;
        }
      });
      if (relevantKey) {
        const query = urlWithQuery[1];
        sessionStorage.setItem(DISCOVER_SEARCH_PARAMS, query);
      } else {
        sessionStorage.removeItem(DISCOVER_SEARCH_PARAMS);
      }
    } else {
      sessionStorage.removeItem(DISCOVER_SEARCH_PARAMS);
    }
  };

  const storeAllQuery = (mainCategory: string, action: "add" | "remove") => {
    const query = sessionStorage.getItem(CATEGORY_ALL_FILTER);
    const allQuery = query ? JSON.parse(query) : {};
    if (mainCategory in allQuery && action === "remove") {
      delete allQuery[mainCategory];
    } else if (action === "add") {
      allQuery[mainCategory] = true;
    }
    sessionStorage.setItem(CATEGORY_ALL_FILTER, JSON.stringify(allQuery));
  };

  const handleRequestForBrand = async (
    requestFor: string,
    source: UserRequestSource,
  ) => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/user-requests`,
        "POST",
        {},
        {
          description: requestFor,
          source,
        },
      );
      if (source === UserRequestSource.SOURCE_REQUEST_BRAND) {
        const combinedRequest: CombinedRequest = {
          userRequestStatus: RequestStatus.pending,
          userRequestUpdatedAt: res.userRequest.updatedAt,
          bentoBrandIdOrUserRequestDescription: res.userRequest.description,
        };
        setUserRequests(SavedBrandStatus.unsent)((prev) => [
          ...prev,
          combinedRequest,
        ]);
        setTotal((prev) => ({
          ...prev,
          unsent: (prev.unsent || 0) + 1,
        }));
        decreaseBrandRequestsCount();
      }
      setAlert("Request submitted successfully!", "success");
    } catch (error) {
      setErrorAlert(error);
    }
  };

  return {
    storeUserQuery,
    handleAddGeopoint,
    handleDeleteGeopoint,
    addCategoryQueryToSearch,
    deleteCategoryFromSearch,
    deleteMetadataFromSearch,
    handleClickOnTag,
    addAllCategoryIdQueryToSearch,
    handleRequestForBrand,
    hasTags,
    removeCategoryIdWithAllSelected,
    selectedTags,
  };
};
