import { grey } from "@mui/material/colors";

const styles = {
  popupBody: {
    p: 3,
  },
  body: {
    mt: -3,
  },
  brandName: {
    maxWidth: {
      xs: "calc(100vw - 100px)",
      md: "350px",
    },
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dialogTitleText: {
    zIndex: 10,
    py: 0,
    px: 0,
    backgroundColor: "transparent",
  },
  dialogGrid: {
    height: 38,
    cursor: "pointer",
    backgroundColor: "transparent",
    px: 0.5,
  },
  dialogWrapper: {
    position: "sticky",
    top: 0,
    right: 0,
    left: 0,
  },
  container: {
    display: "grid",
    gridAutoFlow: "column",
    overflowX: "auto",
  },
  mainImage: {
    width: "100%",
    mt: "-38px",
    maxHeight: "400px",
    objectFit: "cover",
    objectPosition: "center",
  },
  logoWrapper: {
    backgroundColor: grey[50],
    width: "100px",
    height: "100px",
    borderRadius: "50px",
    overflow: "hidden",
    outline: `2px solid ${grey[200]}`,
    mb: 2,
  },
  logo: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  key: {
    color: grey[600],
    fontSize: 14,
  },
  value: {
    textTransform: "capitalize",
  },
  iconButton: {
    color: "dark.green",
    fontSize: 12,
  },
  section: {
    mt: 2,
  },
  brandLink: {
    maxWidth: { xs: 300, md: 200 },
    textOverflow: "ellipsis",
    color: "green.main",
  },
  flag: { fontSize: 12, color: grey[500] },
  icon: {
    fontSize: 14,
    width: "30px",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    color: grey[900],
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
    },
  },
};

export default styles;
