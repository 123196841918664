import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Typography } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { vaOnboardingNavigate } from "features/Auth/Onboarding/helper";
import { HIDE_VA_ONBOARDING_VIDEO } from "utils/localStorage";

const VAVideo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentOrg } = useContext(OrganizationUserContext);
  const slideContext = useContext(SlideAnimationContext);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "back")}${location.search}`,
    );
  };

  const handleNext = async () => {
    if (slideContext) {
      await slideContext.slideOutUp();
      slideContext.slideInUp();
    }

    localStorage.setItem(
      `${HIDE_VA_ONBOARDING_VIDEO}-${currentOrg?.id}`,
      "true",
    );
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "next")}${location.search}`,
    );
  };

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center", mt: 0 }}>
        Watch the intro video below
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ textAlign: "center", mb: 3 }}
      >
        We cover important information and frequently asked questions, and have
        some immediate action items for you to complete
      </Typography>

      <Box
        component="div"
        sx={{ position: "relative", paddingBottom: "56.25%", height: 0 }}
      >
        <Box
          component="iframe"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: 4,
          }}
          src="https://www.loom.com/embed/4c27aa3298724dbb99b17aa5e81f1ef6?sid=752fbd87-9c76-4afe-bdd9-15559d94f744"
        ></Box>
      </Box>

      <Grid item xs="auto" container columnGap={1} sx={{ mt: 4 }}>
        <Grid item xs="auto">
          <Button onClick={handleBack} color="secondary" disableElevation>
            Back
          </Button>
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          <LoadingButton
            disableElevation
            variant="contained"
            onClick={handleNext}
          >
            Next{" "}
            <Box
              sx={{ ml: 1 }}
              component="i"
              className="fa-solid fa-arrow-right"
            />
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

export default VAVideo;
