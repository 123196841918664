import {
  Box,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SubscriptionType } from "schemas/dashboard";

import { HIDE_ONBOARDING_VIDEOS } from "utils/localStorage";
import { trackEvent } from "utils/tracking";

import NeedsHelpDialog from "./NeedsHelpDialog";
import Thumbnail from "./Thumbnail";
import styles from "./styles";

const DiscoverVideo = () => {
  const theme = useTheme();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { subscription, isFreePlan } = useContext(SubscriptionContext);
  const hideOnboardingVideo =
    sessionStorage.getItem(`${HIDE_ONBOARDING_VIDEOS}-${currentOrg?.id}`) ===
    "true";
  const isSubscribed =
    subscription?.subscription?.status === SubscriptionType.ACTIVE &&
    !isFreePlan;

  const [hide, setHide] = useState(hideOnboardingVideo || isSubscribed);
  const [searchParams, setSearchParams] = useSearchParams();
  const isSmallerScreen = useMediaQuery("(max-width:900px)");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const successCheckout = searchParams.get("checkout") === "success";

  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
    searchParams.delete("checkout");
    setSearchParams(searchParams);
  };

  const openNeedsHelp = () => {
    trackEvent("Need Help Link Clicked");
    setOpenDialog(true);
  };

  const hideSection = () => {
    trackEvent("Hide Onboarding Video Section Clicked");
    sessionStorage.setItem(
      `${HIDE_ONBOARDING_VIDEOS}-${currentOrg?.id}`,
      "true",
    );
    setHide(true);
  };

  useEffect(() => {
    if (successCheckout && !openDialog) {
      setOpenDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successCheckout]);

  const headerContent = (
    <>
      <Typography
        gutterBottom
        variant="h6"
        textAlign={isMobileScreen ? "center" : "left"}
      >
        {isMobileScreen ? "Get Started" : "Get started in less than 10 minutes"}
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Watch {isMobileScreen ? "this short tutorial" : "these short tutorials"}{" "}
        to help you send your first pitch and to write a 5x better email.
      </Typography>
    </>
  );

  return hideOnboardingVideo || hide || isSubscribed ? (
    <></>
  ) : (
    <Grid item sx={{ px: 2, py: 1 }}>
      <Grid sx={styles.root}>
        <Grid item sx={styles.closeContainer}>
          <Tooltip title="Hide section">
            <IconButton sx={styles.close} onClick={hideSection}>
              <Box component="i" className="fa-regular fa-xmark" />
            </IconButton>
          </Tooltip>
        </Grid>

        {isSmallerScreen && headerContent}

        <Grid
          container
          gap={4}
          sx={styles.videoContainer}
          alignItems="center"
          item
          wrap="nowrap"
        >
          {!isMobileScreen && !isSmallerScreen && (
            <Grid item md={3}>
              {headerContent}
            </Grid>
          )}

          {!isMobileScreen && (
            <Grid item>
              <Thumbnail
                image="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/crop1.png"
                videoPreview="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/pitching-cover-2.png"
                link="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/pitching-strategies-for-content-creators_qAK1sRYa.mp4"
                title={"What is Pitching"}
              />
              <Typography textAlign="center" sx={{ mt: 1 }}>
                What is Pitching
              </Typography>
              <Typography textAlign="center">2 min</Typography>
            </Grid>
          )}

          {!isMobileScreen && (
            <Grid item>
              <Thumbnail
                image="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/crop2.png"
                videoPreview="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/how-to-use-cover.png"
                link="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/sending-your-first-email-on-bento_Pd10zBoj.mp4"
                title={"How to Use Bento"}
              />

              <Typography textAlign="center" sx={{ mt: 1 }}>
                How to Use Bento
              </Typography>
              <Typography textAlign="center">1.5 min</Typography>
            </Grid>
          )}

          {!isMobileScreen && (
            <Grid item>
              <Thumbnail
                image="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/crop3.png"
                videoPreview="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/common-mistakes-cover.png"
                link="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/common-mistakes-in-pitching-to-brands_Qc8KMkVH.mp4"
                title={"Common Mistakes"}
              />

              <Typography textAlign="center" sx={{ mt: 1 }}>
                Common Mistakes
              </Typography>
              <Typography textAlign="center">2 min</Typography>
            </Grid>
          )}
          <Grid item>
            <Thumbnail
              image="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/crop4.png"
              videoPreview="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/better-email-cover.png"
              link="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/mastering-email-pitching-strategies_BkfZZvkJ.mp4"
              title={"Write a 5x Email"}
            />

            <Typography textAlign="center" sx={{ mt: 1 }}>
              Write a 5x Email
            </Typography>
            <Typography textAlign="center">
              {" "}
              <Link
                onClick={openNeedsHelp}
                sx={{
                  cursor: "pointer",
                  color: "info.main",
                  textDecoration: "none",
                  fontWeight: 800,
                }}
              >
                Need Help?
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <NeedsHelpDialog open={openDialog} handleClose={handleClose} />
    </Grid>
  );
};

export default DiscoverVideo;
