const styles = {
  searchingText: {
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  notifyText: {
    wordBreak: "break-all",
  },
  skeletonContainer: {
    width: { xs: "95vw", md: "auto" },
  },
  excludedContainer: {
    p: 4,
    px: { xs: 2, lg: 20 },
    textAlign: "center",
  },
};

export default styles;
