import { BentoCategoryMap } from "contexts/BentoCategories";
import { TagLabel } from "contexts/Brands";
import lodash, { isArray } from "lodash";
import {
  BESearchParametersToFEParams,
  BentoBrandMetadataTags,
  BentoBrandSearchParameters,
  BentoCategory,
  CompanySize,
  CompanyType,
  DISCOVER_FILTER,
  FollowingSizeRange,
  Location,
  MetadataType,
  Sort,
} from "schemas/dashboard";
import { Map } from "schemas/functions";

import { titleCase } from "./string";

export const getCategoryIdParams = (
  topLevelCategores: BentoCategory[],
  categoriesById: BentoCategoryMap,
  selectedCategoryIds: TagLabel[],
) => {
  let categoryIdQueries: string[] = [];
  for (const category of selectedCategoryIds) {
    const isTopLevelCategory = Boolean(
      category.key.includes(":all") ||
        topLevelCategores.find((c) => c.id === parseInt(category.key)),
    );
    if (isTopLevelCategory) {
      let categoryId = category.key;
      if (category.key.includes(":all")) {
        categoryId = category.key.split(":")[0];
      }
      const subcategories =
        categoriesById[parseInt(categoryId)]?.subcategories || [];
      let idsToAdd = [categoryId];
      idsToAdd = idsToAdd.concat(subcategories.map((sub) => `${sub.id}`));
      categoryIdQueries = categoryIdQueries.concat(idsToAdd);
    } else {
      categoryIdQueries.push(category.key);
    }
  }
  return categoryIdQueries;
};

export const getMinAndMaxInstagram = (
  selectedInstagramFollowings: TagLabel[],
) => {
  const followers: Map = {
    min_instagram_followers: undefined,
    max_instagram_followers: undefined,
  };
  const min = Math.min(
    ...selectedInstagramFollowings?.map((x) => FollowingSizeRange[x.key]?.min),
  );
  const max = Math.max(
    ...selectedInstagramFollowings?.map((x) => FollowingSizeRange[x.key]?.max),
  );

  if (min && !isNaN(min) && min && isFinite(min)) {
    followers["min_instagram_followers"] = min;
  }
  if (max !== Math.max() && !isNaN(max) && isFinite(max)) {
    followers["max_instagram_followers"] = max;
  }
  return followers;
};

export const convertFilterParamsToQuery = (
  topLevelCategories: BentoCategory[],
  categoriesById: BentoCategoryMap,
  discoverFilterParams: Map,
  selectedMetadataTags: BentoBrandMetadataTags[],
  searchQuery?: string,
) => {
  let query = searchQuery || "";
  if (selectedMetadataTags?.length > 0) {
    const clickedTags = selectedMetadataTags?.filter(
      (x) => x.type === MetadataType.tags,
    );
    query += clickedTags?.map((x) => x.value);
  }
  const converted: BentoBrandSearchParameters = {
    category_ids: getCategoryIdParams(
      topLevelCategories,
      categoriesById,
      discoverFilterParams?.selectedCategoryIds,
    ),
    company_types: discoverFilterParams?.selectedCompanyTypes?.map(
      (x: TagLabel) => x.key as CompanyType,
    ),
    locations: discoverFilterParams?.selectedLocations?.map(
      (x: TagLabel) => x.key as Location,
    ),
    geopoints: discoverFilterParams?.selectedGeopoints?.map(
      (x: TagLabel) => x.key,
    ),
    company_sizes: discoverFilterParams?.selectedCompanySizes?.map(
      (x: TagLabel) => x.key as CompanySize,
    ),
    sort: (discoverFilterParams?.selectedSort?.key as Sort) || Sort.Recommended,
    compensation_types: discoverFilterParams?.selectedCompensationTypes?.map(
      (x: TagLabel) => x.key,
    ),
    query,
  };
  const followers = getMinAndMaxInstagram(
    discoverFilterParams?.selectedInstagramFollowings,
  );
  if (followers?.min_instagram_followers) {
    converted["min_instagram_followers"] = followers.min_instagram_followers;
  }
  if (followers?.max_instagram_followers) {
    converted["max_instagram_followers"] = followers.max_instagram_followers;
  }
  return converted;
};

const getTagLabel = (key: string, v: string | number) => {
  // Given a key such as `company_size`, and a value such as `sm`,
  // returns the label `small`. For value with no corresponding tag,
  // returns it as is.
  if (key in BESearchParametersToFEParams) {
    const queryParams = BESearchParametersToFEParams[key];
    if (queryParams in DISCOVER_FILTER && v in DISCOVER_FILTER[queryParams]) {
      const label = DISCOVER_FILTER[queryParams][v]?.name;
      return label;
    }
  }
  if (typeof v === "string") {
    return titleCase(v);
  }
  return v?.toString();
};

export const getTagLabelFromParams = (
  key: string,
  searchParameters: BentoBrandSearchParameters,
) => {
  // Give a `key` such as `company_sizes` and a list of value `[md, sm]`
  // returns readable labels for that key: ["medium", "small"]
  const value = searchParameters[key as keyof BentoBrandSearchParameters];
  const toDisplay: string[] = [];
  if (isArray(value)) {
    for (const v of value) {
      const label = getTagLabel(key, v);
      toDisplay.push(label);
    }
  } else if (value) {
    const label = getTagLabel(key, value);
    toDisplay.push(label);
  }
  return toDisplay;
};

export const isEqualParams = (
  params1: BentoBrandSearchParameters,
  params2: BentoBrandSearchParameters,
) => {
  const p1 = lodash.pickBy(params1, lodash.identity);
  const p2 = lodash.pickBy(params2, lodash.identity);
  return lodash.isEqual(p1, p2);
};

export const parseQuery = (str: string) => {
  const include: string[] = [];
  const exclude: string[] = [];

  // Regular expression to match exclusions
  const pattern = /"([^"]+)"|-(\w+)|(\b\w+(?:-\w+)*\b)/g;
  let match;

  while ((match = pattern.exec(str)) !== null) {
    if (match[1]) {
      // Quoted phrase (e.g., "gluten-free")
      include.push(match[1]);
    } else if (match[2]) {
      // Excluded word (e.g., -world)
      exclude.push(match[2]);
    } else if (match[3]) {
      // Normal word (handles hyphenated words like gluten-free)
      include.push(match[3]);
    }
  }

  return { include, exclude };
};
