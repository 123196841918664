const styles = {
  container: (fromDialogSource: boolean) => ({
    backgroundColor: fromDialogSource ? "white" : "secondary.light",
    borderRadius: 4,
    p: 3,
  }),
  whiteSelect: {
    backgroundColor: "white",
    width: 100,
  },
};

export default styles;
