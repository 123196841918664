import { Map, StringMap } from "schemas/functions";

import { AddressComponent } from "components/LocationAutocomplete/schema";
import { TemplateContentType } from "features/Influencer/ContactList/schema";
import { SelectOption } from "features/Influencer/Tracking/schema";

import { EmailDraft, Message } from "./email";
import { OverridePlanName, Plans } from "./payments";

export type Company = {
  id?: number;
  name: string;
};

export enum ContactMethod {
  EMAIL = "email",
  INSTAGRAM = "instagram",
}

export type Contact = {
  id?: number;
  companyId?: number;
  ownerUserId?: number;
  name?: string;
  title?: string;
  email?: string;
  contactMethod: ContactMethod;
  priority?: ContactPriority | null;
  website?: string;
  tags?: string;
  notes?: string;
  company?: Company;
  interactions?: Interaction[];
  createdAt?: number;
  opportunities?: Opportunity[];
  latestMessageStatus?: string;
};

export enum OrganizationType {
  FOUNDER = "founder",
  INFLUENCER = "influencer",
}

export type OrganizationUser = {
  id: number;
  organizationId: number;
  email: string;
  name: string;
  profileImageUrl?: string;
  lastViewedOutreachAt?: number;
  doNotEmail?: boolean;
};

export enum SubscriptionType {
  TRIALING = "trialing",
  ACTIVE = "active",
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
  PAUSED = "paused",
}

export type Subscription = {
  isSubscribed: boolean;
  billingCycle: [number, number];
  creditsCycle: [number, number];
  monthlyLimit: number;
  remainingMessages: number;
  remainingChatMessages: number;
  chatMonthlyLimit: number;
  remainingBrandRequests: number;
  brandRequestsMonthlyLimit: number;
  recommendationsRefreshMonthlyLimit: number;
  remainingRecommendationsRefresh: number;
  overridePlanName: OverridePlanName;
  referralsBoost: number;
  subscription: {
    id?: number;
    customerId?: string;
    status: SubscriptionType;
    planName?: Plans;
    cancelAt?: number;
  };
};

export enum VaTemplateStatus {
  pending = "pending",
  approved = "approved",
}

export type Organization = {
  id: number;
  name: string;
  type: OrganizationType;
  vaStatus: VAStatus;
  signedUpForVa?: boolean;
  overridePlanName?: OverridePlanName;
  vaTemplateStatus?: VaTemplateStatus;
  bentoCategories?: BentoCategory[];
  excludedBentoSearchFilterIds?: number[];
  includedBentoSearchFilterIds?: number[];
  createdAt?: number;
};

export enum VAStatus {
  pending = "pending",
  approved = "approved",
  disqualified = "disqualified",
  not_interested = "not_interested",
}

export enum PreferredBrandSize {
  small = "small",
  large = "large",
}

export type BentoCategory = {
  displayName?: string;
  name: string;
  id: number;
  subcategories?: BentoCategory[];
};

export type OrganizationBentoCategory = {
  organizationId: number;
  bentoCategoryId: number;
};

export type AddOnItem = {
  id: number;
  itemName: string;
  quantity: number;
};

export type OrganizationProfile = {
  // Default
  id: number;
  isUgcCreator?: boolean;
  isInfluencer?: boolean;
  phoneNumber?: string;

  // Location
  city?: string;
  location?: string; // country code
  latitude?: number;
  longitude?: number;
  addressComponents?: AddressComponent[];
  emailTrackingEnabled?: boolean;

  // Onboarding Page 1
  instagramUrl?: string;
  tiktokUrl?: string;
  youtubeUrl?: string;
  twitchUrl?: string;
  portfolioUrl?: string;
  primarySocialMediaPlatform?: string;

  // Onboarding Page 3 (ignore preferredBrandSize)
  brands?: string;
  preferredBrandSize?: PreferredBrandSize;
  wishlistBrands?: string[];

  // Onboarding Page 4 (va)
  monthlyIncome?: number[];

  // Onboarding Page 6 (va niche)
  onboardingGoogleDocId?: string;

  // Templates
  introduction?: string;
  signature?: string;
  mostImpressivePartnerships?: string;
  caseStudy?: string;
  numOfFollowers?: number;
  engagement?: string;
  mostEngagedPost?: string;
  purchasedTemplate?: boolean;

  // profile only
  uniqueContentIdeas?: string[];
};

export enum VaOnboardingType {
  book_call = "book_call",
  instant = "instant",
}

export enum VaNicheType {
  specific = "specific",
  general = "general",
}

export enum VaBackgroundDetailsField {
  activity_alongside_content = "activity_alongside_content",
  activity_before_content = "activity_before_content",
  why_brands_like_your_content = "why_brands_like_your_content",
  biggest_brand_names = "biggest_brand_names",
  most_proud_partnerships = "most_proud_partnerships",
  other = "other",
}

export enum VaOrganizationProfileStatus {
  pending = "pending",
  completed = "completed",
}

export type VaNicheSpecificDetails = {
  name: string;
  type: VaNicheType;
  is_top_performing?: boolean;
  past_brands?: string;
  top_performing_post_link?: string;
  partnership_reason?: string;
};

export type VaBackgroundQuestionAnswer = {
  question: string;
  answer: string;
  field_name: VaBackgroundDetailsField;
};

export type VaBackground = {
  activity_alongside_content?: VaBackgroundQuestionAnswer;
  activity_before_content?: VaBackgroundQuestionAnswer;
  why_brands_like_your_content?: VaBackgroundQuestionAnswer;
  biggest_brand_names?: VaBackgroundQuestionAnswer;
  most_proud_partnerships?: VaBackgroundQuestionAnswer;
  other?: VaBackgroundQuestionAnswer;
};

export type OrganizationVaProfile = {
  onboardingType?: VaOnboardingType;
  niches?: VaNicheSpecificDetails[];
  backgroundDetails?: { [key: string]: VaBackground };
  status: VaOrganizationProfileStatus;
};

export enum IncomeLevel {
  starting = "starting",
  gettingFeetWet = "gettingFeetWet",
  gettingHangOfIt = "gettingHangOfIt",
  seasoned = "seasoned",
}

export const IncomeLevelMap: { [key in IncomeLevel]: number[] } = {
  [IncomeLevel.starting]: [0, 0],
  [IncomeLevel.gettingFeetWet]: [0, 500],
  [IncomeLevel.gettingHangOfIt]: [500, 2000],
  [IncomeLevel.seasoned]: [2000, 10000],
};

export enum OnboardAction {
  SKIP_SELECTING_BRANDS = "Skip Selecting Brand",
  SKIP_EMAILING_BRANDS = "Skip Emailing Brand",
  EMAIL_TO_BRAND_SENT = "Email To Brand Sent",
  VA_FLOW_COMPLETED = "VA Flow Completed",
}

export type Opportunity = {
  id: string | null;
  company: Company;
  companyId: string | null;
  name: string;
  stage: string;
  fitLevel?: string;
  createdAt?: number;
  updatedAt?: number;
  contacts?: Contact[];
  tags?: OpportunityTag[];
  interactions?: Interaction[];
  contractValueDollars?: number;
  notes?: string;
};

export enum OpportunityStage {
  NEW = "new",
  DISCOVERY = "discovery",
  SCHEDULING_DEMO = "scheduling_demo",
  DEMO = "demo",
  NEGOTIATION = "negotiation",
  WON = "won",
  LOST = "lost",

  OPPORTUNITIES = "opportunities",
  FULFILLING = "fulfilling",
  COMPLETED = "completed",
}

export const OpportunityStageDescription: Map = {
  [OpportunityStage.NEW]: "Opportunities at this stage are not yet categorized",
  [OpportunityStage.DISCOVERY]:
    "Pick the customer's brain on potential usage of the product",
  [OpportunityStage.SCHEDULING_DEMO]:
    "Schedule a demo of the product to the customer(s)",
  [OpportunityStage.DEMO]: "Demo the product to the customer(s)",
  [OpportunityStage.NEGOTIATION]:
    "Negotiate pricing for the product with the customer(s)",
  [OpportunityStage.WON]: "The customer(s) agree to pay for the product",
  [OpportunityStage.LOST]: "The customer(s) are not interested in the product",
};

export const InfluencerStageDescription: Map = {
  [OpportunityStage.OPPORTUNITIES]:
    "Incoming potential brand deals will be listed here.",
  [OpportunityStage.FULFILLING]:
    "In-progress negotiation or creating content with brand",
  [OpportunityStage.COMPLETED]: "Brands with a completed partnership",
};

export enum ContactPriority {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export enum OpportunityFitLevel {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export const FitLevelOrder: string[] = [
  OpportunityFitLevel.LOW,
  OpportunityFitLevel.MEDIUM,
  OpportunityFitLevel.HIGH,
];

export type OpportunityTag = {
  id: number;
  name: string;
};

export enum SearchParams {
  REMINDER_ID = "reminderId",
  CONTACT_ID = "contactId",
  VIEW = "view",
  PAGE = "page",
  DRAWER_OPEN = "drawerOpen",
  DRAWER_TAB = "drawerTab",
  STAGE = "stage",

  // Query Params for Searching Opportunities
  QUERY_TAG_IDS = "queryTagIds",
  QUERY_FIT_LEVEL = "queryFitLevel",
  QUERY_STAGE = "queryStage",
  QUERY_NAME = "queryName",

  // Query params for Searching Contacts
  QUERY_PRIORITY_LEVEL = "queryPriorityLevel",
  QUERY_COMPANY_ID = "queryCompanyId",

  // Query Params for Searching Reminders
  QUERY_TASK_USER = "queryTaskUser",
  QUERY_TASK_STATUS = "queryTaskStatus",
  QUERY_TASK_DUE_DATE = "queryTaskDueDate",

  // Discovery Page
  QUERY_DISCOVER = "queryBrand",
  TAGS_DISCOVER = "tags",
  CATEGORY_ID_DISCOVER = "category_ids",
  TYPE_DISCOVER = "type",
  SORT_DISCOVER = "sort",
  SIZE_DISCOVER = "size",
  LOCATION_DISCOVER = "loc",
  GEOPOINTS_DISCOVER = "geopoints",
  FOLLOWING_DISCOVER = "following",
  COMPENSATION_DISCOVER = "compensation",

  // Referrals
  REFERRAL_CODE = "code",
}

export type DiscoverCategory = {
  mainCategory: { label: string; key: string };
  subCategories?: { label: string; key: string }[];
};

export const BentoBrandCategoryMap: StringMap = {
  fashion_all: "All Fashion",
  fashion: "Fashion",
  mens_fashion: "Men's Fashion",
  womens_fashion: "Women's Fashion",
  fashion_accessories: "Accessories",
  activewear: "Activewear",
  jewelry: "Jewelry",
  eyewear: "Eyewear",
  shoes: "Shoes",
  bags_watches_other: "Bags, Watches & Other Accessories",
  skincare: "Skincare",
  makeup: "Makeup & Cosmetics",
  haircare: "Haircare",
  beauty: "Beauty",
  fragrance: "Fragrance",
  bath_and_body: "Bath & Body",
  other_beauty_personal_care: "Other Beauty & Personal Care",
  travel: "Travel",
  luggage: "Luggage / Suitcases",
  travel_accessories: "Travel Accessories",
  travel_accommodations: "Travel Tours, Accommodations & Attractions",
  travel_transportation: "Travel Transportation",
  childrens_clothing: "Children's Clothing",
  childrens_food: "Children's Food",
  all_baby_and_maternity: "All Baby & Maternity",
  baby_and_maternity: "Baby & Maternity",
  baby_kids_maternity: "Baby, Kids, & Maternity",
  apps: "Apps",
  technology: "Technology & Electronics",
  games_entertainment: "Games & Entertainment",
  appliances_household_products: "Appliances & Household Products",
  home_decor: "Home Decor",
  pet_products: "Pet Products",
  arts_crafts: "Arts/Crafts",
  sports_fitness_outdoors: "Sports, Fitness & Outdoors",
  health_wellness: "Health/Wellness",
  retailers_ecommerce: "Retailers & E-Commerce Platforms",
  food_beverage_all: "All Food/Beverage",
  restaurants: "Restaurants",
  food_beverage: "Food/Beverage",
  education: "Education",
  business_services: "Business Services",
  life_services: "Life Services",
  financial_services: "Financial Services",
  automotive: "Vehicle & Transportation",
  lifestyle: "Lifestyle",
  other: "Other",
};

export enum Sort {
  Recommended = "recommended",
  RecentlyAdded = "recently-added",
}

export const SORTS = [
  { key: Sort.Recommended, name: "Recommended" },
  { key: Sort.RecentlyAdded, name: "Recently Added" },
];

export enum CompanyType {
  All = "all",
  VerifiedPaidPartnership = "verified-paid-partnership",
  NotContactedThisWeek = "not-contacted-this-week",
  WorksWithUgc = "works-with-ugc",
  WorksWithInfluencer = "works-with-influencer",
  RunsTiktokAds = "runs-tiktok-ads",
  NonGenericEmails = "non-generic-emails",
}

export const COMPANY_TYPES = [
  { key: CompanyType.All, name: "All" },
  {
    key: CompanyType.VerifiedPaidPartnership,
    name: "Verified Paid Partnership",
    paidOnly: true,
  },
  {
    key: CompanyType.NotContactedThisWeek,
    name: "Not Contacted This Week",
  },
  {
    key: CompanyType.WorksWithUgc,
    name: "Works with UGC Creators",
    paidOnly: true,
  },
  {
    key: CompanyType.WorksWithInfluencer,
    name: "Works with Influencers",
    paidOnly: true,
  },
  {
    key: CompanyType.RunsTiktokAds,
    name: "Runs TikTok Ads",
  },
  {
    key: CompanyType.NonGenericEmails,
    name: "Direct Contact",
    label: "These brands have a non-generic contact email available",
  },
];

export enum Location {
  All = "all",
  UnitedStates = "us",
  Canada = "ca",
  UK = "gb",
  Australia = "au",
  France = "fr",
  Germany = "de",
  Italy = "it",
  Spain = "es",
  India = "in",
  Europe = "europe",
  Asia = "asia",
  NorthAmerica = "north america",
  SouthAmerica = "south america",
  Oceania = "oceania",
  Africa = "africa",
}

export enum CompensationType {
  affiliate = "affiliate",
  cash = "cash",
  exposure = "exposure",
  products_or_services = "products_or_services",
  other = "other",
}

export const LOCATIONS = [
  { key: Location.All, name: "All" },
  { key: Location.UnitedStates, name: "United States" },
  { key: Location.Canada, name: "Canada" },
  { key: Location.UK, name: "UK" },
  { key: Location.Australia, name: "Australia" },
  { key: Location.France, name: "France" },
  { key: Location.Germany, name: "Germany" },
  { key: Location.Italy, name: "Italy" },
  { key: Location.Spain, name: "Spain" },
  { key: Location.India, name: "India" },
  { key: Location.NorthAmerica, name: "North America" },
  { key: Location.Europe, name: "Europe" },
  { key: Location.Asia, name: "Asia" },
  { key: Location.Africa, name: "Africa" },
  { key: Location.Oceania, name: "Oceania" },
  { key: Location.SouthAmerica, name: "South and Central America" },
];

export enum CompanySize {
  All = "all",
  small = "sm",
  medium = "md",
  large = "lg",
  extraLarge = "xl",
}

export enum FollowingSize {
  All = "all",
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

export const FollowingSizeRange: {
  [key: string]: { min: number; max: number };
} = {
  [FollowingSize.xs]: { min: 0, max: 100000 },
  [FollowingSize.sm]: { min: 100000, max: 500000 },
  [FollowingSize.md]: { min: 500000, max: 1000000 },
  [FollowingSize.lg]: { min: 1000000, max: 10000000 },
  [FollowingSize.xl]: { min: 10000000, max: Math.max() },
};

export const FOLLOWING_SIZES = [
  { key: FollowingSize.All, name: "All" },
  {
    key: FollowingSize.xs,
    name: "Below 100K",
  },
  {
    key: FollowingSize.sm,
    name: "100K - 500K",
  },
  {
    key: FollowingSize.md,
    name: "500K - 1M",
  },
  {
    key: FollowingSize.lg,
    name: "1M - 10M",
  },
  { key: FollowingSize.xl, name: "10M+" },
];

export const COMPENSATION_TYPES = [
  { key: CompensationType.cash, name: "Cash" },
  { key: CompensationType.products_or_services, name: "Products or Services" },
  { key: CompensationType.affiliate, name: "Affiliate" },
  { key: CompensationType.exposure, name: "Exposure" },
  { key: CompensationType.other, name: "Other" },
];

export const COMPANY_SIZES = [
  { key: CompanySize.All, name: "All", label: "" },
  {
    key: CompanySize.small,
    name: "Small",
    dollarSign: 1,
    label: "Company revenue estimated to be below $10M/year",
  },
  {
    key: CompanySize.medium,
    name: "Medium",
    dollarSign: 2,
    label: "Company revenue estimated to be below $100M/year",
  },
  {
    key: CompanySize.large,
    name: "Large",
    dollarSign: 3,
    label: "Company revenue estimated to be below $1B/year",
  },
  {
    key: CompanySize.extraLarge,
    name: "Extra Large",
    dollarSign: 4,
    label: "Company revenue estimated to be above $1B/year",
  },
];

export const BentoBrandSizeToSearchKey = {
  small: "sm",
  medium: "md",
  large: "lg",
  extra_large: "xl",
};

export const DISCOVER_FILTER: { [key: string]: Map } = {
  [SearchParams.TYPE_DISCOVER]: {
    [CompanyType.All]: { name: "All", params: SearchParams.TYPE_DISCOVER },
    [CompanyType.VerifiedPaidPartnership]: {
      name: "Verified Paid Partnership",
      params: SearchParams.TYPE_DISCOVER,
    },
    [CompanyType.NotContactedThisWeek]: {
      name: "Not Contacted This Week",
      params: SearchParams.TYPE_DISCOVER,
    },
    [CompanyType.WorksWithUgc]: {
      name: "Works with UGC Creators",
      params: SearchParams.TYPE_DISCOVER,
    },
    [CompanyType.WorksWithInfluencer]: {
      name: "Works with Influencers",
      params: SearchParams.TYPE_DISCOVER,
    },
    [CompanyType.RunsTiktokAds]: {
      name: "Runs TikTok Ads",
      params: SearchParams.TYPE_DISCOVER,
    },
    [CompanyType.NonGenericEmails]: {
      name: "Direct Contact",
      params: SearchParams.TYPE_DISCOVER,
    },
  },
  [SearchParams.SIZE_DISCOVER]: {
    [CompanySize.All]: { name: "All", params: SearchParams.SIZE_DISCOVER },
    [CompanySize.small]: {
      name: "Small",
      params: SearchParams.SIZE_DISCOVER,
    },
    [CompanySize.medium]: {
      name: "Medium",
      params: SearchParams.SIZE_DISCOVER,
    },
    [CompanySize.large]: {
      name: "Large",
      params: SearchParams.SIZE_DISCOVER,
    },
    [CompanySize.extraLarge]: {
      name: "Extra Large",
      params: SearchParams.SIZE_DISCOVER,
    },
  },
  [SearchParams.LOCATION_DISCOVER]: {
    [Location.All]: { name: "All", params: SearchParams.LOCATION_DISCOVER },
    [Location.UnitedStates]: {
      name: "United States",
      params: SearchParams.LOCATION_DISCOVER,
    },
    [Location.Canada]: {
      name: "Canada",
      params: SearchParams.LOCATION_DISCOVER,
    },
    [Location.UK]: { name: "UK", params: SearchParams.LOCATION_DISCOVER },
    [Location.Australia]: {
      name: "Australia",
      params: SearchParams.LOCATION_DISCOVER,
    },
    [Location.France]: {
      name: "France",
      params: SearchParams.LOCATION_DISCOVER,
    },
    [Location.Germany]: {
      name: "Germany",
      params: SearchParams.LOCATION_DISCOVER,
    },
    [Location.Italy]: {
      name: "Italy",
      params: SearchParams.LOCATION_DISCOVER,
    },
    [Location.Spain]: {
      name: "Spain",
      params: SearchParams.LOCATION_DISCOVER,
    },
    [Location.India]: {
      name: "India",
      params: SearchParams.LOCATION_DISCOVER,
    },
    [Location.Europe]: {
      name: "Europe",
      params: SearchParams.LOCATION_DISCOVER,
    },
    [Location.Asia]: { name: "Asia", params: SearchParams.LOCATION_DISCOVER },
    [Location.Africa]: {
      name: "Africa",
      params: SearchParams.LOCATION_DISCOVER,
    },
    [Location.NorthAmerica]: {
      name: "North America",
      params: SearchParams.LOCATION_DISCOVER,
    },
    [Location.SouthAmerica]: {
      name: "South and Central America",
      params: SearchParams.LOCATION_DISCOVER,
    },
    [Location.Oceania]: {
      name: "Oceania",
      params: SearchParams.LOCATION_DISCOVER,
    },
  },
  [SearchParams.FOLLOWING_DISCOVER]: {
    [FollowingSize.All]: {
      name: "All",
      params: SearchParams.FOLLOWING_DISCOVER,
    },
    [FollowingSize.xs]: {
      name: "Below 100K",
      params: SearchParams.FOLLOWING_DISCOVER,
    },
    [FollowingSize.sm]: {
      name: "100K - 500K",
      params: SearchParams.FOLLOWING_DISCOVER,
    },
    [FollowingSize.md]: {
      name: "500K - 1M",
      params: SearchParams.FOLLOWING_DISCOVER,
    },
    [FollowingSize.lg]: {
      name: "1M - 10M",
      params: SearchParams.FOLLOWING_DISCOVER,
    },
    [FollowingSize.xl]: {
      name: "10M+",
      params: SearchParams.FOLLOWING_DISCOVER,
    },
  },
  [SearchParams.SORT_DISCOVER]: {
    [Sort.Recommended]: {
      name: "Recommended",
      params: SearchParams.SORT_DISCOVER,
    },
    [Sort.RecentlyAdded]: {
      name: "Recently Added",
      params: SearchParams.SORT_DISCOVER,
    },
  },
  [SearchParams.COMPENSATION_DISCOVER]: {
    [CompensationType.cash]: {
      name: "Cash",
      params: SearchParams.COMPENSATION_DISCOVER,
    },
    [CompensationType.products_or_services]: {
      name: "Products or Services",
      params: SearchParams.COMPENSATION_DISCOVER,
    },
    [CompensationType.affiliate]: {
      name: "Affiliate",
      params: SearchParams.COMPENSATION_DISCOVER,
    },
    [CompensationType.exposure]: {
      name: "Exposure",
      params: SearchParams.COMPENSATION_DISCOVER,
    },
    [CompensationType.other]: {
      name: "Other",
      params: SearchParams.COMPENSATION_DISCOVER,
    },
  },
};

export enum SearchParamsValue {
  OPPORTUNITY_DRAWER = "opportunityDrawer",
  REMINDER_DRAWER = "reminderDrawer",
  CONTACT_DRAWER = "contactDrawer",

  // Potential search params values for QUERY_TASK_USER
  QUERY_TASK_USER_CURRENT = "queryTaskUserCurrent",

  TAB_CONTACT = "tabContact",
  TAB_OPPORTUNITY = "tabOpportunity",
  TAB_INTERACTION = "tabInteraction",
}

export type BentoBrandSearchParameters = {
  query?: string;
  sort: Sort;
  category_ids?: Array<string>;
  company_types?: Array<CompanyType>;
  locations?: Array<Location>;
  geopoints?: Array<string>;
  company_sizes?: Array<CompanySize>;
  min_instagram_followers?: number;
  max_instagram_followers?: number;
  compensation_types?: Array<CompensationType>;
};

export const BESearchParametersToFEParams: { [key: string]: string } = {
  sort: SearchParams.SORT_DISCOVER,
  locations: SearchParams.LOCATION_DISCOVER,
  company_sizes: SearchParams.SIZE_DISCOVER,
  company_types: SearchParams.TYPE_DISCOVER,
  geopoints: SearchParams.GEOPOINTS_DISCOVER,
};

// Tasks are for influencer, Reminders are for Founders
export type Reminder = {
  id: string | null;
  status: ReminderStatus;
  userId?: number;
  contactId: number | null;
  user?: OrganizationUser;
  contact?: Contact;
  dueAt?: any; // dueAt can either be a date object or epoch number
  notes?: string;
  opportunities?: Opportunity[];
};

export enum ReminderStatus {
  PENDING = "pending",
  COMPLETE = "complete",
}

export type Task = {
  id?: number;
  status?: TaskStatus;
  description?: string;
  dueAt?: number | string; // epochTime or local datePicker
  brand?: Brand;
  brandId?: number;
  type?: TaskType;
  projectId?: number;
  project?: Project;
  emailDraftId?: number;
  emailDraft?: EmailDraft;
  gmailThreadId?: number;
  messages?: Message[];
  bentoBrandId?: number;
  updatedAt?: number;
  outreachContactId?: number;
  outreachContact?: OutreachContact;
};

export enum TaskStatus {
  PENDING = "pending",
  COMPLETED = "completed",
}

export type Interaction = {
  id?: number;
  userId?: number;
  contactId?: number;
  contact?: Contact;
  interactedAt?: string;
  notes?: string;
  recordingLink?: string;
  recordingSummary?: string;
};

export type UserIntegration = {
  id: number;
  provider: string;
  connected: boolean;
  email?: string;
  integrationError?: string;
  partnershipsSearchedAt?: number;
  partnershipsSearchCompletedAt?: number;
};

export enum CsvKey {
  CONTACT_PERSON = "contactPerson",
  CONTACT_EMAIL = "contactEmail",
  CONTACT_PERSON_TITLE = "contactPersonTitle",
  MESSAGE = "message",
  STATUS = "status",
}

export enum CsvLinkKey {
  INSTAGRAM = "instagram",
}

export enum MessageStatus {
  PENDING = "pending",
  PROCESSING = "processing",
  FAILED = "failed",
  COMPLETED = "completed",
  BOUNCED = "bounced",
  CANCELLED = "cancelled",
}

export type PreviewMessage = {
  templateId?: number;
  templateName?: string;
  body: string;
  subject?: string;
  attachment?: string;
  missingValues?: string[];
  contentType: TemplateContentType;

  isEdited?: boolean;
};

export type PreviewMessageSettings = {
  saveTemplateEdit?: boolean;
};

export enum ReactionType {
  LIKE = "like",
  DISLIKE = "dislike",
}

export type Reaction = {
  id: number;
  reactionType: ReactionType;
};

export enum OutreachContactAutomatedStatus {
  new = "new",
  contacted = "contacted",
  in_discussion = "in_discussion",
  referred = "referred",
  reach_out_later = "reach_out_later",
  declined = "declined",
  invalid = "invalid",
  automated = "automated",
}

export enum OutreachContactStatus {
  pending = "pending",
  bounced = "bounced",
  no_response = "no_response",
  received_response = "received_response",
  interested = "interested",
  not_interested = "not_interested",
}

export type Email = {
  attachment?: string;
  body?: string;
  subject?: string;
  completedAt?: number;
  createdAt: number;
  contentType?: TemplateContentType;
  gmailThreadId?: number;
  outreachContactId?: number;
  scheduledAt: number;
  sendAt: number;
  status: MessageStatus;
  id: number;
  userId: number;
  toEmail?: string;

  // used in drafts
  isChanged?: boolean;
};

export type EmailActivity = {
  userDevice: string;
  formattedAddress: string;
  createdAt: number;
  id: number;
};

export type OutreachContact = {
  id?: number;
  organizationId?: number;
  brand?: Brand;
  contactName: string;
  contactTitle: string;
  email?: string;
  instagramUsername: string;
  contactMethod: ContactMethod;
  createdAt?: number;
  latestMessageStatus?: MessageStatus | null;
  reactions?: Reaction[];
  bentoBrand?: BentoBrand;
  bentoBrandId?: number;
  bentoContactId?: number;
  bentoBrandContents?: BentoBrandContent[];
  automatedStatus?: OutreachContactAutomatedStatus;
  status?: OutreachContactStatus;
  smartTask?: Task;
  updatedAt?: number;
  lastGmailMessageDate?: number;
  lastGmailThreadId?: string;
  lastGmailThreadParticipants?: string;
  lastGmailThreadLength?: number;
  lastGmailMessageSubject?: string;
  lastGmailMessageSnippet?: string;
  latestEmail?: Email;
  isPinned?: boolean;
  isPinnedForTracking?: boolean;
  isImportant?: boolean;
  isArchived?: boolean;
  lastEmailOpenCount: number;
  lastEmailOpenedAt?: number;
  hasTrackedEmails?: boolean;
  propertyValues?: { [key: number]: any };
  nextPendingTask?: Task;
  hasResponded?: boolean;

  emails?: Email[];

  latestSequenceUuid?: string;
  latestSequenceSentCount?: number;
  latestSequenceTotalCount?: number;
  latestSequenceNextSendAt?: number;
  latestOutreachTemplateId?: number;
};

export type OutreachEmailDraft = {
  emailTemplateId?: number;
  subject?: string;
  body: string;
  attachment?: string;
  contentType: TemplateContentType;
};

export type OutreachDraft = {
  bentoBrand: BentoBrand;
  bentoBrandId: number;
  bentoContactId?: number;
  contactEmail?: string;
  contactName?: string;
  contactTitle?: string;
  emailDrafts: OutreachEmailDraft[];
  isAutogenerated?: boolean;
  createdAt?: number;
  id?: number;
};

export type GmailThread = {
  id: string;
  messages: Message[];
  pendingEmailsCount: number;
};

export enum ContentPlatform {
  instagram = "instagram",
}

export enum MetadataType {
  tags = "tags",
  size = "size",
  location = "location",
}

export enum MetadataLocationType {
  country = "country",
  continent = "continent",
}

export type BentoBrandMetadataTags = {
  longitude?: number;
  latitude?: number;
  type: MetadataType;
  value: string;
  locationType?: MetadataLocationType;
};

export type BentoBrand = {
  latestOutreachTemplateId: any;
  countryCode?: string;
  createdAt: number;
  id: number;
  brandName: string;
  website?: string;
  logoUrl?: string;
  mainImageUrl?: string;
  brandImageUrls?: string[];
  tags: string;
  instagramUsername?: string;
  isArchived?: boolean;
  doNotRecommended?: boolean;
  hasValidatedPartnership?: boolean;
  worksWithUgc?: boolean;
  worksWithInfluencer?: boolean;
  isInternational?: boolean;
  hasBrandNotification?: boolean;
  hasContacts?: boolean;
  metadata?: BentoBrandMetadataTags[];
  pricePoint?: string;
  size?: string;

  twitterUsername?: string;
  facebookUsername?: string;
  pinterestUsername?: string;
  tiktokUsername?: string;
  youtubeUrl?: string;
  linkedinUrl?: string;
  pinterestMainImageUsed?: boolean;
  mainImageSource?: string;

  aboutSummary?: string;
  aboutReferenceLink?: string;
  targetAge?: string;
  targetGender?: string;
  targetPricePoint?: string;
  targetPainPoint?: string;
  targetSummary?: string;
  targetReferenceLinks?: string[];
  targetLocation?: string;
  compensationTypes?: CompensationType[];

  // TODO new category: remove these attributes
  mainCategory?: string;
  categories?: string[];

  // ElasticSearch Property
  contactedRecentlyAmount?: number;
  countries?: string[];
  continents?: string[];
  locationNames?: string[];
  tagsAutocomplete?: string[];
  instagramFollowerCount?: number;
  hasTiktokAds?: boolean;
  hasNonGenericContacts?: boolean;
  mainCategoryNames?: string[];
  secondaryCategoryNames?: string[];
  mainCategoryIds?: number[];
  secondaryCategoryIds?: number[];

  // SavedBrand property
  hasOutreachDraft?: boolean;
  latestEmailCompletedAt?: string;
  earliestPendingSendAt?: string;
  orgHasOutreachContact?: boolean;
  isAdmin?: boolean;
  createdByAutomation?: boolean;
  collections?: SavedBrandCollection[];
  // BulkSend property
  sequenceUuid?: string;
};

export enum TargetGender {
  all = "all",
  male = "male",
  female = "female",
}

export type BentoSearchFilter = {
  id: number;
  name: string;
  keywords: string[];
  enableForExclude: boolean;
  enableForInclude: boolean;
  minTargetAge?: number;
  maxTargetAge?: number;
  targetGender?: TargetGender;
};

export type BentoContact = {
  isArchived?: boolean;
  id?: number;
  name?: string;
  title?: string;
  email: string;
  score?: number;
  location?: string;
  bentoBrand?: BentoBrand;
  bentoBrandId?: number;
  bentoContactResponses?: BentoContactResponse[];
  source?: BentoContactSource;
  sourcedFromUserInbox?: boolean;
};

export type BentoContactResponse = {
  id: number;
  userIntegrationId?: number;
  userIntegration?: UserIntegration;
  gmailThreadId: string;
};

export enum BentoContactSource {
  gmail = "gmail",
  gmail_import = "gmail_import",
  referral = "referral",
}

export type NewBentoContact = {
  bentoBrandId: number;
  name?: string;
  email: string;
  title?: string;
  isReferred?: boolean;
};

export type BrandRecommendation = {
  id: number;
  bentoBrand: BentoBrand;
  bentoBrandId: number;
  socialMediaPost?: SocialMediaPost;
  source: BrandRecommendationSource | null;
  archiveReason?: BrandRecommendationArchiveReason;
};

export enum BrandRecommendationArchiveReason {
  DO_NOT_RECOMMEND = "do_not_recommend",
}

export type SocialMediaPost = {
  id: number;
  postAssetTitle?: string;
  postDescription?: string;
  postUrl?: string;
  postAssetUrl?: string;
};

export enum BrandRecommendationSource {
  SOCIAL_MEDIA_POST = "social_media_post",
  TOP_MATCH = "top_match",
  USER_ARCHIVE = "user_archive",
}

export type SavedBrand = {
  id?: number;
  bentoBrand: BentoBrand;
  bentoBrandId: number;
  createdAt: number;
};

export type SavedBrandCollection = {
  id: number;
  name: string;
  sentCount: number;
  unsentCount: number;
  isDefaultForVa?: boolean;
  excludeFromDefaultVa?: boolean;
  sentBrands?: SavedBrand[];
  unsentBrands?: SavedBrand[];
};

export type SavedBrandCollectionAssociation = {
  organizationId: number;
  savedBrandCollectionId: number;
  bentoBrandId: number;
};

export type TikTokAd = {
  id: string;
  ctrPercentile?: number;
  conversionCountPercentile?: number;
  shareCount?: number;
  likeCount?: number;
  title?: string;
  uploadedVideoUrl: string;
};

export type BentoBrandContent = {
  id?: number;
  socialMediaPostId?: number;
  bentoBrandId: number;
  platform: ContentPlatform;
  url: string;
  username: string;
  followerCount: number;
  followingCount?: number;
  postCount?: number;
  title?: string;
  thumbnailUrl?: string;
  likeCount?: number;
  commentCount?: number;
};

export type BentoBrandProduct = {
  id: number;
  name: string;
  bentoBrandId: number;
  description?: string;
  onlineStoreUrl?: string;
  purchaseLocation?: string;
  imageUrl?: string;
};

export type Brand = {
  bentoBrandId?: number;
  id: number;
  organizationId?: number;
  name: string;
  projects?: Project[];
  brandContacts?: BrandContact[];
  updatedAt?: number;
  website?: string;
  instagramUsername?: string;
  hasResponded?: boolean;
  lastGmailMessageDate?: number;
  compensationTypes?: CompensationType[];
};

export type BrandContact = {
  id: number;
  brandId?: number;
  name?: string;
  title?: string;
  email?: string;
};

export type Project = {
  id?: number;
  brandId?: number;
  brandName?: string;
  name: string;
  notes?: string;
  brandContacts?: BrandContact[];
  updatedAt?: number;
  brand?: Brand;
  partnershipType?: PartnershipType;
  tasks?: Task[];
};

export enum ProjectSort {
  NAME = "name:asc",
  LAST_MODIFIED = "updated_at:desc",
}

export enum ProjectFilter {
  PAID = "paid",
  GIFTED = "gifted",
  ALL = "all",
}

export enum PartnershipType {
  PAID = "paid",
  GIFTED = "gifted",
}

export enum TaskType {
  OUTREACH = "outreach",
  PROJECT = "project",
  EMAIL = "email",
}

export enum TaskSort {
  DUE_DATE = "due_at:asc",
}

export enum ProjectCreationSource {
  BRAND_RECOMMENDATION = "brand_recommendation",
  SEARCH_RESULT = "search_result",
}

export type UserNotifications = {
  optInEmail?: boolean;
  optInPhone?: boolean;
};

export enum GmailScope {
  READ = "https://www.googleapis.com/auth/gmail.readonly",
  SEND = "https://www.googleapis.com/auth/gmail.send",
}

export enum RequestStatus {
  pending = "pending",
  found = "found",
  not_found = "not_found",
}

export enum RequestType {
  user_requests = "user_requests",
  brand_notifications = "brand_notifications",
  contact_notifications = "contact_notifications",
}

export type CombinedRequest = {
  additionalContactNotificationStatus?: RequestStatus;
  additionalContactNotificationUpdatedAt?: number;
  bentoBrand?: BentoBrand;
  bentoBrandIdOrUserRequestDescription?: string;
  brandNotificationStatus?: RequestStatus;
  brandNotificationUpdatedAt?: string;
  earliestPendingSendAt?: string;
  hasFoundRequest?: boolean;
  latestEmailCompletedAt?: string;
  latestRequestUpdatedAt?: string;
  userRequestStatus?: RequestStatus;
  userRequestUpdatedAt?: string;
};

export type UserRequest = {
  id?: number;
  description: string;
  source: UserRequestSource;
  status: RequestStatus;
  createdAt: number;
  userId: number;
  bentoBrand?: BentoBrand;
};

export type BrandNotification = {
  bentoBrand?: BentoBrand;
  status: RequestStatus;
  bentoBrandId?: number;
};

export type AdditionalContactNotification = {
  updatedAt: number | undefined;
  latestEmailCompletedAt: any;
  earliestPendingSendAt: any;
  bentoBrand?: BentoBrand;
  status: RequestStatus;
  bentoBrandId?: number;
  bentoContactId?: number;
};

export enum UserRequestSource {
  SOURCE_TOGGLE = "no_results_toggle",
  SOURCE_REQUEST_BRAND = "request_brand",
}

export type ChatMessage = {
  countsTowardQuota: boolean;
  id?: number;
  message: string;
  role: "user" | "assistant";
  bentoBrandId?: number;
};

export enum ChatMessageAction {
  personalize = "personalize",
  openChat = "open",
}

export enum PropertyType {
  shortText = "short_text",
  longText = "long_text",
  attachment = "attachment",
  checkbox = "checkbox",
  multipleSelect = "multiple_select",
  singleSelect = "single_select",
  number = "number",
  date = "date",
  dateTime = "date_time",
}

export const PropertyTypesList = [
  PropertyType.shortText,
  PropertyType.longText,
  PropertyType.attachment,
  PropertyType.checkbox,
  PropertyType.multipleSelect,
  PropertyType.singleSelect,
  PropertyType.number,
  PropertyType.date,
  PropertyType.dateTime,
];

export const PropertyTypeLabel: {
  [key in PropertyType]: string;
} = {
  [PropertyType.shortText]: "Short Text",
  [PropertyType.longText]: "Long Text",
  [PropertyType.number]: "Number",
  [PropertyType.attachment]: "Attachment",
  [PropertyType.checkbox]: "Checkbox",
  [PropertyType.multipleSelect]: "Multiple Select",
  [PropertyType.singleSelect]: "Single Select",
  [PropertyType.date]: "Date",
  [PropertyType.dateTime]: "Date Time",
};

export type SelectPropertyOption = {
  id: string;
  name: string;
  color?: string;
};

export type ContactProperty = {
  id?: number;
  name: string;
  type?: PropertyType;
  selectOptions?: SelectOption[];
  automatedProperty?: AutomatedContactProperty;
};

export enum AutomatedContactProperty {
  stage = "stage",
  status = "status",
  interactions = "interactions",
}

export enum BuiltInContactPropertyName {
  contactName = "contact_name",
  contactTitle = "contact_title",
  email = "email",
  brandName = "brand_name",
  createdAt = "created_at",
  brandHasResponded = "brand_has_responded",
  brandLastGmailMessageDate = "brand_last_gmail_message_date",
}

export const BuildInContactPropertyNamesList = [
  BuiltInContactPropertyName.email,
  BuiltInContactPropertyName.brandName,
  BuiltInContactPropertyName.contactName,
  BuiltInContactPropertyName.contactTitle,
  BuiltInContactPropertyName.createdAt,
  BuiltInContactPropertyName.brandHasResponded,
  BuiltInContactPropertyName.brandLastGmailMessageDate,
];

export const PropertyNameLabel: {
  [key in BuiltInContactPropertyName]: string;
} = {
  [BuiltInContactPropertyName.contactName]: "Contact Name",
  [BuiltInContactPropertyName.contactTitle]: "Contact Title",
  [BuiltInContactPropertyName.email]: "Contact Email",
  [BuiltInContactPropertyName.brandName]: "Brand Name",
  [BuiltInContactPropertyName.createdAt]: "Created On",
  [BuiltInContactPropertyName.brandHasResponded]: "Brand Has Responded",
  [BuiltInContactPropertyName.brandLastGmailMessageDate]:
    "Last Email Sent or Received From Brand",
};

export const TitleMap: { [key: string]: string } = {
  ...PropertyNameLabel,
  last_gmail_message_date: "Date",
  smart_task: "Tasks",
  is_important: "Awaiting Your Response",
  send_follow_up: "Send Follow-Up",
  latest_sequence_status: "Status",
  latest_outreach_template: "Templated Used",
  actions: "Actions",
  move_brand_to: "Move Brand",
  last_email_open_count: "Opens",
  email_thread: "Email Thread",
};

export type MatchColumn = {
  column_index: number;
  contact_property_name: BuiltInContactPropertyName | null;
  contact_property_id: number | null;
  skip_column: boolean;
};

export type RowImport = {
  row: number;
  email?: string;
  skip_reason?: string;
};

export type FailedReason = {
  row: number;
  column: number;
  value: string;
  contact_property_id: number;
  reason: string;
  email?: string;
};

export type UploadSummary = {
  new: RowImport[];
  update: RowImport[];
  skip: RowImport[];
  failed: FailedReason[];
};

export type NumbersMap = { [key: number]: number | undefined };

export type SavedBrandCollectionsMap = {
  [key in number]: SavedBrandCollection;
};

export type NameMap = { [key: number]: string };
export enum SavedBrandStatus {
  unsent = "unsent",
  sent = "sent",
}

export type Automation = {
  id?: number;
  count: number;
  dayOfWeek: number;
  options: SaveBrandAutomationOptions;
  searchParameters: BentoBrandSearchParameters;
};

export type SaveBrandAutomationOptions = {
  collection_option: "none" | "new" | "existing";
  collection_id?: number;
};

export type EmailSequence = {
  bentoBrandId: number;
  sequenceUuid?: string;
  fallbackTemplateIds?: number[];

  // Only used on FE
  category: string;
  bentoBrand: BentoBrand;
};

export type EmailSequenceMap = { [key: number]: EmailSequence };

export enum SendOption {
  same = "same",
  by_category = "by_category",
  by_automation = "by_automation",
}
