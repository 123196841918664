import { BentoBrand } from "schemas/dashboard";

import { getDifferenceFromToday } from "utils/time";

export const getIconsCondition = (bentoBrand: BentoBrand) => {
  const addedWithinLastWeek =
    getDifferenceFromToday(bentoBrand?.createdAt) <= 7;
  const hasPaidPartnership = bentoBrand?.hasValidatedPartnership;
  const worksWithUgc = bentoBrand?.worksWithUgc;
  const worksWithInfluencer = bentoBrand?.worksWithInfluencer;
  const noRecentContacts = bentoBrand?.contactedRecentlyAmount === 0;
  const hasTiktokAds = !!bentoBrand?.hasTiktokAds;
  const hasNonGenericEmails = !!bentoBrand?.hasNonGenericContacts;

  return {
    addedWithinLastWeek,
    hasPaidPartnership,
    worksWithUgc,
    worksWithInfluencer,
    noRecentContacts,
    hasTiktokAds,
    hasNonGenericEmails,
  };
};
