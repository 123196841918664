const styles = {
  root: {
    width: "100%",
    position: "relative",
  },
  container: {
    maxHeight: "350px",
    display: "flex",
    flexDirection: "row",
    columnGap: 2,
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      width: 0,
    },
    width: "100%",
    alignItems: "stretch",
  },
  arrowGridLeft: {
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    cursor: "pointer",
    alignItems: "center",
    display: "flex",
    zIndex: 1,
  },
  arrowGridRight: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
    cursor: "pointer",
    alignItems: "center",
    display: "flex",
    zIndex: 1,
  },
  hideIcon: {
    visibility: "hidden",
  },
  arrowVisibleRight: {
    background:
      "linear-gradient(to left, rgba(255,255,255,0.75), rgba(255,255,255,0))",
  },
  arrowVisibleLeft: {
    background:
      "linear-gradient(to right, rgba(255,255,255,0.75), rgba(255,255,255,0))",
  },
  arrow: {
    mx: 0.5,
    fontSize: 18,
    width: 30,
    height: 30,
  },
};

export default styles;
