import { useAuth } from "@clerk/clerk-react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AdminBentoCategory } from "schemas/admin";
import { BentoBrand } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import Alert from "components/Alert";
import StyledPage from "components/Styled/Page";
import { fetcherAuth } from "utils/api";
import { useAlert } from "utils/useAlert";

import NewBrand from "./NewBrand";
import styles from "./styles";

const AdminBrands = () => {
  const { getToken } = useAuth();
  const navigate = useNavigate();

  const [message, severity, setAlert, closeAlert] = useAlert();

  const [page, setPage] = useState<number>(1);
  const [brandSearch, setBrandSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const [results, setResults] = useState<BentoBrand[]>([]);
  const [totalCount, setTotalCount] = useState<number | undefined>();

  const [categoriesById, setCategoriesById] = useState<{
    [key: number]: AdminBentoCategory;
  }>({});

  const fetchCategories = async () => {
    setLoading(true);

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/admin/bento-categories`,
        "GET",
      );
      const categoriesById: { [key: number]: AdminBentoCategory } = {};
      for (const category of res.bentoCategories) {
        categoriesById[category.id] = category;
      }
      setCategoriesById(categoriesById);
    } catch (error) {
      setAlert(
        "An error occurred, you do not have access to this page",
        "warning",
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchBrands = async (pageToFetch: number) => {
    setLoading(true);

    try {
      const encodedQuery = encodeURIComponent(brandSearch);
      const res = await fetcherAuth(
        getToken,
        `/api/admin/bento-brands?query=${encodedQuery}&page=${pageToFetch}&per_page=${20}`,
        "GET",
      );
      setResults(res.bentoBrands);
      setTotalCount(res.total);
    } catch (error) {
      setAlert(error.message || "An error occurred", "warning");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchBrands(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBrandSearch = (event: CustomEvent) => {
    const { value } = event.target;
    setBrandSearch(value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setPage(1);
    fetchBrands(1);
  };

  const handleChangePage = (e: CustomEvent, newPage: number) => {
    setPage(newPage + 1);
    fetchBrands(newPage + 1);
  };

  return (
    <StyledPage
      content={
        <>
          <Backdrop sx={styles.backdrop} open={loading}>
            <CircularProgress />
          </Backdrop>
          <Grid container sx={{ mt: 2 }} spacing={1} alignItems="center">
            <Grid item xs="auto">
              <Typography variant="h3" gutterBottom>
                <strong>Brands</strong>
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={() => {
                  navigate("new");
                }}
                variant="contained"
                sx={{ mb: 2 }}
              >
                New +
              </Button>
            </Grid>
          </Grid>

          <form onSubmit={handleSubmit}>
            <Grid container sx={{ mb: 3 }} spacing={1}>
              <Grid item xs>
                <TextField
                  label="Search"
                  fullWidth
                  placeholder="Uniqlo"
                  name="contactSearch"
                  onChange={handleBrandSearch}
                />
              </Grid>
              <Grid item xs="auto">
                <Button type="submit" variant="contained" color="primary">
                  Search
                </Button>
              </Grid>
            </Grid>
          </form>

          <TableContainer sx={styles.tableContainer}>
            <Table stickyHeader sx={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Brand Name</TableCell>
                  <TableCell>Website</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((res) => (
                  <TableRow key={res.id}>
                    <TableCell>
                      {res.brandName} ({res.id})
                    </TableCell>
                    <TableCell>{res.website}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          navigate(`${res.id}`);
                        }}
                        variant="outlined"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount || 0}
            rowsPerPage={20}
            rowsPerPageOptions={[20]}
            page={page - 1}
            onPageChange={handleChangePage}
          />
          <Alert
            message={message}
            severity={severity}
            closeAlert={closeAlert}
          />
          <Routes>
            <Route
              path="/:id"
              element={
                <NewBrand
                  brands={results}
                  setBrands={setResults}
                  categoriesById={categoriesById}
                />
              }
            />
            <Route
              path="/new"
              element={
                <NewBrand
                  brands={results}
                  setBrands={setResults}
                  categoriesById={categoriesById}
                />
              }
            />
          </Routes>
        </>
      }
    />
  );
};

export default AdminBrands;
