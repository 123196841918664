import { Box, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CustomEvent } from "schemas/functions";

import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface VideoEmbedProps {
  link: string;
  linkPreview?: string;
  eventName?: string;
}

const VideoEmbed: React.FC<VideoEmbedProps> = ({
  link,
  linkPreview,
  eventName,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [showControls, setShowControls] = useState<boolean>(false);

  const handleClick = (e: CustomEvent) => {
    e.stopPropagation();
    videoRef.current?.play();
    setIsPlaying(true);
    if (eventName) {
      trackEvent(eventName);
    }
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowControls(true);
  };

  const handleMouseLeave = () => {
    setShowControls(false);
  };

  useEffect(() => {
    const video = videoRef.current;

    if (!video) return;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    video.addEventListener("play", handlePlay);
    video.addEventListener("pause", handlePause);

    return () => {
      video.removeEventListener("play", handlePlay);
      video.removeEventListener("pause", handlePause);
    };
  }, []);

  return (
    <>
      <Box
        ref={parentRef}
        sx={styles.videoSection}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          ref={videoRef}
          component="video"
          poster={linkPreview}
          sx={styles.video}
          disablePictureInPicture
          controlsList="nodownload"
          controls={showControls}
        >
          <Box component="source" src={link} type="video/mp4" />
          Your browser does not support the video tag.
        </Box>
        {!isPlaying && (
          <IconButton onClick={handleClick} sx={styles.controls}>
            <Box
              component="i"
              className={`fa-solid fa-${isPlaying ? "pause" : "play"}`}
            />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default VideoEmbed;
