import { SendOption } from "schemas/dashboard";

export type OrganizationBulkSendSettings = {
  numberOfBrands?: number;
  numberOfRepitchBrands?: number;
  templateOption?: SendOption;
  timezone?: string;
  status?: BulkSendSettingsStatus;
  id?: number;
};

export enum BulkSendSettingsStatus {
  active = "active",
  inactive = "inactive",
}
