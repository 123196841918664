import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { BentoBrand, SendOption } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { BulkSendBrandsGroup } from "components/BulkSendDialog/schema";
import { UNCATEGORIZED } from "utils/templates";

interface Props {
  sendOption?: SendOption;
  category: string;
  categories: BulkSendBrandsGroup;
  setCategories: Dispatch<SetStateAction<BulkSendBrandsGroup>>;
  bentoBrand: BentoBrand;
}

const MovingOption = ({
  sendOption,
  category,
  setCategories,
  bentoBrand,
  categories,
}: Props) => {
  const handleChange = (e: CustomEvent) => {
    const { value } = e.target;
    if (value === category) return;

    setCategories((prev) => {
      const copy = { ...prev };
      // Remove the brand from the current category
      const oldCollection = copy[category]?.emailSequences;
      let matchedEmailSequence = null;

      const index = oldCollection.findIndex(
        (es) => es.bentoBrand.id === bentoBrand.id,
      );
      if (index > -1) {
        matchedEmailSequence = oldCollection[index];
        oldCollection.splice(index, 1);
      }
      copy[category].emailSequences = oldCollection;
      if (matchedEmailSequence) {
        // Add the brand to the new category
        const newCollection = copy[value]?.emailSequences;
        newCollection?.push(matchedEmailSequence);
        copy[value].emailSequences = newCollection;
      }
      return copy;
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Move To</InputLabel>
      <Select
        value={category}
        label="Move To"
        onChange={handleChange}
        size="small"
        sx={{ minWidth: 150 }}
        displayEmpty
      >
        <MenuItem value={UNCATEGORIZED}>
          <em>Uncategorized</em>
        </MenuItem>
        {Object.keys(categories)?.map(
          (category) =>
            category !== UNCATEGORIZED && (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ),
        )}
      </Select>
    </FormControl>
  );
};

export default MovingOption;
