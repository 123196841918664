import { Box, List } from "@mui/material";
import { BentoCategory } from "schemas/dashboard";

import TopLevelCategory from "./TopLevelCategory";
import styles from "./styles";

interface Props {
  topLevelCategories: BentoCategory[];
}

export default function CategoryFilter({ topLevelCategories }: Props) {
  return (
    <Box sx={styles.root}>
      <List dense>
        {topLevelCategories?.map((category) => (
          <TopLevelCategory key={`${category.id}`} category={category} />
        ))}
      </List>
    </Box>
  );
}
