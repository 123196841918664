import { Chip, Grid, Typography } from "@mui/material";
import { TagLabel } from "contexts/Brands";
import lodash from "lodash";
import {
  BentoBrandMetadataTags,
  MetadataType,
  SearchParams,
} from "schemas/dashboard";

import { DiscoverSearchChipColor } from "utils/color";
import { usePageSize } from "utils/usePageSize";
import { useSearchBrand } from "utils/useSearchBrand";

import styles from "./styles";

interface Props {
  selectedTags: TagLabel[];
  selectedMetadataTags: BentoBrandMetadataTags[];
}

const DiscoverChips = ({ selectedTags, selectedMetadataTags }: Props) => {
  const { deleteCategoryFromSearch, deleteMetadataFromSearch, hasTags } =
    useSearchBrand();
  const { breaksDiscoverScreen } = usePageSize();

  return hasTags ? (
    <>
      <Grid
        container
        wrap={breaksDiscoverScreen ? "wrap" : "nowrap"}
        item
        xs={12}
        md
        gap={1}
        sx={styles.chipsContainer(breaksDiscoverScreen)}
      >
        {breaksDiscoverScreen ? (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography>List of tags you have selected:</Typography>
          </Grid>
        ) : (
          <></>
        )}

        {selectedTags?.map((chip: TagLabel, index: number) => (
          <Chip
            key={index}
            label={chip.name}
            onDelete={() => deleteCategoryFromSearch(chip)}
            sx={[
              styles.selectedChip,
              { backgroundColor: DiscoverSearchChipColor[chip.params] },
            ]}
            size={breaksDiscoverScreen ? "small" : "medium"}
          />
        ))}
        {selectedMetadataTags?.map(
          (tag: BentoBrandMetadataTags, index: number) => (
            <Chip
              key={index}
              label={lodash.startCase(tag.value)}
              onDelete={() => deleteMetadataFromSearch(tag)}
              sx={[
                styles.selectedChip,
                {
                  backgroundColor:
                    tag.type === MetadataType.location
                      ? DiscoverSearchChipColor[SearchParams.LOCATION_DISCOVER]
                      : DiscoverSearchChipColor[
                          SearchParams.CATEGORY_ID_DISCOVER
                        ],
                },
              ]}
              size={breaksDiscoverScreen ? "small" : "medium"}
            />
          ),
        )}
      </Grid>
    </>
  ) : (
    <></>
  );
};

export default DiscoverChips;
