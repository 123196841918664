const styles = {
  textField: {
    mb: 2,
    "& .MuiInputBase-root": { backgroundColor: "white" },
    "& .MuiFormHelperText-root": { color: "error.main" },
  },
  helperText: {
    mb: 0.5,
  },
};

export default styles;
