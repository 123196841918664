import { Box, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BentoBrand } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { formatNumber } from "utils/string";
import { trackEvent } from "utils/tracking";

interface Props {
  bentoBrand: BentoBrand;
}
const styles = {
  followerCount: {
    fontSize: 12,
  },
  instagramUsername: {
    width: "max-content",
    fontSize: 12,
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

const BrandSocialLinks = ({ bentoBrand }: Props) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const wrapLength = isMobileScreen ? 20 : 50;

  return (
    <Box>
      {(bentoBrand?.instagramUsername || bentoBrand.website) && (
        <Typography sx={styles.instagramUsername} component="div">
          {bentoBrand.instagramFollowerCount && (
            <Typography
              component="span"
              color="textSecondary"
              sx={styles.followerCount}
            >
              {formatNumber(bentoBrand.instagramFollowerCount)} followers{" "}
            </Typography>
          )}
          {bentoBrand.instagramUsername && (
            <Link
              target="_blank"
              href={`https://instagram.com/${bentoBrand?.instagramUsername}`}
              sx={{ width: "max-content" }}
              onClick={(e: CustomEvent) => {
                e.stopPropagation();
                trackEvent("Instagram Link Brand Card Clicked", {
                  "Instagram Username": bentoBrand.instagramUsername,
                  "Bento Brand ID": bentoBrand.id,
                });
              }}
            >
              @{bentoBrand?.instagramUsername}
            </Link>
          )}
          {!isMobileScreen && bentoBrand?.website && (
            <>
              {bentoBrand.instagramUsername && " | "}
              <Link
                target="_blank"
                href={`https://${bentoBrand?.website}`}
                sx={{ width: "max-content" }}
                onClick={(e: CustomEvent) => {
                  e.stopPropagation();
                  trackEvent("Website Brand Card Clicked", {
                    Website: bentoBrand.website,
                    "Bento Brand ID": bentoBrand.id,
                  });
                }}
              >
                {bentoBrand?.website?.length > wrapLength
                  ? `${bentoBrand?.website.substring(0, wrapLength)}...`
                  : bentoBrand.website}
              </Link>
            </>
          )}
        </Typography>
      )}
    </Box>
  );
};

export default BrandSocialLinks;
