import { useAuth } from "@clerk/clerk-react";
import { AlertContext } from "contexts/Alert";
import { AutogeneratedDraftsContext } from "contexts/AutogenerateDrafts";
import { OrganizationUserContext } from "contexts/Organization";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext } from "react";
import {
  BentoBrand,
  SavedBrand,
  SavedBrandCollection,
  SavedBrandCollectionsMap,
  SavedBrandStatus,
} from "schemas/dashboard";

import { fetcherAuth } from "./api";
import { getNow } from "./time";
import { makeDeepCopy } from "./updateLocalState";

export const useCollection = () => {
  const {
    collections,
    setCollections,
    currentTab,
    updateCollection,
    allSavedBrands,
    selectedCollection,
    setAllSavedBrands,
  } = useContext(SavedBrandCollectionsContext);
  const { allDraftBrands, setAllDraftBrands } = useContext(
    AutogeneratedDraftsContext,
  );
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { setAlert, setErrorAlert } = useContext(AlertContext);

  const isSavedBrand = (brand: BentoBrand) => {
    return allSavedBrands.includes(Number(brand.id));
  };

  const isDraftBrand = (bentoBrand: BentoBrand) => {
    return allDraftBrands.includes(Number(bentoBrand.id));
  };

  const getTotal = () => {
    const allSavedCollection = collections[0];
    return (
      (allSavedCollection?.sentCount || 0) +
      (allSavedCollection?.unsentCount || 0)
    );
  };

  const updateTotal = (
    collectionId: number,
    action: "add" | "remove",
    type: SavedBrandStatus,
    change = 1,
  ) => {
    updateCollection(collectionId, type, {
      newCount: (prevCount) => {
        prevCount += action === "add" ? change : -change;
        return prevCount;
      },
    });
  };

  const modifySavedBrands = (
    brand: SavedBrand,
    action: "add" | "remove",
    collectionId?: number,
    type?: SavedBrandStatus,
  ) => {
    if (action === "add") {
      addToCollectionTab(brand, collectionId || 0, type || currentTab);
    } else {
      removeFromCollectionTab(brand, collectionId || 0, type || currentTab);
    }
  };

  const addDraftToCollection = (bentoBrand: BentoBrand) => {
    setAllDraftBrands((prev) => [...prev, Number(bentoBrand.id)]);
    _modifyPropertyOnSelectedCollection(
      bentoBrand,
      "hasOutreachDraft",
      true,
      true,
    );
  };

  const removeDraftFromCollection = (bentoBrand: BentoBrand) => {
    setAllDraftBrands((prev) => {
      return prev.filter((id) => id !== Number(bentoBrand.id));
    });
    _modifyPropertyOnSelectedCollection(
      bentoBrand,
      "hasOutreachDraft",
      false,
      false,
    );
  };

  const createOrUpdateCollection = async (
    name: string,
    collectionId?: number,
    isBulkMode?: boolean,
    excludeFromDefaultVa?: boolean,
  ): Promise<SavedBrandCollection | undefined> => {
    if (!currentOrg?.id) return;
    let url = collectionId
      ? `/api/organization/${currentOrg.id}/saved-brand-collections/${collectionId}`
      : `/api/organization/${currentOrg.id}/saved-brand-collections`;
    try {
      const res = await fetcherAuth(
        getToken,
        url,
        collectionId ? "PUT" : "POST",
        {},
        { name, excludeFromDefaultVa },
      );
      if (collectionId) {
        setCollections((prev) => {
          const copy = { ...prev };
          if (copy[collectionId]) {
            copy[collectionId] = {
              ...copy[collectionId],
              ...res.savedBrandCollection,
            };
          }
          return copy;
        });

        if (!isBulkMode)
          setAlert("Successfully updated your collection", "success");
      } else {
        setCollections((prev) => {
          const copy = { ...prev };
          copy[res.savedBrandCollection.id] = {
            ...res.savedBrandCollection,
            unsentCount: 0,
            unsentBrands: [],
            sentCount: 0,
            sentBrands: [],
          };
          return copy;
        });
        if (!isBulkMode)
          setAlert("Successfully created your collection", "success");
      }
      return res.savedBrandCollection;
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const deleteCollection = async (collectionId: number) => {
    if (!currentOrg?.id) return;
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg.id}/saved-brand-collections/${collectionId}`,
        "DELETE",
      );
      setCollections((prev) => {
        const copy = { ...prev };
        delete copy[collectionId];
        return copy;
      });
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const moveToSent = async (
    bentoBrand: BentoBrand,
    currentSelectedCollectionId: number,
  ) => {
    const savedBrand = {
      bentoBrand,
      bentoBrandId: bentoBrand.id,
      createdAt: getNow(),
    };
    const collection = collections[currentSelectedCollectionId];
    // Remove from the current collection and add to Sent.
    _removeFromCollection(bentoBrand.id, collection, SavedBrandStatus.unsent);
    _addToCollection(savedBrand, collection, SavedBrandStatus.sent);
    removeDraftFromCollection(bentoBrand);
  };

  const bulkMoveToSent = async (
    bentoBrands: BentoBrand[],
    collection: SavedBrandCollection,
  ) => {
    setCollections((prev) => {
      const copy = { ...prev };
      for (const brand of bentoBrands) {
        const savedBrand = {
          bentoBrand: brand,
          bentoBrandId: brand.id,
          createdAt: getNow(),
        };
        const matchedCollection = copy[collection.id];
        _removeFromCollection(
          brand.id,
          matchedCollection,
          SavedBrandStatus.unsent,
        );
        _addToCollection(savedBrand, matchedCollection, SavedBrandStatus.sent);
      }
      return copy;
    });
  };

  const addToCollectionTab = (
    brand: SavedBrand,
    collectionId: number,
    newType: SavedBrandStatus,
  ) => {
    /**
     * This function adds a saved brand to a new collection ID. If the brand is not in all saved list,
     * it will also add to the all saved list.
     */
    setCollections((prev) => {
      const copy = { ...prev };
      const collection = copy[collectionId];
      const { type: originalType, index } = _findInCollection(
        brand.bentoBrand,
        copy,
        collectionId,
      );
      // If a brand is `unsent` but is being added to `sent`, remove from `unsent`
      if (originalType && originalType !== newType) {
        _removeFromCollection(brand.bentoBrand.id, collection, originalType);
      }
      if (index === undefined || index === -1) {
        _addToCollection(brand, collection, newType);
      }
      return copy;
    });
  };

  const removeFromCollectionTab = (
    brand: SavedBrand,
    collectionId?: number,
    oneType?: SavedBrandStatus,
  ) => {
    // Remove a brand from a collection. If no collection ID is provided, remove from all collections.
    // If no types are provided, remove from both sent and unsent.
    setCollections((prev) => {
      const copy = { ...prev };
      if (collectionId) {
        _removeFromTab(copy[collectionId]);
        return copy;
      }
      for (const collection of Object.values(copy)) {
        _removeFromTab(collection);
      }
      return copy;
    });

    const _removeFromTab = (collection: SavedBrandCollection) => {
      if (oneType === undefined || oneType === SavedBrandStatus.sent) {
        _removeFromCollection(
          brand.bentoBrandId,
          collection,
          SavedBrandStatus.sent,
        );
      }
      if (oneType === undefined || oneType === SavedBrandStatus.unsent) {
        _removeFromCollection(
          brand.bentoBrandId,
          collection,
          SavedBrandStatus.unsent,
        );
      }
    };
  };

  const _addToCollection = (
    brand: SavedBrand,
    collection: SavedBrandCollection,
    type: SavedBrandStatus,
  ) => {
    if (type === SavedBrandStatus.sent) {
      const [newList, newCount] = _addToList(
        brand,
        collection.sentBrands,
        collection.sentCount,
      );
      collection.sentBrands = newList;
      collection.sentCount = newCount;
    } else if (type === SavedBrandStatus.unsent) {
      const [newList, newCount] = _addToList(
        brand,
        collection.unsentBrands,
        collection.unsentCount,
      );
      collection.unsentBrands = newList;
      collection.unsentCount = newCount;
    }
  };

  const _removeFromCollection = (
    bentoBrandId: number,
    collection: SavedBrandCollection,
    type: SavedBrandStatus,
  ) => {
    if (type === SavedBrandStatus.sent) {
      const [newSentList, newSentCount] = _removeFromList(
        bentoBrandId,
        collection.sentBrands,
        collection.sentCount,
      );
      collection.sentBrands = newSentList;
      collection.sentCount = newSentCount;
    } else if (type === SavedBrandStatus.unsent) {
      const [newUnsentList, newUnsentCount] = _removeFromList(
        bentoBrandId,
        collection.unsentBrands,
        collection.unsentCount,
      );
      collection.unsentBrands = newUnsentList;
      collection.unsentCount = newUnsentCount;
    }
  };

  const _findInCollection = (
    bentoBrand: BentoBrand,
    currentCollections: SavedBrandCollectionsMap,
    collectionId: number,
  ) => {
    let type = undefined;
    let index = undefined;

    const currentCollection = currentCollections[collectionId || 0];
    if (!currentCollection) {
      return { type, index };
    }

    selectedCollection?.sentBrands?.forEach((sb, i) => {
      if (sb.bentoBrandId === bentoBrand.id) {
        type = SavedBrandStatus.sent;
        index = i;
      }
    });

    if (!type) {
      selectedCollection?.unsentBrands?.forEach((sb, i) => {
        if (sb.bentoBrandId === bentoBrand.id) {
          type = SavedBrandStatus.unsent;
          index = i;
        }
      });
    }
    return { type, index };
  };

  const _modifyPropertyOnSelectedCollection = (
    bentoBrand: BentoBrand,
    property: string, // a key such as 'hasOutreachDraft'
    value: any, // value for that key,
    addToList = true,
    listType = SavedBrandStatus.unsent,
  ) => {
    setCollections((prev) => {
      const copy = makeDeepCopy(prev);
      const { type: savedBrandStatus, index } = _findInCollection(
        bentoBrand,
        copy,
        selectedCollection?.id || 0,
      );
      if (
        index !== -1 &&
        index !== undefined &&
        savedBrandStatus &&
        selectedCollection
      ) {
        // If user modify a brand that is already a SavedBrand, update the property.
        if (savedBrandStatus === SavedBrandStatus.sent) {
          copy[selectedCollection?.id].sentBrands[index]["bentoBrand"][
            property
          ] = value;
        } else if (savedBrandStatus === SavedBrandStatus.unsent) {
          copy[selectedCollection?.id].unsentBrands[index]["bentoBrand"][
            property
          ] = value;
        }
      } else if (addToList) {
        setAllSavedBrands((prev) => [...prev, Number(bentoBrand.id)]);
        // When user create a draft on a brand that is not a SavedBrand yet, add it to `All Saved`.
        const savedBrand = {
          bentoBrand: { ...bentoBrand, [property]: value },
          bentoBrandId: bentoBrand.id,
          createdAt: getNow(),
        };
        const collection = copy[0];
        _addToCollection(savedBrand, collection, listType);
      }
      return copy;
    });
  };

  const _removeFromList = (
    bentoBrandId: number,
    brandList?: SavedBrand[],
    count?: number,
  ): [SavedBrand[], number] => {
    let newList = brandList || [];
    const inBrandListIndex = newList.findIndex(
      (b) => b.bentoBrandId === bentoBrandId,
    );
    let updatedCount = count || 0;
    if (inBrandListIndex >= 0) {
      newList = [
        ...newList.slice(0, inBrandListIndex),
        ...newList.slice(inBrandListIndex + 1),
      ];
      updatedCount = Math.max(0, updatedCount - 1);
    }
    return [newList, updatedCount];
  };

  const _addToList = (
    savedBrand: SavedBrand,
    brandList?: SavedBrand[],
    count?: number,
    originalIndex?: number,
  ): [SavedBrand[], number] => {
    const inBrandListIndex = brandList?.findIndex(
      (b) => b.bentoBrandId === savedBrand.bentoBrandId,
    );
    let newList = brandList || [];
    let updatedCount = count || 0;
    if (inBrandListIndex === undefined || inBrandListIndex < 0) {
      if (originalIndex !== undefined) {
        newList = [
          ...newList.slice(0, originalIndex),
          savedBrand,
          ...newList.slice(originalIndex),
        ];
      } else {
        newList = [savedBrand, ...newList];
      }
      updatedCount += 1;
    } else {
      newList[inBrandListIndex] = savedBrand;
    }
    return [newList, updatedCount];
  };

  return {
    updateTotal,
    isSavedBrand,
    modifySavedBrands,
    addDraftToCollection,
    removeDraftFromCollection,
    isDraftBrand,
    createOrUpdateCollection,
    deleteCollection,
    bulkMoveToSent,
    moveToSent,
    addToCollectionTab,
    removeFromCollectionTab,
    getTotal,
  };
};
