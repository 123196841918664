import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import {
  SavedBrand,
  SavedBrandCollection,
  SavedBrandCollectionsMap,
  SavedBrandStatus,
} from "schemas/dashboard";

import { fetcherAuth } from "utils/api";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

interface SavedBrandCollectionsContextInterface {
  selectedCollectionId: number | null;
  setSelectedCollectionId: Dispatch<SetStateAction<number | null>>;
  currentTab: SavedBrandStatus;
  setCurrentTab: Dispatch<SetStateAction<SavedBrandStatus>>;
  collections: SavedBrandCollectionsMap;
  setCollections: Dispatch<SetStateAction<SavedBrandCollectionsMap>>;
  fetchSavedBrandsForCollection: (
    collectionId: number,
    type?: SavedBrandStatus,
    refreshList?: boolean,
  ) => void;
  fetchLoading: number;
  fetchCollections: () => void;
  getCollectionBrands: (
    collectionId: number,
    type: SavedBrandStatus,
  ) => SavedBrand[] | undefined;
  updateCollection: (
    collectionId: number,
    type: SavedBrandStatus,
    updates: {
      newBrands?:
        | (SavedBrand[] | undefined)
        | ((prev: SavedBrand[] | undefined) => SavedBrand[] | undefined);
      newCount?: number | ((prev: number) => number);
    },
  ) => void;
  selectedCollection: SavedBrandCollection | undefined;
  getCollection: (collectionId: number) => SavedBrandCollection | undefined;
  needsRefreshList: boolean;
  setNeedsRefreshList: Dispatch<SetStateAction<boolean>>;
  allSavedBrands: number[];
  setAllSavedBrands: Dispatch<SetStateAction<number[]>>;
  fetchSavedBentoBrandIds: () => void;
}

const PER_PAGE = 20;

const defaultInterface = {
  currentTab: SavedBrandStatus.unsent,
  setCurrentTab: defaultContextMissingFunction,
  selectedCollectionId: null,
  setSelectedCollectionId: defaultContextMissingFunction,
  fetchSavedBrandsForCollection: defaultContextMissingFunction,
  collections: [],
  setCollections: defaultContextMissingFunction,
  fetchLoading: -1,
  fetchCollections: defaultContextMissingFunction,
  getCollectionBrands: defaultContextMissingFunction,
  updateCollection: defaultContextMissingFunction,
  selectedCollection: undefined,
  getCollection: defaultContextMissingFunction,
  getAllSavedBrands: defaultContextMissingFunction,
  needsRefreshList: false,
  setNeedsRefreshList: defaultContextMissingFunction,
  allSavedBrands: [],
  setAllSavedBrands: defaultContextMissingFunction,
  fetchSavedBentoBrandIds: defaultContextMissingFunction,
};

const SavedBrandCollectionsContext =
  createContext<SavedBrandCollectionsContextInterface>(defaultInterface);

interface SavedBrandCollectionsProviderProps {
  children: React.ReactNode;
}

const SavedBrandCollectionsProvider = ({
  children,
}: SavedBrandCollectionsProviderProps) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { setErrorAlert } = useContext(AlertContext);

  const [collections, setCollections] = useState<SavedBrandCollectionsMap>({});
  const [selectedCollectionId, setSelectedCollectionId] = useState<
    number | null
  >(null);
  const [currentTab, setCurrentTab] = useState<SavedBrandStatus>(
    SavedBrandStatus.unsent,
  );

  const [fetchLoading, setFetchLoading] = useState(-1);
  const [needsRefreshList, setNeedsRefreshList] = useState(false);
  const [allSavedBrands, setAllSavedBrands] = useState<number[]>([]);

  const fetchCollections = async () => {
    if (!currentOrg) return;
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg.id}/saved-brand-collections`,
        "GET",
      );
      const savedBrandCollectionsMap: SavedBrandCollectionsMap = {};
      for (const collection of res.savedBrandCollections) {
        savedBrandCollectionsMap[collection.id] = collection;
      }
      setCollections(savedBrandCollectionsMap);
      setNeedsRefreshList(false);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const fetchSavedBrandsForCollection = async (
    collectionId: number,
    type?: SavedBrandStatus,
    refreshList: boolean = false,
  ) => {
    if (!currentOrg?.id) return;

    if (!type) {
      type = currentTab;
    }

    let cursor = null;
    if (!refreshList) {
      const currentBrands = getCollectionBrands(collectionId, type);
      const lastBrand = currentBrands?.[currentBrands.length - 1];
      cursor = lastBrand ? [lastBrand.createdAt, lastBrand.bentoBrandId] : null;
    }

    if (!cursor) {
      setCollections((prev) => {
        const copy = { ...prev };
        if (copy[collectionId]) {
          if (type === SavedBrandStatus.sent) {
            copy[collectionId].sentBrands = undefined;
          } else {
            copy[collectionId].unsentBrands = undefined;
          }
        }
        return copy;
      });
    }
    setFetchLoading(collectionId);
    let url = `/api/organization/${currentOrg?.id}/saved-brands?per_page=${PER_PAGE}`;
    if (cursor) {
      url += `&cursor=${JSON.stringify(cursor)}`;
    }
    if (collectionId > 0) {
      url += `&saved_brand_collection_id=${collectionId}`;
    }
    if (type === SavedBrandStatus.sent) {
      url += `&sent=${true}`;
    } else {
      url += `&sent=${false}`;
    }

    try {
      const res = await fetcherAuth(getToken, url, "GET");
      if (!cursor) {
        updateCollection(collectionId, type, {
          newBrands: res.brands,
          newCount: res.total,
        });
      } else {
        updateCollection(collectionId, type, {
          newBrands: (prev) => {
            if (!prev) {
              return res.brands;
            }
            const brandIdSet = new Set(prev.map((sb) => sb.bentoBrandId));
            const newSavedBrands = [...prev];
            res.brands.forEach((sb: SavedBrand) => {
              if (!brandIdSet.has(sb.bentoBrandId)) {
                brandIdSet.add(sb.bentoBrandId);
                newSavedBrands.push(sb);
              }
            });
            return newSavedBrands;
          },
          newCount: res.total,
        });
      }
      setNeedsRefreshList(false);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setFetchLoading(-1);
    }
  };

  const getCollectionBrands = (
    collectionId: number,
    type: SavedBrandStatus,
  ) => {
    return collections[collectionId]?.[`${type}Brands`];
  };

  const getCollection = (collectionId: number | null) => {
    return collectionId !== null ? collections[collectionId] : undefined;
  };

  const updateCollection = (
    collectionId: number,
    type: SavedBrandStatus,
    updates: {
      newBrands?:
        | (SavedBrand[] | undefined)
        | ((prev: SavedBrand[] | undefined) => SavedBrand[] | undefined);
      newCount?: number | ((prev: number) => number);
    },
  ) => {
    setCollections((prev) => {
      const copy = { ...prev };
      if (copy[collectionId]) {
        const coll = copy[collectionId];
        if (updates.newBrands !== undefined) {
          const fieldName =
            type === SavedBrandStatus.sent ? "sentBrands" : "unsentBrands";
          if (typeof updates.newBrands === "function") {
            coll[fieldName] = updates.newBrands(coll[fieldName]);
          } else {
            coll[fieldName] = updates.newBrands;
          }
        }
        if (updates.newCount !== undefined) {
          const fieldName =
            type === SavedBrandStatus.sent ? "sentCount" : "unsentCount";
          if (typeof updates.newCount === "function") {
            coll[fieldName] = updates.newCount(coll[fieldName]);
          } else {
            coll[fieldName] = updates.newCount;
          }
        }
      }
      return copy;
    });
  };

  const fetchSavedBentoBrandIds = async () => {
    if (!currentOrg) return;
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg.id}/saved-brands/bento-brand-ids`,
        "GET",
      );
      setAllSavedBrands(res.bentoBrandIds);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const selectedCollection = getCollection(selectedCollectionId);

  return (
    <SavedBrandCollectionsContext.Provider
      value={{
        currentTab,
        setCurrentTab,
        selectedCollectionId,
        setSelectedCollectionId,
        collections,
        setCollections,
        getCollectionBrands,
        updateCollection,
        fetchSavedBrandsForCollection,
        fetchLoading,
        fetchCollections,
        selectedCollection,
        getCollection,
        allSavedBrands,
        setAllSavedBrands,
        needsRefreshList,
        setNeedsRefreshList,
        fetchSavedBentoBrandIds,
      }}
    >
      {children}
    </SavedBrandCollectionsContext.Provider>
  );
};

export { SavedBrandCollectionsProvider, SavedBrandCollectionsContext };
