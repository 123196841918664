import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { CustomEvent, Map } from "schemas/functions";

import { makeDeepCopy } from "utils/updateLocalState";

import {
  SocialMediaFieldKey,
  SocialMediaFields,
  SocialMediaLinks,
  convertOnboardingFormToLink,
  isValidUsername,
  sanitizeInput,
} from "./helpers";
import styles from "./styles";

interface Props {
  validation?: string;
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  setLinksValidation: Dispatch<SetStateAction<string | undefined>>;
  links: SocialMediaLinks[];
  setLinks: Dispatch<SetStateAction<SocialMediaLinks[]>>;
}

const AboutFormLinks = ({
  validation,
  onboardingForm,
  setOnboardingForm,
  setLinksValidation,
  links,
  setLinks,
}: Props) => {
  const { setAlert } = useContext(AlertContext);
  const allKeys = Object.values(SocialMediaFields)?.map((value) => value.key);
  useEffect(() => {
    const links = convertOnboardingFormToLink(onboardingForm);
    setLinks(links);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeField = (
    e: CustomEvent,
    currentFieldName: SocialMediaFieldKey,
  ) => {
    setLinks((prev) => {
      const copy = makeDeepCopy(prev);
      const index = copy.findIndex(
        (link: SocialMediaLinks) => link.fieldName === currentFieldName,
      );
      if (index > -1) {
        copy[index].fieldName = e.target.value as SocialMediaFieldKey;
        copy[index].link = "";
        handleSave(currentFieldName, "");
      }
      return copy;
    });
  };

  const handleAddLink = () => {
    const remaining = getRemainingLinks();
    if (remaining?.length > 0) {
      setLinks((prev) => {
        const copy = makeDeepCopy(prev);
        copy.push({
          fieldName: remaining[0] as SocialMediaFieldKey,
          link: "",
        });
        return copy;
      });
    }
  };

  const handleDeleteLink = (fieldName: SocialMediaFieldKey) => {
    if (links?.length <= 1) {
      setAlert("You must input at least one link", "warning");
      return;
    }
    setLinks((prev) => {
      const copy = makeDeepCopy(prev);
      const index = copy.findIndex(
        (link: SocialMediaLinks) => link.fieldName === fieldName,
      );
      if (index > -1) {
        copy.splice(index, 1);
      }
      handleSave(fieldName, "");
      return copy;
    });
  };

  const getRemainingLinks = () => {
    const existing = links?.map((link) => link.fieldName);
    const remaining = allKeys.filter(
      (item) => !existing?.includes(item as SocialMediaFieldKey),
    );
    return remaining;
  };

  const handleSave = (fieldName: string, value: string) => {
    setOnboardingForm((prev) => ({
      ...prev,
      [`${fieldName}Url`]: value,
    }));
  };

  const handleBlurred = (e: CustomEvent) => {
    const { name, value } = e.target;
    const sanitizedInput = sanitizeInput(value.trim());
    if (name !== SocialMediaFieldKey.portfolio) {
      if (sanitizedInput && !isValidUsername(sanitizedInput)) {
        setLinksValidation(
          "Must contain only letters, numbers, underscores, hyphens or periods",
        );
      } else {
        setLinksValidation(undefined);
      }
    }
  };

  const handleChanged = (e: CustomEvent) => {
    const { name, value } = e.target;
    const sanitizedInput = sanitizeInput(value.trim());
    if (name === SocialMediaFieldKey.portfolio) {
      handleSave(name, `https://${sanitizedInput}`);
    } else {
      handleSave(
        name,
        `${SocialMediaFields[`${name}Url`]?.url}${sanitizedInput}`,
      );
      if (isValidUsername(value)) {
        setLinksValidation(undefined);
      }
    }
  };

  const renderOneLink = (fieldName: SocialMediaFieldKey, link: string) => {
    return (
      <Grid
        container
        columnSpacing={2}
        key={fieldName}
        alignItems="center"
        sx={styles.fieldContainer}
      >
        <Grid item xs={12} md="auto">
          <Select
            sx={
              !!validation
                ? [styles.selectSocial, { mb: 2.5 }]
                : styles.selectSocial
            }
            value={fieldName}
            onChange={(e) => handleChangeField(e, fieldName)}
          >
            <MenuItem key={fieldName} value={fieldName}>
              {SocialMediaFields[`${fieldName}Url`]?.name}
            </MenuItem>
            {getRemainingLinks()?.map((key) => (
              <MenuItem key={key} value={key}>
                {SocialMediaFields[`${key}Url`]?.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid container item xs={12} md alignItems="center">
          <Grid item xs={11}>
            <TextField
              sx={styles.textField}
              fullWidth
              placeholder="handle"
              name={fieldName}
              defaultValue={link}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    sx={styles.linkAdornment}
                    disablePointerEvents
                    position="start"
                  >
                    {SocialMediaFields[`${fieldName}Url`]?.url}
                  </InputAdornment>
                ),
              }}
              onBlur={handleBlurred}
              onChange={handleChanged}
              error={!!validation}
              helperText={validation}
            />
          </Grid>
          <Grid item xs>
            <IconButton
              sx={{
                color: "secondary.main",
                fontSize: 14,
              }}
              onClick={() => handleDeleteLink(fieldName)}
            >
              <Box component="i" className="fa-regular fa-trash" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <FormHelperText sx={styles.helperText}>
        Please share your most active social links below *
      </FormHelperText>

      {links?.map((link) => renderOneLink(link.fieldName, link.link))}
      {getRemainingLinks()?.length > 0 && (
        <Button onClick={handleAddLink}>Add Link +</Button>
      )}
    </>
  );
};

export default AboutFormLinks;
