import { Box, ListItemText, MenuItem, MenuList } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { AlertContext } from "contexts/Alert";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext, useState } from "react";
import { SavedBrandCollection, SavedBrandStatus } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";
import { HEART_COLOR } from "styles/oneOffColor";

import NewCollectionDialog from "features/Home/Sidebar/FavoritesList/Collections/NewCollectionDialog";
import { trackEvent } from "utils/tracking";
import { useCollection } from "utils/useCollection";

import styles from "./styles";

export default function PopoverSavedCollection() {
  const { setAlert } = useContext(AlertContext);
  const {
    anchorEl,
    setAnchorEl,
    brandBeingSaved,
    setBrandBeingSaved,
    moveBrandToCollections,
  } = useContext(SavedBrandContext);
  const { collections } = useContext(SavedBrandCollectionsContext);
  const { addToCollectionTab } = useCollection();

  const [moveToCollections, setMoveToCollections] = useState<
    SavedBrandCollection[]
  >([]);

  const open = Boolean(anchorEl);
  const id = open ? "saved-collection-popover" : undefined;

  // States
  const [openCollectionDialog, setOpenCollectionDialog] = useState(false);

  const handleClose = () => {
    if (moveToCollections.length > 0 && brandBeingSaved?.bentoBrand) {
      moveBrandToCollections(brandBeingSaved?.bentoBrand, moveToCollections);
      setMoveToCollections([]);
    }
    setAnchorEl(null);
    setBrandBeingSaved(null);
  };

  const createNewList = () => {
    setOpenCollectionDialog(true);
  };

  const selectCollection = (
    e: CustomEvent,
    collection: SavedBrandCollection,
  ) => {
    if (!brandBeingSaved) return;
    if (moveToCollections.includes(collection)) {
      return;
    }
    e.stopPropagation();
    trackEvent("Favorites List - Quick Move Saved Brand Button Clicked", {
      "Collection ID": collection?.id,
    });
    setMoveToCollections([...moveToCollections, collection]);
    addToCollectionTab(
      brandBeingSaved,
      collection?.id,
      SavedBrandStatus.unsent,
    );
    setAlert(`Successfully moved to ${collection?.name}`, "success");
  };

  const collectionIds = moveToCollections.map((collection) => collection.id);

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem>
          <ListItemText>Brand Saved To All</ListItemText>

          <Box
            component="i"
            className="fa-solid fa-bookmark"
            sx={{ color: HEART_COLOR }}
          />
        </MenuItem>

        <MenuList sx={{ minWidth: 200 }}>
          {Object.values(collections).map((collection) =>
            collection?.id > 0 ? (
              <MenuItem
                key={collection.id}
                sx={styles.menuItem}
                onClick={(e) => selectCollection(e, collection)}
              >
                <ListItemText>{collection.name}</ListItemText>
                {collectionIds?.includes(collection?.id) ? (
                  <Box
                    component="i"
                    className="fa-regular fa-check"
                    sx={{ color: "primary.main" }}
                  />
                ) : (
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    <Box component="i" className="fa-thin fa-circle-plus" />
                  </Typography>
                )}
              </MenuItem>
            ) : (
              <></>
            ),
          )}

          <MenuItem sx={styles.menuItem} onClick={createNewList}>
            + Create New
          </MenuItem>
        </MenuList>
      </Popover>

      <NewCollectionDialog
        open={openCollectionDialog}
        handleClose={() => setOpenCollectionDialog(false)}
      />
    </div>
  );
}
