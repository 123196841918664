import { Box, Grid, Typography } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext } from "react";
import { BentoBrand } from "schemas/dashboard";

import { accessToVaFeature } from "utils/influencer";

import BrandAnnotation from "../BrandAnnotation";
import { NonVaBrandAnnotations } from "../BrandAnnotation/schema";
import BrandLinks from "../BrandLinks/BrandLinks";
import BrandLocations from "../BrandLocations";
import BrandSimilar from "../BrandSimilar";
import SimilarBrandSkeletonBody from "./SimilarBrandSkeletonBody";
import SkeletonBody from "./SkeletonBody";
import styles from "./styles";

interface Props {
  selectedBrand: BentoBrand | null;
  fetchLoading: boolean;
  similarBrands: BentoBrand[];
  fetchSimilarLoading: boolean;
  isPopupView?: boolean;
}

const BrandBody = ({
  selectedBrand,
  fetchLoading,
  similarBrands,
  fetchSimilarLoading,
  isPopupView,
}: Props) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { subscription, isVaPlan } = useContext(SubscriptionContext);
  const hasAccessToVa = accessToVaFeature(currentOrg, subscription, isVaPlan);

  return (
    <Box>
      {fetchLoading ? (
        <SkeletonBody isPopupView={isPopupView} />
      ) : (
        <>
          {isPopupView &&
            (!!selectedBrand?.pinterestMainImageUsed ||
              !!selectedBrand?.mainImageSource) && (
              <Box
                component="img"
                src={selectedBrand?.mainImageUrl}
                sx={styles.mainImage}
              />
            )}
          {isPopupView && !!selectedBrand?.logoUrl && (
            <Box display="flex" justifyContent="center">
              <Box
                sx={[
                  styles.logoWrapper,
                  {
                    mt:
                      !!selectedBrand?.pinterestMainImageUsed ||
                      !!selectedBrand?.mainImageSource
                        ? "-50px"
                        : 2,
                  },
                ]}
              >
                <Box
                  component="img"
                  src={selectedBrand?.logoUrl}
                  sx={styles.logo}
                />
              </Box>
            </Box>
          )}
          <Box sx={isPopupView ? styles.popupBody : styles.body}>
            {isPopupView && (
              <>
                <Typography sx={{ textAlign: "center" }} variant="h3">
                  {selectedBrand?.brandName}{" "}
                </Typography>
                {selectedBrand && <Box>{selectedBrand?.aboutSummary}</Box>}
              </>
            )}

            <Grid container sx={styles.section} gap={isPopupView ? 0 : 3}>
              <Grid item xs={12} md={isPopupView ? 12 : 5}>
                {selectedBrand && (
                  <BrandLocations selectedBrand={selectedBrand} />
                )}

                {NonVaBrandAnnotations?.map((header, index) => (
                  <BrandAnnotation
                    header={header}
                    selectedBrand={selectedBrand}
                    key={index}
                  />
                ))}
                {hasAccessToVa && (
                  <BrandAnnotation
                    header="Compensation"
                    selectedBrand={selectedBrand}
                  />
                )}
              </Grid>

              {selectedBrand && (
                <Grid
                  item
                  xs
                  sx={[styles.section, isPopupView ? {} : { mt: 0 }]}
                >
                  <Typography>
                    <strong>Social Media Links</strong>
                  </Typography>
                  <BrandLinks bentoBrand={selectedBrand} />
                </Grid>
              )}
            </Grid>

            {!fetchSimilarLoading &&
              selectedBrand &&
              similarBrands?.length > 0 && (
                <Grid container sx={styles.section}>
                  <BrandSimilar
                    similarBrands={similarBrands}
                    bentoBrand={selectedBrand}
                    isPopupView={isPopupView}
                  />
                </Grid>
              )}
          </Box>
        </>
      )}
      {fetchSimilarLoading && !isPopupView && <SimilarBrandSkeletonBody />}
    </Box>
  );
};

export default BrandBody;
