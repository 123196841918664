import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useLocation } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";

import LocationAutocomplete from "components/LocationAutocomplete";
import { UserLocation } from "components/LocationAutocomplete/schema";
import { makeDeepCopy } from "utils/updateLocalState";

import AboutFormLinks from "./Links";
import {
  SocialMediaFields,
  SocialMediaLinks,
  getUserType,
  isValidUsername,
  sanitizeInput,
} from "./helpers";
import styles from "./styles";

interface AboutFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  isProfilePage?: boolean;
  isVaFlow?: boolean;
}

const AboutForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  isProfilePage = false,
  isVaFlow = false,
}: AboutFormProps) => {
  const location = useLocation();

  const [linksValidation, setLinksValidation] = useState<string | undefined>();
  const [isUgcValidation, setIsUgcValidation] = useState<string | undefined>();
  const [links, setLinks] = useState<SocialMediaLinks[]>([]);

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    const copy = makeDeepCopy(onboardingForm);
    copy[name] = value;
    setOnboardingForm(copy);
  };

  const handleUpdateUserType = (e: CustomEvent) => {
    const { value } = e.target;
    const copy = makeDeepCopy(onboardingForm);
    if (value === "both") {
      copy.isUgcCreator = true;
      copy.isInfluencer = true;
    } else if (value === "ugc") {
      copy.isUgcCreator = true;
      copy.isInfluencer = false;
    } else if (value === "influencer") {
      copy.isInfluencer = true;
      copy.isUgcCreator = false;
    }
    copy.userType = value;
    setIsUgcValidation(undefined);
    setOnboardingForm(copy);
  };

  const handleLocationChange = (userLocation: UserLocation) => {
    setOnboardingForm((prev) => ({
      ...prev,
      city: userLocation.address,
      location: userLocation.country_code,
      addressComponents: userLocation.address_components,
      longitude: userLocation.longitude,
      latitude: userLocation.latitude,
    }));
  };

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    if (!onboardingForm.userType) {
      setIsUgcValidation("Please fill out this field.");
      return;
    }

    if (isUgcValidation || linksValidation) {
      return;
    }

    for (const key in onboardingForm) {
      if (key in SocialMediaFields && onboardingForm[key]) {
        const input = sanitizeInput(onboardingForm[key]);
        if (input && !isValidUsername(input)) {
          return;
        }
      }
    }
    setOnboardingForm(onboardingForm);
    saveOnboardingForm(onboardingForm, location.pathname);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="h3" sx={{ mb: 3, textAlign: "center" }}>
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-user"
          />
          Your Basics
        </Typography>

        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={6}>
            <FormHelperText sx={styles.helperText}>Name * </FormHelperText>
            <TextField
              sx={styles.textField}
              fullWidth
              name="name"
              placeholder="First Last"
              onChange={handleChange}
              required
              value={onboardingForm?.name || ""}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.fieldContainer}>
            <FormHelperText sx={styles.helperText}>Location</FormHelperText>
            <LocationAutocomplete
              handleLocationChange={handleLocationChange}
              userLocation={{
                address: onboardingForm.city,
                longitude: onboardingForm.longitude,
                latitude: onboardingForm.latitude,
                country_code: onboardingForm.location,
                address_components: onboardingForm.addressComponents,
              }}
            />
            <FormHelperText>
              <em>
                specifics help us tailor recommendations, (e.g., zip code,
                neighborhood or city)
              </em>
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid sx={styles.fieldContainer}>
          <FormHelperText error={!!isUgcValidation}>
            Are you a UGC creator, Influencer or Both? *
          </FormHelperText>
          <FormControl error={!!isUgcValidation}>
            <FormLabel id="ugc-creator-label"></FormLabel>
            <RadioGroup
              onChange={handleUpdateUserType}
              name="userType"
              row
              aria-labelledby="ugc-creator-label"
              value={onboardingForm?.userType || getUserType(onboardingForm)}
            >
              <FormControlLabel value={"ugc"} control={<Radio />} label="UGC" />
              <FormControlLabel
                value={"influencer"}
                control={<Radio />}
                label="Influencer"
              />
              <FormControlLabel
                value={"both"}
                control={<Radio />}
                label="Both"
              />
            </RadioGroup>
            {isUgcValidation && (
              <FormHelperText sx={{ ml: 0 }}>{isUgcValidation}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {(onboardingForm?.isUgcCreator || onboardingForm?.isInfluencer) && (
          <AboutFormLinks
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            setLinksValidation={setLinksValidation}
            links={links}
            setLinks={setLinks}
            validation={linksValidation}
          />
        )}

        {!isProfilePage && (
          <Grid container>
            <Grid item xs></Grid>
            <Grid item xs="auto">
              <Button disableElevation variant="contained" type="submit">
                Next
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default AboutForm;
