import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BentoCategoriesContext } from "contexts/BentoCategories";
import { useContext } from "react";
import { BentoBrand, BentoCategory } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import MultiBentoCategorySelect from "features/Admin/Brand/NewBrand/MultiBentoCategorySelect";

import { BrandAnnotationHeader, CategoryEdit } from "../schema";

interface Props {
  open: boolean;
  handleClose: () => void;
  editCategories: CategoryEdit;
  setEditCategories: (editCategories: CategoryEdit) => void;
  handleSubmit: (e: CustomEvent) => void;
  selectedBrand: BentoBrand;
}

const EditCategoryDialog = ({
  open,
  handleClose,
  editCategories,
  setEditCategories,
  handleSubmit,
  selectedBrand,
}: Props) => {
  const { categoriesById } = useContext(BentoCategoriesContext);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getExcludedCategories = () => {
    const filteredCategories: { [key: number]: BentoCategory } = {};
    let excluded = new Set();
    if (editCategories?.label === BrandAnnotationHeader.main_categories) {
      excluded = new Set(selectedBrand?.secondaryCategoryIds || []);
    } else {
      excluded = new Set(selectedBrand?.mainCategoryIds || []);
    }
    for (const key in categoriesById) {
      if (!excluded.has(Number(key))) {
        filteredCategories[key] = categoriesById[key];
      }
    }
    return filteredCategories;
  };

  const handleChange = (e: CustomEvent, values: any) => {
    setEditCategories({
      ...editCategories,
      suggestedCategoriesIds: values,
      suggestedCategories: values.map((id: number) => categoriesById[id]?.name),
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={isMobileScreen}>
      <DialogContent sx={{ minHeight: { xs: "auto", md: 500 } }}>
        <Typography variant="h6" textAlign="center">
          Edit {editCategories.label}
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          By submitting edits to brand information, you are helping to improve
          everyone's experience on Bento. Thank you for your contribution!
        </Typography>

        {Object.keys(categoriesById)?.length > 0 && (
          <MultiBentoCategorySelect
            name={editCategories.label}
            categoriesById={getExcludedCategories()}
            handleAutocompleteChange={handleChange}
            value={editCategories?.suggestedCategoriesIds || []}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          disableElevation
          type="submit"
          onClick={handleSubmit}
          color="primary"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditCategoryDialog;
