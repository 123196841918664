import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { GmailScope, UserIntegration } from "schemas/dashboard";
import { Map } from "schemas/functions";

import GoogleConnect from "features/Settings/GoogleConnect";
import { isCommonDomain } from "utils/emails";

interface IProps {
  openIntegrationDialog: boolean;
  setOpenIntegrationDialog: (newOpen: boolean) => void;
  emailHealth: boolean;
  userIntegrations: UserIntegration[];
  userIntegration: UserIntegration | null;
  scopes: Map | null;
  onSuccessAction?: () => Promise<void>;
  integrationDialogText?: string;
  title?: string;
}

export default function ConnectGmailDialog({
  openIntegrationDialog,
  setOpenIntegrationDialog,
  emailHealth,
  userIntegrations,
  userIntegration,
  scopes,
  onSuccessAction,
  integrationDialogText,
  title = "Connect your Gmail account to send emails",
}: IProps) {
  const renderError = () => {
    if (userIntegrations?.length === 0) {
      return (
        <>
          You must allow access so we can send emails and schedule followup
          emails on your behalf ✨ You can revoke your access at any time.
        </>
      );
    } else if (
      userIntegrations &&
      userIntegrations.length >= 1 &&
      scopes !== null &&
      (!scopes[GmailScope.SEND] || !scopes[GmailScope.READ])
    ) {
      return (
        <>
          <Typography
            gutterBottom
            sx={{ color: "warning.main" }}
            variant="body2"
          >
            Insufficient permissions granted to Bento
          </Typography>
          In order for you to send emails you must provide additional access.
          Sign in with Google and select: <br />
          {!scopes[GmailScope.SEND] && (
            <Typography>* Send Email on your behalf.</Typography>
          )}
          {!scopes[GmailScope.READ] && (
            <Typography>
              * View your email messages and settings. We use this to accurately
              schedule follow ups.
            </Typography>
          )}
        </>
      );
    } else if (userIntegration?.integrationError && userIntegration?.email) {
      return (
        <>
          {isCommonDomain(userIntegration?.email) ? (
            <>
              To send emails on Bento, you must connect a @gmail.com email or an
              email that has been connected to Gmail. See{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.google.com/accounts/answer/27441?hl=en&co=GENIE.Platform%3DDesktop&oco=0"
              >
                this link
              </a>{" "}
              for how to create a Google account.
            </>
          ) : (
            <>
              To send emails on Bento with your own domain, your domain must be{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.google.com/a/answer/60216?hl=en"
              >
                verified with Google Workspace
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.google.com/a/answer/172171?hl=en"
              >
                Gmail must be activated
              </a>
              .
            </>
          )}{" "}
          If you have any questions, please reach out to{" "}
          <a href="mailto:hello@onbento.com">hello@onbento.com</a> for
          assistance.
        </>
      );
    } else {
      return (
        <>
          You must re-connect to Gmail so we can send emails and schedule
          followup emails on your behalf ✨ You can revoke your access at any
          time.
        </>
      );
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      open={openIntegrationDialog}
      onClose={() => setOpenIntegrationDialog(false)}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {emailHealth ? (
          <>
            <Typography>Success! You are connected.</Typography>
          </>
        ) : (
          <>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography>
                  {!!integrationDialogText
                    ? integrationDialogText
                    : renderError()}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <GoogleConnect onSuccessAction={onSuccessAction} />
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {userIntegrations.length === 0 ? (
          <Button
            color="secondary"
            onClick={() => setOpenIntegrationDialog(false)}
          >
            Close
          </Button>
        ) : (
          <Button onClick={() => setOpenIntegrationDialog(false)}>Done</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
