import { useAuth } from "@clerk/clerk-react";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { OrganizationUser } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import Alert from "components/Alert";
import StyledPage from "components/Styled/Page";
import { fetcherAuth } from "utils/api";
import { useAlert } from "utils/useAlert";

import SingleView from "./SingleView";

const AdminOrganization = () => {
  const { getToken } = useAuth();
  const [message, severity, setAlert, closeAlert] = useAlert();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [orgUsers, setOrgUsers] = useState<OrganizationUser[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [organizationUser, setOrganizationUser] =
    useState<OrganizationUser | null>(null);

  const fetchOrgUsers = async (query?: string) => {
    setLoading(true);
    try {
      const { organizationUsers } = await fetcherAuth(
        getToken,
        query
          ? `/api/admin/organization-users?query=${query}`
          : "/api/admin/organization-users",
        "GET",
      );
      setOrgUsers(organizationUsers);
    } catch (error) {
      setAlert(
        "An error occurred, you do not have access to this page",
        "warning",
      );
    }
    setLoading(false);
  };

  const handleOrgChange = (organizationId: number) => {
    navigate(`/admin/organizations/${organizationId}`, { replace: true });
  };

  useEffect(() => {
    fetchOrgUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputValue) {
        fetchOrgUsers(inputValue);
      } else {
        fetchOrgUsers();
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <StyledPage
      content={
        <Grid sx={{ mt: 2 }}>
          {organizationUser ? (
            <Typography variant="h5" gutterBottom>
              <strong>
                {organizationUser?.name} - {organizationUser.email}
              </strong>
            </Typography>
          ) : (
            <Typography variant="h5" gutterBottom>
              <strong>Organization</strong>
            </Typography>
          )}
          <Autocomplete
            disablePortal
            loading={loading}
            options={orgUsers}
            sx={{ width: 800, mt: 2 }}
            getOptionLabel={(option) =>
              `${option.name} - ${option.email} | ${option.organizationId}`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type name or email to search"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            onChange={(event: any, newValue: any) => {
              if (newValue?.organizationId) {
                handleOrgChange(newValue.organizationId);
              }
            }}
            onInputChange={(event: CustomEvent) => {
              setInputValue(event.target.value);
            }}
          />
          <Routes>
            <Route
              path="/:id"
              element={
                loading ? (
                  <CircularProgress />
                ) : (
                  <Grid>
                    <SingleView
                      organizationUser={organizationUser}
                      setOrganizationUser={setOrganizationUser}
                      setAlert={setAlert}
                    />
                  </Grid>
                )
              }
            />
          </Routes>
          <Alert
            message={message}
            severity={severity}
            closeAlert={closeAlert}
          />
        </Grid>
      }
    />
  );
};

export default AdminOrganization;
