import { Box, Grid, Menu, MenuItem, Link as MuiLink } from "@mui/material";
import {
  AutogeneratedDraftsContext,
  TabPage,
} from "contexts/AutogenerateDrafts";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext, useState } from "react";
import { CustomEvent } from "schemas/functions";

import styles from "../styles";

export default function PickCollectionDropdown() {
  const { collections, getCollection } = useContext(
    SavedBrandCollectionsContext,
  );
  const { setSavedBrandCollectionId, savedBrandCollectionId, fetchDrafts } =
    useContext(AutogeneratedDraftsContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openCollection = Boolean(anchorEl);

  const toggleCollection = (event: CustomEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const closeCollection = () => {
    setAnchorEl(null);
  };

  const selectCollection = (id: number) => {
    setSavedBrandCollectionId(id);
    fetchDrafts(TabPage.favorite_drafts, id);
    closeCollection();
  };

  const collectionName =
    getCollection(savedBrandCollectionId)?.name || "All Saved";

  return (
    <>
      <Grid sx={[styles.subtitle, { fontSize: 12 }]} gap={0.5}>
        Drafts generated from your 'Saved Brands' lists.
        <br />
        <MuiLink sx={styles.link} onClick={(e) => toggleCollection(e)}>
          {collectionName}{" "}
          <Box
            component="i"
            className="fa-regular fa-chevron-down"
            sx={{ ml: 0.5 }}
          />
        </MuiLink>
      </Grid>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openCollection}
        onClose={closeCollection}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {Object.values(collections).map((collection, index) => (
          <MenuItem key={index} onClick={() => selectCollection(collection.id)}>
            {collection.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
