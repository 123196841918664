const styles = {
  container: {
    mt: 2,
    mb: 1,
    p: 2,
    borderRadius: 4,
    border: `1px solid #E0E0E0`,
  },
  close: {
    position: "absolute",
    right: 10,
    top: 20,
    m: 1,
    width: 20,
    height: 20,
    fontSize: 14,
  },
};

export default styles;
