import { BentoBrand, MetadataType, SearchParams } from "schemas/dashboard";

import { formatNumber } from "utils/string";

import { BrandAnnotationHeader } from "./schema";

export const getMappings = (selectedBrand: BentoBrand) => {
  const mappings: { [key: string]: any } = {
    [BrandAnnotationHeader.main_categories]: {
      key: "mainCategoryNames",
      color: SearchParams.CATEGORY_ID_DISCOVER,
      medataType: MetadataType.tags,
      isArray: true,
      isEditable: true,
      canRecategorize: true,
    },
    [BrandAnnotationHeader.secondary_categories]: {
      key: "secondaryCategoryNames",
      color: SearchParams.CATEGORY_ID_DISCOVER,
      medataType: MetadataType.tags,
      isArray: true,
      isEditable: true,
    },
    [BrandAnnotationHeader.price_point]: {
      key: "pricePoint",
      color: SearchParams.CATEGORY_ID_DISCOVER,
      medataType: MetadataType.tags,
    },
    [BrandAnnotationHeader.company_size]: {
      key: "size",
      color: SearchParams.SIZE_DISCOVER,
      medataType: MetadataType.size,
    },
    [BrandAnnotationHeader.instagram_followers]: {
      key: "instagramFollowerCount",
      convertedValue: selectedBrand?.instagramFollowerCount
        ? `${formatNumber(selectedBrand?.instagramFollowerCount)} `
        : "",
    },
    [BrandAnnotationHeader.compensation]: {
      key: "compensationTypes",
      convertedValue: selectedBrand?.compensationTypes
        ?.map((s) => s.replaceAll("_", " "))
        ?.join(", "),
    },
  };

  return mappings;
};
