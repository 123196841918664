import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface CancelAllEmailsDialogProps {
  open: boolean;
  handleClose?: () => void;
  handleConfirm: () => void;
  loading?: boolean;
  setIncludesFollowup: Dispatch<SetStateAction<boolean>>;
  totalOutreach: number;
  totalFollowup: number;
  onlyFollowups: boolean;
}

export default function CancelAllEmailsDialog({
  open,
  handleClose,
  loading,
  handleConfirm,
  setIncludesFollowup,
  totalOutreach,
  totalFollowup,
  onlyFollowups,
}: CancelAllEmailsDialogProps) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Cancel all {totalOutreach === 0 ? "follow-up" : ""} emails{" "}
        <Box component="i" className="fa-solid fa-octagon-exclamation" />
      </DialogTitle>
      <DialogContent>
        This will cancel{" "}
        <strong>{onlyFollowups ? totalFollowup : totalOutreach}</strong> pending
        email(s) underneath the {onlyFollowups ? "Follow-ups" : "Outreach"} tab.
        <Grid item xs={12} sx={{ mt: 2 }}>
          {totalFollowup > 0 && !onlyFollowups && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setIncludesFollowup(e.target.checked)}
                  />
                }
                label="Include Follow-Ups"
              />

              <FormHelperText>
                If you want to also cancel <strong>{totalFollowup} </strong>{" "}
                pending follow-up email(s) underneath the Follow-ups tab, check
                the box above.
              </FormHelperText>
            </>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton loading={loading} onClick={handleConfirm}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
