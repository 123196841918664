import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Button,
  Chip,
  Grid,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import {
  OutreachContactsContext,
  ScheduledType,
} from "contexts/OutreachContacts";
import { useContext, useState } from "react";

import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";

import CancelAllEmailsDialog from "./CancelAllEmailsDialog";

const styles = {
  scheduledChip: { fontSize: 12, borderRadius: 2, color: grey[800], mb: 0.5 },
  selected: {
    backgroundColor: "primary.main",
    color: "white",
    "&:hover": {
      backgroundColor: "primary.dark",
    },
  },
};

export default function ScheduledTabs() {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setErrorAlert, setAlert } = useContext(AlertContext);

  const { fetchContactsParams, handleFetchContacts, scheduledTotals } =
    useContext(OutreachContactsContext);

  const totalOutreach = scheduledTotals[ScheduledType.outreach] ?? 0;
  const totalFollowup = scheduledTotals[ScheduledType.followUp] ?? 0;

  // No total outreach email exist. So if user cancel pending emails
  // it will only cancel follow-up emails.
  const onlyFollowups = totalOutreach === 0 && totalFollowup > 0;

  const [loading, setLoading] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [includesFollowup, setIncludesFollowup] = useState(false);

  const handleClick = (scheduledType: ScheduledType) => {
    const updatedParams = { ...fetchContactsParams, scheduledType, page: 1 };
    handleFetchContacts(updatedParams);
  };

  const openCancel = () => {
    setOpenConfirmationDialog(true);
    trackEvent("Cancel All Pending Emails Button Clicked");
  };

  const handleCancelAllPendingEmails = async () => {
    try {
      setLoading(true);
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails/cancel-all`,
        "POST",
        {},
        { includesFollowup: includesFollowup || onlyFollowups },
      );
      setAlert("All of your emails were successfully cancelled", "success");
      setOpenConfirmationDialog(false);
      handleClick(ScheduledType.outreach);
      if (includesFollowup && onlyFollowups) {
        handleClick(ScheduledType.followUp);
      }
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      container
      justifyContent={isMobileScreen ? "flex-left" : "space-between"}
      sx={{ mt: 2, px: { xs: 2, md: 0 } }}
    >
      <Grid item container gap={1} xs={8}>
        <Grid item xs="auto">
          <Chip
            clickable
            onClick={() => {
              handleClick(ScheduledType.outreach);
            }}
            sx={[
              styles.scheduledChip,
              fetchContactsParams.scheduledType === ScheduledType.outreach &&
                styles.selected,
            ]}
            label={
              <>
                Outreach (
                {scheduledTotals[ScheduledType.outreach] !== null
                  ? scheduledTotals[ScheduledType.outreach]
                  : "-"}
                ){" "}
                <Box
                  component="i"
                  sx={{ ml: 0.5 }}
                  className="fa-regular fa-paper-plane"
                />
              </>
            }
          />
        </Grid>
        <Grid item xs="auto">
          <Chip
            clickable
            onClick={() => {
              handleClick(ScheduledType.followUp);
            }}
            sx={[
              styles.scheduledChip,
              fetchContactsParams.scheduledType === ScheduledType.followUp &&
                styles.selected,
            ]}
            label={
              <>
                Follow-ups (
                {scheduledTotals[ScheduledType.followUp] !== null
                  ? scheduledTotals[ScheduledType.followUp]
                  : "-"}
                ){" "}
                <Box
                  component="i"
                  sx={{ ml: 0.5 }}
                  className="fa-regular fa-paper-plane"
                />
              </>
            }
          />
        </Grid>
      </Grid>

      <Grid item>
        <Tooltip title="This button will stop all pending emails from being sent.">
          <Button
            sx={{
              fontSize: 12,
              color: "error.main",
              borderColor: "error.main",
              textTransform: "none",
              "&:hover": {
                borderColor: "error.main",
              },
            }}
            variant="outlined"
            onClick={openCancel}
            disabled={
              !Boolean(scheduledTotals[ScheduledType.outreach]) &&
              !Boolean(scheduledTotals[ScheduledType.followUp])
            }
          >
            Cancel All Pending Emails{" "}
            <Box
              component="i"
              className="fa-solid fa-octagon-exclamation"
              sx={{ ml: 1 }}
            />
          </Button>
        </Tooltip>
      </Grid>

      <CancelAllEmailsDialog
        open={openConfirmationDialog}
        handleClose={() => setOpenConfirmationDialog(false)}
        handleConfirm={handleCancelAllPendingEmails}
        loading={loading}
        setIncludesFollowup={setIncludesFollowup}
        totalOutreach={totalOutreach}
        totalFollowup={totalFollowup}
        onlyFollowups={onlyFollowups}
      />
    </Grid>
  );
}
