import { useAuth } from "@clerk/clerk-react";
import { debounce } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  BentoBrand,
  SavedBrand,
  SavedBrandCollection,
  SavedBrandStatus,
} from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { ON_BEHALF_OF_TOKEN } from "utils/localStorage";
import { getNow } from "utils/time";
import { useCollection } from "utils/useCollection";
import { useDrafts } from "utils/useDrafts";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";
import { PaginationType } from "./QuickSendPagination";
import { SavedBrandCollectionsContext } from "./SavedBrandCollections";

interface SavedBrandContextInterface {
  handleAddDebounced: (e: CustomEvent, brand: BentoBrand) => void;
  handleRemoveDebounced: (brand: BentoBrand, collectionId: number) => void;
  removeLoading: number;
  getId: (brand: PaginationType | undefined) => number;
  brandBeingSaved: SavedBrand | null;
  setBrandBeingSaved: Dispatch<SetStateAction<SavedBrand | null>>;
  bulkBrands: BentoBrand[];
  setBulkBrands: React.Dispatch<SetStateAction<BentoBrand[]>>;
  moveBrandToCollections: (
    brand: BentoBrand,
    collections: SavedBrandCollection[],
  ) => void;
  bulkMoveTo: (
    bentoBrands: BentoBrand[],
    moveBrandToCollections: SavedBrandCollection,
  ) => void;
  bulkDelete: () => void;
  moveLoading: boolean;
  selectAll: boolean;
  setSelectAll: Dispatch<SetStateAction<boolean>>;
  anchorEl: any;
  setAnchorEl: any;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  handleAddDebounced: defaultContextMissingFunction,
  handleRemoveDebounced: defaultContextMissingFunction,
  removeLoading: -1,
  moveLoading: false,
  getId: defaultContextMissingFunction,
  brandBeingSaved: null,
  setBrandBeingSaved: defaultContextMissingFunction,
  bulkBrands: [],
  setBulkBrands: defaultContextMissingFunction,
  bulkMoveTo: defaultContextMissingFunction,
  moveBrandToCollections: defaultContextMissingFunction,
  bulkDelete: defaultContextMissingFunction,
  selectAll: false,
  setSelectAll: defaultContextMissingFunction,
  anchorEl: null,
  setAnchorEl: defaultContextMissingFunction,
};

const SavedBrandContext =
  createContext<SavedBrandContextInterface>(defaultInterface);

interface SavedBrandProviderProps {
  children: React.ReactNode;
}

const SavedBrandProvider = ({ children }: SavedBrandProviderProps) => {
  const { getToken } = useAuth();
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const {
    currentTab,
    getCollectionBrands,
    fetchSavedBrandsForCollection,
    selectedCollection,
    setNeedsRefreshList,
    setAllSavedBrands,
  } = useContext(SavedBrandCollectionsContext);
  const { modifySavedBrands, addToCollectionTab } = useCollection();
  const { removeDraftFromList } = useDrafts();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [brandBeingSaved, setBrandBeingSaved] = useState<SavedBrand | null>(
    null,
  );
  const [bulkBrands, setBulkBrands] = useState<BentoBrand[]>([]);
  const [saveLoading, setSaveLoading] = useState(-1);
  const [removeLoading, setRemoveLoading] = useState(-1);
  const [moveLoading, setMoveLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const savedBrands = getCollectionBrands(0, currentTab) ?? [];

  const getId = (brand: PaginationType | undefined) => {
    if (!brand) return -1;
    return "bentoBrandId" in brand
      ? Number(brand.bentoBrandId)
      : Number(brand.id);
  };

  const handleAddDebounced = useMemo(
    () =>
      debounce(async (eventTarget: any, brand: BentoBrand) => {
        setAnchorEl(eventTarget);
        const isAdmin = Boolean(sessionStorage.getItem(ON_BEHALF_OF_TOKEN));
        const savedBrand = {
          bentoBrand: {
            ...brand,
            isAdmin,
          },
          bentoBrandId: brand.id,
          createdAt: getNow(),
        };
        modifySavedBrands(savedBrand, "add", 0, SavedBrandStatus.unsent);
        setBrandBeingSaved(savedBrand);
        setAllSavedBrands((prev) => [...prev, Number(savedBrand.bentoBrandId)]);
        try {
          const res = await fetcherAuth(
            getToken,
            `/api/organization/${currentOrg?.id}/saved-brands/${brand.id}`,
            "PUT",
            {},
            {
              isAdmin,
            },
          );
          const updatedBentoBrand: BentoBrand = res.savedBrand.bentoBrand;
          if (updatedBentoBrand?.orgHasOutreachContact) {
            addToCollectionTab(res.savedBrand, 0, SavedBrandStatus.sent);
          }
        } catch (error) {
          setErrorAlert(error);
        } finally {
          setSaveLoading(-1);
        }
      }, 50),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrg?.id, saveLoading],
  );

  const handleRemoveDebounced = useMemo(
    () =>
      debounce(async (brand: BentoBrand, collectionId: number) => {
        if (!currentOrg?.id) return;
        modifySavedBrands(
          {
            bentoBrand: brand,
            bentoBrandId: brand.id,
            createdAt: getNow(),
          },
          "remove",
          collectionId,
        );
        removeDraftFromList(brand, collectionId);
        setAllSavedBrands((prev) => prev.filter((x) => x !== Number(brand.id)));
        try {
          setRemoveLoading(brand?.id);
          await fetcherAuth(
            getToken,
            `/api/organization/${currentOrg?.id}/saved-brands/${brand.id}`,
            "DELETE",
            {},
            {
              savedBrandCollectionId: collectionId,
            },
          );
        } catch (error) {
          setErrorAlert(error);
        } finally {
          setRemoveLoading(-1);
        }
      }, 50),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrg?.id, removeLoading, savedBrands?.length],
  );

  const moveBrandToCollections = async (
    brand: BentoBrand,
    collections: SavedBrandCollection[],
  ) => {
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/saved-brands/${brand.id}/update-associations`,
        "PUT",
        {},
        {
          savedBrandCollectionIds: collections.map((x) => x.id),
        },
      );
      setNeedsRefreshList(true);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const bulkMoveTo = async (
    bulkBrands: BentoBrand[],
    collection: SavedBrandCollection,
  ) => {
    setMoveLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/saved-brand-collections/${collection?.id}/create-associations`,
        "POST",
        {},
        {
          bentoBrandIds: bulkBrands?.map((x) => x.id),
        },
      );
      setAlert(`Successfully add brands to ${collection.name}`, "success");
      if (selectedCollection) {
        await fetchSavedBrandsForCollection(
          selectedCollection?.id,
          currentTab,
          true,
        );
      }
      await fetchSavedBrandsForCollection(collection?.id, currentTab, true);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setMoveLoading(false);
    }
  };

  const bulkDelete = async () => {
    const bentoBrandIds = bulkBrands.map((x) => Number(x.id));
    for (const brand of bulkBrands) {
      modifySavedBrands(
        {
          bentoBrand: brand,
          bentoBrandId: brand.id,
          createdAt: getNow(),
        },
        "remove",
        selectedCollection?.id,
      );
    }
    if (selectedCollection?.id === 0) {
      setAllSavedBrands((prev) =>
        prev.filter((x) => !bentoBrandIds.includes(Number(x))),
      );
    }
    try {
      let url = selectedCollection?.id
        ? `/api/organization/${currentOrg?.id}/saved-brand-collections/${selectedCollection?.id}/remove-associations`
        : `/api/organization/${currentOrg?.id}/saved-brands/bulk-delete`;

      await fetcherAuth(
        getToken,
        url,
        "DELETE",
        {},
        {
          bentoBrandIds: bulkBrands?.map((x) => x.id),
        },
      );
      setNeedsRefreshList(true);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setRemoveLoading(-1);
    }
  };

  return (
    <SavedBrandContext.Provider
      value={{
        getId,
        handleAddDebounced,
        handleRemoveDebounced,
        removeLoading,
        brandBeingSaved,
        setBrandBeingSaved,
        bulkBrands,
        setBulkBrands,
        bulkMoveTo,
        moveBrandToCollections,
        bulkDelete,
        moveLoading,
        selectAll,
        setSelectAll,
        anchorEl,
        setAnchorEl,
      }}
    >
      {children}
    </SavedBrandContext.Provider>
  );
};

export { SavedBrandProvider, SavedBrandContext };
