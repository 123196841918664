import { grey } from "@mui/material/colors";

const styles = {
  icon: {
    fontSize: 13,
    color: grey[500],
    width: 40,
    height: 40,
    border: `1px solid ${grey[200]}`,
  },
  selected: {
    color: "black",
    border: "1px solid black",
  },
  timezonePicker: {
    mt: 1,
    input: { color: "primary.main" },
    ml: { xs: -2, md: 0 },
    width: 250,
  },
  error: {
    color: "error.main",
  },
};

export default styles;
