import { useAuth } from "@clerk/clerk-react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { BentoContact } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import Alert from "components/Alert";
import FormSelect from "components/Forms/Select";
import StyledPage from "components/Styled/Page";
import { fetcherAuth } from "utils/api";
import { getEnumOptions } from "utils/form";
import { useAlert } from "utils/useAlert";

import EditContact from "./EditContact";
import NewContact from "./NewContact";
import styles from "./styles";

enum SortBy {
  NAME = "name",
  BRAND_NAME = "brand_name",
  EMAIL = "email",
  SCORE = "score",
}

enum Direction {
  ASC = "asc",
  DESC = "desc",
}

const AdminContacts = () => {
  const { getToken } = useAuth();
  const navigate = useNavigate();

  const [message, severity, setAlert, closeAlert] = useAlert();

  const [loading, setLoading] = useState(false);

  const [contactSearch, setContactSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string>(SortBy.SCORE);
  const [direction, setDirection] = useState<string>(Direction.DESC);
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [contactSearchResults, setContactSearchResults] = useState<
    BentoContact[]
  >([]);

  const fetchContacts = async (pageToFetch: number) => {
    setLoading(true);

    try {
      const encodedQuery = encodeURIComponent(contactSearch);
      const { contacts, total } = await fetcherAuth(
        getToken,
        `/api/admin/bento-contacts?query=${encodedQuery}&sort_by=${sortBy}&direction=${direction}&page=${pageToFetch}`,
        "GET",
      );
      setContactSearchResults(contacts);
      setTotalCount(total);
    } catch (error) {
      setAlert(
        "An error occurred, you do not have access to this page",
        "warning",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContactSearch = (event: CustomEvent) => {
    const { value } = event.target;
    setContactSearch(value);
  };

  const handleSelectChange = (e: CustomEvent, field: string) => {
    let { value } = e.target;

    if (field === "sortBy") {
      setSortBy(value);
    } else {
      setDirection(value);
    }
  };

  const handleChangePage = (e: CustomEvent, newPage: number) => {
    setPage(newPage + 1);
    fetchContacts(newPage + 1);
  };

  const refetchData = () => {
    fetchContacts(page);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setPage(1);
    fetchContacts(1);
  };

  return (
    <StyledPage
      content={
        <Grid>
          <Backdrop sx={styles.backdrop} open={loading}>
            <CircularProgress />
          </Backdrop>

          <Grid container sx={{ mt: 2 }} spacing={1} alignItems="center">
            <Grid item xs="auto">
              <Typography variant="h3" gutterBottom>
                <strong>Contacts</strong>
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={() => {
                  navigate("new");
                }}
                variant="contained"
                sx={{ mb: 2 }}
              >
                New +
              </Button>
            </Grid>
          </Grid>

          <form onSubmit={handleSubmit}>
            <Grid container sx={{ mb: 3 }} spacing={1}>
              <Grid item xs>
                <TextField
                  label="Search"
                  fullWidth
                  placeholder="Nordstrom"
                  name="contactSearch"
                  onChange={handleContactSearch}
                />
              </Grid>
              <Grid item xs="auto">
                <FormSelect
                  formKey={"sortBy"}
                  value={sortBy}
                  label="Sort By"
                  options={getEnumOptions(SortBy)}
                  handleChange={handleSelectChange}
                  hasNone={false}
                />
              </Grid>
              <Grid item xs="auto">
                <FormSelect
                  formKey={"direction"}
                  value={direction}
                  label="Direction"
                  options={getEnumOptions(Direction)}
                  handleChange={handleSelectChange}
                  hasNone={false}
                />
              </Grid>
              <Grid item xs="auto">
                <Button type="submit" variant="contained" color="primary">
                  Search
                </Button>
              </Grid>
            </Grid>
          </form>
          <TableContainer sx={styles.tableContainer}>
            <Table stickyHeader sx={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Brand Name</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>Is Archived</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactSearchResults.map((res) => (
                  <TableRow
                    key={res.id}
                    sx={res.isArchived ? styles.archivedRow : {}}
                  >
                    <TableCell>{res.bentoBrand?.brandName}</TableCell>
                    <TableCell>{res.name}</TableCell>
                    <TableCell>{res.title}</TableCell>
                    <TableCell>{res.email}</TableCell>
                    <TableCell>{res.score}%</TableCell>
                    <TableCell>{res.isArchived ? "True" : "False"}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          navigate(`${res.id}`);
                        }}
                        variant="outlined"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount || 0}
            rowsPerPage={20}
            rowsPerPageOptions={[20]}
            page={page - 1}
            onPageChange={handleChangePage}
          />
          <Alert
            message={message}
            severity={severity}
            closeAlert={closeAlert}
          />
          <Routes>
            <Route
              path="/:id"
              element={
                <EditContact
                  setAlert={setAlert}
                  refetchContacts={refetchData}
                  setLoading={setLoading}
                />
              }
            />
            <Route
              path="/new"
              element={
                <NewContact
                  setAlert={setAlert}
                  refetchContacts={refetchData}
                  setLoading={setLoading}
                />
              }
            />
          </Routes>
        </Grid>
      }
    />
  );
};

export default AdminContacts;
