import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { useContext, useState } from "react";
import { CustomEvent } from "schemas/functions";

import { sanitizeInput } from "features/Auth/Onboarding/AboutForm/helpers";
import { getDomainOfUrl, isValidHttpUrl } from "utils/string";
import { makeDeepCopy } from "utils/updateLocalState";

import { BentoBrandLinks } from "../helpers";
import { EditField } from "../schema";

interface Props {
  open: boolean;
  handleClose: () => void;
  editField: EditField;
  onSubmit: (e: CustomEvent, editField: EditField, newValue: string) => void;
}

const EditLinkDialog = ({ open, handleClose, editField, onSubmit }: Props) => {
  const [newValue, setNewValue] = useState("");
  const { setAlert } = useContext(AlertContext);

  const handleSubmit = (e: CustomEvent) => {
    const fieldValue = `${BentoBrandLinks[editField.name]?.prefix}${newValue}`;
    if (!isValidHttpUrl(fieldValue)) {
      setAlert(
        "The link you provided is not a valid URL. Please try again.",
        "warning",
      );
      return;
    }

    let isEqual = false;
    if (editField.name === "website") {
      isEqual = getDomainOfUrl(fieldValue) === getDomainOfUrl(editField.value);
    } else {
      isEqual = fieldValue === editField.value;
    }
    if (isEqual) {
      setAlert(
        "We notice you suggested the link we already have on the brand. Please try again.",
        "warning",
      );
      return;
    }
    onSubmit(e, makeDeepCopy(editField), newValue);
    setNewValue("");
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Typography variant="h6" textAlign="center">
          Editing This Link{" "}
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          By submitting edits to brand information, you are helping to improve
          everyone's experience on Bento. Thank you for your contribution!
        </Typography>
        <FormHelperText>{editField?.label}</FormHelperText>
        <TextField
          required
          fullWidth
          value={newValue}
          size="small"
          placeholder={sanitizeInput(editField.value)}
          onChange={(e) => setNewValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment disablePointerEvents position="start">
                {BentoBrandLinks[editField.name]?.prefix}
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          disableElevation
          type="submit"
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!(newValue && newValue?.trim()?.length > 0)}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditLinkDialog;
