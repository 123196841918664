import { useMediaQuery, useTheme } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { BentoBrand, BentoContact, NewBentoContact } from "schemas/dashboard";
import { Conditions } from "schemas/outreach";

import { trackEvent } from "utils/tracking";
import { usePageSize } from "utils/usePageSize";

import { DiscoverViewContext } from "./DiscoverView";

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

export enum QuickSendTabView {
  EXPAND = "EXPAND",
  COLLAPSE = "COLLAPSE",
}

export type RecentContactDetails = {
  contact: BentoContact | NewBentoContact | null;
  sendAt: null | string;
};

interface QuickSendDrawerContextInterface {
  quickSendBrandId: number;
  setQuickSendBrandId: Dispatch<SetStateAction<number>>;
  brandDetailsId: number;
  setBrandDetailsId: Dispatch<SetStateAction<number>>;
  handleOpenQuickSendIndividual: (
    brandId: number | string,
    clickFrom: string,
    referredEmail?: string,
    openBrand?: boolean,
  ) => void;
  handleChangeQuickSend: (brandId: number | string, clickFrom: string) => void;
  handleOpenBrandDetails: (brand: BentoBrand, clickFrom?: string) => void;
  sendFrom: string;
  setSendFrom: Dispatch<SetStateAction<string>>;
  isEdited: boolean;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  closeDrawer: () => void;
  view: QuickSendTabView;
  setView: Dispatch<SetStateAction<QuickSendTabView>>;
  brandView: QuickSendTabView;
  setBrandView: Dispatch<SetStateAction<QuickSendTabView>>;
  conditions: Conditions;
  setConditions: Dispatch<SetStateAction<Conditions>>;
  referredEmail: string;
  openAddNewContact: boolean;
  setOpenAddNewContact: Dispatch<SetStateAction<boolean>>;
  draftIsSet: number;
  setDraftIsSet: Dispatch<SetStateAction<number>>;
  handleOpenDraftWindow: () => void;
  draftOpen: boolean;
  handleCloseDraftWindow: () => void;
  draftView: QuickSendTabView;
  setDraftView: Dispatch<SetStateAction<QuickSendTabView>>;
  previewLoading: number;
  setPreviewLoading: Dispatch<SetStateAction<number>>;
}

const defaultInterface = {
  handleOpenQuickSendIndividual: defaultContextMissingFunction,
  handleOpenBrandDetails: defaultContextMissingFunction,
  handleChangeQuickSend: defaultContextMissingFunction,
  quickSendBrandId: -1,
  setQuickSendBrandId: defaultContextMissingFunction,
  brandDetailsId: -1,
  setBrandDetailsId: defaultContextMissingFunction,
  sendFrom: "",
  setSendFrom: defaultContextMissingFunction,
  isEdited: false,
  setIsEdited: defaultContextMissingFunction,
  closeDrawer: defaultContextMissingFunction,
  view: QuickSendTabView.EXPAND,
  setView: defaultContextMissingFunction,
  brandView: QuickSendTabView.EXPAND,
  setBrandView: defaultContextMissingFunction,
  conditions: {
    skipsBusinessHour: false,
    skipsRecentContact: false,
  },
  setConditions: defaultContextMissingFunction,
  referredEmail: "",
  openAddNewContact: false,
  setOpenAddNewContact: defaultContextMissingFunction,
  draftIsSet: -1,
  setDraftIsSet: defaultContextMissingFunction,
  handleOpenDraftWindow: defaultContextMissingFunction,
  draftOpen: false,
  handleCloseDraftWindow: defaultContextMissingFunction,
  draftView: QuickSendTabView.EXPAND,
  setDraftView: defaultContextMissingFunction,
  previewLoading: -1,
  setPreviewLoading: defaultContextMissingFunction,
};

const QuickSendDrawerContext =
  createContext<QuickSendDrawerContextInterface>(defaultInterface);

interface QuickSendDrawerProviderProps {
  children: React.ReactNode;
}

const QuickSendDrawerProvider = ({
  children,
}: QuickSendDrawerProviderProps) => {
  const { isTwoColumnView, isLargeRecommendedPage } = usePageSize();
  const { discoverTab, setDiscoverTab } = useContext(DiscoverViewContext);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const hideDiscoverTab =
    useMediaQuery("(max-width:1200px)") || isLargeRecommendedPage;

  const [quickSendBrandId, setQuickSendBrandId] = useState<number>(-1);
  const [brandDetailsId, setBrandDetailsId] = useState<number>(-1);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const [sendFrom, setSendFrom] = useState("");
  const [referredEmail, setReferredEmail] = useState<string>("");
  const [draftOpen, setDraftOpen] = useState<boolean>(false);
  const [view, setView] = useState<QuickSendTabView>(QuickSendTabView.EXPAND);
  const [draftView, setDraftView] = useState<QuickSendTabView>(
    QuickSendTabView.EXPAND,
  );
  const [brandView, setBrandView] = useState<QuickSendTabView>(
    QuickSendTabView.EXPAND,
  );
  const [draftIsSet, setDraftIsSet] = useState<number>(-1);
  const [conditions, setConditions] = useState<Conditions>({
    skipsBusinessHour: false,
    skipsRecentContact: false,
  });
  const [previewLoading, setPreviewLoading] = useState<number>(-1);

  const [openAddNewContact, setOpenAddNewContact] = useState(false);

  const handleOpenBrandDetails = (brand: BentoBrand, clickFrom?: string) => {
    trackEvent("Open Brand Details Clicked", {
      "Clicked From": clickFrom,
      "Bento Brand ID": brand.id,
    });

    if (discoverTab !== null && hideDiscoverTab) {
      setDiscoverTab(null);
    }
    if (isTwoColumnView) {
      setView(QuickSendTabView.COLLAPSE);
    }
    if (brandDetailsId !== brand.id) {
      setBrandDetailsId(brand.id);
    }
    setBrandView(QuickSendTabView.EXPAND);
  };

  const handleOpenDraftWindow = () => {
    setDraftOpen(true);
    if (!isLargeRecommendedPage) {
      setView(QuickSendTabView.COLLAPSE);
      setBrandView(QuickSendTabView.COLLAPSE);
    }
    setDraftView(QuickSendTabView.EXPAND);
  };

  const handleCloseDraftWindow = () => {
    setDraftOpen(false);
  };

  const handleOpenQuickSendIndividual = (
    brandId: number | string,
    clickFrom: string,
    referredEmail?: string,
    openBrand: boolean = false,
  ) => {
    if (!isLargeRecommendedPage) {
      setDraftView(QuickSendTabView.COLLAPSE);
    }
    trackEvent("Quick Send Button Clicked", {
      "Clicked From": clickFrom,
      brandId,
    });
    if (quickSendBrandId !== Number(brandId)) {
      setPreviewLoading(Number(brandId));
    }
    setQuickSendBrandId(Number(brandId));
    if (!isMobileScreen && (openBrand || brandDetailsId > -1)) {
      setBrandDetailsId(Number(brandId));
      if (openBrand) {
        setBrandView(QuickSendTabView.EXPAND);
      }
    }
    setView(QuickSendTabView.EXPAND);
    setSendFrom(clickFrom);
    setReferredEmail(referredEmail || "");
  };

  const handleChangeQuickSend = (
    brandId: number | string,
    clickFrom: string,
  ) => {
    if (quickSendBrandId < 0 || view !== QuickSendTabView.EXPAND) {
      return;
    }
    handleOpenQuickSendIndividual(brandId, clickFrom, undefined, true);
  };

  const closeDrawer = () => {
    setQuickSendBrandId(-1);
    setPreviewLoading(-1);
    setBrandDetailsId(-1);
    setDraftIsSet(-1);
  };

  return (
    <QuickSendDrawerContext.Provider
      value={{
        handleOpenQuickSendIndividual,
        handleChangeQuickSend,
        handleOpenBrandDetails,
        quickSendBrandId,
        setQuickSendBrandId,
        brandDetailsId,
        setBrandDetailsId,
        sendFrom,
        setSendFrom,
        isEdited,
        setIsEdited,
        closeDrawer,
        view,
        setView,
        brandView,
        setBrandView,
        conditions,
        setConditions,
        referredEmail,
        openAddNewContact,
        setOpenAddNewContact,
        draftIsSet,
        setDraftIsSet,
        handleOpenDraftWindow,
        draftOpen,
        handleCloseDraftWindow,
        draftView,
        setDraftView,
        previewLoading,
        setPreviewLoading,
      }}
    >
      {children}
    </QuickSendDrawerContext.Provider>
  );
};

export { QuickSendDrawerProvider, QuickSendDrawerContext };
