import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import moment from "moment";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { SendOption } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";
import { routes } from "schemas/routes";

import SendTemplateOption from "components/BulkSendDialog/PickSendOption";
import TemplateScreen from "components/TemplateScreen";
import { Template } from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { getLocation } from "utils/influencer";
import { trackEvent } from "utils/tracking";

import { BulkSendSettingsStatus, OrganizationBulkSendSettings } from "./schema";
import styles from "./styles";

interface Props {
  open: boolean;
  handleClose: () => void;
  settings: OrganizationBulkSendSettings;
  setSettings: Dispatch<SetStateAction<OrganizationBulkSendSettings>>;
  selectedTemplates: Template[];
  setSelectedTemplates: Dispatch<SetStateAction<Template[]>>;
}

export default function UpcomingPitchScheduleDialog({
  open,
  handleClose,
  settings,
  setSettings,
  selectedTemplates,
  setSelectedTemplates,
}: Props) {
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuth();
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { currentOrg, profile } = useContext(OrganizationUserContext);
  const { outreachTemplates } = useContext(OutreachTemplatesContext);
  const [tempSettings, setTempSettings] =
    useState<OrganizationBulkSendSettings>(settings);
  const [validation, setValidation] = useState<string>("");
  const [validationMessage, setValidationMessage] = useState<
    string | JSX.Element
  >("");
  const location = getLocation(profile);

  const pickOption = (option: SendOption) => {
    setTempSettings((prev) => ({
      ...prev,
      templateOption: option,
    }));
  };

  const handleTimezoneChange = (
    event: CustomEvent,
    newValue: string | null,
  ) => {
    if (newValue) {
      trackEvent("Timezone Changed", { timezone: newValue });
      setTempSettings((prev) => ({
        ...prev,
        timezone: newValue,
      }));
    }
  };

  const validatAllFields = () => {
    if (!tempSettings?.templateOption) {
      setValidation("templateOption");
      setValidationMessage("Please select a send option");
      return false;
    }

    if (
      tempSettings?.numberOfBrands === undefined ||
      tempSettings?.numberOfBrands === null ||
      tempSettings?.numberOfBrands < 0 ||
      tempSettings?.numberOfBrands > 100
    ) {
      setValidation("numberOfBrands");
      setValidationMessage("The number you select must be between 0 and 100.");
      return false;
    }

    if (
      tempSettings?.numberOfRepitchBrands === undefined ||
      tempSettings?.numberOfRepitchBrands === null ||
      tempSettings?.numberOfRepitchBrands < 0 ||
      tempSettings?.numberOfRepitchBrands > 100
    ) {
      setValidation("numberOfRepitchBrands");
      setValidationMessage("The number you select must be between 0 and 100.");
      return false;
    }

    if (!(selectedTemplates?.length > 0)) {
      setValidation("selectedTemplates");
      setValidationMessage(
        <>
          You do not have any templates. Please go to the{" "}
          <Link to={`/${routes.templates}`}>Templates</Link> tab to create one.
        </>,
      );
      return false;
    }
    setValidation("");
    setValidationMessage("");
    return true;
  };

  const createOrUpdateAutomation = async () => {
    const validate = validatAllFields();
    if (!validate) return;

    setLoading(true);

    try {
      const res = await fetcherAuth(
        getToken,
        tempSettings?.id
          ? `/api/organization/${currentOrg?.id}/bulk-send-settings/${tempSettings?.id}`
          : `/api/organization/${currentOrg?.id}/bulk-send-settings`,
        tempSettings?.id ? "PATCH" : "POST",
        {},
        {
          ...tempSettings,
          timezone: tempSettings?.timezone || moment.tz.guess(),
          templateIds: selectedTemplates?.map((t) => t.id),
        },
      );
      setTempSettings(res.bulkSendSetting);
      setSettings(res.bulkSendSetting);
      setAlert("Succesfully updated settings", "success");
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTemplates?.length === 0 && outreachTemplates?.length > 0) {
      const sortedByLastUsed = outreachTemplates?.sort((a, b) => {
        return (b.lastUsedAt || 0) - (a.lastUsedAt || 0);
      });
      if (sortedByLastUsed?.length > 0) {
        setSelectedTemplates([sortedByLastUsed[0]]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplates?.length, outreachTemplates?.length]);

  useEffect(() => {
    setTempSettings(settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>Set Up Automated Scheduling</DialogTitle>
      <DialogContent>
        <SendTemplateOption
          sendOption={tempSettings?.templateOption}
          pickOption={pickOption}
          groupingLoading={false}
          validOptions={[SendOption.same, SendOption.by_automation]}
        />

        {tempSettings?.templateOption && (
          <>
            <Box sx={{ mb: 3 }}>
              <strong>
                How many emails do you want to send per week to new brands?
              </strong>

              {validation === "numberOfBrands" ? (
                <FormHelperText sx={styles.error}>
                  <em>{validationMessage}</em>
                </FormHelperText>
              ) : (
                <FormHelperText>
                  Select a number between 0 and 100. Emails will be sent between
                  Monday - Thursday.
                </FormHelperText>
              )}

              <Grid item xs={12} sx={{ mt: 1 }}>
                <TextField
                  sx={{ width: 80 }}
                  size="small"
                  variant="outlined"
                  type="number"
                  placeholder={"100"}
                  value={tempSettings?.numberOfBrands}
                  onChange={(e) => {
                    setTempSettings((prev) => ({
                      ...prev,
                      numberOfBrands: Math.floor(Number(e.target.value)),
                    }));
                    if (
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 100
                    ) {
                      setValidation("");
                      setValidationMessage("");
                    }
                  }}
                />
              </Grid>
            </Box>
            <Box sx={{ mb: 3 }}>
              <strong>
                How many re-pitching emails do you want to send per week?
              </strong>

              {validation === "numberOfRepitchBrands" ? (
                <FormHelperText sx={styles.error}>
                  <em>{validationMessage}</em>
                </FormHelperText>
              ) : (
                <FormHelperText>
                  Select a number between 0 and 100. Emails will be sent between
                  Monday - Thursday to already pitched brands that you have
                  never gotten a response and you haven't sent any emails to in
                  the last 30 days.
                </FormHelperText>
              )}

              <Grid item xs={12} sx={{ mt: 1 }}>
                <TextField
                  sx={{ width: 80 }}
                  size="small"
                  variant="outlined"
                  type="number"
                  placeholder={"100"}
                  value={tempSettings?.numberOfRepitchBrands}
                  onChange={(e) => {
                    setTempSettings((prev) => ({
                      ...prev,
                      numberOfRepitchBrands: Math.floor(Number(e.target.value)),
                    }));
                    if (
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 100
                    ) {
                      setValidation("");
                      setValidationMessage("");
                    }
                  }}
                />
              </Grid>
            </Box>
            {selectedTemplates?.length > 0 && (
              <Box sx={{ mb: 3 }}>
                <strong>
                  Select the default templates to send for this option
                </strong>
                <FormHelperText>
                  Templates must contain only &#123;First Name&#125; and
                  &#123;Brand Name&#125; variables.
                  {tempSettings?.templateOption ===
                    SendOption.by_automation && (
                    <>
                      {" "}
                      <br />
                      If a brand does not belong to any automation, we will use
                      the templates below.
                    </>
                  )}
                </FormHelperText>
                <TemplateScreen
                  templates={selectedTemplates}
                  setTemplates={setSelectedTemplates}
                />
              </Box>
            )}

            {validation === "selectedTemplates" && (
              <FormHelperText sx={styles.error}>
                <em>{validationMessage}</em>
              </FormHelperText>
            )}
            <Box sx={{ mb: 3 }}>
              <strong>Select timezone</strong>
              <FormHelperText>
                Emails will be sent between 10:00 AM and 5:00 PM (using local
                timezone).{" "}
                {location && (
                  <>
                    <br />
                    Your location is <strong>{getLocation(profile)}</strong>.
                  </>
                )}
              </FormHelperText>

              <Autocomplete
                options={moment.tz.names()}
                value={tempSettings?.timezone || moment.tz.guess()}
                onChange={handleTimezoneChange}
                renderInput={(params) => (
                  <TextField {...params} sx={styles.timezonePicker} />
                )}
                disableClearable
                size="small"
              />
            </Box>

            <Box>
              <strong>Pause Outreach</strong>
              <Switch
                size="small"
                onChange={(e) => {
                  setTempSettings((prev) => ({
                    ...prev,
                    status: e.target.checked
                      ? BulkSendSettingsStatus.inactive
                      : BulkSendSettingsStatus.active,
                  }));
                }}
                checked={
                  tempSettings?.status === BulkSendSettingsStatus.inactive
                }
              />
              {tempSettings?.status === BulkSendSettingsStatus.inactive ? (
                <FormHelperText>
                  If you want to resume your outreach, toggle the switch above.
                </FormHelperText>
              ) : (
                <FormHelperText>
                  If you want to temporarily pause your outreach, toggle the
                  switch above.
                </FormHelperText>
              )}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          variant="contained"
          disableElevation
          disabled={!tempSettings?.templateOption}
          onClick={createOrUpdateAutomation}
          loading={loading}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
