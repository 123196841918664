import { grey } from "@mui/material/colors";

const styles = {
  key: {
    color: grey[600],
    fontSize: 12,
  },
  value: {
    textTransform: "capitalize",
    fontSize: 12,
  },
};

export default styles;
