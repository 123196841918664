import { grey } from "@mui/material/colors";

const styles = {
  textField: {
    "& .MuiInputBase-root": { backgroundColor: "white" },
    "& .MuiFormHelperText-root": { color: "error.main" },
  },
  helperText: {
    mb: 0.5,
  },
  fieldContainer: {
    mb: 1,
  },
  selectSocial: {
    backgroundColor: "white",
    width: "120px",
  },
  linkAdornment: {
    "& .MuiTypography-root": {
      color: grey[900],
      fontSize: { xs: 12, md: 14 },
    },
  },
};

export default styles;
