import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  OrganizationVaProfile,
  VaNicheSpecificDetails,
  VaOnboardingType,
} from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import VideoEmbed from "components/VideoEmbed";
import { vaOnboardingNavigate } from "features/Auth/Onboarding/helper";
import { getSource } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

interface Props {
  organizationVaProfile: OrganizationVaProfile | null;
  setOrganizationVaProfile: Dispatch<
    SetStateAction<OrganizationVaProfile | null>
  >;
  saveVaProfileForm: (
    organizationVaProfile: OrganizationVaProfile | null,
    page?: string,
    source?: string | null,
    skipMovingNext?: boolean,
  ) => void;
}

const VaNicheDetailsForm = ({
  organizationVaProfile,
  setOrganizationVaProfile,
  saveVaProfileForm,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const slideContext = useContext(SlideAnimationContext);

  const [niches, setNiches] = useState<VaNicheSpecificDetails[]>([]);

  const allQuestionsAnswered = () => {
    for (const niche of niches) {
      if (
        !niche.past_brands ||
        !niche.top_performing_post_link ||
        (organizationVaProfile?.onboardingType === VaOnboardingType.instant &&
          !niche.partnership_reason)
      ) {
        return false;
      }
    }
    return true;
  };

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "back")}${location.search}`,
    );
  };

  const handleNext = async (e: CustomEvent) => {
    e.preventDefault();
    if (!allQuestionsAnswered()) {
      setAlert("Please answer all the questions", "error");
      return;
    }
    const copy = makeDeepCopy(organizationVaProfile);
    const source = getSource();
    copy.niches = niches;
    saveVaProfileForm(copy, location.pathname, source);
  };

  const onChange = (e: CustomEvent, index: number) => {
    const { name, value } = e.target;
    setNiches((prev) => {
      const copy = makeDeepCopy(prev);
      copy[index][name] = value;
      return copy;
    });
  };

  const renderNicheQuestion = (index: number) => {
    const name = niches[index]?.name;
    return (
      <Grid container alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h6">
          <strong>{name}</strong>
        </Typography>
        <Grid item xs={12}>
          <FormHelperText>
            What are big brand names you've worked with in {name}? *
          </FormHelperText>
          <TextField
            sx={styles.textField}
            fullWidth
            name="past_brands"
            placeholder={"Type your answer here..."}
            onChange={(e) => onChange(e, index)}
            required
            value={niches[index]?.past_brands || ""}
          />
          <FormHelperText>
            Share a top performing post (IG or TikTok link) in {name}{" "}
            (preferably it is on a brand page): *
          </FormHelperText>
          <TextField
            sx={styles.textField}
            fullWidth
            name="top_performing_post_link"
            placeholder={"https://instagram.com/link"}
            onChange={(e) => onChange(e, index)}
            required
            value={niches[index]?.top_performing_post_link || ""}
          />
          {organizationVaProfile?.onboardingType ===
            VaOnboardingType.instant && (
            <>
              <FormHelperText>
                Share why brands in {name} would want to partner with you on
                content? (<strong>Be specific.</strong> Some ideas: share your
                personal tie to this niche, explain a case study of how you've
                worked with a brand in this niche in the past and the results
                you've had, share a concept in this niche you've been dying to
                create for a brand) *
              </FormHelperText>
              <TextField
                sx={styles.textField}
                fullWidth
                name="partnership_reason"
                placeholder={"Type your answer here..."}
                onChange={(e) => onChange(e, index)}
                required
                multiline
                rows={4}
                value={niches[index]?.partnership_reason || ""}
              />
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (
      organizationVaProfile?.niches &&
      organizationVaProfile?.niches?.length > 0
    ) {
      setNiches(organizationVaProfile?.niches);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationVaProfile?.niches]);

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center", mt: 0 }}>
        Let's gather additional info about your selected niches
      </Typography>

      {organizationVaProfile?.onboardingType === VaOnboardingType.instant && (
        <Grid container justifyContent="center" sx={{ my: 3 }}>
          <FormHelperText sx={{ textAlign: "center" }}>
            Watch this video to guide you on how to answer the questions below.
          </FormHelperText>

          <VideoEmbed
            link="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/niche_part2.mov"
            eventName="VA Onboarding Flow - Answer Niche Specific Questions Video Clicked"
          />
        </Grid>
      )}

      <form onSubmit={handleNext}>
        {niches?.length > 0 &&
          niches?.map((niche, index) => renderNicheQuestion(index))}

        <Grid item xs="auto" container columnGap={1} sx={{ mt: 4 }}>
          <Grid item xs="auto">
            <Button onClick={handleBack} color="secondary" disableElevation>
              Back
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <Button disableElevation variant="contained" type="submit">
              Next{" "}
              <Box
                sx={{ ml: 1 }}
                component="i"
                className="fa-solid fa-arrow-right"
              />
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default VaNicheDetailsForm;
