import { Box, CardHeader, Grid, Typography } from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { useContext } from "react";
import { BentoBrand } from "schemas/dashboard";

import Carousel from "components/Carousel";
import BrandTitle from "features/Influencer/Discover/Shared/BrandTitle";

import styles from "./styles";

interface Props {
  bentoBrand: BentoBrand;
  similarBrands: BentoBrand[];
  isPopupView?: boolean;
}
const BrandSimilar = ({ similarBrands, isPopupView }: Props) => {
  const { handleOpenBrandDetails, handleOpenQuickSendIndividual } = useContext(
    QuickSendDrawerContext,
  );
  const { setBrandNavigationHistory } = useContext(DiscoverViewContext);

  const onClick = (brand: BentoBrand) => {
    setBrandNavigationHistory((prev) => [...prev, brand]);
    handleOpenBrandDetails(brand, "Similar Brands");
    handleOpenQuickSendIndividual(
      brand?.id,
      "Similar Brands",
      undefined,
      false,
    );
  };

  const renderContentList = () => {
    return (
      <>
        {similarBrands?.map((brand, index) => (
          <Box key={index} sx={styles.card} onClick={() => onClick(brand)}>
            <CardHeader
              sx={styles.cardHeader(isPopupView)}
              title={
                <Grid
                  container
                  alignItems="center"
                  gap={1}
                  justifyContent="space-between"
                >
                  <BrandTitle bentoBrand={brand} isCardView={!isPopupView} />
                </Grid>
              }
            />
          </Box>
        ))}
      </>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography gutterBottom>
        <strong>Similar Brands</strong>
      </Typography>

      {isPopupView ? (
        <Grid container gap={2}>
          {renderContentList()}
        </Grid>
      ) : (
        <Carousel contents={renderContentList()} />
      )}
    </Box>
  );
};

export default BrandSimilar;
