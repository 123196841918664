import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { EmailSettingTemplate, EmailSettings } from "schemas/email";
import { ALL } from "schemas/forms";
import { CustomEvent } from "schemas/functions";

import { MAXIMUM_FOLLOWUP_ALLOWED } from "constants/templates";
import { Template } from "features/Influencer/ContactList/schema";
import { sanitize } from "utils/string";
import { UNCATEGORIZED } from "utils/templates";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import OutreachSelect from "./OutreachSelect";
import styles from "./styles";

interface Props {
  templates: Template[];
  setTemplates: (templates: Template[]) => void;
  allEmailSettings?: EmailSettings[];
  setDisplayTemplateScreen?: Dispatch<SetStateAction<boolean>>;
  category?: string;
}

export default function TemplateScreen({
  templates,
  setTemplates,
  setDisplayTemplateScreen,
  category,
  allEmailSettings,
}: Props) {
  const { emailSettings } = useContext(OrganizationUserContext);

  const {
    templates: allTemplates,
    outreachTemplates,
    followupTemplates,
    fetchOutreachTemplates,
  } = useContext(OutreachTemplatesContext);

  const templateFromEmailSettings = emailSettings?.emailSettingTemplates?.map(
    (template) => template.templateId,
  );

  const filteredOutreachTemplates = outreachTemplates?.filter(
    (t) => !t.emailTemplateId,
  );
  const filteredFollowUps = followupTemplates?.filter(
    (t) => !t.emailTemplateId,
  );

  const handleChange = (e: CustomEvent, index: number) => {
    const { value } = e.target;
    const copy = makeDeepCopy(templates);
    copy[index] = allTemplates?.find((x) => x.id === value);
    setTemplates(copy);
  };

  const selectedFollowups = templates?.slice(1, templates?.length);

  const addFollowUp = () => {
    trackEvent(
      "Favorites List - Add Follow Up Button Pressed on Customization Dialog",
    );
    setTemplates([...templates, followupTemplates?.[0]]);
  };

  const removeFollowUp = (index: number) => {
    trackEvent("Favorites List - Remove Follow-Up on Customization Dialog");
    const copy = makeDeepCopy(templates);
    copy.splice(index, 1);
    setTemplates(copy);
  };

  const getText = (index: number) => {
    if (index === 1) return "st";
    if (index === 2) return "nd";
    if (index === 3) return "rd";
    return "th";
  };

  const initializeEmails = () => {
    if (templates?.length > 0) return;

    if (allTemplates?.length === 0) {
      return;
    }

    const matchedTemplates = [];
    let presetTemplates: EmailSettingTemplate[] = [];

    const index = category
      ? allEmailSettings?.findIndex((x) => x.category === sanitize(category))
      : -1;

    if (
      category &&
      category !== ALL &&
      category !== UNCATEGORIZED &&
      index !== -1 &&
      index !== undefined &&
      allEmailSettings
    ) {
      presetTemplates = allEmailSettings[index]?.emailSettingTemplates || [];
    }

    // If no templates found in the category or the brands are uncategorized, use the `last_used` templates
    if (presetTemplates?.length === 0) {
      presetTemplates = emailSettings?.emailSettingTemplates || [];
    }

    for (const settingTemplate of presetTemplates) {
      const template = allTemplates?.find(
        (x) => x.id === settingTemplate.templateId,
      );
      if (template) {
        matchedTemplates.push(template);
      }
    }
    if (matchedTemplates?.length > 0) {
      setTemplates(matchedTemplates);
      return;
    }

    if (filteredOutreachTemplates?.length > 0) {
      setTemplates([filteredOutreachTemplates[0]]);
    } else if (filteredOutreachTemplates?.length === 0) {
      fetchOutreachTemplates();
    }
  };

  useEffect(() => {
    initializeEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    templates?.length,
    filteredOutreachTemplates,
    emailSettings?.emailSettingTemplates?.length,
    allEmailSettings?.length,
  ]);

  return (
    <>
      {setDisplayTemplateScreen && (
        <Grid container alignItems="center" gap={1} sx={{ mb: 1 }}>
          <IconButton sx={{ fontSize: 13, color: "black" }}>
            <Box
              component="i"
              className="fa-regular fa-arrow-left"
              onClick={() => setDisplayTemplateScreen(false)}
            />
          </IconButton>
          <Typography variant="h6">Select Templates</Typography>
        </Grid>
      )}
      <Grid container alignItems="center">
        <Grid item xs={12} md={2.5}>
          <Typography sx={styles.label}>Outreach:</Typography>
        </Grid>
        {templates[0] && (
          <OutreachSelect
            templates={templates}
            handleChange={handleChange}
            filteredOutreachTemplates={filteredOutreachTemplates}
            templateFromEmailSettings={templateFromEmailSettings}
          />
        )}
      </Grid>

      {selectedFollowups?.map((followup, index) => (
        <Grid container alignItems="center" key={index}>
          <Grid item xs={12} md={2.5}>
            <Typography sx={styles.label}>
              {index + 1}
              {getText(index + 1)} Follow-Up:
            </Typography>
          </Grid>
          <Select
            onChange={(e) => handleChange(e, index + 1)}
            value={followup?.id}
            sx={styles.noBorder}
            size="small"
          >
            {filteredFollowUps?.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                <Box sx={styles.templateName}>
                  {template.name}
                  {template.id &&
                    templateFromEmailSettings?.includes(template.id) && (
                      <Chip
                        label="Last Used"
                        size="small"
                        sx={{
                          ml: 1,
                          fontSize: 10,
                          backgroundColor: "primary.light",
                        }}
                      />
                    )}
                </Box>
              </MenuItem>
            ))}
          </Select>

          <IconButton
            sx={{ fontSize: 13, color: "black" }}
            onClick={() => removeFollowUp(index + 1)}
          >
            <Box component="i" className="fa-regular fa-trash" />
          </IconButton>
        </Grid>
      ))}
      {followupTemplates?.length > 0 &&
        templates?.length < MAXIMUM_FOLLOWUP_ALLOWED + 1 && (
          <Grid container alignItems="center">
            <Grid item xs={12} md={2.5} />
            <Grid item xs md>
              <Button
                disableElevation
                size="small"
                onClick={addFollowUp}
                sx={{
                  fontSize: 12,
                  textTransform: "none",
                  ml: { xs: -1, md: 1 },
                }}
              >
                Add Follow Up +
              </Button>
            </Grid>
          </Grid>
        )}
    </>
  );
}
