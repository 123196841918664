import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { BrandsContext } from "contexts/Brands";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useState } from "react";
import { BentoBrand, MetadataType } from "schemas/dashboard";

import { fetcherAuth } from "utils/api";
import { DiscoverSearchChipColor } from "utils/color";
import { accessToVaFeature } from "utils/influencer";
import { ON_BEHALF_OF_TOKEN } from "utils/localStorage";
import { useSearchBrand } from "utils/useSearchBrand";

import EditCategoryDialog from "./EditCategory";
import { getMappings } from "./helpers";
import { BrandAnnotationHeader, CategoryEdit } from "./schema";
import styles from "./styles";

interface Props {
  selectedBrand?: BentoBrand | null;
  header: string;
}

const BrandAnnotation = ({ header, selectedBrand }: Props) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { subscription, isVaPlan } = useContext(SubscriptionContext);
  const { requestRecategorization } = useContext(BrandsContext);
  const hasAccessToVa = accessToVaFeature(currentOrg, subscription, isVaPlan);
  const isAdmin = Boolean(sessionStorage.getItem(ON_BEHALF_OF_TOKEN));

  const { handleClickOnTag } = useSearchBrand();
  const { getToken } = useAuth();
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [editCategories, setEditCategories] = useState<CategoryEdit | null>(
    null,
  );

  const { setAlert, setErrorAlert } = useContext(AlertContext);
  const mappings = selectedBrand ? getMappings(selectedBrand) : {};

  const closeDialog = () => {
    setOpenCategoryDialog(false);
    setEditCategories(null);
  };

  const handleEditCategories = () => {
    let idKeys = "";
    let currentKey = "";
    if (header === BrandAnnotationHeader.main_categories) {
      idKeys = "mainCategoryIds";
      currentKey = "mainCategoryNames";
    } else {
      idKeys = "secondaryCategoryIds";
      currentKey = "secondaryCategoryNames";
    }
    setEditCategories({
      categories: selectedBrand?.[currentKey as keyof BentoBrand] as string[],
      suggestedCategories: selectedBrand?.[
        currentKey as keyof BentoBrand
      ] as string[],
      field: mappings[header]?.key,
      label: header,
      suggestedCategoriesIds:
        (selectedBrand?.[idKeys as keyof BentoBrand] as unknown as number[]) ??
        [],
    });
    setOpenCategoryDialog(true);
  };

  const renderChip = (
    value: string,
    backgroundColor: string,
    type: MetadataType,
  ) => {
    return (
      <Chip
        key={value?.toString()}
        size="small"
        sx={{
          backgroundColor,
          my: 0.5,
          fontSize: 11,
        }}
        label={value.replaceAll("_", " ")}
        onClick={(e) =>
          handleClickOnTag(e, {
            type,
            value,
          })
        }
      />
    );
  };

  const displayValue = () => {
    if (!selectedBrand || !(header in mappings)) return <></>;
    const mappingsValue = mappings[header] || {
      key: "",
      color: "",
      metadataType: "",
      isArray: false,
      convertedValue: "",
    };
    // @ts-ignore
    const { key, color, metadataType, isArray, convertedValue } = {
      ...mappingsValue,
    };
    // @ts-ignore
    const values: unknown = selectedBrand[key as keyof BentoBrand];
    if (convertedValue) {
      return convertedValue;
    } else if (isArray && Array.isArray(values)) {
      return values?.map((x: any) =>
        renderChip(x, DiscoverSearchChipColor[color], metadataType),
      );
    } else if (values && typeof values === "string" && values?.length > 0) {
      return renderChip(
        values?.toString(),
        DiscoverSearchChipColor[color],
        metadataType,
      );
    } else {
      return "";
    }
  };

  const handleSubmitSuggestedCategories = async () => {
    if (!selectedBrand || !editCategories) return;
    if (editCategories?.suggestedCategories?.length === 0) {
      setAlert("Please add at least one category.", "error");
      return;
    }

    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/user-suggestions/${selectedBrand.id}`,
        "POST",
        {},
        {
          fieldName:
            editCategories?.label === BrandAnnotationHeader.main_categories
              ? "mainCategoryIds"
              : "secondaryCategoryIds",
          currentValue: editCategories?.categories?.join(", "),
          suggestedValueIds: editCategories?.suggestedCategoriesIds,
          suggestedValue: editCategories?.suggestedCategories.join(", "),
          label: editCategories?.label,
        },
      );
      setAlert("Your suggestion has been submitted.", "success");
      closeDialog();
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const value = displayValue();
  const isEditable = mappings?.[header]?.isEditable;
  const canRecategorize =
    (hasAccessToVa || isAdmin) && mappings?.[header]?.canRecategorize;

  return selectedBrand ? (
    <Grid item xs={12}>
      {(value?.length > 0 || isEditable) && (
        <Typography>
          <Box component="span" sx={styles.key}>
            {header}:{" "}
          </Box>{" "}
          <Box sx={styles.value} component="span">
            {displayValue()}
            {isEditable && (
              <Tooltip title="Edit this categorization">
                <IconButton
                  sx={{ fontSize: 12 }}
                  onClick={handleEditCategories}
                >
                  <Box component="i" className="fa-regular fa-edit" />
                </IconButton>
              </Tooltip>
            )}
            {canRecategorize && (
              <Tooltip title="Request recategorization">
                <IconButton
                  sx={{ fontSize: 12 }}
                  onClick={() => requestRecategorization(selectedBrand?.id)}
                >
                  <Box component="i" className="fa-regular fa-refresh" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Typography>
      )}

      {editCategories && (
        <EditCategoryDialog
          open={openCategoryDialog}
          handleClose={closeDialog}
          editCategories={editCategories}
          setEditCategories={setEditCategories}
          handleSubmit={handleSubmitSuggestedCategories}
          selectedBrand={selectedBrand}
        />
      )}
    </Grid>
  ) : (
    <></>
  );
};

export default BrandAnnotation;
