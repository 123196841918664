import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { AutomationsContext } from "contexts/Automations";
import { BentoCategoriesContext } from "contexts/BentoCategories";
import { BrandsContext } from "contexts/Brands";
import { OrganizationUserContext } from "contexts/Organization";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Automation,
  BentoBrandSearchParameters,
  SavedBrandCollection,
  SearchParams,
  Sort,
} from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import TemplateScreen from "components/TemplateScreen";
import { Template } from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import {
  convertFilterParamsToQuery,
  isEqualParams,
} from "utils/discoverSearch";
import { daysOfWeek } from "utils/time";
import { makeDeepCopy } from "utils/updateLocalState";
import { useSearchBrand } from "utils/useSearchBrand";

import AutomationParams from "./AutomationParams";
import styles from "./styles";

interface Props {
  open: boolean;
  handleClose: () => void;
  editAutomation?: Automation;
}

const CreateAutomationDialog = ({
  open,
  handleClose,
  editAutomation,
}: Props) => {
  const { hasTags } = useSearchBrand();
  const { setAutomations } = useContext(AutomationsContext);
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { collections } = useContext(SavedBrandCollectionsContext);
  const [searchParams] = useSearchParams();
  const { discoverFilterParams, selectedMetadataTags } =
    useContext(BrandsContext);
  const searchQuery = searchParams.get(SearchParams.QUERY_DISCOVER);
  const [currentSearchParams, setCurrentSearchParams] =
    useState<BentoBrandSearchParameters>({
      query: "",
      sort: Sort.Recommended,
    });

  const { topLevelCategories, categoriesById } = useContext(
    BentoCategoriesContext,
  );
  const [templateErrorMesage, setTemplateErrorMessage] = useState("");

  const [warning, setWarning] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [runLoading, setRunLoading] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState<Template[]>([]);

  const [automation, setAutomation] = useState<Automation>({
    count: 20,
    dayOfWeek: 0,
    options: {
      collection_option: "none",
      collection_id: 0,
    },
    searchParameters: {
      query: "",
      sort: Sort.Recommended,
    },
  });

  useEffect(() => {
    const converted = convertFilterParamsToQuery(
      topLevelCategories,
      categoriesById,
      discoverFilterParams,
      selectedMetadataTags,
      searchQuery || "",
    );
    if (editAutomation) {
      setAutomation(editAutomation);
      setCurrentSearchParams(converted);
    } else {
      setAutomation((prev) => {
        const copy = makeDeepCopy(prev);
        copy["searchParameters"] = converted;
        return copy;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;

    if (name === "count" && (value < 0 || value > 50)) {
      setWarning("You can only pick between 0 or 50 brands");
    } else if (name === "count") {
      setWarning("");
    }

    setAutomation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeCollection = (e: CustomEvent) => {
    const { value } = e.target;
    let collectionOption: "none" | "new" | "existing" = "none";
    if (value === 0) {
      collectionOption = "none";
    } else if (value === -1) {
      collectionOption = "new";
    } else {
      collectionOption = "existing";
    }
    setAutomation((prev) => ({
      ...prev,
      options: {
        collection_id: value,
        collection_option: collectionOption,
      },
    }));
  };

  const handleSave = async () => {
    setLoading(true);

    const body = makeDeepCopy(automation);
    if (body?.options?.collection_id === -1) {
      body.options.collection_id = null;
    }
    if (body.searchParameters.category_ids) {
      body.searchParameters.category_ids =
        body.searchParameters.category_ids.map((v: string) => parseInt(v));
    }
    if (selectedTemplates?.length > 0) {
      body.templateIds = selectedTemplates.map((x) => x.id);
    }
    try {
      const res = await fetcherAuth(
        getToken,
        automation?.id
          ? `/api/organization/${currentOrg?.id}/automations/${automation.id}`
          : `/api/organization/${currentOrg?.id}/automations`,
        automation?.id ? "PUT" : "POST",
        {},
        body,
      );
      setAutomations((prev) => {
        const copy = makeDeepCopy(prev);
        const index = copy?.findIndex(
          (x: Automation) => x.id === res.automation?.id,
        );
        if (index === -1) {
          copy.push(res.automation);
        } else {
          copy[index] = res.automation;
        }
        return copy;
      });
      setAlert("Successfully updated your automation", "success");
      setTemplateErrorMessage("");
      if (!automation?.id) {
        handleClose();
      }
    } catch (error) {
      if (error?.message?.includes("contains templating characters")) {
        setTemplateErrorMessage(error?.message);
      } else {
        setErrorAlert(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!automation?.id) return;
    setDeleteLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/automations/${automation?.id}`,
        "DELETE",
      );
      setAutomations((prev) => prev.filter((x) => x.id !== automation?.id));
      handleClose();
      setAlert("Successfully deleted automation", "success");
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleReplace = () => {
    setAutomation((prev) => ({
      ...prev,
      searchParameters: currentSearchParams,
    }));
  };

  const handleRunAutomation = async () => {
    setRunLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/automations/${automation?.id}/run`,
        "POST",
      );
      setAlert("Successfully ran automation.", "success");
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setRunLoading(false);
    }
  };

  const fetchAutomationEmailSetting = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/automations/${automation?.id}/email-setting`,
        "GET",
      );
      if (res.templates && res.templates?.length > 0) {
        setSelectedTemplates(res.templates);
      }
    } catch (error) {
      setErrorAlert(error);
    }
  };

  useEffect(() => {
    if (editAutomation && automation?.id) {
      fetchAutomationEmailSetting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAutomation, automation?.id]);

  const selectedCollectionId =
    automation.options.collection_id === undefined ||
    automation.options.collection_id === null
      ? -1
      : automation.options.collection_id;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      fullScreen={isMobileScreen}
    >
      <DialogTitle>
        <IconButton sx={styles.icon} onClick={handleClose}>
          <Box component="i" className="fa-regular fa-arrow-left" />
        </IconButton>
        {automation?.id ? "Edit Automation" : "Create Automated Search"}
      </DialogTitle>

      <DialogContent>
        Bento will generate a list of brands based on these customizations.
        <Typography sx={{ mt: 2, fontSize: 16 }}>
          <strong>Set Customizations</strong>
        </Typography>
        <Grid container item xs={12} sx={{ my: 1 }} gap={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" fullWidth>
              <FormHelperText>Run this search on: </FormHelperText>
              <Select
                onChange={handleChange}
                label=""
                value={automation.dayOfWeek}
                name="dayOfWeek"
              >
                {daysOfWeek?.map((option: string, index: number) => (
                  <MenuItem key={index} value={index}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md>
            <FormHelperText error={!!warning}>
              Number of brands to find:{" "}
            </FormHelperText>
            <TextField
              variant="standard"
              type="number"
              size="small"
              value={automation.count}
              fullWidth
              name="count"
              onChange={handleChange}
            />
            <FormHelperText sx={{ color: "error.main" }}>
              {warning}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" fullWidth>
              <FormHelperText>Store results on: </FormHelperText>
              <Select
                label=""
                value={selectedCollectionId}
                onChange={handleChangeCollection}
              >
                {Object.values(collections).map(
                  (option: SavedBrandCollection, index: number) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ),
                )}
                <MenuItem value={0}>All Saved</MenuItem>
                <MenuItem value={-1}>
                  <em>New Collection (to be created)</em>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <AutomationParams
          categoriesById={categoriesById}
          searchParameters={automation.searchParameters}
          handleReplace={handleReplace}
        />
        {(searchQuery || hasTags) &&
          automation?.id &&
          automation?.id > 0 &&
          !isEqualParams(automation.searchParameters, currentSearchParams) && (
            <AutomationParams
              categoriesById={categoriesById}
              searchParameters={currentSearchParams}
              isDashboardSearch={true}
              handleReplace={handleReplace}
            />
          )}
        <Typography sx={{ mt: 2, mb: 1, fontSize: 16 }}>
          <strong>Templates for this automation: </strong>
        </Typography>
        <FormHelperText sx={{ color: "error.main" }}>
          <em>{templateErrorMesage}</em>
        </FormHelperText>
        <TemplateScreen
          templates={selectedTemplates}
          setTemplates={setSelectedTemplates}
        />
      </DialogContent>

      <DialogActions>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid container item xs={3}>
            {automation?.id && automation?.id > 0 && (
              <>
                <LoadingButton
                  loading={deleteLoading}
                  disableElevation
                  onClick={handleDelete}
                  size="small"
                  sx={{ color: "error.main" }}
                >
                  Delete
                </LoadingButton>

                <LoadingButton
                  disableElevation
                  size="small"
                  onClick={handleRunAutomation}
                  loading={runLoading}
                >
                  Run Automation
                </LoadingButton>
              </>
            )}
          </Grid>

          <Grid container item xs={9} justifyContent="flex-end">
            <Button size="small" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              onClick={handleSave}
              loading={loading}
              disableElevation
              variant="contained"
              size="small"
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAutomationDialog;
