import { Box } from "@mui/material";
import { CompanyType } from "schemas/dashboard";

import styles from "./styles";

export const companyTypeIcons = {
  [CompanyType.NotContactedThisWeek]: (
    <Box
      component="i"
      sx={[styles.icon, styles.star]}
      className="fa-solid fa-star"
    />
  ),
  [CompanyType.VerifiedPaidPartnership]: (
    <Box
      component="i"
      sx={[styles.icon, styles.paidPartnershipBadge]}
      className="fa-solid fa-briefcase"
    />
  ),
  [CompanyType.WorksWithUgc]: (
    <Box
      component="i"
      className="fa-solid fa-tag"
      sx={[styles.icon, styles.worksWithUgcBadge]}
    />
  ),
  [CompanyType.WorksWithInfluencer]: (
    <Box
      component="i"
      className="fa-solid fa-hashtag"
      sx={[styles.icon, styles.worksWithInfluencerBadge]}
    />
  ),
  [CompanyType.RunsTiktokAds]: (
    <Box
      component="i"
      className="fa-brands fa-tiktok"
      sx={[styles.icon, styles.tiktok]}
    />
  ),
  [CompanyType.NonGenericEmails]: (
    <Box
      component="i"
      className="fa-solid fa-user"
      sx={[styles.icon, styles.nonGeneric]}
    />
  ),
};
