import { Box, Typography } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext } from "react";
import { BentoCategory } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { makeDeepCopy } from "utils/updateLocalState";

import DetailedCategoryFormComponent from "./FormComponent";

interface Props {
  setNeedsUpdateCategory: React.Dispatch<React.SetStateAction<boolean>>;
  topLevelCategories: BentoCategory[];
}

const DetailedCategoryForm = ({
  setNeedsUpdateCategory,
  topLevelCategories,
}: Props) => {
  const { currentOrg, setCurrentOrg } = useContext(OrganizationUserContext);

  const isSelected = (chip: BentoCategory) => {
    return Boolean(currentOrg?.bentoCategories?.some((x) => x.id === chip.id));
  };

  const handleSelectAll = (e: CustomEvent, category: BentoCategory) => {
    e.stopPropagation();
    setNeedsUpdateCategory(true);
    const { checked } = e.target;
    if (checked) {
      setCurrentOrg((prev) => {
        const copy = makeDeepCopy(prev);
        if (category?.subcategories) {
          for (const subcategory of category?.subcategories) {
            copy.bentoCategories.push(subcategory);
          }
        }
        copy.bentoCategories.push(category);
        return copy;
      });
    } else {
      let ids = [category.id];
      if (category?.subcategories) {
        ids = ids.concat(category?.subcategories?.map((x) => x.id));
      }
      setCurrentOrg((prev) => {
        if (prev) {
          const copy = makeDeepCopy(prev);
          copy.bentoCategories = copy.bentoCategories.filter(
            (x: BentoCategory) => !ids.includes(x.id),
          );
          return copy;
        }
        return prev;
      });
    }
  };

  const selectChip = (chip: BentoCategory) => {
    setNeedsUpdateCategory(true);
    setCurrentOrg((prev) => {
      const copy = makeDeepCopy(prev);
      const index = copy.bentoCategories.findIndex(
        (x: BentoCategory) => x.id === chip.id,
      );
      if (index > -1) {
        copy.bentoCategories.splice(index, 1);
      } else {
        copy.bentoCategories.push(chip);
      }
      return copy;
    });
  };

  const expandCategory = (category: BentoCategory) => {
    let ids = [category.id];
    if (category?.subcategories) {
      ids = ids.concat(category?.subcategories?.map((x) => x.id));
    }
    const userSelectedIds = currentOrg?.bentoCategories?.map((x) => x.id) || [];
    return Boolean(
      ids.some((categoryId) => userSelectedIds.includes(categoryId)),
    );
  };

  const isSelectAll = (category: BentoCategory) => {
    const subcategoryIds = category?.subcategories?.map((x) => x.id) || [];
    const allIds = [category.id, ...subcategoryIds];
    const userSelectedIds = currentOrg?.bentoCategories?.map((x) => x.id) || [];
    return allIds.every((categoryId) => userSelectedIds.includes(categoryId));
  };

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center", mt: 2 }}>
        <Box
          component="i"
          color="secondary.dark"
          sx={{ pr: 2 }}
          className="fa-solid fa-layer-group"
        />
        Your Brand Types
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ textAlign: "center", mb: 3 }}
      >
        What are your preferred types of brands to work with? *
      </Typography>

      <DetailedCategoryFormComponent
        topLevelCategories={topLevelCategories}
        expandCategory={expandCategory}
        isSelectAll={isSelectAll}
        handleSelectAll={handleSelectAll}
        isSelected={isSelected}
        selectChip={selectChip}
      />
    </>
  );
};

export default DetailedCategoryForm;
