import { grey } from "@mui/material/colors";

const styles = {
  tableContainer: {
    borderRadius: 2,
    border: "1px solid",
    maxHeight: { xs: "auto", md: "calc(100vh - 350px)" },
    borderColor: grey[200],
  },
  table: {
    maxWidth: { xs: "100%", md: "auto" },
    minWidth: { xs: "100%", md: 650 },
  },
  backdrop: {
    color: "#fff",
    zIndex: 9999,
    position: "absolute",
    borderRadius: 2,
  },
  archivedRow: {
    backgroundColor: grey[100],
  },
};

export default styles;
