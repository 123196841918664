import { BentoBrand, EmailSequence, PreviewMessage } from "schemas/dashboard";

import { Template } from "features/Influencer/ContactList/schema";

export enum EmailSettingType {
  automation = "automation",
  category = "category",
}
export type BulkSendBrandsGroup = {
  [key: string]: {
    emailSequences: EmailSequence[];
    fallbackTemplates: Template[];
    type?: EmailSettingType;
    automationId?: number;
  };
};

export type EmailSequenceBe = {
  bentoBrandId: number;
  sequenceUuid?: string;
  fallbackTemplateIds: number[];
};

export type Preview = {
  bentoBrand: BentoBrand;
  templates: Template[];
  outreachMessages: PreviewMessage[];
};

export enum DialogScreen {
  send_option = "send_option",
  edit_brands_list = "edit_brands_list",
  preview_email = "preview_email",
}

export type BentoBrandScoreMap = {
  [key: string]: {
    saved_brand_collection_id?: number;
  };
};

export type BulkSendEmailSetting = {
  templateIds: number[];
  type: EmailSettingType;
  automationId?: number;
  category?: string;
  isUncategorized?: boolean;
};
