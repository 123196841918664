import {
  Avatar,
  Box,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { BrandsContext } from "contexts/Brands";
import { SavedBrandContext } from "contexts/SavedBrand";
import { Dispatch, SetStateAction, useContext } from "react";
import { BentoBrand, EmailSequence, SendOption } from "schemas/dashboard";

import { BulkSendBrandsGroup } from "components/BulkSendDialog/schema";
import { getSourceLabel } from "utils/influencer";
import { ON_BEHALF_OF_TOKEN } from "utils/localStorage";
import { generateInitials } from "utils/string";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import MovingOption from "./MovingOption";
import styles from "./styles";

interface Props {
  bentoBrands: BentoBrand[];
  setCategories: Dispatch<SetStateAction<BulkSendBrandsGroup>>;
  category: string;
  removeFromBulkBrands: (bentoBrand: BentoBrand) => void;
  sendOption?: SendOption;
  categories: BulkSendBrandsGroup;
}

const EditBrandList = ({
  bentoBrands,
  setCategories,
  category,
  removeFromBulkBrands,
  sendOption,
  categories,
}: Props) => {
  const { handleRemoveDebounced } = useContext(SavedBrandContext);
  const { requestRecategorization } = useContext(BrandsContext);

  const isAdmin = Boolean(sessionStorage.getItem(ON_BEHALF_OF_TOKEN));

  const handleDelete = (bentoBrand: BentoBrand) => {
    setCategories((prev) => {
      const copy = makeDeepCopy(prev);
      const index = copy[category].emailSequences.findIndex(
        (es: EmailSequence) => es.bentoBrand.id === bentoBrand.id,
      );
      if (index > -1) {
        copy[category].emailSequences.splice(index, 1);
      }

      return copy;
    });
    // Remove from the selected bulk schedule on the sidebar
    removeFromBulkBrands(bentoBrand);
    // Archive on BE so it will not be add to automation again
    if (bentoBrand?.isAdmin || bentoBrand?.createdByAutomation) {
      handleRemoveDebounced(bentoBrand, 0);
    }
  };

  const goToInstagram = (bentoBrand: BentoBrand) => {
    trackEvent("Instagram Link Viewed", {
      "Instagram Username": bentoBrand.instagramUsername,
    });
    window.open(
      `https://instagram.com/${bentoBrand.instagramUsername}`,
      "_blank",
    );
  };

  const handleRecategorize = async (bentoBrand: BentoBrand) => {
    requestRecategorization(bentoBrand.id);
  };

  const renderRow = (bentoBrand: BentoBrand) => {
    return (
      <Box sx={styles.brandContainer} key={bentoBrand.id}>
        <IconButton
          onClick={() => handleDelete(bentoBrand)}
          sx={styles.removeButton}
        >
          <Box component="i" className="fa-regular fa-circle-xmark" />
        </IconButton>

        <Box sx={styles.brandImage}>
          {bentoBrand.logoUrl ? (
            <img
              src={bentoBrand.logoUrl}
              alt={`${bentoBrand.brandName} logo`}
            />
          ) : (
            <Avatar sx={styles.avatar}>
              {generateInitials(bentoBrand.brandName)}
            </Avatar>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={styles.brandName}>{bentoBrand.brandName}</Box>

          {bentoBrand.instagramUsername && (
            <Link
              target="_blank"
              onClick={() => goToInstagram(bentoBrand)}
              sx={{ fontSize: 12 }}
            >
              @{bentoBrand.instagramUsername}
            </Link>
          )}

          <Grid item xs={12}>
            <FormHelperText sx={{ color: "secondary.main" }}>
              {getSourceLabel(bentoBrand)}
            </FormHelperText>
          </Grid>
        </Box>

        {isAdmin && (
          <Tooltip title="Request recategorization">
            <IconButton
              sx={{ fontSize: 12 }}
              onClick={(e) => handleRecategorize(bentoBrand)}
            >
              <Box component="i" className="fa-regular fa-refresh" />
            </IconButton>
          </Tooltip>
        )}

        <Box>
          <MovingOption
            sendOption={sendOption}
            category={category}
            setCategories={setCategories}
            bentoBrand={bentoBrand}
            categories={categories}
          />
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Typography>
        You can remove a brand from the list to not include it in bulk send or
        move it to a different list.
      </Typography>
      {bentoBrands?.map((bentoBrand) => renderRow(bentoBrand))}
    </>
  );
};

export default EditBrandList;
