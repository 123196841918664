import { BentoBrand } from "schemas/dashboard";

import { Template } from "features/Influencer/ContactList/schema";
import {
  TemplateContent,
  TemplateGoal,
  TemplateHighlight,
  TemplateTone,
} from "features/Influencer/Templates/TemplatesTable/CreateTemplate/PresetFlow/schema";

import { titleCase } from "./string";

export const UNCATEGORIZED = "Uncategorized";

export const getContents = () => {
  const influencer = {
    key: TemplateContent.INFLUENCER,
    value: "Influencer Content",
  };
  const ugc = {
    key: TemplateContent.UGC,
    value: "UGC Content",
  };
  return [influencer, ugc];
};

export const getGoals = () => {
  const paid = {
    key: TemplateGoal.PAID,
    value: "Paid collaborations",
  };
  const gift = {
    key: TemplateGoal.GIFT,
    value: "Gifted opportunities",
  };
  return [paid, gift];
};

export const getHighlights = (isUgcCreator: boolean, isInfluencer: boolean) => {
  const portfolio = {
    key: TemplateHighlight.PORTFOLIO,
    value: "Your portfolio",
    icon: "fa-solid fa-share-nodes",
  };
  const caseStudy = {
    key: TemplateHighlight.CASE_STUDY,
    value: "A successful past partnership",
    icon: "fa-solid fa-thumbs-up",
  };
  const namedBrands = {
    key: TemplateHighlight.PAST_PARTNERSHIPS,
    value: "Brands you've worked with",
    icon: "fa-solid fa-star",
  };
  const engagement = {
    key: TemplateHighlight.ENGAGEMENT_NUMBERS,
    value: "Your engagement numbers or following count",
    icon: "fa-solid fa-hashtag",
  };

  if (isUgcCreator && !isInfluencer) {
    return [portfolio, caseStudy, namedBrands];
  } else {
    return [portfolio, caseStudy, namedBrands, engagement];
  }
};

export const getTones = () => {
  return [
    {
      key: TemplateTone.UPBEAT,
      value: "Upbeat & Sweet",
    },
    {
      key: TemplateTone.CASUAL,
      value: "Casual & Friendly",
    },
    {
      key: TemplateTone.PROFESSIONAL,
      value: "Professional & Thorough",
    },
  ];
};

export const sortTemplates = (
  templates: Template[],
  defaultTemplates: Template[],
) => {
  let fetchedTemplates = templates;
  if (!templates?.[0]?.lastUsedAt) {
    // TODO: remove this when most templates have `lastUsedAt` field
    const defaultIds = defaultTemplates.map((x: Template) => x.id);
    const nonDefaults = fetchedTemplates.filter(
      (x: Template) => !defaultIds.includes(x.id),
    );
    const defaults = fetchedTemplates.filter((x: Template) =>
      defaultIds.includes(x.id),
    );
    fetchedTemplates = [...defaults, ...nonDefaults];
  }
  return fetchedTemplates;
};

export const getCategoryName = (brand: BentoBrand) => {
  if (brand?.mainCategoryNames && brand?.mainCategoryNames.length > 0) {
    return titleCase(brand.mainCategoryNames?.join(", "));
  } else if (
    brand?.secondaryCategoryNames &&
    brand?.secondaryCategoryNames.length > 0
  ) {
    return titleCase(brand?.secondaryCategoryNames?.join(", "));
  } else {
    return UNCATEGORIZED;
  }
};
