import { Box, Button, Grid, Link, Tooltip } from "@mui/material";
import { QuickSendContext } from "contexts/QuickSend";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useState } from "react";
import { BentoContact, NewBentoContact } from "schemas/dashboard";
import { SetAlertType } from "schemas/functions";

import { getScoreColor } from "utils/color";
import { trackEvent } from "utils/tracking";
import { usePageSize } from "utils/usePageSize";

import ViewScoreDialog from "./ViewScoreDialog";
import { ReplyScoreLabel } from "./helpers";
import styles from "./styles";

interface ContactScoreProps {
  contacts?: (BentoContact | NewBentoContact)[];
  setAlert: SetAlertType;
}

export default function ContactScore({
  contacts,
  setAlert,
}: ContactScoreProps) {
  const { isOnboardingPage } = usePageSize();
  const [openViewScore, setOpenViewScore] = useState(false);
  const { bentoContact } = useContext(QuickSendContext);

  const { subscription, isVaPlan, setUpgradeDialogSource } =
    useContext(SubscriptionContext);
  const displayScore = subscription?.isSubscribed && isVaPlan;

  const handleViewScore = () => {
    trackEvent("View Score Button Clicked");
    setOpenViewScore(true);
  };

  const renderToolTip = (contact: BentoContact | NewBentoContact) => {
    const score = (contact as BentoContact)?.score;
    if (!score) {
      return;
    }
    return displayScore
      ? `Reply Score: ${ReplyScoreLabel(score || 0)}`
      : "To view how likely this contact will respond to you, click on the View Score button below.";
  };

  const renderScoreAnnotation = () => {
    if (displayScore && bentoContact) {
      return (
        <Tooltip title={renderToolTip(bentoContact)}>
          <div>
            <Box
              sx={{ color: getScoreColor(bentoContact?.score || 0), ml: 0.5 }}
            >
              {bentoContact?.score}{" "}
              <Box component="i" className="fa-regular fa-circle-info" />
            </Box>
          </div>
        </Tooltip>
      );
    } else if (bentoContact?.sourcedFromUserInbox) {
      return (
        <Tooltip
          title={
            "This is an exclusive contact (who are 2-3 times more likely to respond). Upgrade to email."
          }
          enterTouchDelay={0}
        >
          <div>
            <Button
              onClick={() => setUpgradeDialogSource("Exclusive Contact")}
              size="small"
              sx={{ textTransform: "none", fontSize: 12, color: "info.main" }}
            >
              Exclusive 💎
            </Button>
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          enterTouchDelay={0}
          title="View how likely this contact will respond to you with Bento's 'Reply Score' feature. Upgrade to unlock"
        >
          <Link sx={styles.viewScore} onClick={handleViewScore}>
            Reply Score
          </Link>
        </Tooltip>
      );
    }
  };

  return (
    <>
      {contacts &&
        contacts?.length > 0 &&
        bentoContact &&
        !isOnboardingPage &&
        bentoContact?.score !== undefined && (
          <Grid item xs={6} container alignItems="center">
            {renderScoreAnnotation()}
          </Grid>
        )}

      <ViewScoreDialog
        open={openViewScore}
        setAlert={setAlert}
        handleClose={() => setOpenViewScore(false)}
      />
    </>
  );
}
