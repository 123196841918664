import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  BentoCategory,
  Organization,
  OrganizationProfile,
  OrganizationUser,
  VAStatus,
  VaTemplateStatus,
} from "schemas/dashboard";
import { CustomEvent, Map, SetAlertType } from "schemas/functions";
import { OverridePlanName } from "schemas/payments";

import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import AdminLoginAs from "../LoginAs";
import DetailedCategoryForm from "./DetailedCategoryForm";
import styles from "./styles";

interface Props {
  organizationId: number;
  organizationUser: OrganizationUser | null;
  setOrganizationUser: Dispatch<SetStateAction<OrganizationUser | null>>;
  setAlert: SetAlertType;
}

export default function Settings({
  organizationId,
  setAlert,
  organizationUser,
  setOrganizationUser,
}: Props) {
  const { getToken } = useAuth();

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [organization, setOrganization] = useState<Organization | null>(null);
  const [needsUpdateCategory, setNeedsUpdateCategory] = useState(false);

  const [topLevelCategories, setTopLevelCategories] = useState<BentoCategory[]>(
    [],
  );
  const [selectedCategories, setSelectedCategories] = useState<BentoCategory[]>(
    [],
  );

  const [organizationProfile, setOrganizationProfile] =
    useState<OrganizationProfile | null>(null);

  const fetchCategories = async () => {
    setLoading(true);

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/admin/bento-categories`,
        "GET",
      );
      setTopLevelCategories(res.topLevelCategories);
    } catch (error) {
      setAlert(error.message || "Unable to fetch categories", "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchProfile = async (organizationId: number) => {
    setLoading(true);
    if (!organizationId) return;
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/admin/organization-profiles/${organizationId}`,
      );
      setOrganizationUser(res.organizationUser);
      setOrganization(res.organization);
      setOrganizationProfile(res.organizationProfile);
      setSelectedCategories(res.organization.bentoCategories);
    } catch (error) {
      setAlert(
        error?.message ||
          "An error occurred, you do not have access to this page",
        "warning",
      );
    } finally {
      setLoading(false);
    }
  };

  const onChangeOrgUser = (name: string, value: any) => {
    setOrganizationUser((prevOrganizationUser) => {
      if (prevOrganizationUser) {
        return {
          ...prevOrganizationUser,
          [name]: value,
        };
      } else return prevOrganizationUser;
    });
  };

  const onChangeOrg = (name: string, value: any) => {
    setOrganization((prevOrganization: Organization | null) => {
      const copy = makeDeepCopy(prevOrganization);
      if (
        (name === "overridePlanName" || name === "vaTemplateStatus") &&
        value === ""
      ) {
        delete copy[name];
      } else {
        copy[name] = value;
      }
      return copy;
    });
  };

  const handleSendVaTemplateApprovalEmail = async () => {
    setLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/admin/organizations/${organizationId}/send-va-template-approval-email`,
        "POST",
      );
      setAlert("Email have been sent to the user", "success");
      setOrganization((prevOrganization: Organization | null) => {
        const copy = makeDeepCopy(prevOrganization);
        copy.vaTemplateStatus = VaTemplateStatus.pending;
        return copy;
      });
    } catch (error) {
      setAlert(error.message || "Unable to fetch categories", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!organization) return;

    setSaveLoading(true);

    try {
      if (organizationUser) {
        await fetcherAuth(
          getToken,
          `/api/admin/organization-users/${organizationUser.id}`,
          "PUT",
          {},
          {
            doNotEmail: organizationUser.doNotEmail,
          },
        );
      }

      if (organization) {
        const body: Map = {};
        if (organization.vaStatus) {
          body.vaStatus = organization.vaStatus;
        }
        body.overridePlanName = organization?.overridePlanName;
        body.vaTemplateStatus = organization?.vaTemplateStatus;
        await fetcherAuth(
          getToken,
          `/api/admin/organizations/${organization.id}`,
          "PUT",
          {},
          body,
        );
      }
      if (organization && !organization?.vaStatus) {
        await fetcherAuth(
          getToken,
          `/api/admin/organizations/${organization.id}/remove-va-status`,
          "PUT",
        );
      }

      if (needsUpdateCategory) {
        await fetcherAuth(
          getToken,
          `/api/admin/organizations/${organization.id}/bento-categories`,
          "PUT",
          {},
          {
            categoryIds: selectedCategories?.map((x) => x.id),
          },
        );
      }
      setAlert("Profile saved", "success");
    } catch (error) {
      if (error?.message.includes("Google integration")) {
        setAlert(
          "Cannot approve VA user yet. User has not granted Gmail permissions to read / send email",
          "error",
        );
        setOrganization((prevOrganization: Organization | null) => {
          if (prevOrganization) {
            return {
              ...prevOrganization,
              vaStatus: VAStatus.pending,
            };
          } else return prevOrganization;
        });
        return;
      }
      setAlert(
        error.message || "An error occurred when updating profile",
        "warning",
      );
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile(organizationId);
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const emailVaTemplateSent =
    organization?.vaTemplateStatus === VaTemplateStatus.approved ||
    organization?.vaTemplateStatus === VaTemplateStatus.pending;

  return (
    <Box sx={{ py: 2 }}>
      <Grid container sx={{ mb: 2 }} alignItems="center" gap={1}>
        Email: {organizationUser?.email} | {organizationUser?.organizationId}
        <AdminLoginAs
          organizationId={Number(organizationId)}
          setAlert={setAlert}
        />
        <Grid item xs={12}>
          {organizationProfile?.monthlyIncome &&
          organizationProfile?.monthlyIncome?.length === 2 ? (
            <>
              Monthly Income: ${organizationProfile?.monthlyIncome?.[0]} - $
              {organizationProfile?.monthlyIncome?.[1]}
            </>
          ) : (
            <>Monthly Income: Not Provided</>
          )}
        </Grid>
      </Grid>

      <Divider sx={{ my: 2, width: 400 }} />

      <Typography variant="h6">Update Information</Typography>
      {organizationUser && (
        <Grid container gap={2}>
          <Grid item xs={12}>
            Do Not Email:{" "}
            <Switch
              checked={organizationUser.doNotEmail}
              onChange={(e: CustomEvent) =>
                onChangeOrgUser("doNotEmail", e.target.checked)
              }
              disabled={loading}
              name="doNotEmail"
            />
            {organizationUser.doNotEmail
              ? "User will not receive emails"
              : "User will receive emails"}
          </Grid>

          <Typography variant="h6">Virtual Assistant Settings</Typography>

          <Grid item xs={12} container alignItems="center" gap={1}>
            <Box sx={styles.label}>Virtual Assistant Status:</Box>
            <Select
              value={organization?.vaStatus || ""}
              disabled={loading}
              sx={{ minWidth: 200, ml: 2 }}
              onChange={(e) => onChangeOrg("vaStatus", e.target.value)}
              size="small"
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={VAStatus.pending}>Pending</MenuItem>
              <MenuItem value={VAStatus.approved}>Approved</MenuItem>
              <MenuItem value={VAStatus.disqualified}>Disqualified</MenuItem>
              <MenuItem value={VAStatus.not_interested}>
                Not Interested
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} container alignItems="center" gap={1}>
            <Box sx={styles.label}>Override Plan Name:</Box>
            <Select
              value={organization?.overridePlanName || ""}
              disabled={loading}
              sx={{ minWidth: 200, ml: 2 }}
              onChange={(e) => onChangeOrg("overridePlanName", e.target.value)}
              size="small"
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={OverridePlanName.STARTER}>Starter</MenuItem>
              <MenuItem value={OverridePlanName.PRO}>Pro</MenuItem>
              <MenuItem value={OverridePlanName.VA}>Virtual Assistant</MenuItem>
            </Select>
          </Grid>

          <Grid container item xs={12} alignItems="center" gap={1}>
            <Box sx={styles.label}>VA Template Status: </Box>
            <Select
              value={organization?.vaTemplateStatus || ""}
              disabled={loading}
              sx={{ minWidth: 200, ml: 2 }}
              onChange={(e) => onChangeOrg("vaTemplateStatus", e.target.value)}
              size="small"
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={VaTemplateStatus.pending}>Pending</MenuItem>
              <MenuItem value={VaTemplateStatus.approved}>Approved</MenuItem>
            </Select>

            <Grid>
              <LoadingButton
                disableElevation
                size="small"
                onClick={handleSendVaTemplateApprovalEmail}
                disabled={emailVaTemplateSent}
                loading={loading || saveLoading}
                variant="outlined"
                sx={{ textTransform: "none" }}
              >
                VA Templates Ready for Approval{" "}
                <Box
                  sx={{ ml: 1 }}
                  component="i"
                  className="fa-regular fa-envelope"
                />
              </LoadingButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 3 }} />
            <Typography variant="h6">Categories</Typography>

            {loading || topLevelCategories?.length === 0 ? (
              <CircularProgress />
            ) : (
              <DetailedCategoryForm
                topLevelCategories={topLevelCategories}
                setNeedsUpdateCategory={setNeedsUpdateCategory}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
              />
            )}
          </Grid>

          <LoadingButton
            variant="contained"
            onClick={handleSave}
            loading={saveLoading}
            disabled={loading}
          >
            Save
          </LoadingButton>
        </Grid>
      )}
    </Box>
  );
}
