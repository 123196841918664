import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext } from "react";
import theme from "styles/theme";

import { AddOnDialogSource } from "features/Settings/AddOns/schema";
import { DISPLAYED_ADD_ON_DIALOG } from "utils/localStorage";

interface AddOnFeatureDialogProps {
  open: boolean;
  handleClose: (city?: string) => void;
}

export default function AddOnFeatureDialog({
  open,
  handleClose,
}: AddOnFeatureDialogProps) {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setAddOnDialogSource } = useContext(SubscriptionContext);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const redirectToAddOn = () => {
    setAddOnDialogSource(AddOnDialogSource.feature_announcement);
    localStorage.setItem(
      `${DISPLAYED_ADD_ON_DIALOG}-${currentOrg?.id}`,
      "true",
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullScreen={isMobileScreen}
    >
      <DialogTitle>
        <Grid container justifyContent="center">
          Get a Pitch Written For You
        </Grid>
      </DialogTitle>
      <DialogContent>
        Just dropped 🔥 Get a custom pitch written for you by professional
        (human) copywriters to help you land more deals.
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="secondary">
          Close
        </Button>
        <Button variant="contained" disableElevation onClick={redirectToAddOn}>
          Buy Now
        </Button>
      </DialogActions>
    </Dialog>
  );
}
