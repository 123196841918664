import { VaBackgroundDetailsField } from "schemas/dashboard";

export const DefaultBackgroundQuestions = {
  activity_alongside_content: {
    question:
      "What do you do alongside creating content or do you do this full-time? Elaborate.",
    answer: "",
    field_name: VaBackgroundDetailsField.activity_alongside_content,
  },
  activity_before_content: {
    question:
      "What were you doing before content and how did you get into creating content?",
    answer: "",
    field_name: VaBackgroundDetailsField.activity_before_content,
  },
  why_brands_like_your_content: {
    question:
      "Why do brands like your content? Share what you bring to the table.",
    answer: "",
    field_name: VaBackgroundDetailsField.why_brands_like_your_content,
  },
  biggest_brand_names: {
    question:
      "What are the biggest brand names you have partnered with in the past?",
    answer: "",
    field_name: VaBackgroundDetailsField.biggest_brand_names,
  },
  most_proud_partnerships: {
    question: "What past partnerships of yours are you most proud of and why?",
    answer: "",
    field_name: VaBackgroundDetailsField.most_proud_partnerships,
  },
  other: {
    question:
      "Anything else you would like to share? Share anything that would help us write unique and curated pitches for you.",
    answer: "",
    field_name: VaBackgroundDetailsField.other,
  },
};

export const TemplateBackgroundQuestions = {
  activity_alongside_content: {
    question:
      "Share anything about your past experience (e.g. education, work experience and/or side hobbies) that has helped you create high quality content.",
    answer: "",
    field_name: VaBackgroundDetailsField.activity_alongside_content,
  },
  why_brands_like_your_content: {
    question:
      "Why do brands like your content? Share what you bring to the table.",
    answer: "",
    field_name: VaBackgroundDetailsField.why_brands_like_your_content,
  },
  most_proud_partnerships: {
    question: "What past partnerships of yours are you most proud of and why?",
    answer: "",
    field_name: VaBackgroundDetailsField.most_proud_partnerships,
  },
  other: {
    question:
      "Anything else you would like to share? Share anything that would help us write unique and curated pitches for you.",
    answer: "",
    field_name: VaBackgroundDetailsField.other,
  },
};
