import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext } from "react";
import { Map } from "schemas/functions";
import { DisplayName, Plans } from "schemas/payments";

import { TERMS_AND_CONDITIONS_LINK } from "constants/influencer";
import { trackEvent } from "utils/tracking";

interface ManageDialogProps {
  plan: Plans;
  open: boolean;
  loading: boolean;
  onClose: () => void;
  openCancel: () => void;
  handleCheckout: (
    plan: Plans,
    flow?: string,
    openTarget?: "_self" | "_blank",
  ) => void;
  allLoading?: Map;
}

export default function ManageDialog({
  plan,
  open,
  onClose,
  handleCheckout,
  openCancel,
  loading,
  allLoading,
}: ManageDialogProps) {
  const { subscription, plans, isStarterPlan } =
    useContext(SubscriptionContext);

  const isStarterOnProPlan =
    isStarterPlan && (plan === Plans.PRO || plan === Plans.PRO_YEARLY);

  const getUpdatePlan = () => {
    if (plan === Plans.PRO) {
      return Plans.PRO_YEARLY;
    } else {
      return Plans.VA_YEARLY;
    }
  };

  const getPriceDiscount = () => {
    if (!plans) return <></>;
    return (
      <Box component="span" sx={{ color: "info.main" }}>
        (${plans[Plans.PRO_YEARLY]?.price}/month)
      </Box>
    );
  };

  const trackTermsAndCondition = () => {
    trackEvent("Terms and Conditions for VA Plan Clicked");
  };

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <IconButton
        sx={{
          color: "primary.main",
          fontSize: 14,
          position: "absolute",
          right: 10,
          top: 10,
        }}
        onClick={onClose}
      >
        <Box component="i" className="fa-regular fa-xmark" />
      </IconButton>
      <DialogTitle>Manage Current Plan</DialogTitle>
      <DialogContent>
        <Typography paragraph>
          You are currently on the <strong>{DisplayName[plan]}</strong> plan{" "}
          {isStarterOnProPlan ? "(we moved you from Starter to Pro)" : ""}. Use
          the link below to change your current plan, modify your payment
          method, and see invoice history.
        </Typography>

        <LoadingButton
          loading={
            loading ||
            (allLoading && isStarterOnProPlan && allLoading[Plans.STARTER])
          }
          variant="outlined"
          onClick={() => {
            handleCheckout(
              isStarterOnProPlan && subscription?.subscription?.planName
                ? subscription?.subscription?.planName
                : plan,
              undefined,
            );
          }}
        >
          Manage Plan
        </LoadingButton>

        {plan === Plans.PRO && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" component="div">
              Get Annual Discount {getPriceDiscount()}
            </Typography>
            <Typography paragraph>
              By switching to yearly, you will save <strong>25%</strong> on your
              monthly payment. Click on the button below to switch to an annual
              subscription.
            </Typography>
            <LoadingButton
              variant="contained"
              onClick={() => {
                handleCheckout(getUpdatePlan(), "subscription_update");
              }}
              disableElevation
              loading={
                allLoading
                  ? allLoading[Plans.STARTER_YEARLY] ||
                    allLoading[Plans.PRO_YEARLY]
                  : false
              }
            >
              Switch
            </LoadingButton>
          </>
        )}
        {plan === Plans.VA && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" component="div">
              Bento's Virtual Assistant service
            </Typography>
            <Typography paragraph>
              Get all the perks of a Virtual Assistant. Plus, subscribe by April
              7th, 11:59pm EST and get a money-back guarantee if you don't earn
              more than you pay. <br />
              <br />
              <a
                href={TERMS_AND_CONDITIONS_LINK}
                target="_blank"
                rel="noopener noreferrer"
                onClick={trackTermsAndCondition}
              >
                Terms & Conditions Apply
              </a>
            </Typography>
            <LoadingButton
              variant="contained"
              onClick={() => {
                handleCheckout(getUpdatePlan(), "subscription_update");
              }}
              disableElevation
              loading={allLoading ? allLoading[Plans.VA_YEARLY] : false}
            >
              Upgrade to Yearly
            </LoadingButton>
          </>
        )}
        {!subscription?.subscription.cancelAt && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" paragraph>
              Cancel Subscription
            </Typography>
            <Typography paragraph>
              By ending your subscription, you will lose access to your{" "}
              <strong>{DisplayName[plan]}</strong> plan benefits.
            </Typography>

            {isStarterOnProPlan && (
              <Alert severity="warning" sx={{ my: 2 }}>
                We currently charge you the same rate as the previous{" "}
                <strong>Starter </strong> tier. If you cancel, the discount will
                also be cancelled.
              </Alert>
            )}

            <Button variant="outlined" color="secondary" onClick={openCancel}>
              Cancel Subscription
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
