import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Backdrop,
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useEffect, useState } from "react";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";

import { AddOnDetails, AddOnName } from "./schema";
import styles from "./styles";

interface Props {
  source?: string;
  grid?: number;
  fromDialog?: boolean;
  validOptions?: string[];
}

const AddOns = ({
  source,
  grid = 4,
  fromDialog = false,
  validOptions = [AddOnName.pitching_templates, AddOnName.support_call],
}: Props) => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setErrorAlert } = useContext(AlertContext);

  const [addOns, setAddOns] = useState<{ [key: string]: AddOnDetails }>({});
  const [loading, setLoading] = useState<AddOnName | null>(null);
  const [quantity, setQuantity] = useState(1);
  const [fetchLoading, setFetchLoading] = useState(false);

  const fetchAddOns = async () => {
    try {
      setFetchLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments-add-ons`,
      );
      setAddOns(res);
    } catch (error) {
      setErrorAlert(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleChangeQuantity = (e: CustomEvent) => {
    const quantity = Number(e.target.value);
    setQuantity(quantity);
  };

  const createSessionCheckoutLink = async (addOnName: AddOnName) => {
    setLoading(addOnName);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments-add-ons/checkout`,
        "POST",
        {},
        {
          itemName: addOnName,
          quantity,
          source,
          pageSource: window.location.pathname,
        },
      );
      window.open(res.session.url, "_self");
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(null);
    }
  };

  const renderCheckoutButton = (key: AddOnName) => {
    return (
      <LoadingButton
        loading={loading === key}
        variant="contained"
        sx={{ mt: 4 }}
        disableElevation
        onClick={() => createSessionCheckoutLink(key)}
        fullWidth
        type="submit"
      >
        Checkout
      </LoadingButton>
    );
  };

  useEffect(() => {
    if (currentOrg) {
      fetchAddOns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  const getDescription = (key: AddOnName) => {
    if (key === AddOnName.support_call) {
      return (
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {addOns[key].display_name} <br />$
            {Number(addOns[key].price).toFixed(2)}
          </Typography>

          <li>Review your pitch email and provide suggestions</li>
          <li>
            Brainstorm the right pitching strategy for you (e.g. the best niche
            for you, your follow up strategy etc.)
          </li>
          <li>
            Do a walk through of Bento's platform and share best practices
          </li>
          <li>Help you negotiate a current deal you have</li>
        </Box>
      );
    } else {
      return (
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {addOns[key].display_name} <br />$
            {Number(addOns[key].price).toFixed(2)}/template
          </Typography>

          <Box>
            One of our <strong>professional (human) copywriters</strong> will
            write you a pitch personalized to you. We utilize best practices
            gathered from thousands of pitches sent on Bento to ensure your
            pitch meets industry standards.
          </Box>

          <FormHelperText sx={{ mb: 0.5, mt: 2 }}>
            How many templates do you want us to write?
          </FormHelperText>
          <Select
            sx={styles.whiteSelect}
            onChange={handleChangeQuantity}
            value={quantity}
          >
            {[...Array(10).keys()].map((i) => (
              <MenuItem key={i} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </Select>

          {quantity && (
            <Typography sx={{ mt: 1, fontSize: 12 }}>
              <em>
                You will be charged $
                {Number(quantity * Number(addOns[key].price)).toFixed(2)} USD
              </em>
            </Typography>
          )}
        </Box>
      );
    }
  };

  return fetchLoading ? (
    <Backdrop
      sx={{
        backgroundColor: "#fff",
        zIndex: 9999,
        position: "absolute",
      }}
      open={fetchLoading}
      appear={false}
    >
      <CircularProgress />
    </Backdrop>
  ) : (
    <Grid
      container
      item
      xs={12}
      gap={2}
      justifyContent={fromDialog ? "center" : "flex-left"}
    >
      {validOptions?.map((key) =>
        key in addOns ? (
          <Grid
            item
            xs={12}
            lg={grid}
            sx={styles.container(fromDialog)}
            key={key}
            container
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            {getDescription(key as AddOnName)}
            <Box>{renderCheckoutButton(key as AddOnName)}</Box>
          </Grid>
        ) : (
          <></>
        ),
      )}
    </Grid>
  );
};

export default AddOns;
