import { Box, Button, Grid, Typography } from "@mui/material";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { SubscriptionContext } from "contexts/Subscription";
import { Dispatch, SetStateAction, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";

import AddOnDialog from "features/Settings/AddOns/AddOnsDialog";
import { AddOnDialogSource } from "features/Settings/AddOns/schema";
import { makeDeepCopy } from "utils/updateLocalState";

import { onboardingNavigate } from "../helper";
import styles from "../styles";

interface PitchEmailFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
}

const PitchEmailForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
}: PitchEmailFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const slideContext = useContext(SlideAnimationContext);
  const { setAddOnDialogSource } = useContext(SubscriptionContext);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${onboardingNavigate(location.pathname, onboardingForm, "back")}${
        location.search
      }`,
    );
  };

  const selectOption = (hasPitchEmail: boolean) => {
    const onboardingFormCopy = makeDeepCopy(onboardingForm);
    onboardingFormCopy.hasPitchEmail = hasPitchEmail;
    setOnboardingForm(onboardingFormCopy);
  };

  const handleNext = (e: CustomEvent) => {
    e.preventDefault();
    saveOnboardingForm(onboardingForm, location.pathname);
  };

  return (
    <form onSubmit={handleNext}>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center" }}>
        <Box
          component="i"
          color="secondary.dark"
          sx={{ pr: 2 }}
          className="fa-solid fa-paper-plane"
        />
        Let's get your pitch down!
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 3, textAlign: "center" }}>
        How can I help you?
      </Typography>

      <Grid container justifyContent="center" gap={1}>
        <Grid item xs="auto">
          <Button
            sx={[
              styles.pitchButton,
              onboardingForm.hasPitchEmail === true &&
                styles.selectedPitchButton,
            ]}
            variant="outlined"
            onClick={() => selectOption(true)}
            disableElevation
          >
            <Box
              sx={styles.pitchIcon}
              component="i"
              className="fa-solid fa-file-lines"
            />
            Improve my current pitch
          </Button>
        </Grid>
        <Grid item xs="auto">
          <Button
            sx={[
              styles.pitchButton,
              onboardingForm.hasPitchEmail === false &&
                styles.selectedPitchButton,
            ]}
            variant="outlined"
            onClick={() => selectOption(false)}
            disableElevation
          >
            <Box
              sx={styles.pitchIcon}
              component="i"
              className="fa-solid fa-pen-to-square"
            />
            Help me write a new one
          </Button>
        </Grid>
        <Grid item xs="auto">
          <Button
            sx={[styles.pitchButton]}
            variant="outlined"
            disableElevation
            onClick={() => setAddOnDialogSource(AddOnDialogSource.onboarding)}
          >
            <Box
              sx={styles.pitchIcon}
              component="i"
              className="fa-solid fa-hands"
            />
            Hire a copywriter to write for me
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-start" sx={{ mt: 2 }}>
        <Grid item xs="auto">
          <Button onClick={handleBack} color="secondary" disableElevation>
            Back
          </Button>
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          <Button
            disableElevation
            type="submit"
            variant="contained"
            disabled={
              onboardingForm.hasPitchEmail !== false &&
              onboardingForm.hasPitchEmail !== true
            }
          >
            Next
          </Button>
        </Grid>
      </Grid>

      <AddOnDialog />
    </form>
  );
};

export default PitchEmailForm;
