import { Dispatch, SetStateAction } from "react";
import { BentoCategory } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import DetailedCategoryFormComponent from "features/Influencer/Profile/DetailedCategoryForm/FormComponent";
import { makeDeepCopy } from "utils/updateLocalState";

interface Props {
  setNeedsUpdateCategory: Dispatch<React.SetStateAction<boolean>>;
  topLevelCategories: BentoCategory[];
  selectedCategories: BentoCategory[];
  setSelectedCategories: Dispatch<SetStateAction<BentoCategory[]>>;
}

const DetailedCategoryForm = ({
  setNeedsUpdateCategory,
  topLevelCategories,
  selectedCategories,
  setSelectedCategories,
}: Props) => {
  const isSelected = (chip: BentoCategory) => {
    return selectedCategories?.some((x) => x.id === chip.id);
  };

  const handleSelectAll = (e: CustomEvent, category: BentoCategory) => {
    e.stopPropagation();
    setNeedsUpdateCategory(true);
    const { checked } = e.target;
    if (checked) {
      setSelectedCategories((prev) => {
        const copy = makeDeepCopy(prev);
        if (category?.subcategories) {
          for (const subcategory of category?.subcategories) {
            copy.push(subcategory);
          }
        }
        copy.push(category);
        return copy;
      });
    } else {
      let ids = [category.id];
      if (category?.subcategories) {
        ids = ids.concat(category?.subcategories?.map((x) => x.id));
      }
      setSelectedCategories((prev) => {
        if (prev) {
          let copy = makeDeepCopy(prev);
          copy = copy.filter((x: BentoCategory) => !ids.includes(x.id));
          return copy;
        }
        return prev;
      });
    }
  };

  const selectChip = (chip: BentoCategory) => {
    setNeedsUpdateCategory(true);
    setSelectedCategories((prev) => {
      const copy = makeDeepCopy(prev);
      const index = copy.findIndex((x: BentoCategory) => x.id === chip.id);
      if (index > -1) {
        copy.splice(index, 1);
      } else {
        copy.push(chip);
      }
      return copy;
    });
  };

  const isSelectAll = (category: BentoCategory) => {
    const subcategoryIds = category?.subcategories?.map((x) => x.id) || [];
    const allIds = [category.id, ...subcategoryIds];
    const userSelectedIds = selectedCategories?.map((x) => x.id) || [];
    return allIds.every((categoryId) => userSelectedIds.includes(categoryId));
  };

  const expandCategory = (category: BentoCategory) => {
    let ids = [category.id];
    if (category?.subcategories) {
      ids = ids.concat(category?.subcategories?.map((x) => x.id));
    }
    const userSelectedIds = selectedCategories?.map((x) => x.id) || [];
    return ids.some((categoryId) => userSelectedIds.includes(categoryId));
  };

  return (
    <>
      <DetailedCategoryFormComponent
        topLevelCategories={topLevelCategories}
        expandCategory={expandCategory}
        isSelectAll={isSelectAll}
        handleSelectAll={handleSelectAll}
        isSelected={isSelected}
        selectChip={selectChip}
      />
    </>
  );
};

export default DetailedCategoryForm;
