import {
  Organization,
  VAStatus,
  VaNicheSpecificDetails,
  VaOnboardingType,
  VaOrganizationProfileStatus,
  VaTemplateStatus,
} from "schemas/dashboard";
import { Map } from "schemas/functions";
import { routes } from "schemas/routes";

import {
  CURRENT_VA_STEP,
  HIDE_ONBOARDING_VIDEOS,
  ON_BEHALF_OF_TOKEN,
} from "utils/localStorage";

const NICHES_REQUIRED = 4;

export const redirectToMainDashboard = (
  res: Map,
  currentOrg: Organization | null,
  location: any,
) => {
  // Bypass VA Onboarding route for email-specific links or if they are not VA user.
  const hasOnboarded = res.organization.vaStatus === VAStatus.approved;

  const isInVaOnboardingFlow =
    res.organization.vaStatus === VAStatus.pending ||
    (res.organization.signedUpForVa &&
      res.organization.vaStatus !== VAStatus.not_interested &&
      res.organization.vaStatus !== VAStatus.disqualified);

  if (!isInVaOnboardingFlow || hasOnboarded) {
    return `/${routes.brands}/all${location.search}`;
  }

  const vaTemplateApprovalLink =
    currentOrg?.vaTemplateStatus === VaTemplateStatus.pending;
  if (vaTemplateApprovalLink) {
    return `/${routes.templates}${location.search}`;
  }
};

export const redirectToStep = (
  res: Map,
  grantedEmailAccess: boolean,
  location: any,
) => {
  // Calculate the correct route to return to if user quit VA onboarding midway.
  const currentVaStep = localStorage.getItem(
    `${CURRENT_VA_STEP}-${res.organization.id}`,
  );
  const isSubscribedEmail = window.location.href?.includes(
    `s=email&ref=va_subscribed`,
  );
  const hideOnboardingVideo =
    localStorage.getItem(`${HIDE_ONBOARDING_VIDEOS}-${res.organization.id}`) ===
    "true";
  const isAdmin = Boolean(sessionStorage.getItem(ON_BEHALF_OF_TOKEN));

  const hasCategories =
    res.organization?.bentoCategories &&
    res.organization?.bentoCategories?.length > 0;
  const hasSpecificVaNiches =
    res.organizationVaProfile?.niches &&
    res.organizationVaProfile?.niches?.length === NICHES_REQUIRED;
  const hasCompleteNicheDetails =
    hasSpecificVaNiches &&
    res.organizationVaProfile?.niches.every(
      (niche: VaNicheSpecificDetails) =>
        niche?.past_brands && niche?.past_brands.length > 0,
    );
  const hasCompletedBackground =
    (res.organizationVaProfile?.background &&
      Object.keys(res.organizationVaProfile?.background)?.length > 0) ||
    res.organizationVaProfile?.onboardingType === VaOnboardingType.book_call;

  const completedFinalTouch =
    res.organizationProfile?.wishlistBrands &&
    res.organizationProfile?.wishlistBrands?.length > 0 &&
    res.organizationProfile.pitchEmail?.length > 0;

  const oldVaUserCompletedFlow =
    res.organizationProfile?.onboardingGoogleDocId &&
    res.organizationProfile?.onboardingGoogleDocId?.length > 0 &&
    !res.organizationVaProfile;
  const newVaUserCompletedFlow =
    res.organizationVaProfile?.status === VaOrganizationProfileStatus.completed;

  if (oldVaUserCompletedFlow || newVaUserCompletedFlow) {
    return `/${routes.vaFaq}${location.search}`;
  } else if (!res.organizationProfile) {
    return `/${routes.aboutVaOnboarding}${location.search}`;
  } else if (!hasCategories) {
    return `/${routes.categoryVaOnboarding}${location.search}`;
  } else if (
    !res.organizationProfile.monthlyIncome ||
    res.organizationProfile.monthlyIncome?.length === 0
  ) {
    return `/${routes.incomeOnboarding}${location.search}`;
  } else if (!currentVaStep && !isAdmin) {
    return `/${routes.vaIntroduction}${location.search}`;
  } else if (!res.organization.vaStatus) {
    return `/${routes.vaPaidPortal}${location.search}`;
  } else if (!grantedEmailAccess || isSubscribedEmail) {
    return `/${routes.vaStepsToGetStarted}${location.search}`;
  } else if (!res.organizationVaProfile?.onboardingType) {
    return `/${routes.vaOnboardingType}${location.search}`;
  } else if (!hideOnboardingVideo) {
    return `/${routes.vaVideoOverview}${location.search}`;
  } else if (!hasSpecificVaNiches) {
    return `/${routes.vaOnboardingNichePick}${location.search}`;
  } else if (!hasCompleteNicheDetails) {
    return `/${routes.vaOnboardingNicheDetails}${location.search}`;
  } else if (!hasCompletedBackground) {
    return `/${routes.vaOnboardingBackground}${location.search}`;
  } else if (!completedFinalTouch) {
    return `/${routes.vaOnboardingFinalTouch}${location.search}`;
  } else {
    return `/${routes.vaFaq}${location.search}`;
  }
};
