export type CategoryEdit = {
  categories: string[];
  suggestedCategories: string[];
  suggestedCategoriesIds: number[];
  field: string;
  label: string;
};

export enum BrandAnnotationHeader {
  main_categories = "Main Categories",
  secondary_categories = "Secondary Categories",
  price_point = "Price Point",
  company_size = "Company Size",
  instagram_followers = "Instagram Followers",
  compensation = "Compensation",
}

export const NonVaBrandAnnotations = [
  BrandAnnotationHeader.main_categories,
  BrandAnnotationHeader.secondary_categories,
  BrandAnnotationHeader.price_point,
  BrandAnnotationHeader.company_size,
  BrandAnnotationHeader.instagram_followers,
];
