import { grey } from "@mui/material/colors";

const styles = {
  key: {
    color: grey[600],
    fontSize: 12,
  },
  moreButton: { textTransform: "none", color: grey[500], fontSize: 10, ml: -1 },
};

export default styles;
