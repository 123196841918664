import { Box, Button, FormHelperText, Grid, Typography } from "@mui/material";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  OrganizationVaProfile,
  VaOnboardingType,
  VaOrganizationProfileStatus,
} from "schemas/dashboard";

import { vaOnboardingNavigate } from "features/Auth/Onboarding/helper";
import { getSource } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

interface Props {
  organizationVaProfile: OrganizationVaProfile | null;
  setOrganizationVaProfile: Dispatch<
    SetStateAction<OrganizationVaProfile | null>
  >;
  saveVaProfileForm: (
    organizationVaProfile: OrganizationVaProfile | null,
    page?: string,
    source?: string | null,
  ) => void;
}

const VaOnboardingTypeForm = ({
  organizationVaProfile,
  setOrganizationVaProfile,
  saveVaProfileForm,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const slideContext = useContext(SlideAnimationContext);
  const source = getSource();

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "back")}${location.search}`,
    );
  };

  const handleNext = () => {
    saveVaProfileForm(organizationVaProfile, location.pathname, source);
  };

  const handleSelect = (option: VaOnboardingType) => {
    setOrganizationVaProfile((prev) => {
      let copy = makeDeepCopy(prev);
      if (!copy) {
        copy = {};
      }
      copy.onboardingType = option;
      return copy;
    });
  };

  useEffect(() => {
    if (!organizationVaProfile) {
      setOrganizationVaProfile({
        onboardingType: VaOnboardingType.instant,
        status: VaOrganizationProfileStatus.pending,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationVaProfile?.onboardingType]);

  return (
    <>
      <Typography variant="h3" sx={{ textAlign: "center", mb: 2 }}>
        How would you like to onboard?
      </Typography>

      <Grid container item xs={12} justifyContent="center" gap={2}>
        <Grid
          gap={1}
          container
          sx={[
            styles.container,
            organizationVaProfile?.onboardingType ===
              VaOnboardingType.instant && styles.selected,
          ]}
          onClick={() => handleSelect(VaOnboardingType.instant)}
        >
          <Typography sx={styles.emoji}>📝</Typography>
          <Typography sx={styles.header}>
            Instantly, <br /> with a questionnaire
          </Typography>
        </Grid>

        <Grid
          gap={1}
          container
          sx={[
            styles.container,
            organizationVaProfile?.onboardingType ===
              VaOnboardingType.book_call && styles.selected,
          ]}
          onClick={() => handleSelect(VaOnboardingType.book_call)}
        >
          <Typography sx={styles.emoji}> ☎️ </Typography>
          <Typography sx={styles.header}>
            Over a video call <br />
            with the Bento Team
            <FormHelperText>(~ 2 weeks wait time) </FormHelperText>
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs="auto" container columnGap={1} sx={{ mt: 4 }}>
        <Grid item xs="auto">
          <Button onClick={handleBack} color="secondary" disableElevation>
            Back
          </Button>
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          <Button disableElevation variant="contained" onClick={handleNext}>
            Next{" "}
            <Box
              sx={{ ml: 1 }}
              component="i"
              className="fa-solid fa-arrow-right"
            />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default VaOnboardingTypeForm;
