const styles = {
  cardContainer: {
    pb: 4, // padding for saved brands
  },
  cardContainerMobile: {
    pb: 10,
  },
  brandsList: {
    pb: { xs: 12, md: 0 },
  },
};

export default styles;
