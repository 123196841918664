import { Typography } from "@mui/material";
import { BentoBrand } from "schemas/dashboard";

interface Props {
  bentoBrand: BentoBrand;
}

const InCollection = ({ bentoBrand }: Props) => {
  const collectionNames =
    bentoBrand?.collections?.map((collection) => collection.name) || [];

  return collectionNames?.length > 0 ? (
    <Typography variant="body2">
      In{" "}
      <em>
        <strong>{collectionNames?.join(", ")}</strong>
      </em>
    </Typography>
  ) : (
    <></>
  );
};

export default InCollection;
