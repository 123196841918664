import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useEffect } from "react";

import { trackEvent } from "utils/tracking";

import AddOns from ".";
import { AddOnDialogSource, AddOnName } from "./schema";

export default function AddOnDialog() {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { addOnDialogSource, setAddOnDialogSource, displayTemplateForm } =
    useContext(SubscriptionContext);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const close = () => {
    setAddOnDialogSource("");
  };

  useEffect(() => {
    if (addOnDialogSource !== "") {
      trackEvent("Shown Add-ons Dialog", {
        Reason: addOnDialogSource,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOnDialogSource, currentOrg?.id]);

  const getTitle = () => {
    if (
      addOnDialogSource === AddOnDialogSource.templates_page ||
      addOnDialogSource === AddOnDialogSource.onboarding ||
      addOnDialogSource === AddOnDialogSource.templates_sidebar ||
      displayTemplateForm
    ) {
      return {
        header: "",
        subheader: "",
        options: [AddOnName.pitching_templates],
        grid: 12,
        maxWidth: "sm",
      };
    } else {
      return {
        header: "Purchase Additional Features",
        subheader: "Purchase additional features to help you pitch to brands.",
        options: [AddOnName.pitching_templates, AddOnName.support_call],
        grid: 5.5,
        maxWidth: "md",
      };
    }
  };

  return (
    <Dialog
      open={Boolean(addOnDialogSource && addOnDialogSource !== "")}
      onClose={close}
      fullScreen={isMobileScreen}
      // @ts-ignore
      maxWidth={getTitle().maxWidth}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          {getTitle().header}
          <IconButton sx={{ fontSize: 14 }} onClick={close}>
            <Box
              component="i"
              className={
                isMobileScreen
                  ? "fa-regular fa-arrow-left"
                  : "fa-regular fa-xmark"
              }
            />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2 }}>{getTitle().subheader}</Typography>

        <AddOns
          source={addOnDialogSource}
          grid={getTitle().grid}
          fromDialog={true}
          validOptions={getTitle().options}
        />
      </DialogContent>
    </Dialog>
  );
}
