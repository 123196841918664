import { grey } from "@mui/material/colors";

const styles = {
  subtitle: {
    mt: 1,
    color: grey[600],
  },
};

export default styles;
