import { Grid, MenuItem, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import { SubscriptionContext } from "contexts/Subscription";
import { Dispatch, SetStateAction, useContext } from "react";
import { Map } from "schemas/functions";

import DropdownSelect from "components/DropdownSelect";
import { useSearchBrand } from "utils/useSearchBrand";

interface Props {
  label: string;
  options: { key: string; name: string; label?: string; paidOnly?: boolean }[];
  params: string;
  iconsMap?: Map;
  setClose: Dispatch<SetStateAction<boolean>>;
  close: boolean;
  isOptionSelected: (key: string, params: string) => boolean;
  selectedOptionIcon: JSX.Element;
}

const SelectFilter = ({
  label,
  options,
  params,
  iconsMap,
  setClose,
  close,
  isOptionSelected,
  selectedOptionIcon,
}: Props) => {
  const { addCategoryQueryToSearch } = useSearchBrand();
  const { setUpgradeDialogSource, subscription } =
    useContext(SubscriptionContext);

  const handleAddOption = (
    name: string,
    value: string,
    paidOnly: boolean = false,
  ) => {
    if (paidOnly && !subscription?.isSubscribed) {
      setUpgradeDialogSource("Restricted Filtering Feature");
      return;
    }
    addCategoryQueryToSearch(name, value);
    setClose(true);
  };

  const getIcon = (key: string) => {
    if (iconsMap && key in iconsMap) {
      return iconsMap[key];
    }
  };

  return (
    <DropdownSelect label={label} id={label} close={close} setClose={setClose}>
      {options.map((option) => (
        <MenuItem
          key={option.key}
          value={option.key}
          sx={
            option.paidOnly && !subscription?.isSubscribed
              ? {
                  color: grey[500],
                  "& i": { opacity: 0.3 },
                }
              : {}
          }
          onClick={() => handleAddOption(params, option.key, option.paidOnly)}
        >
          <Tooltip title={option.label} placement="right">
            <Grid container>
              {isOptionSelected(option.key, params) && selectedOptionIcon}{" "}
              {getIcon(option.key)}
              {option.name}
            </Grid>
          </Tooltip>
        </MenuItem>
      ))}
    </DropdownSelect>
  );
};

export default SelectFilter;
