import {
  FormControlLabel,
  Grid,
  Skeleton,
  Switch,
  Typography,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { forwardRef, useContext } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { BentoBrand, SearchParams, UserRequestSource } from "schemas/dashboard";
import { routes } from "schemas/routes";

import { useSearchBrand } from "utils/useSearchBrand";

import BrandCard from "../BrandCard";
import { BrandCardSource } from "../schema";
import styles from "./styles";

interface Props {
  brandsLoading: boolean;
  fetchMoreLoading: boolean;
  brands: BentoBrand[];
  ref: any;
}
const REF_INDEX = 9; // The index used to start loading more recommendations

export default forwardRef<HTMLDivElement, Props>(
  ({ brandsLoading, fetchMoreLoading, brands }, ref) => {
    const [searchParams] = useSearchParams();

    const { handleRequestForBrand } = useSearchBrand();

    const brandQuery = searchParams.get(SearchParams.QUERY_DISCOVER);
    const isSearching = brandQuery && brandQuery?.length > 0;
    const { excludedBentoSearchFilterIds, searchFilters } = useContext(
      OrganizationUserContext,
    );

    const isExcludedKeyWords = () => {
      if (!excludedBentoSearchFilterIds) return false;
      for (const excludedFilterId of excludedBentoSearchFilterIds) {
        const matchedFilter = searchFilters?.find(
          (f) => f.id === excludedFilterId,
        );
        if (matchedFilter) {
          const inMatchedFilter = matchedFilter?.keywords?.find(
            (x) => x === brandQuery?.toLowerCase(),
          );
          if (inMatchedFilter) return true;
        }
      }
      return false;
    };

    return (
      <Grid item container rowGap={1}>
        <Grid item xs={12}>
          <Typography sx={styles.searchingText} variant="h6" textAlign="left">
            {isSearching && `Searching "${brandQuery}"`}
          </Typography>
        </Grid>

        {!brandsLoading && !fetchMoreLoading && brands.length === 0 && (
          <Grid container item xs={12} rowGap={2}>
            {isExcludedKeyWords() ? (
              <Grid sx={styles.excludedContainer}>
                <Typography sx={{ fontSize: 16, mb: 2 }}>
                  No results found.
                </Typography>
                It appears you have excluded the keyword{" "}
                <strong>
                  <em>{brandQuery}</em>
                </strong>{" "}
                from your recommendation preferences. To change this, go to your{" "}
                <Link to={`/${routes.profile}`}>Profile page</Link> and update
                the excluded keyword filters at the{" "}
                <strong>Let us tailor your recommendations</strong> section.
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  No results found {isExcludedKeyWords() && "True"}
                  {isSearching && (
                    <FormControlLabel
                      sx={{ mx: 1 }}
                      control={
                        <Switch
                          size="small"
                          onChange={() =>
                            handleRequestForBrand(
                              searchParams.get("queryBrand") || "",
                              UserRequestSource.SOURCE_TOGGLE,
                            )
                          }
                        />
                      }
                      label={
                        <Typography sx={styles.notifyText} component="span">
                          Notify me when results for{" "}
                          <strong>"{searchParams.get("queryBrand")}"</strong>{" "}
                          are found.
                        </Typography>
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  Try changing the filters above to see more results.
                </Grid>
              </>
            )}
          </Grid>
        )}

        {brandsLoading && (
          <Grid
            container
            item
            xs={12}
            justifyContent={"center"}
            sx={styles.skeletonContainer}
          >
            {Array.from({ length: 20 }, (x, i) => (
              <Skeleton
                sx={{ mb: 2 }}
                key={i}
                variant="rounded"
                width={"100%"}
                height={100}
              />
            ))}
          </Grid>
        )}

        <Grid container item xs={12} justifyContent="center" rowGap={1}>
          {!brandsLoading &&
            brands?.map((brand: BentoBrand, idx: number) => (
              <BrandCard
                ref={
                  (
                    brands.length > REF_INDEX
                      ? idx === brands.length - REF_INDEX
                      : idx === brands.length - 1
                  )
                    ? ref
                    : undefined
                }
                key={`brand-card-${brand.id}-${idx}`}
                brand={brand}
                source={BrandCardSource.DISCOVER_BRAND}
              />
            ))}
        </Grid>
      </Grid>
    );
  },
);
