import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { BrandsContext } from "contexts/Brands";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { SubscriptionContext } from "contexts/Subscription";
import { UserRequestsContext } from "contexts/UserRequests";
import { debounce } from "lodash";
import { useContext, useMemo, useState } from "react";
import {
  BentoBrand,
  CombinedRequest,
  RequestStatus,
  SavedBrandStatus,
  UserNotifications,
} from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";
import { useCollection } from "utils/useCollection";
import { useRequests } from "utils/useRequests";

import { BrandCardSource } from "../../schema";
import BrandCardMenu from "../Menu";
import NotifyDialog from "../NotifyDialog";
import styles from "./styles";

interface Props {
  brand: BentoBrand;
  source?: BrandCardSource;
  inBrandDetailsHeader?: boolean;
}

export default function BrandCardActions({
  brand,
  source,
  inBrandDetailsHeader = false,
}: Props) {
  const { setAlert } = useContext(AlertContext);
  const theme = useTheme();
  const { lowerWidth } = useContext(DiscoverViewContext);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { getToken } = useAuth();
  const { setErrorAlert } = useContext(AlertContext);
  const { isSavedBrand } = useCollection();
  const { removeFromRequests } = useRequests();

  const { userRequests, setUserRequests, setTotal } =
    useContext(UserRequestsContext);
  const { handleAddDebounced, handleRemoveDebounced } =
    useContext(SavedBrandContext);
  const { selectedCollection } = useContext(SavedBrandCollectionsContext);
  const { currentOrg, userNotifications } = useContext(OrganizationUserContext);
  const { setBrands, setRecommendedBrands } = useContext(BrandsContext);
  const { handleOpenQuickSendIndividual, closeDrawer } = useContext(
    QuickSendDrawerContext,
  );
  const {
    canMakeBrandRequest,
    setUpgradeDialogSource,
    decreaseBrandRequestsCount,
  } = useContext(SubscriptionContext);

  const [openNotifyDialog, setOpenNotifyDialog] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<UserNotifications | null>(
    userNotifications || { optInEmail: true, optInPhone: false },
  );

  const index = useMemo(
    () =>
      userRequests(SavedBrandStatus.unsent)?.findIndex(
        (x) =>
          Number(x?.bentoBrand?.id) === Number(brand.id) &&
          x.brandNotificationUpdatedAt,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brand.id, userRequests(SavedBrandStatus.unsent)?.length],
  );

  const checked = index !== undefined && index > -1;

  const handleNotificationApiCall = useMemo(
    () =>
      debounce(async (isChecked: boolean) => {
        if (!currentOrg?.id) return;
        try {
          const url = `/api/organization/${currentOrg?.id}/brand-notifications`;
          const method = isChecked ? "PUT" : "DELETE";
          const res = await fetcherAuth(
            getToken,
            url,
            method,
            {},
            { bentoBrandId: brand.id },
          );

          if (method === "PUT" && index === -1) {
            const combinedRequest: CombinedRequest = {
              brandNotificationStatus: RequestStatus.pending,
              brandNotificationUpdatedAt: res.brandNotification.updatedAt,
              bentoBrand: brand,
              earliestPendingSendAt:
                res.brandNotification.earliestPendingSendAt,
              latestEmailCompletedAt:
                res.brandNotification.latestEmailCompletedAt,
            };
            let status = SavedBrandStatus.unsent;
            if (
              res.brandNotification.earliestPendingSendAt ||
              res.brandNotification.latestEmailCompletedAt
            ) {
              status = SavedBrandStatus.sent;
            }
            setUserRequests(status)((prev) => [...prev, combinedRequest]);
            decreaseBrandRequestsCount();
            setTotal((prev) => {
              const copy = makeDeepCopy(prev);
              copy[status] = (copy[status] || 0) + 1;
              return copy;
            });
          } else if (method === "DELETE") {
            removeFromRequests(
              brand,
              SavedBrandStatus.unsent,
              "brandNotificationUpdatedAt",
            );
            removeFromRequests(
              brand,
              SavedBrandStatus.sent,
              "brandNotificationUpdatedAt",
            );
          }
        } catch (error) {
          setBrands((prevBrands: BentoBrand[]) => {
            return prevBrands.map((prevBrand: BentoBrand) =>
              brand.id === prevBrand.id
                ? { ...prevBrand, hasBrandNotification: !isChecked }
                : prevBrand,
            );
          });

          setRecommendedBrands((prevBrands: BentoBrand[]) => {
            return prevBrands.map((prevBrand: BentoBrand) =>
              brand.id === prevBrand.id
                ? { ...prevBrand, hasBrandNotification: !isChecked }
                : prevBrand,
            );
          });

          setErrorAlert(error);
        }
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrg?.id, brand.id],
  );

  const handleNotification = (e: CustomEvent) => {
    const { checked } = e.target;

    if (checked && !canMakeBrandRequest()) {
      setUpgradeDialogSource("Hits Brand Request Limit");
      return;
    }

    if (checked && !userNotifications) {
      setOpenNotifyDialog(true);
    } else {
      updateNotificationForBrand(checked);
    }
  };

  const updateNotificationForBrand = (checked: boolean) => {
    handleNotificationApiCall(checked);
    setBrands((prevBrands: BentoBrand[]) => {
      return prevBrands.map((prevBrand: BentoBrand) =>
        brand.id === prevBrand.id
          ? { ...prevBrand, hasBrandNotification: checked }
          : prevBrand,
      );
    });

    setRecommendedBrands((prevBrands: BentoBrand[]) => {
      return prevBrands.map((prevBrand: BentoBrand) =>
        brand.id === prevBrand.id
          ? { ...prevBrand, hasBrandNotification: checked }
          : prevBrand,
      );
    });

    const alertMessage = checked
      ? `We'll notify you when we find an email address for you to reach out to for ${brand.brandName}!`
      : `Notifications for ${brand.brandName}  is turned off`;

    setAlert(alertMessage, "success");
  };

  const openQuickSend = (e: CustomEvent) => {
    e.stopPropagation();
    handleOpenQuickSendIndividual(brand.id, "Discover", undefined, true);
  };

  const isSelected = isSavedBrand(brand);

  const buttonSectionDesktop = () => {
    if (!brand.hasContacts) {
      return (
        <Grid
          container
          justifyContent="flex-end"
          item
          xs="auto"
          alignItems="center"
          sx={{ mr: 1, ml: -1 }}
          onClick={(e: CustomEvent) => e.stopPropagation()}
        >
          <FormControlLabel
            control={
              <Switch
                defaultChecked={checked}
                onChange={handleNotification}
                sx={styles.switch}
              />
            }
            label="Notify Me"
            labelPlacement="start"
            sx={[
              styles.notifyLabel,
              inBrandDetailsHeader && styles.notifyLabelHeader,
            ]}
          />
        </Grid>
      );
    } else {
      return (
        <div onClick={(e: CustomEvent) => e.stopPropagation()}>
          {isSelected ? (
            <IconButton
              sx={[inBrandDetailsHeader && styles.icon, styles.savedHeart]}
              onClick={(e: CustomEvent) => {
                e.stopPropagation();
                handleRemoveDebounced(brand, selectedCollection?.id || 0);
              }}
            >
              <Box component="i" className="fa-solid fa-bookmark" />
            </IconButton>
          ) : (
            <IconButton
              onClick={(e: CustomEvent) => {
                e.stopPropagation();
                handleAddDebounced(e.currentTarget, brand);
              }}
              sx={[styles.regularHeart, inBrandDetailsHeader && styles.icon]}
            >
              <Box component="i" className="fa-regular fa-bookmark" />
            </IconButton>
          )}
          {source && (
            <Button
              onClick={openQuickSend}
              variant="contained"
              size="small"
              disableElevation
              sx={styles.quickSendButton}
            >
              Email
              <Box
                component="i"
                className="fa-regular fa-envelope"
                sx={{ ml: 1 }}
              />
            </Button>
          )}
          {source && (
            <BrandCardMenu setAlert={setAlert} brand={brand} source={source} />
          )}
          {/* This happens in the one case when inBrandDetailsHeader */}
          {isMobileScreen && (
            <IconButton
              sx={[styles.icon, { color: "green.main", fontSize: 15, ml: 1 }]}
              onClick={(e: CustomEvent) => {
                closeDrawer();
                openQuickSend(e);
              }}
            >
              <Box component="i" className="fa-regular fa-envelope" />
            </IconButton>
          )}
        </div>
      );
    }
  };

  const buttonSectionMobile = () => {
    if (!brand.hasContacts) {
      return (
        <Box
          onClick={(e: CustomEvent) => {
            e.stopPropagation();
          }}
          sx={{ mr: 1, ml: -1 }}
        >
          <Switch
            defaultChecked={checked}
            onChange={handleNotification}
            sx={styles.switch}
          />
        </Box>
      );
    }
    return (
      <Grid
        item
        xs="auto"
        container
        alignItems="center"
        justifyContent="flex-end"
        key={Number(brand.id)}
      >
        {isSelected ? (
          <IconButton
            sx={styles.savedHeart}
            onClick={(e: CustomEvent) => {
              e.stopPropagation();
              handleRemoveDebounced(brand, selectedCollection?.id || 0);
            }}
          >
            <Box>
              <Box component="i" className="fa-solid fa-bookmark" />
            </Box>
          </IconButton>
        ) : (
          <IconButton
            onClick={(e: CustomEvent) => {
              e.stopPropagation();
              handleAddDebounced(e.currentTarget, brand);
            }}
            sx={{ fontSize: 16 }}
          >
            <Box component="i" className="fa-regular fa-bookmark" />
          </IconButton>
        )}
        {source && (
          <IconButton
            sx={{ color: "green.main", fontSize: 15 }}
            onClick={openQuickSend}
          >
            <Box component="i" className="fa-regular fa-envelope" />
          </IconButton>
        )}
      </Grid>
    );
  };

  const renderButtons = () => {
    if ((isMobileScreen || lowerWidth) && !inBrandDetailsHeader) {
      return buttonSectionMobile();
    } else {
      return buttonSectionDesktop();
    }
  };

  return (
    <>
      {renderButtons()}
      <NotifyDialog
        open={openNotifyDialog}
        handleClose={() => setOpenNotifyDialog(false)}
        setAlert={setAlert}
        handleCreateBrandNotification={() => updateNotificationForBrand(true)}
        notifications={notifications}
        setNotifications={setNotifications}
      />
    </>
  );
}
