import { Typography } from "@mui/material";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import moment from "moment";
import { useContext } from "react";
import { BentoBrand } from "schemas/dashboard";

interface Props {
  bentoBrand: BentoBrand;
}

const DateOrEmail = ({ bentoBrand }: Props) => {
  const { templates } = useContext(OutreachTemplatesContext);
  let templateName = "";
  if (bentoBrand?.latestOutreachTemplateId) {
    const template = templates.find(
      (template) => template.id === bentoBrand?.latestOutreachTemplateId,
    );
    templateName = template?.name || "";
  }
  if (bentoBrand?.earliestPendingSendAt) {
    return (
      <>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Scheduled for{" "}
          <strong>
            <em>
              {" "}
              {moment(bentoBrand?.earliestPendingSendAt).format("MMM DD, YYYY")}
            </em>
          </strong>
        </Typography>

        {templateName && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Template:{" "}
            <strong>
              <em>{templateName}</em>
            </strong>
          </Typography>
        )}
      </>
    );
  } else if (bentoBrand?.latestEmailCompletedAt) {
    return (
      <Typography variant="body2" sx={{ mt: 1 }}>
        Emailed on{" "}
        <strong>
          <em>
            {" "}
            {moment(bentoBrand?.latestEmailCompletedAt).format("MMM DD, YYYY")}
          </em>
        </strong>
      </Typography>
    );
  } else {
    return <></>;
  }
};

export default DateOrEmail;
