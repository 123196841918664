import { CompensationType } from "schemas/dashboard";

export interface AdminNewBrand {
  compensationTypes: CompensationType[];
  id?: number;
  brandName?: string;
  website?: string;
  tags?: string;
  mainCategory?: string;
  categories?: string[];
  pricePoint?: string;
  instagramUsername?: string;
  instagramFollowerCount?: number;
  isArchived?: boolean;
  doNotRecommend?: boolean;
  hasValidatedPartnership?: boolean;
  logoUrl?: string;
  mainImageUrl?: string;
  bentoBrandLocations?: Location[];
  mainCategoryIds: number[];
  secondaryCategoryIds: number[];
}

export enum Continents {
  africa = "Africa",
  antarctica = "Antarctica",
  asia = "Asia",
  europe = "Europe",
  oceania = "Oceania",
  north_america = "North America",
  south_america = "South America",
}

export type Location = {
  id?: number;
  country: string;
  continent: Continents;
  isArchived?: boolean;
  source: LocationSource;
};

export enum LocationSource {
  MANUAL = "manual",
  GOOGLE = "google",
}
