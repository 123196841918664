import { Avatar, Box, Link } from "@mui/material";
import { grey } from "@mui/material/colors";
import { BentoBrand } from "schemas/dashboard";

import { generateInitials } from "utils/string";
import { trackEvent } from "utils/tracking";

const styles = {
  roundImage: {
    objectFit: "contain",
    width: 30,
    height: 30,
    borderRadius: 40,
    border: "1px solid",
    borderColor: grey[200],
  },
  avatar: {
    backgroundColor: "green.dark",
    width: 30,
    height: 30,
    fontSize: "15px",
  },
};

interface BrandLogoProps {
  bentoBrand: BentoBrand;
}

export default function BrandLogo({ bentoBrand }: BrandLogoProps) {
  const handleClick = () => {
    trackEvent("Brand Logo Clicked", {
      "Bento Brand ID": bentoBrand?.id,
    });
  };

  return (
    <Link
      component="div"
      onClick={handleClick}
      sx={{ cursor: "pointer", p: 0, m: 0, height: 30 }}
    >
      {bentoBrand?.logoUrl ? (
        <Box sx={styles.roundImage} component="img" src={bentoBrand?.logoUrl} />
      ) : (
        <Avatar sx={styles.avatar}>
          {generateInitials(bentoBrand?.brandName)}
        </Avatar>
      )}
    </Link>
  );
}
