import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useEffect, useState } from "react";
import {
  VaBackground,
  VaBackgroundDetailsField,
  VaNicheSpecificDetails,
  VaNicheType,
} from "schemas/dashboard";
import { CustomEvent, Map } from "schemas/functions";

import { TemplateBackgroundQuestions } from "features/Auth/VAOnboarding/VaProfileForm/Background/schema";
import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import { TemplateScreen } from "../schema";
import styles from "./styles";

const TemplateQuestions = () => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setErrorAlert, setAlert } = useContext(AlertContext);

  const emptyNiche = {
    name: "",
    past_brands: "",
    top_performing_post_link: "",
    partnership_reason: "",
    type: VaNicheType.general,
  };

  const { unfilledForm, setUnfilledForm } = useContext(SubscriptionContext);
  const open = Boolean(unfilledForm?.id);

  const [screen, setScreen] = useState<TemplateScreen>(TemplateScreen.niche);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [niches, setNiches] = useState<VaNicheSpecificDetails[]>([emptyNiche]);
  const [background, setBackground] = useState<VaBackground | null>(
    TemplateBackgroundQuestions,
  );
  const [requiresBackground, setRequiresBackground] = useState(true);

  const hasBoughtTemplateAddOns = async () => {
    setLoading(true);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments-add-ons/template-bought`,
      );
      setRequiresBackground(!res.hasBoughtTemplates);
    } catch (error) {
      setErrorAlert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async (e: CustomEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (screen === TemplateScreen.completed) {
      setUnfilledForm(null);
    } else if (screen === TemplateScreen.niche && requiresBackground) {
      setScreen(TemplateScreen.background);
    } else {
      submitForm(e);
    }
  };

  const submitForm = async (e: CustomEvent) => {
    e.preventDefault();
    setSubmitLoading(true);

    const getFilledAnswer = () => {
      const filledNiches = [];
      for (const niche of niches) {
        if (
          niche?.name ||
          niche?.past_brands ||
          niche?.partnership_reason ||
          niche?.top_performing_post_link
        ) {
          filledNiches.push(niche);
        }
      }
      return filledNiches;
    };

    const filledNiches = getFilledAnswer();

    const data: Map = {
      background,
    };
    if (filledNiches.length > 0) {
      data["niches"] = filledNiches;
    }

    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments-add-ons/${unfilledForm?.id}/template-submit`,
        "POST",
        {},
        data,
      );
      setAlert("Form submitted.", "success");
      setScreen(TemplateScreen.completed);
    } catch (error) {
      setErrorAlert(error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleBack = () => {
    setScreen(TemplateScreen.niche);
  };

  const onChange = (e: CustomEvent, index: number) => {
    const { name, value } = e.target;
    setNiches((prev) => {
      const copy = makeDeepCopy(prev);
      copy[index][name] = value;
      return copy;
    });
  };

  const onBackgroundChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    setBackground((prev) => {
      const copy = makeDeepCopy(prev);
      copy[name]["answer"] = value;
      return copy;
    });
  };

  const renderBackgroundQuestion = (
    fieldName: VaBackgroundDetailsField,
    rows?: number,
  ) => {
    return (
      <Grid item xs={12}>
        <FormHelperText>{background?.[fieldName]?.question}</FormHelperText>
        <TextField
          fullWidth
          name={fieldName}
          placeholder={"Type your answer here..."}
          onChange={onBackgroundChange}
          value={background?.[fieldName]?.answer}
          multiline
          rows={rows || 3}
        />
      </Grid>
    );
  };

  const renderNicheQuestion = (index: number) => {
    return (
      <Grid container alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Typography>
            <strong>Template {index + 1}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormHelperText>
            What template is this for?{" "}
            <Tooltip
              enterTouchDelay={0}
              title="Examples of what creators need a template for: General Template, Pet Template, Beauty Template, Influencer Template etc."
            >
              <Box
                sx={styles.info}
                component="i"
                className="fa-regular fa-circle-info"
              />
            </Tooltip>
          </FormHelperText>
          <TextField
            fullWidth
            name="name"
            placeholder={"General template, pet template..."}
            onChange={(e) => onChange(e, index)}
            value={niches[index]?.name || ""}
          />

          <FormHelperText>
            What are big brand names you've worked with{" "}
            {unfilledForm?.quantity &&
              unfilledForm?.quantity > 0 &&
              "that you want to highlight in this template"}{" "}
            ?{" "}
            <Tooltip
              enterTouchDelay={0}
              title="Ideally these are household names, or it's brands that the particular niche you are targeting would recognize"
            >
              <Box
                sx={styles.info}
                component="i"
                className="fa-regular fa-circle-info"
              />
            </Tooltip>
          </FormHelperText>
          <TextField
            fullWidth
            name="past_brands"
            placeholder={
              "Type brand names you want to highlight in this template..."
            }
            onChange={(e) => onChange(e, index)}
            value={niches[index]?.past_brands || ""}
          />
          <FormHelperText>
            Share a top performing post (IG or TikTok link):{" "}
            <Tooltip
              enterTouchDelay={0}
              title="If you are an influencer, share a top performing post that can give brands a sample of your work. If you are a UGC creator, share a top performing post that a brand has posted on their channel with your content."
            >
              <Box
                sx={styles.info}
                component="i"
                className="fa-regular fa-circle-info"
              />
            </Tooltip>
          </FormHelperText>
          <TextField
            fullWidth
            name="top_performing_post_link"
            placeholder={"https://instagram.com/link"}
            onChange={(e) => onChange(e, index)}
            value={niches[index]?.top_performing_post_link || ""}
          />

          <FormHelperText>
            Share why brands would want to partner with you{" "}
            <Tooltip
              enterTouchDelay={0}
              title="Be specific. Some ideas: share your personal tie to this niche, explain a case study of how you've worked with a brand in the past and the results you've had, share a concept in this niche you've been dying to create for a brand)"
            >
              <Box
                sx={styles.info}
                component="i"
                className="fa-regular fa-circle-info"
              />
            </Tooltip>
          </FormHelperText>
          <TextField
            fullWidth
            name="partnership_reason"
            placeholder={"Type your answer here..."}
            onChange={(e) => onChange(e, index)}
            multiline
            rows={4}
            value={niches[index]?.partnership_reason || ""}
          />
        </Grid>
      </Grid>
    );
  };

  const renderScreen = () => {
    if (screen === TemplateScreen.niche) {
      return <>{niches.map((_, index) => renderNicheQuestion(index))}</>;
    } else if (screen === TemplateScreen.background) {
      return (
        <>
          {Object.keys(background || {}).map((fieldName) => {
            return renderBackgroundQuestion(
              fieldName as VaBackgroundDetailsField,
              fieldName === VaBackgroundDetailsField.other ? 6 : 3,
            );
          })}
        </>
      );
    } else {
      return (
        <>
          Thank you for purchasing a custom pitch template! Our professional
          copywriters will get started shortly. <br />
          ✍️ Customers typically receive their pitch within one week of
          submitting their intake form. You'll receive an email notification
          once your pitch is ready. <br />
          In the meantime, feel free to start saving brands to a list on Bento.
          That way, you can start pitching immediately once your template is
          complete.
        </>
      );
    }
  };

  useEffect(() => {
    if (open && unfilledForm?.quantity) {
      hasBoughtTemplateAddOns();
      setNiches(
        Array.from({ length: unfilledForm?.quantity }, () => emptyNiche),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, open, unfilledForm?.quantity]);

  return (
    <Dialog
      open={open}
      onClose={() => setUnfilledForm(null)}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        <form onSubmit={handleNext}>
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {screen === TemplateScreen.completed
                ? "Pitch Template Intake Completed!"
                : "Pitch Template Intake"}
            </Typography>
            {renderScreen()}
          </Box>

          <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
            {screen === TemplateScreen.background && (
              <Button onClick={handleBack}>Back</Button>
            )}
            <LoadingButton
              loading={loading || submitLoading}
              variant="contained"
              type="submit"
              disableElevation
            >
              {screen === TemplateScreen.completed ? "Close" : "Next"}
            </LoadingButton>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default TemplateQuestions;
