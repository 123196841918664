import { Autocomplete, TextField } from "@mui/material";
import { AdminBentoCategory } from "schemas/admin";
import { CustomEvent } from "schemas/functions";

import { camelToTitleCase } from "utils/string";

interface IProps {
  categoriesById: { [key: number]: AdminBentoCategory };
  name: string;
  value: number[];
  handleAutocompleteChange: (e: CustomEvent, value: number[] | null) => void;
}

const MultiBentoCategorySelect = ({
  categoriesById,
  name,
  handleAutocompleteChange,
  value,
}: IProps) => {
  return (
    <Autocomplete
      multiple
      autoSelect
      autoHighlight
      fullWidth
      options={Object.keys(categoriesById).map((key) => parseInt(key))}
      forcePopupIcon={false}
      getOptionLabel={(id: number) => {
        return categoriesById[id]?.displayName || categoriesById[id]?.name;
      }}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={camelToTitleCase(name)}
          name={name}
          placeholder="Add Category..."
        />
      )}
      onChange={handleAutocompleteChange}
      value={value}
    />
  );
};

export default MultiBentoCategorySelect;
