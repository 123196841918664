import { VaOnboardingType } from "schemas/dashboard";
import { Map } from "schemas/functions";
import { routes } from "schemas/routes";

import { TemplateHighlight } from "features/Influencer/Templates/TemplatesTable/CreateTemplate/PresetFlow/schema";

export function vaOnboardingNavigate(
  currentPathname: string,
  direction: "next" | "back",
  paidThroughSettingsPage = false,
  onboardingType?: VaOnboardingType,
) {
  let steps = [
    routes.aboutVaOnboarding,
    routes.categoryVaOnboarding,
    routes.incomeOnboarding,
    routes.vaIntroduction,
  ];
  if (!paidThroughSettingsPage) {
    steps = [...steps, routes.vaPaidPortal];
  }
  steps = [
    ...steps,
    routes.vaStepsToGetStarted,
    routes.vaOnboardingType,
    routes.vaVideoOverview,
    routes.vaOnboardingNichePick,
    routes.vaOnboardingNicheDetails,
  ];
  if (onboardingType !== VaOnboardingType.book_call) {
    steps = [...steps, routes.vaOnboardingBackground];
  }
  steps = [
    ...steps,
    routes.vaOnboardingFinalTouch,
    routes.vaFaq,
    routes.dashboard,
  ];
  const currentStep = steps.findIndex((step) => currentPathname.endsWith(step));
  if (direction === "next" && currentStep < steps.length - 1) {
    return steps[currentStep + 1];
  } else if (direction === "back" && currentStep > 0) {
    return steps[currentStep - 1];
  } else {
    return currentPathname;
  }
}

export function onboardingNavigate(
  currentPathname: string,
  onboardingForm: Map,
  direction: "next" | "back",
  transferredFromVa = false,
  purchasedTemplate = false,
) {
  if (currentPathname.endsWith(routes.aboutOnboarding)) {
    if (direction === "next") {
      return routes.categoryOnboarding;
    } else {
      // no back button for about
      return routes.aboutOnboarding;
    }
  } else if (currentPathname.endsWith(routes.categoryOnboarding)) {
    if (direction === "next") {
      return routes.pitchOnboarding;
    } else {
      return routes.aboutOnboarding;
    }
  } else if (currentPathname.endsWith(routes.pitchOnboarding)) {
    if (direction === "next") {
      if (!onboardingForm.hasPitchEmail) {
        return routes.pitchingGoalOnboarding;
      } else {
        return routes.currentPitchOnboarding;
      }
    } else {
      return routes.categoryOnboarding;
    }
  } else if (currentPathname.endsWith(routes.pitchingGoalOnboarding)) {
    if (direction === "next") {
      return routes.pitchingHighlightOnboarding;
    } else {
      return routes.pitchOnboarding;
    }
  } else if (currentPathname.endsWith(routes.pitchingHighlightOnboarding)) {
    if (direction === "next") {
      if (
        !onboardingForm.defaultTemplateHighlights ||
        onboardingForm.defaultTemplateHighlights.length === 0 ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PORTFOLIO,
        )
      ) {
        return routes.portfolioOnboarding;
      } else if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.CASE_STUDY,
        ) ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PAST_PARTNERSHIPS,
        )
      ) {
        return routes.partnershipsOnboarding;
      } else if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.ENGAGEMENT_NUMBERS,
        )
      ) {
        return routes.engagementOnboarding;
      } else {
        return routes.standOutOnboarding;
      }
    } else {
      return routes.pitchingGoalOnboarding;
    }
  } else if (currentPathname.endsWith(routes.portfolioOnboarding)) {
    if (direction === "next") {
      if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.CASE_STUDY,
        ) ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PAST_PARTNERSHIPS,
        )
      ) {
        return routes.partnershipsOnboarding;
      } else if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.ENGAGEMENT_NUMBERS,
        )
      ) {
        return routes.engagementOnboarding;
      } else {
        return routes.standOutOnboarding;
      }
    } else {
      return routes.pitchingHighlightOnboarding;
    }
  } else if (currentPathname.endsWith(routes.partnershipsOnboarding)) {
    if (direction === "next") {
      if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.ENGAGEMENT_NUMBERS,
        )
      ) {
        return routes.engagementOnboarding;
      } else {
        return routes.standOutOnboarding;
      }
    } else {
      if (
        !onboardingForm.defaultTemplateHighlights ||
        onboardingForm.defaultTemplateHighlights.length === 0 ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PORTFOLIO,
        )
      ) {
        return routes.portfolioOnboarding;
      }
      return routes.pitchingHighlightOnboarding;
    }
  } else if (currentPathname.endsWith(routes.engagementOnboarding)) {
    if (direction === "next") {
      return routes.standOutOnboarding;
    } else {
      if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.CASE_STUDY,
        ) ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PAST_PARTNERSHIPS,
        )
      ) {
        return routes.partnershipsOnboarding;
      } else if (
        !onboardingForm.defaultTemplateHighlights ||
        onboardingForm.defaultTemplateHighlights.length === 0 ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PORTFOLIO,
        )
      ) {
        return routes.portfolioOnboarding;
      }
      return routes.pitchingHighlightOnboarding;
    }
  } else if (currentPathname.endsWith(routes.standOutOnboarding)) {
    if (direction === "next") {
      return routes.draftTemplateOnboarding;
    } else {
      if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.ENGAGEMENT_NUMBERS,
        )
      ) {
        return routes.engagementOnboarding;
      } else if (
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.CASE_STUDY,
        ) ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PAST_PARTNERSHIPS,
        )
      ) {
        return routes.partnershipsOnboarding;
      } else if (
        !onboardingForm.defaultTemplateHighlights ||
        onboardingForm.defaultTemplateHighlights.length === 0 ||
        onboardingForm.defaultTemplateHighlights?.includes(
          TemplateHighlight.PORTFOLIO,
        )
      ) {
        return routes.portfolioOnboarding;
      }
      return routes.pitchingHighlightOnboarding;
    }
  } else if (currentPathname.endsWith(routes.draftTemplateOnboarding)) {
    if (direction === "next") {
      return transferredFromVa
        ? routes.selectBrandOnboarding
        : routes.recommendationOnboarding;
    } else {
      return routes.standOutOnboarding;
    }
  } else if (currentPathname.endsWith(routes.recommendationOnboarding)) {
    if (direction === "next") {
      return purchasedTemplate
        ? routes.dashboard
        : routes.selectBrandOnboarding;
    }
  } else if (currentPathname.endsWith(routes.currentPitchOnboarding)) {
    if (direction === "next") {
      return transferredFromVa
        ? routes.selectBrandOnboarding
        : routes.recommendationOnboarding;
    } else {
      return routes.pitchOnboarding;
    }
  } else if (currentPathname.endsWith(routes.selectBrandOnboarding)) {
    if (direction === "back") {
      return routes.recommendationOnboarding;
    }
  }
}
