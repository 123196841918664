import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext } from "react";
import { SetAlertType } from "schemas/functions";
import { Plans } from "schemas/payments";
import { routes } from "schemas/routes";

import { VA_LANDING_PAGE_LINK } from "constants/influencer";
import { trackEvent } from "utils/tracking";
import { useSubscription } from "utils/useSubscription";

import styles from "./styles";

interface ViewScoreDialogProps {
  open: boolean;
  handleClose: () => void;
  setAlert: SetAlertType;
}

export default function ViewScoreDialog({
  open,
  handleClose,
  setAlert,
}: ViewScoreDialogProps) {
  const { plans, subscription } = useContext(SubscriptionContext);
  const { checkoutLoading, handleCheckout } = useSubscription();

  const trackLearnMore = () => {
    trackEvent("VA Landing Page Clicked", { source: "Upgrade Reply Score" });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <IconButton sx={styles.closeButton} onClick={handleClose}>
        <Box component="i" className="fa-regular fa-xmark" />
      </IconButton>
      <DialogTitle sx={{ textAlign: "center" }}>
        This is a feature for Virtual Assistant Users 🔐
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        Unlock exclusive access to brands more likely to respond with Bento's
        "Reply Score" feature. Bento assigns a rating to brands and contact
        persons based on whether they have previously responded to a creator on
        the platform.
        {plans && (
          <>
            <Typography sx={{ mt: 2 }} variant="h6">
              <strong>${plans[Plans.VA_YEARLY]?.price}/month</strong>
            </Typography>
            <Typography color="textSecondary" paragraph>
              paid yearly
            </Typography>
          </>
        )}
        With this plan, your virtual assistant will reach out to thousands of
        brands on your behalf by leveraging data from the hundreds of thousands
        of email interactions on our platform. We're offering a money-back
        guarantee, but only until <strong>April 7th, 11:59pm EST</strong>. If
        you don't earn back at least what you paid within 12 months, we'll
        refund you in full - no questions asked.{" "}
        <Link
          onClick={trackLearnMore}
          href={VA_LANDING_PAGE_LINK}
          target="_blank"
          sx={{ color: "info.main", textDecoration: "none" }}
        >
          <strong>Learn More</strong>
        </Link>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center" sx={{ mb: 2 }}>
          <LoadingButton
            disableElevation
            variant="contained"
            loading={checkoutLoading}
            onClick={() => {
              let flow = undefined;
              if (
                subscription?.isSubscribed &&
                subscription?.subscription?.planName !== Plans.FREE
              ) {
                flow = "subscription_update";
              }
              handleCheckout(
                Plans.VA_YEARLY,
                flow,
                "_self",
                `/${routes.dashboard}`,
                `View Score Dialog`,
              );
            }}
          >
            {subscription?.subscription?.planName !== Plans.FREE
              ? "Upgrade"
              : "Subscribe"}
          </LoadingButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
