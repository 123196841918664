import {
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext } from "react";
import { BentoSearchFilter } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { titleCase } from "utils/string";

import styles from "./styles";

interface Props {
  options: BentoSearchFilter[];
  values: number[];
  handleChange: (e: CustomEvent) => void;
  name: string;
}
const SingleFilter = ({ values, handleChange, options, name }: Props) => {
  const { excludedBentoSearchFilterIds, includedBentoSearchFilterIds } =
    useContext(OrganizationUserContext);

  const displayMultipleValueSelected = (selected: number[]) => {
    if (selected.length === 0) {
      return <em>None</em>;
    }
    const selectedFilters = options.filter((filter) =>
      selected.includes(filter.id),
    );
    return selectedFilters.map((filter) => titleCase(filter.name)).join(", ");
  };

  const renderHelperText = (searchFilter: BentoSearchFilter) => {
    if (searchFilter?.minTargetAge && searchFilter?.maxTargetAge) {
      return `Age: ${searchFilter.minTargetAge} - ${searchFilter.maxTargetAge}`;
    } else if (searchFilter?.minTargetAge) {
      return `Age: ${searchFilter.minTargetAge} +`;
    } else if (searchFilter?.keywords) {
      return searchFilter?.keywords.join(", ");
    } else {
      return "";
    }
  };

  const filterOptions = () => {
    return options.filter((filter) => {
      if (name === "include") {
        return !excludedBentoSearchFilterIds?.includes(filter.id);
      } else {
        return !includedBentoSearchFilterIds?.includes(filter.id);
      }
    });
  };
  return (
    <FormControl fullWidth>
      <FormHelperText sx={{ ml: 0, mb: 0.5 }}>
        <em>{titleCase(name)}</em> these keywords when finding brands for me:
      </FormHelperText>
      <Select
        multiple
        name={name}
        value={values}
        onChange={handleChange}
        renderValue={(selected) => displayMultipleValueSelected(selected)}
        sx={styles.select}
        displayEmpty
      >
        {filterOptions().map(
          (searchFilter) =>
            (name === "include"
              ? searchFilter?.enableForInclude
              : searchFilter?.enableForExclude) && (
              <MenuItem key={searchFilter.id} value={searchFilter.id}>
                <Checkbox checked={values.indexOf(searchFilter.id) > -1} />
                <ListItemText
                  primary={titleCase(searchFilter.name)}
                  secondary={renderHelperText(searchFilter)}
                />
              </MenuItem>
            ),
        )}
      </Select>
    </FormControl>
  );
};

export default SingleFilter;
