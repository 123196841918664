import {
  BentoBrand,
  BrandContact,
  OpportunityStage,
  Organization,
  OrganizationProfile,
  Subscription,
} from "schemas/dashboard";
import { StringMap } from "schemas/functions";
import { OverridePlanName } from "schemas/payments";

import { ON_BEHALF_OF_TOKEN } from "./localStorage";
import { titleCase } from "./string";

export const getAllStages = (isInfluencer: boolean) => {
  if (isInfluencer) {
    return [
      OpportunityStage.OPPORTUNITIES,
      OpportunityStage.FULFILLING,
      OpportunityStage.COMPLETED,
    ];
  } else {
    return [
      OpportunityStage.NEW,
      OpportunityStage.DISCOVERY,
      OpportunityStage.SCHEDULING_DEMO,
      OpportunityStage.DEMO,
      OpportunityStage.NEGOTIATION,
      OpportunityStage.WON,
      OpportunityStage.LOST,
    ];
  }
};

export const getStageName = (stage: OpportunityStage) => {
  return titleCase(stage);
};

export const getBrandContactName = (contact: BrandContact) => {
  return contact?.name || contact?.title || contact?.email || "";
};

export const hasSocialMediaProfile = (
  organizationProfile: OrganizationProfile | StringMap | null,
) => {
  if (!organizationProfile) {
    return false;
  }
  return (
    organizationProfile?.instagramUrl ||
    organizationProfile?.tiktokUrl ||
    organizationProfile?.youtubeUrl ||
    organizationProfile?.twitchUrl ||
    organizationProfile?.portfolioUrl
  );
};

export const getProfilePlatform = (
  organizationProfile: OrganizationProfile | null,
) => {
  if (organizationProfile?.instagramUrl) {
    return { platform: "Instagram " };
  } else if (organizationProfile?.tiktokUrl) {
    return { platform: "Tiktok " };
  } else if (organizationProfile?.youtubeUrl) {
    return { platform: "YouTube " };
  } else if (organizationProfile?.twitchUrl) {
    return { platform: "Twitch " };
  } else {
    return { platform: "" };
  }
};

export const accessToVaFeature = (
  currentOrg: Organization | null,
  subscription?: Subscription | null,
  isVaPlan?: boolean,
) => {
  return (
    (subscription?.isSubscribed && isVaPlan) ||
    currentOrg?.overridePlanName === OverridePlanName.VA
  );
};

export const getSourceLabel = (bentoBrand: BentoBrand) => {
  const isAdmin = Boolean(sessionStorage.getItem(ON_BEHALF_OF_TOKEN));

  if (bentoBrand?.isAdmin) {
    return "From Virtual Assistant";
  } else if (bentoBrand?.createdByAutomation) {
    return "From Automation";
  } else {
    return isAdmin ? "From User" : "";
  }
};

export const getLocation = (profile?: OrganizationProfile | null) => {
  let formattedString = "";
  if (profile?.addressComponents) {
    const city = profile?.addressComponents?.find((x) =>
      x.types?.includes("locality"),
    )?.longText;
    if (city) {
      formattedString += city;
    }
    const country = profile?.addressComponents?.find((x) =>
      x.types?.includes("country"),
    )?.longText;
    if (country) {
      if (city) {
        formattedString += `, ${country}`;
      } else {
        formattedString += country;
      }
    }
  }
  return formattedString;
};
