import {
  Backdrop,
  Box,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { grey } from "@mui/material/colors";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { forwardRef, useContext, useEffect, useState } from "react";
import { BentoBrand } from "schemas/dashboard";

import { trackEvent } from "utils/tracking";

import BrandBody from "../../DetailedBrandView/components/BrandBody";
import { useBrandDetails } from "../../DetailedBrandView/components/BrandBody/useBrandDetails";
import BrandTitle from "../../Shared/BrandTitle";
import { BrandCardSource } from "../schema";
import styles from "./styles";

interface BrandCardProps {
  brand: BentoBrand;
  source?: BrandCardSource;
}

export default forwardRef<HTMLDivElement, BrandCardProps>(
  ({ brand, source }, ref) => {
    const { previewLoading, handleChangeQuickSend } = useContext(
      QuickSendDrawerContext,
    );
    const { setBrandNavigationHistory } = useContext(DiscoverViewContext);
    const [expanded, setExpanded] = useState(false);

    const handleClickCard = () => {
      trackEvent("Brand Card Clicked", {
        "Bento Brand ID": brand?.id,
      });
      setBrandNavigationHistory([brand]);
      setExpanded(!expanded);
      handleChangeQuickSend(brand?.id, "Discover");
    };

    const {
      fetchSelectedBentobrand,
      selectedBrand,
      fetchLoading,
      similarBrands,
      fetchSimilarLoading,
    } = useBrandDetails({ brandDetailsId: brand?.id });

    useEffect(() => {
      if (brand?.id && expanded) {
        fetchSelectedBentobrand();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand?.id, expanded]);

    return (
      <Box ref={ref} sx={styles.brandCard} key={brand?.id} component="div">
        <CardHeader
          sx={[
            {
              ".MuiCardHeader-avatar": {
                alignSelf: "flex-start",
                mt: 1.5,
              },
              cursor: "pointer",
              "@media (hover: hover) and (pointer: fine)": {
                "&:hover": { backgroundColor: grey[100] },
              },
            },
          ]}
          onClick={handleClickCard}
          title={
            <Grid
              container
              alignItems="center"
              gap={1}
              justifyContent="space-between"
            >
              <BrandTitle
                bentoBrand={brand}
                source={source}
                expanded={expanded}
              />
            </Grid>
          }
        />
        {expanded && (
          <Collapse in={expanded}>
            <CardContent>
              <BrandBody
                selectedBrand={selectedBrand}
                fetchLoading={fetchLoading}
                similarBrands={similarBrands}
                fetchSimilarLoading={fetchSimilarLoading}
              />
            </CardContent>
          </Collapse>
        )}

        <Backdrop
          sx={{ color: "#fff", zIndex: 201 }}
          open={previewLoading === brand.id}
        >
          <CircularProgress />
        </Backdrop>
      </Box>
    );
  },
);
