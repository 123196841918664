import { EmailSequenceMap, SendOption } from "schemas/dashboard";
import { EmailSettings } from "schemas/email";
import { ALL } from "schemas/forms";

import { Template } from "features/Influencer/ContactList/schema";

import { BulkSendBrandsGroup, EmailSettingType } from "./schema";

export const groupBrandByCategories = (
  templates: Template[],
  allEmailSettings: EmailSettings[],
  emailSequences: EmailSequenceMap,
  sendOption: SendOption,
) => {
  const categories: BulkSendBrandsGroup = {};
  for (const emailSequence of Object.values(emailSequences)) {
    const fallbackTemplates: Template[] = [];
    const category =
      sendOption === SendOption.by_category ? emailSequence.category : ALL;
    if (!categories[category]) {
      const emailSetting = allEmailSettings.find(
        (es) => es.category === category.toLowerCase().trim(),
      );
      if (emailSetting && emailSetting?.emailSettingTemplates) {
        for (const es of emailSetting.emailSettingTemplates) {
          const templateId = es.templateId;
          const template = templates.find((t) => t.id === templateId);
          if (template) {
            fallbackTemplates.push(template);
          }
        }
      }
      categories[category] = {
        emailSequences: [],
        fallbackTemplates,
        type: EmailSettingType.category,
      };
    }
    categories[category]["emailSequences"].push(emailSequence);
  }
  if (sendOption === SendOption.by_category) {
    // Sort categories key alphabetically
    const sortedKeys = Object.keys(categories).sort();
    const sortedCategories: BulkSendBrandsGroup = {};
    for (const key of sortedKeys) {
      sortedCategories[key] = categories[key];
    }
    // Add Uncategorized list
    const uncategorizedFallbackTemplates: Template[] = [];
    const uncategorizedEmailSetting = allEmailSettings.find(
      (es) => es.category === null && es.automationId === null,
    );
    if (
      uncategorizedEmailSetting &&
      uncategorizedEmailSetting?.emailSettingTemplates
    ) {
      for (const es of uncategorizedEmailSetting.emailSettingTemplates) {
        const templateId = es.templateId;
        const template = templates.find((t) => t.id === templateId);
        if (template) {
          uncategorizedFallbackTemplates.push(template);
        }
      }
    }
    sortedCategories["Uncategorized"] = {
      emailSequences: [],
      fallbackTemplates: uncategorizedFallbackTemplates,
      type: EmailSettingType.category,
    };
    return sortedCategories;
  } else {
    return categories;
  }
};
