import { Box, Grid, IconButton, Typography } from "@mui/material";
import { routes } from "schemas/routes";

import { getPageName } from "constants/trackingProps";
import DiscoverSearchBar from "features/Influencer/Discover/Search";
import { isPage } from "utils/general";

import styles from "./styles";

interface TopNavigationProps {
  width: string;
  handleClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    isProfileAnchor: boolean,
  ) => void;
  profileMenu: JSX.Element;
}

function TopNavigationMobile({
  width,
  handleClick,
  profileMenu,
}: TopNavigationProps) {
  const isDiscoverPage = isPage(routes.brands);

  const menuBarsMobileIcon = (
    <IconButton onClick={(e) => handleClick(e, false)} sx={styles.iconButton}>
      <Box component="i" className="fa-regular fa-bars" />
    </IconButton>
  );

  return (
    <Grid
      sx={[
        styles.mobileNavigation,
        {
          width,
        },
      ]}
      gap={1}
      justifyContent="space-between"
      alignItems="center"
    >
      {isDiscoverPage ? (
        <>
          {menuBarsMobileIcon} <DiscoverSearchBar />
        </>
      ) : (
        <Typography variant="h6" component="div" sx={{ alignItems: "center" }}>
          {menuBarsMobileIcon} <strong>{getPageName()}</strong>
        </Typography>
      )}

      {profileMenu}
    </Grid>
  );
}

export default TopNavigationMobile;
