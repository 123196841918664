import { grey } from "@mui/material/colors";

const styles = {
  button: {
    textTransform: "none",
    color: "black",
    border: `1px solid ${grey[500]}`,
  },
  textField: {
    "& .MuiInputBase-root": { backgroundColor: "white" },
    "& .MuiFormHelperText-root": { color: "error.main" },
  },
  nicheText: {
    color: "black",
    fontSize: 14,
    fontWeight: 600,
    textTransform: "capitalize",
  },
  label: {
    fontSize: 10,
    color: grey[500],
  },
};

export default styles;
