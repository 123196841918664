import { Box, Checkbox, CircularProgress, IconButton } from "@mui/material";
import { CustomEvent } from "schemas/functions";

import styles from "../styles";

interface Props {
  isFrozen: boolean;
  isBulkMode: boolean;
  isChecked: boolean;
  handleSelect: () => void;
  bentoBrand: any;
  removeLoading: number | null;
  handleDelete: (e: CustomEvent) => void;
}

const LeftActionButton = ({
  isFrozen,
  isBulkMode,
  isChecked,
  handleSelect,
  bentoBrand,
  removeLoading,
  handleDelete,
}: Props) => {
  if (isFrozen) return <></>;
  if (isBulkMode) {
    return (
      <Box onClick={(e) => e.stopPropagation()}>
        <Checkbox
          onChange={handleSelect}
          disableRipple
          checked={isChecked}
          icon={
            <Box
              sx={{ color: "green.main" }}
              component="i"
              className="fa-regular fa-square"
            />
          }
          checkedIcon={
            <Box
              component="i"
              className="fa-solid fa-square-check"
              sx={{ color: "green.main" }}
            />
          }
        />
      </Box>
    );
  } else {
    return (
      <>
        {removeLoading === bentoBrand?.id ? (
          <CircularProgress size={20} />
        ) : (
          <IconButton onClick={handleDelete} sx={styles.removeButton}>
            <Box component="i" className="fa-regular fa-circle-xmark" />
          </IconButton>
        )}
      </>
    );
  }
};

export default LeftActionButton;
