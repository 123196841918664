import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { OrganizationUser } from "schemas/dashboard";
import { SetAlertType } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { formatEpochTimestamp } from "utils/time";

import { AdminAddOnItem } from "./schema";

interface Props {
  setAlert: SetAlertType;
  organizationUser: OrganizationUser | null;
}

const AdminAddOns = ({ organizationUser, setAlert }: Props) => {
  const { getToken } = useAuth();
  const [userPurchases, setUserPurchases] = useState<AdminAddOnItem[]>([]);
  const [loading, setLoading] = useState(false);

  const [emailLoading, setEmailLoading] = useState(false);

  const fetchUserPurchases = async () => {
    if (!organizationUser) return;
    try {
      setLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/admin/organizations/${organizationUser?.organizationId}/add-ons`,
        "GET",
      );
      setUserPurchases(res.userPurchases);
    } catch (error) {
      setAlert(error.message || "Unable to fetch user purchases", "error");
    } finally {
      setLoading(false);
    }
  };

  const sendTemplateCompletedEmail = async () => {
    try {
      setEmailLoading(true);
      await fetcherAuth(
        getToken,
        `/api/admin/organizations/${organizationUser?.organizationId}/add-ons/send-completed-template-email`,
        "POST",
      );
      setAlert("Succesfully sent email.", "success");
    } catch (error) {
      setAlert(error.message || "Unable to send email", "error");
    } finally {
      setEmailLoading(false);
    }
  };

  useEffect(() => {
    fetchUserPurchases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationUser?.id]);
  return (
    <>
      <Typography variant="h6">Add-Ons Purchases</Typography>

      <LoadingButton
        size="small"
        variant="contained"
        disableElevation
        loading={emailLoading}
        onClick={sendTemplateCompletedEmail}
      >
        Send Template Completed Email
      </LoadingButton>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer>
          <Table sx={{ maxWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date Purchased</TableCell>
                <TableCell align="right">Item Name</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Purchase Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userPurchases.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {formatEpochTimestamp({
                      epochTimestamp: row?.createdAt,
                      fullDate: true,
                    })}
                  </TableCell>
                  <TableCell align="right">{row.itemName}</TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">
                    {row?.document && (
                      <Link
                        target="_blank"
                        sx={{ cursor: "pointer" }}
                        href={row.document}
                      >
                        View Google Doc
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default AdminAddOns;
