import { grey } from "@mui/material/colors";

const styles = {
  root: {
    overflowY: "auto",
  },
  close: {
    fontSize: 14,
  },
  editor: {
    px: 2,
    borderBottom: `1px solid ${grey[300]}`,
  },
  templateRow: {
    borderBottom: `1px solid ${grey[300]}`,
    pl: 2,
    py: 1.5,
    cursor: "pointer",
  },
  templateName: {
    color: grey[900],
    fontSize: 13,
    maxWidth: 400,
    textOverflow: "ellipsis",
  },
  addTemplate: { px: 1.5, py: 2 },
  templateLink: {
    cursor: "pointer",
    textDecoration: "none",
    textTransform: "none",
  },
  writeLink: {
    cursor: "pointer",
    textDecoration: "none",
    textTransform: "none",
  },
  header: {
    borderBottom: `1px solid ${grey[200]}`,
    py: 1.5,
    px: 2,
  },
};

export default styles;
