import { Grid } from "@mui/material";
import { forwardRef } from "react";
import { BentoBrand } from "schemas/dashboard";

import SkeletonBrand from "../../SkeletonBrand";
import SelectedBrand from "./SelectedBrand";

const styles = {
  container: {
    maxHeight: "calc(100vh - 100px)",
    overflow: "auto",
    pb: 15,
  },
};

const REF_OFFSET = 9;

interface IProps {
  displayedBrands: BentoBrand[];
  loading: boolean;
  collectionId: number;
  isBulkMode: boolean;
  isDefaultCollection?: boolean;
}

const BrandList = forwardRef(
  (
    {
      displayedBrands,
      loading,
      collectionId,
      isBulkMode,
      isDefaultCollection,
    }: IProps,
    ref,
  ) => {
    return (
      <Grid container sx={styles.container}>
        {displayedBrands.map((bentoBrand: BentoBrand, index: number) => (
          <SelectedBrand
            key={bentoBrand.id}
            ref={
              (
                REF_OFFSET > displayedBrands.length
                  ? displayedBrands.length - 1 === index
                  : displayedBrands.length - REF_OFFSET === index
              )
                ? ref
                : null
            }
            bentoBrand={bentoBrand}
            collectionId={collectionId}
            isBulkMode={isBulkMode}
            isDefaultCollection={isDefaultCollection}
          />
        ))}
        {loading && (
          <>
            <SkeletonBrand />
            <SkeletonBrand />
            <SkeletonBrand />
          </>
        )}
      </Grid>
    );
  },
);

export default BrandList;
