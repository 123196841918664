import { useAuth } from "@clerk/clerk-react";
import { AlertContext } from "contexts/Alert";
import { BrandsContext } from "contexts/Brands";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useState } from "react";
import { BentoBrand } from "schemas/dashboard";

import { fetcherAuth } from "utils/api";

interface Props {
  brandDetailsId: number;
}
const _SIZE_OF_SIMILAR_BRANDS = 5;

export const useBrandDetails = ({ brandDetailsId }: Props) => {
  const [selectedBrand, setSelectedBrand] = useState<BentoBrand | null>(null);
  const { selectedBrandCache, setSelectedBrandCache } =
    useContext(BrandsContext);
  const { getToken } = useAuth();
  const { setErrorAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);

  const [fetchLoading, setFetchLoading] = useState(true);
  const [fetchSimilarLoading, setFetchSimilarLoading] = useState(true);
  const [similarBrands, setSimilarBrands] = useState<BentoBrand[]>([]);

  const fetchSelected = async () => {
    if (selectedBrandCache[brandDetailsId]?.brand) {
      setSelectedBrand(selectedBrandCache[brandDetailsId]?.brand);
      setFetchLoading(false);
      return;
    }

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-brands/detailed-brand-view/${brandDetailsId}`,
      );
      setSelectedBrand(res.bentoBrand);
      setSelectedBrandCache((prev) => ({
        ...prev,
        [brandDetailsId]: {
          ...prev[brandDetailsId],
          brand: res.bentoBrand,
        },
      }));
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const fetchSimilarBrand = async () => {
    if (selectedBrandCache[brandDetailsId]?.similarTo) {
      setSimilarBrands(selectedBrandCache[brandDetailsId]?.similarTo);
      setFetchSimilarLoading(false);
      return;
    }

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-brands/${brandDetailsId}/similar?size=${_SIZE_OF_SIMILAR_BRANDS}`,
      );
      setSimilarBrands(res.bentoBrands);
      setSelectedBrandCache((prev) => ({
        ...prev,
        [brandDetailsId]: {
          ...prev[brandDetailsId],
          similarTo: res.bentoBrands,
        },
      }));
    } finally {
      setFetchSimilarLoading(false);
    }
  };

  const fetchSelectedBentobrand = async () => {
    if (!currentOrg?.id) {
      return;
    }

    setFetchLoading(true);
    setFetchSimilarLoading(true);
    try {
      await Promise.all([fetchSelected(), fetchSimilarBrand()]);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  return {
    fetchSelectedBentobrand,
    selectedBrand,
    fetchLoading,
    similarBrands,
    fetchSimilarLoading,
  };
};
