import { Box, Button, Chip, Typography } from "@mui/material";
import { BentoCategoryMap } from "contexts/BentoCategories";
import { BentoBrandSearchParameters } from "schemas/dashboard";

import { getTagLabelFromParams, parseQuery } from "utils/discoverSearch";
import { titleCase } from "utils/string";

import styles from "./styles";

interface Props {
  categoriesById: BentoCategoryMap;
  searchParameters: BentoBrandSearchParameters;
  isDashboardSearch?: boolean;
  handleReplace: () => void;
}

const AutomationParams = ({
  categoriesById,
  searchParameters,
  isDashboardSearch,
  handleReplace,
}: Props) => {
  const renderField = (key: string) => {
    if (key === "query" && searchParameters[key]) {
      // @ts-ignore
      const { include, exclude } = parseQuery(searchParameters[key]);

      return (
        <>
          {include?.length > 0 && (
            <Box sx={styles.labelContainer}>
              Query (include):
              <Chip sx={styles.chip} size="small" label={include?.join(", ")} />
            </Box>
          )}
          {exclude?.length > 0 && (
            <Box sx={styles.labelContainer}>
              Query (exclude):
              <Chip sx={styles.chip} size="small" label={exclude?.join(", ")} />
            </Box>
          )}
        </>
      );
    }

    let labels;
    if (key === "category_ids") {
      labels =
        searchParameters[key]?.map((id) => categoriesById[parseInt(id)].name) ||
        [];
    } else {
      labels = getTagLabelFromParams(key, searchParameters);
    }

    return labels?.length > 0 ? (
      <Box sx={styles.labelContainer}>
        {titleCase(key)}:{" "}
        {labels?.map(
          (label, index) =>
            label && (
              <Chip sx={styles.chip} key={index} size="small" label={label} />
            ),
        )}
      </Box>
    ) : (
      <></>
    );
  };

  return (
    <>
      {!isDashboardSearch && (
        <Typography sx={{ mt: 2, mb: 1, fontSize: 16 }}>
          <strong>Parameters for this current automation:</strong>
        </Typography>
      )}

      {isDashboardSearch && (
        <Box sx={{ my: 1 }}>
          <em>Update this automation with current search?</em>

          <Button
            sx={{ ml: 1 }}
            size="small"
            variant="outlined"
            onClick={handleReplace}
          >
            Replace
          </Button>
        </Box>
      )}

      {Object.keys(searchParameters)?.map((key) => (
        <Box key={key}>{renderField(key)}</Box>
      ))}
    </>
  );
};

export default AutomationParams;
