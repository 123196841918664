import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  OrganizationVaProfile,
  VaNicheSpecificDetails,
  VaNicheType,
  VaOnboardingType,
  VaOrganizationProfileStatus,
} from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import VideoEmbed from "components/VideoEmbed";
import { vaOnboardingNavigate } from "features/Auth/Onboarding/helper";
import { getSource } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

interface Props {
  organizationVaProfile: OrganizationVaProfile | null;
  setOrganizationVaProfile: Dispatch<
    SetStateAction<OrganizationVaProfile | null>
  >;
  saveVaProfileForm: (
    organizationVaProfile: OrganizationVaProfile | null,
    page?: string,
    source?: string | null,
    skipMovingNext?: boolean,
  ) => void;
}

const VaNichePickForm = ({
  organizationVaProfile,
  setOrganizationVaProfile,
  saveVaProfileForm,
}: Props) => {
  const { setAlert } = useContext(AlertContext);
  const location = useLocation();
  const navigate = useNavigate();
  const slideContext = useContext(SlideAnimationContext);

  const hasCompleted =
    organizationVaProfile?.status === VaOrganizationProfileStatus.completed;

  const [niches, setNiches] = useState<VaNicheSpecificDetails[]>([
    { name: "", type: VaNicheType.general, is_top_performing: false },
    { name: "", type: VaNicheType.general, is_top_performing: false },
    { name: "", type: VaNicheType.specific, is_top_performing: false },
    { name: "", type: VaNicheType.specific, is_top_performing: false },
  ]);

  const allQuestionsAnswered = () => {
    return niches.every((niche: VaNicheSpecificDetails) => {
      return niche.name?.trim()?.length > 0;
    });
  };

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "back")}${location.search}`,
    );
  };

  const handleNext = async (e: CustomEvent) => {
    e.preventDefault();
    if (!allQuestionsAnswered()) {
      setAlert("Please fill out all niches", "error");
      return;
    }
    const copy = makeDeepCopy(organizationVaProfile);
    const source = getSource();
    copy.niches = niches;
    saveVaProfileForm(copy, location.pathname, source);
  };

  const onChange = (e: CustomEvent, index: number) => {
    const { name, value } = e.target;
    setNiches((prev) => {
      const copy = makeDeepCopy(prev);
      copy[index][name] = value;
      return copy;
    });
  };

  const handleTopPerforming = (e: CustomEvent, index: number) => {
    const { checked } = e.target;
    setNiches((prev) => {
      const copy = makeDeepCopy(prev);
      const hasCheckedIndex = copy.findIndex(
        (x: VaNicheSpecificDetails) => x.is_top_performing,
      );
      if (hasCheckedIndex !== index && hasCheckedIndex > -1 && checked) {
        copy[hasCheckedIndex]["is_top_performing"] = false;
      }
      copy[index]["is_top_performing"] = checked;
      return copy;
    });
  };

  const renderNicheQuestion = (index: number, type: VaNicheType) => {
    return (
      <Grid container alignItems="center" sx={{ mb: 2 }} gap={2}>
        <Grid item xs={12} md>
          <Typography sx={styles.nicheText}>{type} Niche</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <TextField
            sx={styles.textField}
            fullWidth
            name="name"
            placeholder={"Type niche here..."}
            onChange={(e) => onChange(e, index)}
            required
            value={niches[index].name}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControlLabel
            onChange={(e) => handleTopPerforming(e, index)}
            control={
              <Checkbox
                icon={<Box component="i" className="fa-regular fa-square" />}
                checkedIcon={
                  <Box component="i" className="fa-solid fa-check-square" />
                }
                checked={niches[index].is_top_performing}
              />
            }
            label={<FormHelperText>My top performing niche</FormHelperText>}
          />
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (
      organizationVaProfile?.niches &&
      Object.keys(organizationVaProfile?.niches)?.length > 0
    ) {
      setNiches(Object.values(organizationVaProfile?.niches));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationVaProfile?.niches]);

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center", mt: 0 }}>
        {organizationVaProfile?.onboardingType === VaOnboardingType.book_call
          ? "Prework: Picking your niches"
          : "Let's get you onboarded: Picking your niches"}{" "}
        🚀
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ mb: 3, fontSize: 14 }}>
        The first step is to pick your niches. We recommend you select:
        <ul>
          <li>
            The (general) niche you've had the most success with in the past
            (i.e, where you've hahd the most paid deals)
          </li>
          <li>
            The (general) niche you've had the second most success with in the
            past
          </li>
          <li>
            The (specific) niche where you have a unique edge for creating
            content
          </li>
          <li>The (specific) niche you're looking to explore more</li>
        </ul>
        Some things to know:
        <ul>
          <li>
            The niches you select will be the ones for which we create
            personalized pitch templates. We wil create a total of 5 pitches: 4
            for the niches you select and 1 general pitch that can be used for
            any niche.
          </li>
          <li>
            Your VA can pitch to other niches using your General pitch template
            (but they will prioritize the specific niche templates you currently
            have).
          </li>
          <li>
            You can always create additional pitches for other niches and ask
            your VA to use those templates for pitches to other niches (if you
            don't want them using the general pitch).
          </li>
        </ul>
      </Typography>

      <Grid
        container
        justifyContent="center"
        flexDirection="column"
        sx={{ my: 3 }}
      >
        <FormHelperText sx={{ textAlign: "center" }}>
          Watch this video to guide you on how to choose your niches!
        </FormHelperText>
        <VideoEmbed
          link={
            "https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/niche_part1.mov"
          }
          eventName="VA Onboarding Flow - How To Choose Niche Video Clicked"
        />
      </Grid>
      <form onSubmit={handleNext}>
        {niches?.length > 0 &&
          niches?.map((niche, index) => renderNicheQuestion(index, niche.type))}

        <Grid item xs="auto" container columnGap={1} sx={{ mt: 4 }}>
          {!hasCompleted && (
            <Grid item xs="auto">
              <Button onClick={handleBack} color="secondary" disableElevation>
                Back
              </Button>
            </Grid>
          )}
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <Button disableElevation variant="contained" type="submit">
              Next{" "}
              <Box
                sx={{ ml: 1 }}
                component="i"
                className="fa-solid fa-arrow-right"
              />
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default VaNichePickForm;
