import { grey } from "@mui/material/colors";

const styles = {
  card: {
    cursor: "pointer",
    border: `1px solid ${grey[200]}`,
    flex: 1,
    "&:hover": {
      backgroundColor: grey[50],
    },
  },
  cardHeader: (isPopView?: boolean) => ({
    ".MuiCardHeader-avatar": {
      alignSelf: "flex-start",
    },
    width: isPopView
      ? "auto"
      : {
          xs: "250px",
          sm: "300px",
        },
    minWidth: isPopView ? "250px" : "auto",
  }),
};

export default styles;
