import { Map } from "schemas/functions";
import {
  INSTAGRAM_USERNAME_URL,
  TIKTOK_USERNAME_URL,
  TWITCH_USERNAME_URL,
  YOUTUBE_USERNAME_URL,
} from "schemas/social";

export enum SocialMediaFieldKey {
  instagram = "instagram",
  tiktok = "tiktok",
  youtube = "youtube",
  twitch = "twitch",
  portfolio = "portfolio",
}
export const SocialMediaFields: {
  [key: string]: { url: string; name: string; key: string };
} = {
  instagramUrl: {
    url: INSTAGRAM_USERNAME_URL,
    name: "Instagram",
    key: SocialMediaFieldKey.instagram,
  },
  tiktokUrl: {
    url: TIKTOK_USERNAME_URL,
    name: "TikTok",
    key: SocialMediaFieldKey.tiktok,
  },
  youtubeUrl: {
    url: YOUTUBE_USERNAME_URL,
    name: "YouTube",
    key: SocialMediaFieldKey.youtube,
  },
  twitchUrl: {
    url: TWITCH_USERNAME_URL,
    name: "Twitch",
    key: SocialMediaFieldKey.twitch,
  },
  portfolioUrl: {
    url: "https://",
    name: "Portfolio",
    key: SocialMediaFieldKey.portfolio,
  },
};

export const sanitizeInput = (str: string | undefined) => {
  const inputStr = str?.trim();
  if (!inputStr) {
    return "";
  }
  const regex =
    /(?:instagram\.com|tiktok\.com|youtube\.com|twitch\.tv)\/(?:_u\/|@)?([^/?\s]*)(\/|\?|$)/;
  const matches = regex.exec(inputStr);
  const extractedUsername = matches && matches.length > 1 ? matches[1] : null;
  if (extractedUsername !== null) return extractedUsername;
  if (inputStr.startsWith("@")) {
    return inputStr.slice(1);
  }

  const urlRegex = /^(?:https?:\/\/)?(?:www\.)?([^/?\s]*)/;
  const urlMatches = urlRegex.exec(inputStr);
  const extractedUrl =
    urlMatches && urlMatches.length > 1 ? urlMatches[1] : null;
  if (extractedUrl !== null) return extractedUrl;
  return inputStr;
};

export const isValidUsername = (str: string | undefined) => {
  if (!str) {
    return false;
  }
  const regex = /^[a-zA-Z0-9_.-]+$/;
  return regex.test(str);
};

export const getUserType = (onboardingForm: Map) => {
  if (onboardingForm?.isUgcCreator && onboardingForm?.isInfluencer) {
    return "both";
  } else if (onboardingForm?.isUgcCreator) {
    return "ugc";
  } else if (onboardingForm?.isInfluencer) {
    return "influencer";
  } else {
    return "";
  }
};

export type SocialMediaLinks = {
  fieldName: SocialMediaFieldKey;
  link: string;
};

export const convertOnboardingFormToLink = (onboardingForm: Map) => {
  const links = [];
  let hasKey = false;
  for (const key in SocialMediaFieldKey) {
    if (onboardingForm[`${key}Url`]) {
      links.push({
        fieldName: key as SocialMediaFieldKey,
        link: sanitizeInput(onboardingForm[`${key}Url`]),
      });
      hasKey = true;
    }
  }

  if (!hasKey && onboardingForm?.isInfluencer) {
    links.push({
      fieldName: SocialMediaFieldKey.instagram,
      link: "",
    });
  } else if (!hasKey) {
    links.push({
      fieldName: SocialMediaFieldKey.portfolio,
      link: "",
    });
  }

  return links;
};
