import { Box, useMediaQuery, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { BrandsContext } from "contexts/Brands";
import { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchParams } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";
import { routes } from "schemas/routes";

import { isPage } from "utils/general";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

export default function DiscoverSearchBar() {
  const theme = useTheme();
  const { abortController } = useContext(BrandsContext);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const brandQuery = searchParams.get(SearchParams.QUERY_DISCOVER);

  const handleAutocompleteChange = (e: CustomEvent, value: any) => {
    handleSearch(value?.trim() || "");
  };

  const handleKeyDown = (e: CustomEvent) => {
    const value = e.target.value;
    if (e.keyCode === 13) {
      handleSearch(value?.trim() || "");
    }
  };

  const handleSearch = async (value: string) => {
    if (abortController?.current) {
      abortController.current?.abort();
    }
    if (!isPage(routes.brands)) {
      navigate(`/${routes.brands}/all?${SearchParams.QUERY_DISCOVER}=${value}`);
    } else {
      searchParams.delete(SearchParams.TAGS_DISCOVER);
      searchParams.delete(SearchParams.TYPE_DISCOVER);
      searchParams.delete(SearchParams.SIZE_DISCOVER);
      searchParams.delete(SearchParams.FOLLOWING_DISCOVER);
      searchParams.delete(SearchParams.COMPENSATION_DISCOVER);
      searchParams.delete(SearchParams.LOCATION_DISCOVER);
      searchParams.delete(SearchParams.SORT_DISCOVER);
      searchParams.set(SearchParams.QUERY_DISCOVER, value);
      setSearchParams(searchParams);
    }

    trackEvent("Search Keyword Entered", { keyword: value });
  };

  const isDiscoverPage = isPage(routes.brands);
  const hideBar = !isDiscoverPage && isMobileScreen;

  return hideBar ? (
    <></>
  ) : (
    <Autocomplete
      sx={{ width: "100%" }}
      value={brandQuery || ""}
      freeSolo
      loadingText="Searching..."
      options={[]}
      onChange={handleAutocompleteChange}
      disablePortal
      renderInput={(params) => (
        <TextField
          sx={styles.root}
          {...params}
          placeholder="Search Brand, City, Category, Aesthetic..."
          InputProps={{
            ...params.InputProps,
            startAdornment: !isMobileScreen && (
              <Box
                component="i"
                className="fa-regular fa-search"
                sx={{ px: 2 }}
              />
            ),
          }}
          onKeyDown={handleKeyDown}
        />
      )}
    />
  );
}
