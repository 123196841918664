import { Theme, lighten } from "@mui/material";

const styles = {
  hover: (theme: Theme) => ({
    "&:hover": {
      color: "white",
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
  }),
  selected: {
    backgroundColor: "primary.main",
    color: "white",
    "&:hover": {
      backgroundColor: "primary.main",
    },
  },
};

export default styles;
