import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  OrganizationVaProfile,
  VaBackground,
  VaBackgroundDetailsField,
} from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import VideoEmbed from "components/VideoEmbed";
import { vaOnboardingNavigate } from "features/Auth/Onboarding/helper";
import { getSource } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import { DefaultBackgroundQuestions } from "./schema";
import styles from "./styles";

interface Props {
  organizationVaProfile: OrganizationVaProfile | null;
  setOrganizationVaProfile: Dispatch<
    SetStateAction<OrganizationVaProfile | null>
  >;
  saveVaProfileForm: (
    organizationVaProfile: OrganizationVaProfile | null,
    page?: string,
    source?: string | null,
    skipMovingNext?: boolean,
  ) => void;
}

const VaBackgroundForm = ({
  organizationVaProfile,
  setOrganizationVaProfile,
  saveVaProfileForm,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const slideContext = useContext(SlideAnimationContext);

  const [background, setBackground] = useState<VaBackground | null>(
    DefaultBackgroundQuestions,
  );

  const allQuestionsAnswered = () => {
    return Object.values(background || {}).every((value) => {
      return value.answer?.trim()?.length > 0;
    });
  };

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "back")}${location.search}`,
    );
  };

  const handleNext = async (e: CustomEvent) => {
    e.preventDefault();
    if (!allQuestionsAnswered()) {
      setAlert("Please fill out all questions", "error");
      return;
    }
    const copy = makeDeepCopy(organizationVaProfile);
    copy.backgroundDetails = background;
    saveVaProfileForm(copy, location.pathname, getSource());
  };

  const onChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    setBackground((prev) => {
      const copy = makeDeepCopy(prev);
      copy[name]["answer"] = value;
      return copy;
    });
  };

  const renderBackgroundQuestion = (
    fieldName: VaBackgroundDetailsField,
    rows?: number,
  ) => {
    return (
      <Grid item xs={12}>
        <FormHelperText>{background?.[fieldName]?.question} *</FormHelperText>
        <TextField
          sx={styles.textField}
          fullWidth
          name={fieldName}
          placeholder={"Type your answer here..."}
          onChange={onChange}
          required
          value={background?.[fieldName]?.answer}
          multiline
          rows={rows || 3}
        />
      </Grid>
    );
  };

  useEffect(() => {
    if (
      organizationVaProfile?.backgroundDetails &&
      Object.keys(organizationVaProfile?.backgroundDetails)?.length > 0
    ) {
      setBackground(organizationVaProfile?.backgroundDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationVaProfile?.backgroundDetails]);

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center", mt: 0 }}>
        3/4
        <br />
        Let's get to know you and your background
      </Typography>

      <Grid container justifyContent="center" sx={{ my: 3 }}>
        <FormHelperText sx={{ textAlign: "center" }}>
          Watch this video to guide you on how to answer the questions below.
        </FormHelperText>

        <VideoEmbed
          link="https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/niche_part3.mov"
          eventName="VA Onboarding Flow - Answer Background Questions Video Clicked"
        />
      </Grid>

      <form onSubmit={handleNext}>
        <Typography sx={{ mb: 2 }}>
          <strong>
            ❗ Don't worry about how you write things; we use copywriters to
            make everything sound polished. Feel free to ramble, write in bullet
            points, or write an essay. It's better to write more than less.{" "}
          </strong>
        </Typography>

        {Object.keys(background || {}).map((fieldName) => {
          return renderBackgroundQuestion(
            fieldName as VaBackgroundDetailsField,
            fieldName === VaBackgroundDetailsField.other ? 6 : 3,
          );
        })}
        <Grid item xs="auto" container columnGap={1} sx={{ mt: 4 }}>
          <Grid item xs="auto">
            <Button onClick={handleBack} color="secondary" disableElevation>
              Back
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <Button disableElevation variant="contained" type="submit">
              Next{" "}
              <Box
                sx={{ ml: 1 }}
                component="i"
                className="fa-solid fa-arrow-right"
              />
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default VaBackgroundForm;
