import { Chip, Grid } from "@mui/material";
import { BrandsContext } from "contexts/Brands";
import { useContext, useEffect } from "react";
import {
  BentoBrand,
  BentoBrandMetadataTags,
  MetadataType,
} from "schemas/dashboard";

import { useAutoCompletePrediction } from "components/LocationAutocomplete/useAutoCompletePrediction";
import { getColorForMetadataTag } from "utils/color";
import { DISCOVER_METADATA_TAGS } from "utils/localStorage";
import { useSearchBrand } from "utils/useSearchBrand";

interface Props {
  selectedBrand?: BentoBrand | null;
  justifyContent?: "flex-start" | "center";
}

const BrandMetadata = ({
  selectedBrand,
  justifyContent = "flex-start",
}: Props) => {
  const { setSelectedMetadataTags } = useContext(BrandsContext);
  const { handleAddGeopoint, handleClickOnTag } = useSearchBrand();
  const { geopoint, inputValue, setInputValue } =
    useAutoCompletePrediction(handleAddGeopoint);

  useEffect(() => {
    if (geopoint?.country_code && geopoint?.longitude && geopoint?.latitude) {
      setSelectedMetadataTags((prev) => {
        const exists = prev?.find((x) => x.value === inputValue);
        if (!exists) {
          prev.push({
            ...geopoint,
            type: MetadataType.location,
            value: inputValue,
          });
        }
        sessionStorage.setItem(DISCOVER_METADATA_TAGS, JSON.stringify(prev));
        return prev;
      });
      setInputValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geopoint]);

  if (!selectedBrand) return <></>;

  return (
    <Grid container justifyContent={justifyContent} item xs={12} gap={1}>
      {selectedBrand?.metadata?.map(
        (tag: BentoBrandMetadataTags, index: number) => (
          <Chip
            onClick={(e) => handleClickOnTag(e, tag, setInputValue)}
            size="small"
            sx={{
              backgroundColor: getColorForMetadataTag(tag.type),
              textTransform: "capitalize",
              fontSize: 11,
            }}
            label={tag.value}
            key={index}
          />
        ),
      )}
    </Grid>
  );
};

export default BrandMetadata;
