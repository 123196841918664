import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { BentoCategory } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import styles from "./styles";

interface Props {
  topLevelCategories: BentoCategory[];
  expandCategory: (category: BentoCategory) => boolean;
  isSelectAll: (category: BentoCategory) => boolean;
  handleSelectAll: (e: CustomEvent, category: BentoCategory) => void;
  isSelected: (chip: BentoCategory) => boolean;
  selectChip: (Chip: BentoCategory) => void;
}

const DetailedCategoryFormComponent = ({
  topLevelCategories,
  expandCategory,
  isSelectAll,
  handleSelectAll,
  isSelected,
  selectChip,
}: Props) => {
  return (
    <Grid container spacing={2}>
      {topLevelCategories?.map((category, index) => (
        <Grid item xs={12} md={6} lg={4} key={category.id}>
          <Accordion
            sx={{
              boxShadow: "none",
            }}
            defaultExpanded={expandCategory(category)}
          >
            <AccordionSummary
              sx={{ background: "none", backgroundColor: "none", height: 20 }}
              expandIcon={
                <Box component="i" className="fa-regular fa-chevron-down" />
              }
            >
              <Grid container alignItems="center" gap={1}>
                <Typography>
                  <strong>{category?.name}</strong>
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox size="small" checked={isSelectAll(category)} />
                  }
                  label="Select All"
                  onChange={(e) => handleSelectAll(e, category)}
                  onClick={(e) => e.stopPropagation()}
                />
              </Grid>
            </AccordionSummary>

            <AccordionDetails sx={{ mt: 0 }}>
              <Grid container gap={2}>
                {category?.subcategories?.map((subcategory, index) => (
                  <Chip
                    key={index}
                    sx={[
                      styles.hover,
                      isSelected(subcategory) ? styles.selected : {},
                    ]}
                    label={subcategory.name}
                    onClick={() => selectChip(subcategory)}
                    size="small"
                  />
                ))}
                <Chip
                  key={index}
                  sx={[
                    styles.hover,
                    isSelected(category) ? styles.selected : {},
                  ]}
                  label={`Other`}
                  onClick={() => selectChip(category)}
                  size="small"
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
};

export default DetailedCategoryFormComponent;
