import { grey } from "@mui/material/colors";

const styles = {
  button: {
    textTransform: "none",
    color: "black",
    border: `1px solid ${grey[500]}`,
  },
  textField: {
    mb: 2,
    "& .MuiInputBase-root": { backgroundColor: "white" },
    "& .MuiFormHelperText-root": { color: "error.main" },
  },
  delete: {
    color: "secondary.main",
    fontSize: 12,
  },
  nicheText: {
    color: "black",
    fontSize: 14,
    fontWeight: 600,
    textTransform: "capitalize",
  },
  error: {
    color: "error.main",
    mt: -2,
  },
  linkAdornment: {
    "& .MuiTypography-root": {
      color: grey[900],
      fontSize: { xs: 12, md: 14 },
    },
  },
};

export default styles;
